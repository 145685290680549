import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, IconButton, Slider, Typography } from '@mui/material'
import { GridArrowDownwardIcon } from '@mui/x-data-grid'
import { t } from 'i18next'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import Map from '../tracks/Map'
import { useMutation } from 'react-query'
import MapDetails from './MapDetails'
import { CheckPoint, FinishLine, TTransposedMoment } from '../../shared/types/training.type'
import { TTransposedTraining } from '../../shared/types/transposedTraining.type'
import axios, { AxiosResponse } from 'axios'
import { Pause, PlayCircle, Start } from '@mui/icons-material'
import { getTime } from '../../shared/utils/MapSection'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { convertSpeed, getConvertedSpeed, getSpeedForChart } from '../../shared/utils/conversion'
import { get, isEmpty, toNumber } from 'lodash'
import MapControlBar from './MapControlBar'
import { TAverageChronology } from '../../shared/types/exam.type'
import { LatLng } from 'use-places-autocomplete'
import dayjs from 'dayjs'
import Duration from 'dayjs/plugin/duration'
import BaseAccordion from '../MyTrainings/BaseAccordion'
import { useAuth } from '../../hooks/useAuth'
import { MapColorSpeedConfig } from '../../shared/types/trainingSetting.type'
let colormap = require('colormap')

dayjs.extend(Duration)
type MapSectionProps = {
    transposedFile?: string,
    chronology?: TAverageChronology[],
    flIndex?: number | null,
    setDistance: (distance?: string) => void,
    setSpeed: (val: number[]) => void,
    setBpm: (val: number[]) => void
    setDistanceRaw: (val: number[]) => void,
    setTime: (val: number[]) => void,
    setStrideFrequency: (val: number[]) => void,
    setStrideLength: (val: number[]) => void,
    onIndexChange: (index: number) => void,
    setIsLoading: (value: boolean) => void,
    isFinishLineActive: boolean,
    enableCheckbox: boolean,
    showSave: boolean,
    enabled: boolean,
    finishLine?: FinishLine,
    checkPoints?: CheckPoint[],
    children?: React.ReactNode

}

export type DetailInfoProps = {
    title: string,
    value?: number | string,
    icon: string,
}
const colors = ["#e0e0e0", "#b3a400", "#f7911a", "#99141a"]
const CHECKBOX_KEY = 'display_section_map'

function MapSection({ children, checkPoints, finishLine, enabled, showSave, enableCheckbox, transposedFile, chronology, flIndex, setDistance, setSpeed, setBpm, setDistanceRaw, setTime, setStrideFrequency, setStrideLength, onIndexChange, setIsLoading, isFinishLineActive }: MapSectionProps) {
    const [result, setResult] = useState<TTransposedTraining>()
    const [currentIndex, setCurrentindex] = useState(0)
    const [detailsInfo, setDetailInfo] = useState<DetailInfoProps[]>()
    const transposedMutation = useMutation((): Promise<AxiosResponse<TTransposedTraining>> => axios.get(transposedFile as string))
    const [bgStr, setBgStr] = useState('')
    const mapRef = useRef(null)
    const metric = useSelector<RootState>(state => state.user.user?.unit_system) as "metric" | "imperial" | undefined
    const [latLngs, setLatLngs] = useState<(google.maps.LatLngLiteral & { color: string })[]>([])
    const [play, setIsPlay] = useState(false)
    const [center, setCenter] = useState<LatLng>()
    const colorMapType = useSelector<RootState>(state => state.user.user?.training_settings?.map_colors_type) as 'gait' | 'speed'
    const { updateUserTraingSetting, getSpeedUnit } = useAuth()
    const map_colors_speed_configuration = useSelector<RootState>(state => state.user.user?.training_settings?.map_colors_speed_configuration) as MapColorSpeedConfig
    let id = useSelector<RootState>(state => state.user.user?.training_settings?.id) as number


    useEffect(() => {
        if (transposedFile) {
            setIsLoading(true)
            transposedMutation.mutateAsync()
                .then((res) => {
                    setResult(res.data)
                    // setCenter({ lat: res?.data?.latitude[0] ?? 0, lng: res?.data?.longitude[0] ?? 0 })
                    calculateDistance(res.data?.distance)
                    setSpeed(res?.data?.speed ?? [])
                    setBpm(res?.data?.bpm ?? [])
                    setDistanceRaw(res.data?.distance ?? [])
                    setTime(res.data?.timestamp ?? [])
                    setStrideFrequency(res.data?.pace ?? [])
                    setStrideLength(res.data?.amplitude ?? [])

                }).catch(err => {
                    console.log({ err });

                }).finally(() => {
                    setIsLoading(false)
                })
        }
    }, [transposedFile])

    useEffect(() => {
        if (colorMapType === 'gait') {
            if (chronology && chronology?.length > 0) {
                let gradients: string[] = []
                let i = 0

                chronology.forEach((element, index) => {
                    let gr1 = `${colors[toNumber(element.gait)]} ${i}%`
                    let gr2 = `${colors[toNumber(element.gait)]} ${i + element.percentage}%`
                    i += element.percentage
                    gradients.push(`${gr1},${gr2}`)
                });
                setBgStr(`linear-gradient(90deg, ${gradients.join(',')})`)
            }
        } else {
            if (result?.speed && result?.speed?.length > 0) {
                let gradients: string[] = []

                result?.speed.forEach(speed => {
                    let gr1 = getColorForSpeed(speed)
                    gradients.push(`${gr1}`)
                })
                setBgStr(`linear-gradient(90deg, ${gradients.join(',')})`)
            }
        }

    }, [chronology, colorMapType, result?.speed])


    const calculateDistance = (distance: number[]) => {
        if (distance.length > 0) {
            let a = distance[distance.length - 1]
            let b = distance[0]

            setDistance(convertSpeed(metric, a - b))
        }
    }

    useEffect(() => {
        if (latLngs.length > 0) {
            /* @ts-ignore */
            mapRef?.current?.drawTraject(latLngs)
        }
    }, [latLngs])

    //Get total time of timestamp
    const getTimeStampLength = useCallback((): number => {
        if (result) {
            return result?.timestamp[result?.timestamp?.length - 1] - result?.timestamp[0]
        }
        return 0
    }, [result?.timestamp])

    //Get current time by index
    const getCurrentTime = () => {
        if (result) {
            return result?.timestamp[currentIndex] - result?.timestamp[0]
        }
        return 0
    }

    const getTimeStampArrLength = () => {
        return result?.timestamp ? result?.timestamp?.length - 1 : 0
    }

    useEffect(() => {
        if (isEmpty(result?.timestamp)) return

        let indexLength = getTimeStampArrLength()

        let bounds = handleLatLngs(indexLength)
        /* @ts-ignore */
        let center = mapRef.current.getCenter(bounds)
        setCenter(center)
    }, [result?.timestamp, colorMapType])


    const getColor = (allure: number[], speed: number[], index: number) => {
        if (colorMapType === 'gait') {
            return get(colors, `[${allure[index]}]`, colors[0])
        }
        if (colorMapType === 'speed') {
            let currentSpeed = speed[index]
            return getColorForSpeed(currentSpeed)
        }
    }

    function getColorForSpeed(speed: number) {

        var percentage = speed / map_colors_speed_configuration.max_value;
        var colors = colormap({
            colormap: 'jet',
            nshades: 100,
            format: 'hex',
            alpha: 1
        });
        if (speed > map_colors_speed_configuration.max_value) return colors[99];

        var percentage = Math.floor(((speed) / (map_colors_speed_configuration.max_value - map_colors_speed_configuration.min_value)) * 100);

        return colors[percentage];
    }

    const handleLatLngs = (index: number) => {
        let latSlice = result?.latitude ? result?.latitude.slice(0, index) : []
        let lngSlice = result?.longitude ? result?.longitude.slice(0, index) : []
        let allure = result?.allure ? result?.allure : []
        let speed = result?.speed ? result?.speed : []
        let arr: (google.maps.LatLngLiteral & { color: string })[] = []
        if (!isEmpty(latSlice)) {
            latSlice.forEach((lat, i) => {
                let color = getColor(allure, speed, i)
                arr.push({ lat, lng: lngSlice[i], color })
            })

            setLatLngs(arr)
            return arr
        }
    }

    const drawCps = (current: number) => {
        /* @ts-ignore */
        let cps = checkPoints?.filter(e => e.index_moment <= current)

        /* @ts-ignore */
        mapRef.current.addCheckPoints(cps)

    }

    const drawFinishLine = (current: number) => {
        if (finishLine?.index_moment) {

            if (finishLine.index_moment <= current) {
                /* @ts-ignore */
                mapRef.current.drawFinishLine(finishLine.finish_line)
            } else {
                /* @ts-ignore */
                mapRef.current.drawFinishLine(null)

            }
        }
    }

    useEffect(() => {

        handleLatLngs(currentIndex)
        drawFinishLine(currentIndex)
        drawCps(currentIndex)
        onIndexChange(currentIndex)
        setDetailInfo([
            {
                title: 'map:allure',
                value: result?.allure[currentIndex] ? t("intervals:gait:" + result?.allure[currentIndex]) : ' - ',
                icon: 'eq-icon-2 eq-horsestep-galloping'
            },
            {
                title: 'graph:vitesse',
                value: (getSpeedForChart(result?.speed[currentIndex] ?? 0, getSpeedUnit()?.value ?? '') ?? '0') + ' ' + t(getSpeedUnit()?.key ?? ""),
                icon: 'eq-icon-2 eq-horse-fullspeed'
            },
            {
                title: 'graph:distance',
                value: convertSpeed(metric, result?.distance[currentIndex]),
                icon: 'eq-icon-2 eq-direction'
            },
            {
                title: 'map:heartRate',
                value: result?.bpm[currentIndex] ? result?.bpm[currentIndex] + ' Bpm' : '0 Bpm',
                icon: 'eq-icon-2 eq-heartbeat'
            },
            {
                title: 'map:strideFrequency',
                value: (result?.pace[currentIndex] ?? 0) + ' str/sec',
                icon: 'eq-icon-2 eq-horseshoe-refresh'
            },
        ])
    }, [currentIndex, checkPoints, finishLine])

    //Timer for 1sec
    useEffect(() => {
        const interval = setInterval(() => {
            myCallback()
        }, 500);
        return () => clearInterval(interval);
    }, [play]);

    const myCallback = () => {
        if (play) {
            if (currentIndex < getTimeStampArrLength()) setCurrentindex(prev => prev + 1)
        }
    }





    return (

        <BaseAccordion
            title='live.map'
            enableCheckBox={enableCheckbox}
            showInfo={false}
            showSave={false}
            defaultChecked={enabled}
            onChange={value => { updateUserTraingSetting(id, { [CHECKBOX_KEY]: value }) }}
        >

            <>
                {children}
                <MapDetails details={detailsInfo}></MapDetails>
                <Box my={2}></Box>
                {!transposedMutation.isLoading && <Map ref={mapRef} center={center} height='500px'></Map>}

                <MapControlBar
                    isFinishLineActive={isFinishLineActive}
                    play={play}
                    changePlay={val => setIsPlay(val)}
                    currentTime={getTime(getCurrentTime())}
                    totalTime={getTime(getTimeStampLength())}
                    max={getTimeStampArrLength()}
                    currentIndex={currentIndex}
                    onRangeChange={val => setCurrentindex(val)}
                    flIndex={flIndex}
                    bg={bgStr}
                ></MapControlBar>
            </>
        </BaseAccordion>





    )
}

export default memo(MapSection)