import React from 'react'
import { TcomputedKeyParam } from '../../shared/types/computedKeyParam.type'
import { Box, FormControl, InputLabel, ListSubheader, MenuItem, Select, Typography } from '@mui/material'
import { get } from 'lodash'

ParamSection.defaultProps = {
    hideSelect: false
}

function ParamSection({ keyParam, groupedKeys, onChangekey, hideSelect }: {
    keyParam: TcomputedKeyParam,
    groupedKeys?: Record<string, TcomputedKeyParam[]>
    onChangekey?: (newId: string, oldParam: TcomputedKeyParam) => void,
    hideSelect?: boolean
}) {
    const getText = () => {
        let value = get(keyParam, 'formatted_value', "")
        let unit = get(keyParam, 'key_parameter_layout.insight_parameter.unit_translated', "")

        if (!value) {
            return '-'
        }
        return value.concat(' ').concat(!['hh:mm:ss', "mm:ss.ms"].includes(unit) ? unit : '')

    }

    return (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} height={'100%'}>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} flex={1}>
                <div className="icon" >
                    <span className={`eq-${keyParam?.key_parameter_layout?.insight_parameter?.icon} eq-icon-2`}>
                        <span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span></span>
                </div>
                <Typography variant='body2' fontWeight={500} >{getText()}</Typography>
                <Typography variant='body2' color={'GrayText'} textAlign={'center'}>{get(keyParam, 'key_parameter_layout.insight_parameter.name_translated', "")}</Typography>
            </Box>


            {
                !hideSelect && <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 200 }} size='small'>
                    <Select defaultValue="" id="grouped-select"
                        value={keyParam.key_parameter_layout.insight_parameter['@id']}
                        onChange={e => typeof onChangekey === 'function' && onChangekey(e.target.value, keyParam)}
                    >
                        
                        {
                            groupedKeys && Object.values(groupedKeys).map(values => (
                                values.map(value => (
                                    <MenuItem value={value.key_parameter_layout.insight_parameter['@id']}>
                                        <Typography variant='body2' textOverflow={'ellipsis'} whiteSpace={'nowrap'} overflow={'hidden'}>
                                            {value.key_parameter_layout.insight_parameter.name_translated}
                                        </Typography>
                                    </MenuItem>

                                ))
                            ))
                        }


                    </Select>
                </FormControl>
            }

        </Box>
    )
}

export default ParamSection