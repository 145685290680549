import { Box, Skeleton } from '@mui/material'
import React from 'react'

function TrackViewSkeleton() {
    return (
        <Box>
            <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} gap={1}>
                <Skeleton variant='rounded' width={400}></Skeleton>
                <Box display={'flex'} gap={2}>
                <Skeleton variant='rounded' width={100}></Skeleton>
                <Skeleton variant='rounded' width={100}></Skeleton>
                <Skeleton variant='rounded' width={100}></Skeleton>
                <Skeleton variant='rounded' width={100}></Skeleton>
                </Box>
            </Box>
            <Box display={'flex'} gap={2} sx={{mt:3}}>
                <Skeleton variant='rounded' width={100}></Skeleton>
                <Skeleton variant='rounded' width={100}></Skeleton>
                </Box>
            
            <Skeleton variant='rounded' width={'100%'} height={'250px'} sx={{mt:3}}></Skeleton>
            <Skeleton variant='rounded' width={'100%'} height={'250px'} sx={{mt:3}}></Skeleton>
            
        </Box>
    )
}

export default TrackViewSkeleton