import React, { createRef, useMemo, useRef, useState } from 'react'
import { CheckPoint } from '../../shared/types/training.type'
import { Box, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { startTimeFrom0 } from '../../shared/utils/MapSection'
import AddMinSpeed from './AddMinSpeed'
import CheckPointsGraphDisplaySettings from './CheckPointsGraphDisplaySettings'
import { Scale } from '../../pages/trainings/ExamView'
import { Layers } from '@mui/icons-material'
import { t } from 'i18next'
import { scale } from '../../enums/scale.enum'
import CheckPointsChart from './charts/CheckPointsChart'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { CheckPointsGrid } from './charts/CheckPointsGrid'
import { CheckPointsOptionsChart } from './charts/CheckPointsOptionsChart'

type CheckpointsSectionProps = {
    editable?: boolean,
    checkPoints: CheckPoint[],
    time: number[]
    rawDistance: number[]
    speed: number[]
    bpm: number[],
    scaleOption: Scale,
    onChangeScale: (value: Scale) => void,
    isFinishlineActive: boolean,
    flIndex?: number | null
}
function CheckpointsSection({ editable, checkPoints, time, speed, bpm, rawDistance, onChangeScale, isFinishlineActive, flIndex }: CheckpointsSectionProps) {

    const ref = useRef<any>(null)
    const chartRef = useRef<any>(null)

    const [scaleOption, setScaleOption] = useState<Scale>('DISTANCE')
    return (
        <Stack direction={'column'} spacing={2}>

            {/* Scale Section */}
            <Box display={'flex'} justifyContent={'end'} gap={1}>
                {editable && <CheckPointsGraphDisplaySettings></CheckPointsGraphDisplaySettings>}
                <Box display={'flex'} flexDirection={'column'} bgcolor={'var(--lightGray)'} borderRadius={3} p={1}>
                    <Typography variant='caption' color={'GrayText'} display={'flex'} alignItems={'center'} gap={'3px'}>
                        <Layers sx={{ width: 14, height: 14 }}></Layers>
                        {t('graph:scale')} </Typography>
                    <RadioGroup

                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                    >
                        {scale.map(s => (
                            <FormControlLabel key={s.value} value={s.value} checked={scaleOption === s.value} control={<Radio onChange={e => {

                                setScaleOption(e.target.value as Scale)
                            }} size='small' />} label={<Typography variant='caption' fontWeight={500}>{t(s.key)}</Typography>} />

                        ))}

                    </RadioGroup>
                </Box>
            </Box>

            {
                editable &&
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <AddMinSpeed></AddMinSpeed>

                </Box>
            }
            <CheckPointsChart checkpoints={checkPoints} distance={rawDistance} time={time} speed={speed} bpm={bpm} scaleOption={scaleOption} isFinishLineActive={isFinishlineActive} flIndex={flIndex}></CheckPointsChart>
            <CheckPointsOptionsChart ref={chartRef} onHover={(cpId) => { ref.current?.highlightRow(cpId) }} defaultScale={scaleOption} checkPoints={checkPoints} time={time} distance={rawDistance}></CheckPointsOptionsChart>
            <CheckPointsGrid onRowHover={(cpId) => { chartRef.current?.highlightPoint(cpId) }} ref={ref} CheckPoints={checkPoints}></CheckPointsGrid>
        </Stack>
    )
}

export default CheckpointsSection