import React, { useCallback, useEffect, useMemo } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { TAverageGait } from '../../shared/types/exam.type';
import { isEmpty } from 'lodash';
import { t } from 'i18next';

const colors = ["#e0e0e0", "#b3a400", "#f7911a", "#99141a"]

function DonutActivityZone({ data }: { data?: TAverageGait[] }) {


    const formatData = useCallback(() => {
        if (!isEmpty(data)) {
            return data?.map(item => ({
                name: t('intervals:gait:' + item?.gait) +
                    ` ( ${item?.percentage} %) <br>
                 ${Math.trunc((item?.time_seconds ?? 0) / 60)} min ${item?.distance_formatted && ' - ' + item?.distance_formatted} <br>
                  ${item?.speed_formatted} ${item?.average_heart_rate !== 0 ? ' - ' + item?.average_heart_rate + ' Bpm' : ''}`,
                y: item?.percentage,
                color: colors[item?.gait]
            }))
        }
    }, [data])

    const options = useMemo(() => {



        if (!isEmpty(data)) {
            return {
                chart: {
                    type: 'pie',
                    backgroundColor: 'transparent'
                },
                title: {
                    text: '',

                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        colorByPoint: true,
                        type: 'pie',
                        size: '70%',
                        innerSize: '60%',
                        dataLabels: {
                            enabled: true,
                            distance: '5%',
                            style: {
                                fontSize: '10px'
                            },
                        }
                    }
                },
                xAxis: {
                    visible: false,
                },
                yAxis: {
                    visible: false
                },
                series: [{
                    data: formatData()
                }]
            }
        } else {
            return null
        }
    }, [data])



    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}

export default DonutActivityZone