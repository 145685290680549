import { Box, Button, Checkbox, Divider, Pagination, Stack, TextField, Typography } from '@mui/material'
import { t } from 'i18next'
import { chunk, isEmpty } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { TChannel, TChannelType } from '../../shared/types/Channel.type'
import ChannelItem from './ChannelItem'
import { CheckBox } from '@mui/icons-material'
import Channels from './Channels'

type NewChannelStep1Props = {
    step: number,
    onSave: () => void,
    channels: TChannel[],
    selectedChannels: string[],
    onChannelsChange: (values: string[]) => void,
    onBack: () => void,
    createNewChannel: () => void,
    deleteChannel: (id: number,type:TChannelType) => void,
    editChannel: (item: TChannel) => void,
    isLoading: boolean,
    isDeleting: boolean,
}

function NewConfigStep2({ step, onSave, channels, selectedChannels, onChannelsChange, onBack, createNewChannel, deleteChannel, editChannel, isLoading, isDeleting }: NewChannelStep1Props) {

    
    const [currentChannels, setCurrentChannel] = useState<TChannel[]>([])
    const [page, setPage] = React.useState(1);

    const paginatedChannels = useMemo(() => {
        return chunk(channels, 2)
    }, [channels])

    useEffect(() => {
        if (isEmpty(paginatedChannels)) return
        setCurrentChannel(paginatedChannels[page - 1])
    }, [page, paginatedChannels])

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <Box mt={2}>
            <Divider sx={{ my: 2 }}></Divider>
            <Channels
                selectable
                selectedChannels={selectedChannels}
                onSelectionChange={(items)=>onChannelsChange(items)}
                channels={channels}
                createNewChannel={createNewChannel}
                loading={isLoading}
                onDelete={deleteChannel}
                onEdit={(item) => { editChannel(item) }}
                isDeleting={isDeleting}
            />


            {/* <Box width={'100%'}>
                <Stack direction={'column'} spacing={3} mt={3}>
                    {
                        isEmpty(channels) ?
                            <Box display={'flex'} justifyContent={'center'} py={1}>
                                <Typography variant='subtitle2'>{t('notification:noChannel')}</Typography>
                            </Box>
                            :
                            currentChannels.map((channel, i) => (
                                <>
                                    <ChannelItem channel={channel} key={channel.id} hideMenu={true}>
                                        <>
                                            <Checkbox checked={selectedChannels.includes(channel?.['@id'])} onChange={(_, checked) => handleChange(checked, channel['@id'])}></Checkbox>
                                        </>
                                    </ChannelItem>
                                    {currentChannels.length - 1 !== i && <Divider sx={{ my: 2 }}></Divider>}
                                </>
                            ))}

                </Stack>
                <Divider sx={{ my: 1 }}></Divider>
                <Box display={'flex'} justifyContent={'center'} p={2}>
                    <Pagination onChange={handleChangePage} count={paginatedChannels.length}></Pagination>
                </Box>
            </Box> */}
            <Divider sx={{ my: 1 }}></Divider>
            <Box display={'flex'} justifyContent={'space-between'} >
                <Button onClick={onBack} disabled={step === 0} color='inherit' >{t('button:back')}</Button>
                <Button onClick={onSave} disabled={isEmpty(selectedChannels)} variant='contained'>{t('button:next')}</Button>
            </Box>
        </Box>

    )
}

export default NewConfigStep2