import React, { useEffect, useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { Ttrack, trackSurface } from '../../shared/types/tracks.type'
import { BackHandOutlined, CommentOutlined, GrassRounded } from '@mui/icons-material'
import { LatLng, getGeocode, getLatLng } from 'use-places-autocomplete'
import { hands } from '../../enums/trackGroup.enums'
import { t } from 'i18next'
import { get, isEmpty } from 'lodash'
import MapImage from './MapImage'

type TrackCardProps = {
    item?: Ttrack,
    surfaces?: trackSurface[]
}

function TrackCard({ item, surfaces }: TrackCardProps) {
    const [cords, setCords] = useState<LatLng>()
    useEffect(() => {
        if (!item?.address) return
        (async () => {
            let res = await getGeocode({ address: item?.address })
            const { lat, lng } = getLatLng(res[0]);
            setCords({ lat, lng })
        })()
    }, [item?.address])

    const getSurface = (): string => {
        if (!isEmpty(surfaces)) {
            let surface = surfaces?.find(e => get(e, '@id') === get(item, 'track_surface[@id]'))
            if (surface) {
                return surface.translation_key?.replaceAll('.', ':') ?? ''
            } else {
                return '-'
            }

        } else {
            return '-'
        }

    }

    const getHandTurn = (): string => {
        let hand = hands.find(e => e.value === item?.hand_turn)
        if (hand) {
            return hand.key
        } else {
            return '-'
        }
    }

    return (
        <Box sx={{ cursor: 'pointer' }} >
            <MapImage checkPoints={item?.checkpoints} finishLine={item?.finish_line} center={cords}></MapImage>
            <Typography variant='subtitle2' mt={1} fontWeight={600} sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item?.name}</Typography>
            <Stack direction={'column'} spacing={'4px'} position={'relative'} overflow={'hidden'} >
                <Box display={'flex'} gap={1} alignItems={'center'}>
                    <GrassRounded sx={{ width: 17, height: 17, color: 'gray' }}></GrassRounded>
                    <Typography variant='caption' color={'GrayText'}>{t(getSurface())}</Typography>
                </Box>
                <Box display={'flex'} gap={1} alignItems={'center'}>
                    <BackHandOutlined sx={{ width: 17, height: 17, color: 'gray' }}></BackHandOutlined>
                    <Typography variant='caption' color={'GrayText'}>{t(getHandTurn())}</Typography>
                </Box>
                <Box display={'flex'} gap={1} alignItems={'center'}>
                    <CommentOutlined sx={{ width: 17, height: 17, color: 'gray' }}></CommentOutlined>
                    <Typography variant='caption' color={'GrayText'} sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', lineClamp: 2 }}>{item?.comment}</Typography>
                </Box>
            </Stack>
        </Box>
    )
}

export default TrackCard