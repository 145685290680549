import { Grid, Skeleton } from '@mui/material'
import React from 'react'

function EditTrainingsItemSkeleton() {
    return (
        <Grid key={2} container spacing={3} my={2}>
            {[...Array(6)].map(i => (
                <Grid key={i} item md={2} sm={2}>
                    <Skeleton height={50}></Skeleton>
                </Grid>
            ))}
        </Grid>
    )
}

export default EditTrainingsItemSkeleton