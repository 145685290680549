import { Box, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridRowClassNameParams, GridValueGetterParams } from '@mui/x-data-grid';
import { t } from 'i18next';
import React, { forwardRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { convertStrideLength, formatMetricIntoImperialDistance, getSpeedForChart } from '../../../shared/utils/conversion';
import { CheckPoint } from '../../../shared/types/training.type';
import { capitalize, get, toNumber } from 'lodash';
import { useAuth } from '../../../hooks/useAuth';
import I18nDataGrid from '../../global/I18nDataGrid';

export const CheckPointsGrid = forwardRef<any, { CheckPoints: CheckPoint[], onRowHover: (cpId: number) => void }>(({ CheckPoints,onRowHover }, ref) => {
  const { getSpeedUnit } = useAuth()
  const metric = useSelector<RootState>(state => state.user.user?.unit_system) as "metric" | "imperial" | undefined
  
  const [hovered, setHovered] = useState<number | null>(null)
  React.useImperativeHandle(ref, () => ({
    highlightRow(cpId: number | null) {
      setHovered(cpId)
    },
  }));

  const columns: GridColDef[] = [
    {
      field: 'idx',
      renderHeader: () => { return "" },
      renderCell: (params: GridRenderCellParams<CheckPoint>) => {
        return params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
      },

    },
    {
      field: 'cps',
      renderHeader: () => (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
          <Typography >{capitalize(t('tracks:checkpoints'))}</Typography>
        </Box>
      ), flex: 1,
      valueGetter: (val: GridValueGetterParams<CheckPoint>) => {
        return val.row.checkpoint?.name ?? ' - '
      }
    },
    {
      field: 'gait',
      renderHeader: () => (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
          <Typography >{capitalize(t('map:allure'))}</Typography>
        </Box>
      ), flex: 1,
      valueGetter: (val: GridValueGetterParams<CheckPoint>) => {
        return t('intervals:gait:' + val.row.gait)
      }
    },
    {
      field: 'pace',
      renderHeader: () => (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
          <Typography >{t('intervals:pace')}</Typography>
          <Typography variant='caption'>({t(getSpeedUnit()?.key ?? "")})</Typography>

        </Box>
      ),
      flex: 1,
      valueGetter: (val: GridValueGetterParams<CheckPoint>) => {
        return getSpeedForChart(val.row.speed, getSpeedUnit()?.value ?? "")
      }
    },

    {
      field: 'mean_heart_rate',
      renderHeader: () => (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
          <Typography >{t('intervals:averageFC')}</Typography>
          <Typography variant='caption'>({t('intervals:bpm')})</Typography>
        </Box>
      ),
      flex: 1,
      valueGetter: (val: GridValueGetterParams<CheckPoint>) => {
        return val.row.heart_rate
      }
    },
    {
      field: 'mean_stride_frequency',
      renderHeader: () => (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
          <Typography >{t('intervals:averageCad')}</Typography>
          <Typography variant='caption'>({t('intervals:stepPerSecond')})</Typography>
        </Box>
      ),
      flex: 1,
      valueGetter: (val: GridValueGetterParams<CheckPoint>) => {
        return val.row?.stride_frequency ?? "-"
      }


    },
    {
      field: 'mean_stride_length',
      renderHeader: () => (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
          <Typography >{t('intervals:averageAmpl')}</Typography>
          <Typography variant='caption'>({metric === 'metric' ? t('compare:meters') : t('scale:pieds')})</Typography>
        </Box>
      ),
      flex: 1,
      valueGetter: (val: GridValueGetterParams<CheckPoint>) => {
        return convertStrideLength(metric, val.row.stride_length) ?? '-'
      }

    },
  ];
  const handlePopperOpen = (event: any) => {
    if (event) {
      let id = event.currentTarget?.dataset?.id
      onRowHover(toNumber(id))

    }

  };
  return (
    <div>
      <I18nDataGrid
        columns={columns}
        rows={CheckPoints}
        getRowClassName={(param: GridRowClassNameParams<CheckPoint>) => param.row.id === hovered ? 'row-active' : ''}
        componentsProps={{
          row: {
            onMouseOver: handlePopperOpen,
          }
        }}
        getRowId={item => toNumber(item?.id)}
        disableRowSelectionOnClick
      />
    </div>
  )
})

