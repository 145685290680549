export const trackGroup = [
    {
        key: 'sand',
        value: 'sand'
    },
    {
        key: 'turf',
        value: 'turf'
    },
]

export const trackSurfaces = [
    {
        key: 'qualification:trackSurface:grass',
        value: 'grass'
    },
    {
        key: 'qualification:trackSurface:turf',
        value: 'turf'
    },
    {
        key: 'qualification:trackSurface:dirt',
        value: 'dirt'
    },
    {
        key: 'qualification:trackSurface:sand',
        value: 'sand'
    },
    {
        key: 'qualification:trackSurface:allWeather',
        value: 'all-weather'
    },
    {
        key: 'qualification:trackSurface:woodChip',
        value: 'wood-chips'
    },
    {
        key: 'qualification:trackSurface:machefer',
        value: 'machefer'
    },
    {
        key: 'qualification:trackSurface:polytrack',
        value: 'polytrack'
    },
]

export const hands = [
    {
        key: 'tracks:left',
        value: -1
    },
    {
        key: 'tracks:right',
        value: 1
    },
    {
        key: 'tracks:straightLine',
        value: 0
    },
]