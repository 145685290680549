import { useMutation } from "react-query"
import { User } from "../api/user"
import { useState } from "react"
import { Job } from "../shared/types/job.type"
import { AxiosError } from "axios"
import { get } from "lodash"

export const useJobs = () => {
    const [data, setData] = useState<Array<Job> | []>([])

    const fetchJobsMutation = useMutation(() => User.getJobs())
    const getJobs = () => {
        fetchJobsMutation.mutateAsync()
            .then(({ data }) => {
                let items = get(data, 'hydra:member', []) as Array<Job> | []
                items = items.map(item => ({
                    ...item,
                    translation_key: item.translation_key.split('.').join(':')
                }))
                setData(items)
            }).catch((err: AxiosError) => {
                console.log({ 'Job Error Message': err.message });

            })
    }

    return {
        getJobs,
        Jobs: data,
        isLoading: fetchJobsMutation.isLoading
    }

}