import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Theats } from '../../../shared/types/training.type'
import { mergeArr, startTimeFrom0 } from '../../../shared/utils/MapSection'
import { Scale } from '../../../pages/trainings/ExamView'
import { get, isEmpty, isUndefined, toNumber } from 'lodash'
import { Stack } from '@mui/material'
import HighchartsReact from 'highcharts-react-official'
import Highcharts, { PlotLineOrBand } from 'highcharts'
import { t } from 'i18next'
import { convertSpeed, getSpeedForChart, getSpeedTooltipForChart } from '../../../shared/utils/conversion'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import dayjs from 'dayjs'
import I18nDataGrid from '../../global/I18nDataGrid'
import HeatGrid from './HeatGrid'
import { useChart } from '../../../hooks/useChart'
import { useAuth } from '../../../hooks/useAuth'
import Duration from 'dayjs/plugin/duration'

dayjs.extend(Duration)

type IntervalTrainingProps = {
  speed: number[]
  bpm: number[],
  distance: number[],
  heats?: Theats[]
  time: number[],
  flIndex?: number | null,
  scaleOption: Scale,
  isFinishLineActive: boolean,

}

function IntervalTraining({ speed, bpm, heats, time, scaleOption, distance, flIndex, isFinishLineActive }: IntervalTrainingProps) {
  const chartRef = useRef<HighchartsReact.RefObject>(null)
  const metric = useSelector<RootState>(state => state.user.user?.unit_system) as "metric" | "imperial" | undefined
  const speedUnit = useSelector<RootState>(state => state.user.user?.default_speed_unit)
  const [hoveredHeat, setHoveredHeat] = useState<number | null>(null)
  const { addFinishLine } = useChart()
  const [svg, setSvg] = useState<any[] | undefined>([])
  const { getSpeedUnit } = useAuth()
  const formattedTime = useMemo(() => {
    return startTimeFrom0(time)
  }, [time])

  //get heat range to slice the time and distance array
  const heatIndex = useMemo((): { start_index?: number, end_index?: number } => {
    if (isEmpty(heats)) return { start_index: undefined, end_index: undefined }
    let firstHeat = heats && heats[0].index_moment_start
    let lastHeat = heats && heats[heats.length - 1].index_moment_end

    return { start_index: firstHeat, end_index: lastHeat }
  }, [heats])

  //create plotBands from heats
  const plotBands = useMemo(() => {
    if (heats && heats.length > 0) {
      return heats.map((heat, index) => {

        return {
          id: heat.id,
          color: heat.is_work ? 'rgba(237, 231, 225,0.8)' : 'rgba(237, 231, 225,0.4)',
          label: {
            text: index + 1,
          },
          from: scaleOption === 'DISTANCE' ? distance[heat.index_moment_start] : time[heat.index_moment_start],
          to: scaleOption === 'DISTANCE' ? distance[heat.index_moment_end] : time[heat.index_moment_end],
          events: {
            mouseover: function () {
              /* @ts-ignore */

              /* @ts-ignore */
              this.style.fill = 'lightgray'
              /* @ts-ignore */
              this['mydata:id'] = heat.id
              setHoveredHeat(heat.id)

            },
            mouseout: function () {
              /* @ts-ignore */
              this.style.fill = heat.is_work ? 'rgba(237, 231, 225,0.8)' : 'rgba(237, 231, 225,0.4)'
              /* @ts-ignore */
              this['mydata:id'] = heat.id
              setHoveredHeat(null)

            },
          }
        }
      })
    } else {
      return []
    }
  }, [heats, scaleOption])


  const timeSlice = useMemo((): number[] => {
    if (formattedTime.length > 0 && Object.values(heatIndex).every(e => e)) {
      return formattedTime.slice(heatIndex.start_index, heatIndex.end_index)
    } else {
      return []
    }
  }, [formattedTime, heatIndex])

  const distanceSlice = useMemo((): number[] => {
    if (distance.length > 0 && Object.values(heatIndex).every(e => !isUndefined(e))) {
      return distance.slice(heatIndex.start_index, heatIndex.end_index)
    } else {
      return []
    }
  }, [distance, heatIndex])

  useEffect(() => {

    if (!isFinishLineActive) return

    try {
      if (svg && !isEmpty(svg)) {
        svg.forEach((svg: any) => svg.destroy())
        setSvg([])
      }
    } catch (err) {

    }

    let data = addFinishLine(flIndex ?? null, scaleOption, distance, formattedTime, chartRef.current?.chart, 8)
    setSvg(data)
  }, [flIndex, scaleOption, formattedTime, distance, isFinishLineActive])

  const options = useMemo(() => {
    return {
      chart: {
        type: 'line',
        zoomType: "x",
        backgroundColor: 'transparent',
        animation: false,
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      legend: { enabled: false },

      yAxis: [{
        labels: {
          style: {
            color: 'white'
          },
          /* @ts-ignore */
          formatter: function () {
            /* @ts-ignore */
            return getSpeedForChart(toNumber(this.value), getSpeedUnit()?.value ?? "")
          }
        }, // Primary yAxis
        title: {
          text: t('graph:vitesse') + ` (${t(getSpeedUnit()?.key ?? "")})`,
          style: {
            color: "rgb(44, 175, 254)"
          }
        },
        opposite: false
      }, { // Primary yAxis
        title: {
          text: t('graph:bpm'),
          style: {
            color: 'rgba(245, 39, 39, 0.8)'
          }
        },
        labels: {
          style: {
            color: 'white'
          },
        },
        opposite: true
      }],
      xAxis: {
        plotBands,
        crosshair: {
          width: 1,
        },
        type: scaleOption === 'DISTANCE' ? 'linear' : 'datetime',
        labels: {
          style: {
            color: 'white'
          },
          /* @ts-ignore */
          formatter: function () {
            /* @ts-ignore */  
            return scaleOption === 'DISTANCE' ? convertSpeed(metric, this.value) : dayjs.duration(this.value, 'seconds').minutes() + ' mn'
          }
        }
      },
      tooltip: {
        shared: true,
        shadow: false,
        style: {
          fontWeight: 'bold',
          fontSize: '12px',
          backgound: 'transparent'
        },
        positioner: function () {
          return { x: get(this, 'chart.plotWidth', 0) - 80, y: -10 };
        },
        formatter: function (this: Highcharts.TooltipFormatterContextObject) {
          let str = ''
          str += this?.points && this?.points[0]?.y + " " + t('graph:bpm') + ' - '
          /* @ts-ignore */
          str += getSpeedTooltipForChart(getSpeedForChart(toNumber(this.points[1].y), getSpeedUnit()?.value), getSpeedUnit()?.value)
          return str

        },
      },
      series: [
        {
          yAxis: 0,
          data: mergeArr(scaleOption === 'DISTANCE' ? distanceSlice : timeSlice, speed),

          point: {

          },
        },
        {
          yAxis: 1,
          data: mergeArr(scaleOption === 'DISTANCE' ? distanceSlice : timeSlice, bpm),
          color: 'rgba(245, 39, 39, 0.8)',
          point: {
          },
        },
      ]
    }
  }, [formattedTime, scaleOption, distanceSlice, timeSlice, plotBands])

  const onRowHover = (item?: Theats, action?: 'entered' | 'leave') => {
    let chart = chartRef.current?.chart
    if (!item || !chart) return

    let plots = get(chart, 'xAxis[0].plotLinesAndBands', []) as Highcharts.PlotLineOrBand[]
    if (plots.length > 0) {
      let plot: Highcharts.PlotLineOrBand | undefined = plots.find(e => get(e, 'id') === item?.id)
      if (plot) {

        plot.svgElem.attr({ fill: action === 'entered' ? 'lightgray' : item.is_work ? 'rgba(237, 231, 225,0.8)' : 'rgba(237, 231, 225,0.4)' })
      }
    }

  }

  return (
    <Stack direction={'column'} spacing={3} mt={3}>
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        options={options}
        containerProps={{ style: { position: 'relative', height: '220px' } }}
      />
      <HeatGrid hoveredHeat={hoveredHeat} onRowHover={onRowHover} speedUnit={t(getSpeedUnit()?.key ?? "")} heats={heats}></HeatGrid>
    </Stack>
  )
}

export default IntervalTraining