import React, { forwardRef, memo, useMemo } from 'react'
import { TAnalyticParams } from '../../shared/types/analyticParameters.type'
import { Box, Button, Checkbox, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Typography } from '@mui/material'
import ListSkeleton from '../exams/loader/ListSkeleton'
import { capitalize, isEmpty, lowerCase } from 'lodash'
import { t } from 'i18next'




function AvailableParams({ groupedParams, loading, selectedParams, allParams, onParamCheck }: { groupedParams: Partial<Record<string, TAnalyticParams[]>>, loading: boolean, selectedParams: TAnalyticParams[], allParams: TAnalyticParams[], onParamCheck: (item: TAnalyticParams, checked: boolean) => void }) {



    //effects
    const mappedNames = useMemo(() => {
        if (isEmpty(selectedParams)) return []
        return selectedParams.map(e => lowerCase(e.name))
    }, [selectedParams])

    //fns
    return (
        <>
            <Box  sx={{ borderBlock: '1px solid #eee', flex: 1, overflowY: 'auto', maxHeight: 500, borderRight: '1px solid #eee', position: 'relative' }} >
                <List disablePadding sx={{ position: 'relative' }} className='parentList'>
                    {

                        loading ? <ListSkeleton height={80} length={8}></ListSkeleton> :
                            Object.keys(groupedParams).map(e => (

                                <>
                                    <ListSubheader className={e}>{t('dataParameters:groups:' + e)}</ListSubheader>
                                    {
                                        groupedParams[e]?.map(item => (
                                            <ListItem disableGutters disablePadding>
                                                <ListItemButton>
                                                    <ListItemText primary={item.name_translated} secondary={<div dangerouslySetInnerHTML={{ __html: item.details_translated }}></div>}>

                                                    </ListItemText>
                                                    <ListItemIcon>
                                                        <Checkbox onChange={(_, checked) => onParamCheck(item, checked)} checked={mappedNames.includes(lowerCase(item.name))}></Checkbox>
                                                    </ListItemIcon>
                                                </ListItemButton>
                                            </ListItem >
                                        ))
                                    }
                                </>
                            ))
                    }
                </List >
            </Box >
        </>
    )
}
export default memo(AvailableParams)