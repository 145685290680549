import { TrainingSetting } from './../shared/types/trainingSetting.type';
import { AxiosError, AxiosResponse } from "axios";
import { loginForm } from "../components/auth/LoginForm";
import { TUser } from "../shared/types/user.type";
import axiosInstance from "./base";
import { TFile } from "../shared/types/file.type";
import { TQualifyChoices } from '../shared/types/qualifyChoices.type';

export class User {
    static register(data: loginForm): Promise<AxiosResponse<TUser>> {
        return axiosInstance.post('users', data)
    }

    static login(data: loginForm): Promise<AxiosResponse<{ token: string, user_id: number }>> {
        return axiosInstance.post('authentication-token', data)
    }
    static update(data: Partial<TUser>): Promise<AxiosResponse<TUser>> {
        return axiosInstance.patch(`users/${data.id}`, data, {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            }
        })
    }

    static resetPassword(data: { password: string, token: string }) {
        return axiosInstance.post('reset-password-submit ', data)
    }

    static requestResetPassword(data: { email: string }) {
        return axiosInstance.post('reset-password-request ', data)
    }

    static uploadImage(data: FormData): Promise<AxiosResponse<TFile>> {
        return axiosInstance.post('upload_file', data)
    }


    static getUser(): Promise<AxiosResponse<TUser>> {
        return axiosInstance.get('users/me')
    }

    static getJobs() {
        return axiosInstance.get('jobs')
    }

    static updateSettings(id: number, data: Partial<TrainingSetting>): Promise<AxiosResponse<TrainingSetting>> {
        return axiosInstance.patch(`user_training_settings/${id}`, data, {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            }
        })

    }


    static getQualifyChoices(id?: number): Promise<AxiosResponse<TQualifyChoices>> {
        return axiosInstance.get(`users/${id}/qualify_training_choices`)
    }
}