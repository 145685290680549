import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, Tab, Tabs } from '@mui/material'
import React, { ReactElement, useEffect, useState } from 'react'
import { routes } from '../../pages/routes'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Add } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { setRiderModal, setShoeingModal, setTrackModal, setTrackNameModal } from '../../store/reducers/modal.reducer'

function ProfileTabs({ children, onRouteChanged }: { children: ReactElement, onRouteChanged: (path: string | undefined, element: React.ReactNode) => void }) {

    const location = useLocation()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [value, setValue] = useState('1')
    const tabs = [
        {
            title: "navbar:profileOptions:profile",
            value: routes.DASHBOARD_ROUTES.PROFILE,
            disabled: false,
            index: '1',
            children: null
        },
        {
            title: "navbar:generalSettings",
            value: routes.DASHBOARD_ROUTES.SETTINGS,
            disabled: false,
            index: '2',
            children: null
        },
        {
            title: "navbar:myTrainings",
            value: routes.DASHBOARD_ROUTES.MYTRAININGS,
            disabled: false,
            index: '3',
            children: null
        },
        {
            title: "navbar:analyticsSettings",
            value: routes.DASHBOARD_ROUTES.MYANALYTICS,
            disabled: false,
            index: '4',
            children: null
        },
        {
            title: "navbar:myTracks",
            value: routes.DASHBOARD_ROUTES.TRACKS,
            disabled: false,
            index: '5',
            children: <Button onClick={() => dispatch(setTrackNameModal(true))} variant='contained' startIcon={<Add></Add>}>{t('tracks:addTrack')}</Button>
        },
        {
            title: "navbar:myShoeing",
            value: routes.DASHBOARD_ROUTES.SHOEING,
            disabled: false,
            index: '6',
            children: <Button onClick={() => dispatch(setShoeingModal(true))} variant='contained' startIcon={<Add></Add>}>{t('horseShoeing:addHorseShoeing')}</Button>
        },
        {
            title: "navbar:myRiders",
            value: routes.DASHBOARD_ROUTES.RIDERS,
            disabled: false,
            index: '7',
            children: <Button onClick={() => dispatch(setRiderModal(true))} variant='contained' startIcon={<Add></Add>}>{t('riders:addRider')}</Button>

        },
        {
            title: "navbar:myTrackConditions",
            value: routes.DASHBOARD_ROUTES.TRACKSCONDITIONS,
            disabled: false,
            index: '8',
            children: <Button onClick={() => dispatch(setTrackModal(true))} variant='contained' startIcon={<Add></Add>}>{t('trackCondition:addTrackCondition')}</Button>
        },
        {
            title: "notification:title",
            value: routes.DASHBOARD_ROUTES.NOTIFICATIONS,
            disabled: false,
            index: '9',
        },
    ]

    useEffect(() => {
        let { pathname } = location

        let route = tabs.find(tab => tab.value === pathname)
        onRouteChanged(route?.title, route?.children)
        if (route) {
            setValue(route.index)
        } else {
            if (pathname.includes('tracks')) {
                let sub = tabs.find(e => e.value === routes.DASHBOARD_ROUTES.TRACKS)
                setValue(sub?.index ?? '5')
                onRouteChanged(sub?.title, sub?.children)

            }
        }
    }, [location.pathname])


    const handleChange = (event: React.SyntheticEvent, newValue: string) => {


        let route = tabs.find(tab => tab.index === newValue)

        if (route) {
            let path = route.value
            navigate(path)
        }

    };

    return (
        <Box sx={{ width: '100%', typography: 'body1', mt: 2, position: 'relative', flex: 1 }}>
            <TabContext value={value}>
                <Tabs
                    variant="scrollable"
                    sx={{ maxWidth: '90vw' }}
                    scrollButtons='auto'
                    value={value} onChange={handleChange}>
                    {tabs.map((tab, i) => (
                        <Tab key={tab.index} disabled={tab.disabled} label={t(tab.title)} value={tab.index} />
                    ))}
                </Tabs>
                <TabPanel value={value} sx={{ p: 0, m: 0, mt: 2, position: 'relative' }}>
                    {children}
                </TabPanel>
            </TabContext>

        </Box>
    )
}

export default ProfileTabs