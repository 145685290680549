import { TQualifyData } from '../../components/modals/EditTrainingsModal';
import { TExam } from '../../shared/types/exam.type';
import { THorse } from '../../shared/types/horse.type';
import { TRider } from '../../shared/types/rider.type';
import { TtrackCondition } from '../../shared/types/trackCondition.type';
import { Ttrack } from '../../shared/types/tracks.type';
import { TShoeing } from './../../shared/types/shoeing.type';
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type DisplayState = {
    shoeingModal: boolean,
    horseModal: boolean,
    riderModal: boolean,
    tracksModal: boolean,
    trackNameModal: boolean,
    editTrainingModal: boolean,
    editTrainingsModal: boolean,
    sheoingItemToEdit: null | TShoeing,
    riderItemToEdit: null | TRider,
    trackItemToEdit: null | TtrackCondition,
    trackNameItemToEdit: null | Ttrack,
    trainingToEdit: null | TExam,
    horseToEdit: null | THorse,
    editedExamQualifyData: null | TQualifyData

}

const initialState: DisplayState = {
    shoeingModal: false,
    horseModal: false,
    sheoingItemToEdit: null,
    riderModal: false,
    riderItemToEdit: null,
    trackNameModal: false,
    tracksModal: false,
    editTrainingModal: false,
    editTrainingsModal: false,
    trackItemToEdit: null,
    trackNameItemToEdit: null,
    trainingToEdit: null,
    horseToEdit: null,
    editedExamQualifyData: null,
}

export const modalReducer = createSlice({
    initialState,
    name: 'modals',
    reducers: {
        setShoeingModal: (state, action: PayloadAction<boolean>) => {
            state.shoeingModal = action.payload
        },
        setShoeingItemToEdit: (state, action: PayloadAction<TShoeing | null>) => {
            state.sheoingItemToEdit = action.payload
        },
        setRiderModal: (state, action: PayloadAction<boolean>) => {
            state.riderModal = action.payload
        },
        setRiderItemToEdit: (state, action: PayloadAction<TRider | null>) => {
            state.riderItemToEdit = action.payload
        },
        setTrackModal: (state, action: PayloadAction<boolean>) => {
            state.tracksModal = action.payload
        },
        setTrackItemToEdit: (state, action: PayloadAction<TtrackCondition | null>) => {
            state.trackItemToEdit = action.payload
        },
        setTrackNameModal: (state, action: PayloadAction<boolean>) => {
            state.trackNameModal = action.payload
        },
        setTrackNameItemToEdit: (state, action: PayloadAction<Ttrack | null>) => {
            state.trackNameItemToEdit = action.payload
        },
        setEditTrainingModal: (state, action: PayloadAction<boolean>) => {
            state.editTrainingModal = action.payload
        },
        setHorseModal: (state, action: PayloadAction<boolean>) => {
            state.horseModal = action.payload
        },
        setTrainingToEdit: (state, action: PayloadAction<TExam | null>) => {
            state.trainingToEdit = action.payload
        },
        setHorseToEdit: (state, action: PayloadAction<THorse | null>) => {
            state.horseToEdit = action.payload
        },
        setEditTrainingsModal: (state, action: PayloadAction<boolean>) => {
            state.editTrainingsModal = action.payload
        },
        setEditedExamQualifyData: (state, action: PayloadAction<TQualifyData | null>) => {
            state.editedExamQualifyData = action.payload
        },
    }
})

export const { setShoeingModal, setShoeingItemToEdit, setRiderModal, setRiderItemToEdit, setTrackModal, setTrackItemToEdit, setTrackNameModal, setTrackNameItemToEdit, setEditTrainingModal, setTrainingToEdit, setHorseModal, setHorseToEdit, setEditTrainingsModal, setEditedExamQualifyData } = modalReducer.actions
export default modalReducer.reducer