export type PATHS = "AUTH_ROUTES" | "DASHBOARD_ROUTES" | "SETTINGS_ROUTES" | "OTHER_ROUTES"

type subRoute = {
    [r: string]: string
}

export const routes: Record<PATHS, subRoute> = {
    AUTH_ROUTES: {
        LOGIN: '/login',
        REGISTER: '/register',
        FORGOT_PASSWORD: "/forgot-password",
        EMAIL_SENT: "/email-sent",
        EMAIL_SENT_PASSWORD: "/check-email",
        GENERATE_PASSWORD: "/generate-password",
    },
    DASHBOARD_ROUTES: {
        DASHBOARD: '/dashboard',
        YARD: '/dashboard/yard',
        HOME: '/dashboard/home',
        TRAININGS: '/dashboard/trainings',
        COMPARAISON: '/dashboard/comparaison',
        ANALYTICS: '/dashboard/analytics',
        USER: '/dashboard/user',
        PROFILE: '/dashboard/user/profile',
        SETTINGS: '/dashboard/user/settings',
        SHOEING: '/dashboard/user/horseShoeings',
        RIDERS: '/dashboard/user/riders',
        TRACKSCONDITIONS: '/dashboard/user/trackConditions',
        NOTIFICATIONS: '/dashboard/user/notifications',
        TRACKS: '/dashboard/user/tracks',
        MYTRAININGS: '/dashboard/user/myTrainings',
        MYANALYTICS: '/dashboard/user/myAnalytics',
        TRACK_VIEW: '/dashboard/user/tracks/:id',
        EXAM_VIEW: '/dashboard/training/:id',
    },
    SETTINGS_ROUTES: {
        PDF: '/settings/notification',
        COMPARAISON: '/settings/comparaison',
    },
    OTHER_ROUTES: {
        UNSUBSCRIBE: '/unsubscribe'
    }


} as const