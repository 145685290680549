import { Delete, Edit } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import React, { useState } from 'react'
import DeleteModal from '../modals/DeleteModal'

type DataGridActionsProps = {
    onDelete: () => void,
    onEdit: () => void,
    isDeleting: boolean,
    deleteTitle: string,
    deleteSubtitle: string
}

function DataGridActions({ onDelete, onEdit, isDeleting, deleteTitle, deleteSubtitle }: DataGridActionsProps) {
    const [openDelete, setOpenDelete] = useState(false)
    return (
        <Box display={'flex'} gap={1} flexWrap={'wrap'}>
            {/* Edit Section */}
            <IconButton onClick={onEdit} size='small' color='info'>
                <Edit sx={{ width: 15, height: 15 }}></Edit>
            </IconButton>
            {/* Delete Section */}
            <IconButton onClick={() => setOpenDelete(true)} size='small' color='error'>
                <Delete sx={{ width: 15, height: 15 }}></Delete>
            </IconButton>

            <DeleteModal isDeleting={isDeleting} onDelete={onDelete} title={deleteTitle} subtitle={deleteSubtitle} open={openDelete} handleClose={() => setOpenDelete(false)}></DeleteModal>
        </Box>
    )
}

export default DataGridActions