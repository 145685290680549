import { Done, Pause, PlayArrow, PlayCircle } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { debounce, toNumber } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Range } from '../../styled/Range'
import FinishFlag from '../../assets/icons/finishFlag.svg'
type MapControlBarProps = {
    play: boolean,
    changePlay: (play: boolean) => void,
    currentTime: string,
    bg: string,
    totalTime: string,
    max: number,
    currentIndex: number,
    onRangeChange: (val: number) => void,
    flIndex?: number | null,
    isFinishLineActive: boolean

}

function MapControlBar({ play, changePlay, currentTime, totalTime, max, currentIndex, onRangeChange, bg, flIndex,isFinishLineActive }: MapControlBarProps) {


    const getLeftPos = (): string => {
        let el = document.querySelector('#track')
        if (el && flIndex) {
            let width = el.getBoundingClientRect().width
            let val = (toNumber(flIndex) * width) / max;

            return String((val / Math.round(width)) * 100).concat('%')
        }
        return "0px"
    }



    return (
        <>
            <Box display={'flex'} alignItems={'center'} gap={2} mt={1}>
                <IconButton size='small' onClick={() => changePlay(!play)}>{!play ? <PlayArrow></PlayArrow> : <Pause></Pause>}</IconButton>
                <Box position={'relative'} width={'100%'} flex={1}>
                    <Range name='track' bg={bg} id='track' step={1} onChange={e => onRangeChange(toNumber(e.target.value))} min={0} max={max} value={currentIndex} type='range'></Range>
                    {
                        (flIndex && isFinishLineActive) && <img src={FinishFlag} alt='finish-flag' style={{ textAnchor: 'start', position: 'absolute', left: `calc(${getLeftPos()} - 8px)`, bottom: 15, width: 23, height: 23 }}></img>
                    }
                </Box>
                <Typography variant='subtitle2' fontSize={12}>{currentTime} / {totalTime}</Typography>
            </Box>
        </>

    )
}

export default MapControlBar