import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { t } from 'i18next'
import React from 'react'

type DeleteModalProps = {
    open: boolean,
    handleClose: () => void,
    title: string,
    subtitle?: string,
    isDeleting: boolean,
    onDelete: () => void,
    dangerText?: string
}



function DeleteModal({ open, handleClose, title, subtitle, onDelete, isDeleting, dangerText }: DeleteModalProps) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle id="responsive-dialog-title">
                {t(title)}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {subtitle && t(subtitle)}

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    {t('alert.cancel')}
                </Button>
                <LoadingButton variant='contained' loading={isDeleting} onClick={onDelete} color='error' autoFocus>
                    {t(dangerText ?? "")}
                </LoadingButton>
            </DialogActions>

        </Dialog>
    )
}
DeleteModal.defaultProps = {
    dangerText: 'deleteModal:confirm'
}

export default DeleteModal