import { Add, Send } from '@mui/icons-material'
import { Box, Button, Chip, CircularProgress, Divider, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Tooltip } from '@mui/material'
import { t } from 'i18next'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { z } from 'zod'
import { TContactOptIn } from '../../shared/types/Channel.type'
import { useMutation } from 'react-query'
import axios from 'axios'
import { toast } from 'react-toastify'

type NewChannelStep2Props = {
    onSave: () => void,
    emails: Partial<TContactOptIn>[],
    handleEmails: (val: Partial<TContactOptIn>[]) => void,
    errorEmail: boolean
}

function NewChannelStep2({ onSave, emails, handleEmails, errorEmail }: NewChannelStep2Props) {
    const [email, setEmail] = useState('')
    const [error, hasError] = useState(false)
    const emailSchema = z.string().email();

    const sendInvitationMutation = useMutation((url: string) => axios.post(url, null, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    }), {
        onSuccess: () => {
            toast(t('invitation:msg.sent'), { type: 'success' })
        }
    })

    const addEmailToList = () => {
        try {
            emailSchema.parse(email)
            handleEmails([...emails, { target: email, opted_in: true }])
            setEmail('')
            hasError(false)
            return false
        } catch (err) {
            hasError(true)
            return true
        }
    }

    return (
        <>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
                <FormControl error={error} variant="outlined" fullWidth size='small'>
                    <OutlinedInput
                        error={errorEmail}
                        value={email}
                        onChange={(e) => {
                            hasError(false)
                            setEmail(e.target.value)
                        }}
                        onKeyUp={e => {
                            if (e.key === 'Enter' || e.keyCode === 13) {
                                addEmailToList()
                            }
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <Tooltip title={t('button:add')} arrow>
                                    <IconButton
                                        disabled={isEmpty(email)}
                                        aria-label="toggle password visibility"
                                        onClick={() => { addEmailToList() }}
                                        edge="end"
                                    >
                                        <Add></Add>
                                    </IconButton>
                                </Tooltip>

                            </InputAdornment>
                        }
                        fullWidth placeholder={t('equimetre:table:email')}></OutlinedInput>
                    {
                        error ? <FormHelperText error id="accountId-error">
                            {t('form:error:email')}
                        </FormHelperText> : <FormHelperText>{t('notification:emailHint')}</FormHelperText>
                    }

                </FormControl>
            </Box>
            {
                emails.length > 0 &&
                <>
                    <Divider sx={{ my: 1 }}></Divider>
                    <Box display={'flex'} gap={1} flexWrap={'wrap'}>

                        {emails.map((email, i) => (
                            <Chip key={email.target} icon={!email.opted_in ?
                                sendInvitationMutation.isLoading ? <CircularProgress size={9}></CircularProgress> :
                                    <Tooltip arrow title={t('sendInviteTo') + ' ' + email.target}>
                                        <Send sx={{ cursor: 'pointer' }} onClick={() => sendInvitationMutation.mutateAsync(email?.resend_email_url ?? "")}></Send>
                                    </Tooltip>
                                : undefined} color={email.opted_in ? 'info' : 'default'} label={email.target} size='small' sx={{ borderRadius: 1 }} onDelete={() => {
                                    let mails = [...emails]
                                    mails.splice(i, 1)
                                    handleEmails(mails)
                                }}></Chip>
                        ))}
                    </Box>
                </>
            }
        </>

    )
}

export default NewChannelStep2