import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Scale } from "../pages/trainings/ExamView";
import { get, isNumber, set } from "lodash";

export const useChart = () => {

    const metric = useSelector<RootState>(state => state.user.user?.unit_system) as "metric" | "imperial" | undefined


    function getXForMomentIndex(momentIndex: number, scale: Scale, distance: number[], time: number[]) {

        var valueToFind = 0;
        if (scale === 'DISTANCE') {
            valueToFind = distance[momentIndex];

        } else {
            //scale = time
            valueToFind = time[momentIndex]
        }

        return valueToFind;
    }

    const destroySvg = (chart: any) => {
        if (chart && chart?.hasOwnProperty('svg')) {
            chart?.svg?.foreach((s: any) => s?.destroy())
            chart.svg = []
        }
    }
    const addFinishLine = (value: number | null, scale: Scale, distance: number[], time: number[], chart?: any, mb?: number) => {


        if (chart && isNumber(value)) {

            let valueToFind = getXForMomentIndex(value, scale, distance, time)
            if (isNaN(valueToFind)) return

            var sizeOfLine = 2;
            var xValue = valueToFind

            let marginBottom = mb ? mb : chart?.marginBottom

            var tmpLine = chart.renderer.path(['M', chart.xAxis[0].toPixels(xValue), marginBottom, 'V', chart.plotHeight + marginBottom]).attr({
                fill: '#FFFFFF',
                stroke: 'black',
                'stroke-width': sizeOfLine,
                'stroke-dasharray': sizeOfLine,
                zIndex: 4
            }).add();
            var tmpLine2 = chart.renderer.path(['M', chart.xAxis[0].toPixels(xValue), marginBottom + sizeOfLine, 'V', chart.plotHeight + marginBottom + sizeOfLine]).attr({
                fill: '#FFFFFF',
                stroke: 'white',
                'stroke-width': sizeOfLine,
                'stroke-dasharray': sizeOfLine,
                zIndex: 4
            }).add();
            var tmpLine3 = chart.renderer.path(['M', chart.xAxis[0].toPixels(xValue) + sizeOfLine, marginBottom, 'V', chart.plotHeight + marginBottom]).attr({
                fill: '#FFFFFF',
                stroke: 'white',
                'stroke-width': sizeOfLine,
                'stroke-dasharray': sizeOfLine,
                zIndex: 4
            }).add();
            var tmpLine4 = chart.renderer.path(['M', chart.xAxis[0].toPixels(xValue) + sizeOfLine, marginBottom + sizeOfLine, 'V', chart.plotHeight + marginBottom + sizeOfLine]).attr({
                fill: '#FFFFFF',
                stroke: 'black',
                'stroke-width': sizeOfLine,
                'stroke-dasharray': sizeOfLine,
                zIndex: 4
            }).add();
            let svg = []
            svg.push(tmpLine);
            svg.push(tmpLine2);
            svg.push(tmpLine3);
            svg.push(tmpLine4);
            set(chart, 'svgs', svg)
            return svg
        }

    }

    return {
        addFinishLine
    }
}