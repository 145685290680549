import { Box, Button, Card, CardHeader, Chip, CircularProgress, Container, Divider, FormControl, Grid, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Header from '../../../components/dashboard/Header'
import { useBeforeUnload, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useTracks } from '../../../hooks/useTracks';
import { get, isEmpty, lowerCase, toNumber, uniqBy } from 'lodash';
import { RootState } from '../../../store';
import { TUser } from '../../../shared/types/user.type';
import { useHorses } from '../../../hooks/useHorses';
import { Add, ArrowBack, Build, ContactMail } from '@mui/icons-material';
import NewChannelStepper from '../../../components/notifications/NewChannelStepper';
import NewConfigurationStepper from '../../../components/notifications/NewConfigurationStepper';
import { useNotificationsSettings } from '../../../hooks/useNotificationSettings';
import Channels from '../../../components/notifications/Channels';
import Configurations from '../../../components/notifications/Configurations';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { TChannel, TChannelType } from '../../../shared/types/Channel.type';
import { TSchedule } from '../../../shared/types/schedule.type';
import axiosInstance from '../../../api/base';
import i18n from '../../../locales';
import BaseModal from '../../../components/modals/BaseModal';
import ChooseBuild from '../../../components/notifications/ChooseBuild';

function Notifications({ standalone }: { standalone?: boolean }) {
    let [searchParams, setSearchParams] = useSearchParams();

    const [channelToEdit, setChannelToEdit] = useState<TChannel | null>(null)
    const [configToEdit, setConfigToEdit] = useState<TSchedule | null>(null)
    const [newConfigModal, setNewConfigModal] = useState(false)
    const [recipientModal, setRecipientModal] = useState(false)
    const [newChannelModal, setNewChannelModal] = useState(false)
    const [newConfigurationModal, setNewConfigurationModal] = useState(false)
    const [configType, setConfigType] = useState<'SIMPLE' | 'ADVANCED' | undefined>(undefined)
    const [cnt, setContinue] = useState(false)
    //Hooks
    const user = useSelector<RootState>(state => state.user.user) as TUser
    const { setToken, getUser, isUserFetching, auth } = useAuth()
    const { tracks, trainingTypes, isLoading, isLoadingTrainingTypes } = useTracks(cnt && !isUserFetching && !isEmpty(user), auth?.id, true, true)
    const { horses, isLoadingHorses } = useHorses(cnt && !isUserFetching && !isEmpty(user), auth?.['@id'])
    const { channels, channelsLoading, configs, configsLoading, deleteChannel, deleteWhatsappChannel, deleteConfig, saveChannel, saveConfig } = useNotificationsSettings(cnt && !isUserFetching && !isEmpty(user), auth?.['@id'])

    const dataLoading = isLoadingHorses || isLoading || isLoadingTrainingTypes


    //
    const [selectedOption, setSelectedOption] = useState<'channel' | 'config' | undefined>(undefined)

    //Getting Query Params from header
    const TOKEN = searchParams.get('token')
    const LANG = searchParams.get('lang')
    //

    const queryClient = useQueryClient()



    useEffect(() => {
        //if token defined set 

        if (TOKEN) {



            setToken(TOKEN)

            getUser()

            removeToken()

            setContinue(true)


        } else {
            setContinue(true)
        }
    }, [TOKEN])



    useEffect(() => {
        if (LANG && standalone && cnt) {
            i18n.changeLanguage(lowerCase(LANG))
        }
    }, [LANG, standalone, cnt])


    const removeToken = () => {
        if (searchParams.has('token')) {
            searchParams.delete('token');
            setSearchParams(searchParams);
        }
    }


    const deleteSelectedChannel = async (id: number, channelType: TChannelType) => {
        try {
            await (channelType === 'EmailCommunicationChannel' ? deleteChannel : deleteWhatsappChannel).mutateAsync(id)
            toast(t('rights:success'), { type: 'success' })
            queryClient.refetchQueries(['channels'])
        } catch (error) {
            toast(t('error:generic'), { type: 'error' })

        }
    }


    const deleteSelectedConfig = async (id: number) => {

        try {
            await deleteConfig.mutateAsync(id)
            toast(t('rights:success'), { type: 'success' })
            queryClient.refetchQueries(['configs'])
        } catch (error) {
            toast(t('error:generic'), { type: 'error' })

        }
    }

    const duplicateChannel = ({ name,
        targets,
        lang,
        unit_system,
        speed_unit }: TChannel) => {
        let dataObj = {
            name: name + ' copy',
            targets,
            lang,
            unit_system,
            speed_unit,
            user: user['@id']
        }

        saveChannel.mutateAsync(dataObj).then((result) => {
            //TODO: back and refetch
            toast(t('rights:success'), { type: 'success' })
            setChannelToEdit(result.data)
            setSelectedOption('channel')
            queryClient.refetchQueries(['channels'])
        }).catch(err => {
            toast(t('error:generic'), { type: 'error' })

        })

    }
    const duplicateConfig = ({ communication_channels, horse_ages, max_best600, name, track_names, training_types, horses, schedules, active }: TSchedule) => {
        let dataObj: any = {
            communication_channels: communication_channels.map(channel => get(channel, '@id')),
            name: name + ' copy',
            horse_ages,
            max_best600,
            track_names: track_names.map(track => get(track, '@id')),
            training_types: training_types.map(tr => get(tr, '@id')),
            horses: horses.map(h => get(h, '@id')),
            schedules: [
                {
                    "type": get(schedules, '[0].type'),
                    "timezone": get(schedules, '[0].timezone'),
                    "day_of_week": get(schedules, '[0].day_of_week'),
                    "hour": get(schedules, '[0].hour', undefined),
                    "minute": get(schedules, '[0].minute', undefined)
                }
            ],
            active,
            user: user?.['@id']
        }
        saveConfig.mutateAsync(dataObj).then((result) => {
            toast(t('rights:success'), { type: 'success' })
            setConfigToEdit(result.data)
            setSelectedOption('config')
            queryClient.refetchQueries(['configs'])

        }).catch(err => {
            toast(t('error:generic'), { type: 'error' })

        })


    }

    

    return (

        <Container maxWidth="lg" sx={{ my: 2 }}>
            <Card >
                <Box sx={{ p: 3 }}>
                    <Typography variant='h6'>{t('notification:title')}</Typography>
                    <Box mt={1} display={'flex'} gap={1}>
                        <Button onClick={() => setNewConfigModal(true)} variant='contained'>{t('button:addNewConfig')}</Button>
                        <Button onClick={() => setRecipientModal(true)} variant='outlined'>{t('notification:channel')}</Button>
                    </Box>
                </Box>
                <Divider sx={{ my: 1 }}></Divider>
                <Configurations
                    onDuplicate={duplicateConfig}
                    onEdit={(item) => {
                        setConfigToEdit(item)
                        setConfigType('ADVANCED')
                        setSelectedOption('config')
                        setNewConfigurationModal(true)
                    }}
                    onDelete={deleteSelectedConfig}
                    isDeleting={deleteConfig.isLoading}
                    configs={configs}
                    loading={configsLoading}></Configurations>
            </Card>

            {/* Modals */}
            <BaseModal
                open={newConfigModal}
                width={'50%'}
                handleClose={() => setNewConfigModal(false)}
                title='button:addNewConfig'
            >
                <ChooseBuild createNewConfig={(value) => {
                    setConfigType(value)
                    setNewConfigModal(false)
                    setNewConfigurationModal(true)
                }}></ChooseBuild>

            </BaseModal>

            <BaseModal
                open={recipientModal}
                width={'70%'}
                handleClose={() => {
                    setChannelToEdit(null)
                    setRecipientModal(false)}}
                title={t('notification:channel')}
            >
                <Channels
                    createNewChannel={() => {
                        setChannelToEdit(null)
                        setNewChannelModal(true)
                    }}
                    onDuplicate={duplicateChannel}
                    onEdit={(item) => {
                        setChannelToEdit(item)
                        setNewChannelModal(true)
                    }} isDeleting={deleteChannel.isLoading || deleteWhatsappChannel.isLoading}
                    onDelete={deleteSelectedChannel}
                    channels={channels}
                    loading={channelsLoading}></Channels>
            </BaseModal>

            <BaseModal
                open={newChannelModal}
                handleClose={() => {
                    setChannelToEdit(null)
                    setNewChannelModal(false)
                }}
                title={channelToEdit ? t('channel:editList') : t('channel:createNewList')}
                width={'50%'}
            >
                <NewChannelStepper itemToEdit={channelToEdit} onSaveSuccess={() => {
                    setChannelToEdit(null)
                    setSelectedOption(undefined)
                    setNewChannelModal(false)
                }}></NewChannelStepper>
            </BaseModal>

            <BaseModal
                open={newConfigurationModal}
                handleClose={() => {
                    setConfigToEdit(null)
                    setNewConfigurationModal(false)
                }}
                title={configToEdit ? t('configuration:edit') : configType === 'SIMPLE' ? t('configuration:buildSimple') : t('configuration:buildAdvanced')}
                width={'70%'}
            >
                <NewConfigurationStepper
                    configType={configType}
                    createNewChannel={() => {
                        setChannelToEdit(null)
                        setNewChannelModal(true)
                    }}
                    editChannel={(item) => {
                        setChannelToEdit(item)
                        setNewChannelModal(true)
                    }}
                    deleteChannel={deleteSelectedChannel}
                    dataLoading={dataLoading}
                    isLoading={channelsLoading}
                    isDeleting={deleteChannel.isLoading || deleteWhatsappChannel.isLoading}
                    itemToEdit={configToEdit}
                    onSaveSuccess={() => {
                        queryClient.refetchQueries(['configs'])
                        setNewConfigurationModal(false)
                    }} horses={horses} tracks={tracks} trainingTypes={trainingTypes} channels={channels}></NewConfigurationStepper>
            </BaseModal>
        </Container >
    )
}
Notifications.defailtProps = {
    standalone: false
}
export default Notifications