import React, { useEffect } from 'react'
import BaseModal from './BaseModal'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { setRiderItemToEdit, setRiderModal, setShoeingItemToEdit, setShoeingModal } from '../../store/reducers/modal.reducer'
import { Box, Grid, TextField, Typography } from '@mui/material'
import { t } from 'i18next'
import { LoadingButton } from '@mui/lab'
import { SubmitHandler, useForm } from 'react-hook-form'
import { TShoeing } from '../../shared/types/shoeing.type'
import {  get, isEqual, toNumber } from 'lodash'
import { TUser } from '../../shared/types/user.type'
import { useRiders } from '../../hooks/useRiders'
import { TRider } from '../../shared/types/rider.type'

function Rider() {
    const open = useSelector<RootState>(state => state.modals.riderModal) as boolean
    const itemToEdit = useSelector<RootState>(state => state.modals.riderItemToEdit, isEqual) as TRider | null
    const auth = useSelector<RootState>(state => state.user.user) as TUser | null
    const { saveRider, isSaving, updateRider, isUpdating } = useRiders(false)
    const dispatch = useDispatch()


    useEffect(() => {
        if (itemToEdit) {
            setValue('id', itemToEdit.id)
            setValue('name', itemToEdit?.name)
            setValue('weight', itemToEdit?.weight)
        }
    }, [itemToEdit])

    const {
        register,
        handleSubmit,
        reset,
        setValue,

        formState: { errors, touchedFields },
    } = useForm<Partial<TRider>>({
    })

    const onSubmit: SubmitHandler<Partial<TRider>> = (data) => {
        const tData: Partial<TRider> =
        {
            ...data,
            weight: toNumber(data?.weight),
            trainer: get(auth, '@id')
        }
        let promise = itemToEdit ? updateRider(tData) : saveRider(tData)

        promise
            .then(() => {
                //On Success reset Form
                reset()
                dispatch(setRiderItemToEdit(null))
            dispatch(setRiderModal(false))

            }).catch(err => { })
    }

    return (
        <BaseModal title={itemToEdit ? 'tracks:information' : 'riders:addRider'} open={open} handleClose={() => {
            dispatch(setRiderModal(false))
            dispatch(setRiderItemToEdit(null))
            reset()
        }}>
            <Box>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <Grid container spacing={3}>

                        <Grid item md={12} xs={12}>
                            <TextField error={errors?.hasOwnProperty('name')} {...register('name', { required: true })}  fullWidth label={t('equimetre:table:name')}></TextField>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <TextField
                                inputProps={{
                                    step: "0.1"
                                }}
                                type='number' {...register('weight')}  fullWidth label={t('parameters:weight') + '(KG)'}></TextField>
                        </Grid>
                    </Grid>
                    <Box display={'flex'} mt={2} justifyContent={'end'}>
                        <LoadingButton loading={isSaving || isUpdating} type='submit' variant='contained'>{itemToEdit ? t('button:edit') : t('button:save')}</LoadingButton>
                    </Box>
                </form>
            </Box>

        </BaseModal>
    )
}

export default Rider