import { Alert, Divider, Grid } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TUser } from '../../shared/types/user.type'
import { RootState } from '../../store'
import { useTracks } from '../../hooks/useTracks'
import TrackCard from '../../components/tracks/TrackCard'
import TrackCardSkeleton from '../../components/tracks/TrackCardSkeleton'
import { trackSurface } from '../../shared/types/tracks.type'
import { Link, useNavigate } from 'react-router-dom'
import { routes } from '../routes'
import { isEmpty, isNull, isUndefined } from 'lodash'
import EmptyState from '../../components/global/EmptyState'

function Tracks() {
    const auth = useSelector<RootState>(state => state.user.user) as TUser | null
    const isUserLoading = useSelector<RootState>(state => state.user.isUserLoading) as boolean
    const { tracks, isLoading, deleteTrack, isDeleting, getTrackSurfaces } = useTracks(!isUserLoading && !isEmpty(auth), auth?.id)
    const [surfaces, setSurfaces] = useState<[] | trackSurface[]>()
    const navigate = useNavigate()
    useEffect(() => {
        getTrackSurfaces()
            .then(response => {
                setSurfaces(response)
            }).catch(err => {
                console.error('failed to load track surfaces');
            })
    }, [])

    const route = (id: number) => {
        return `${routes.DASHBOARD_ROUTES.TRACK_VIEW}`.replace(':id', String(id))

    }

    return (
        <>
            <Alert severity='info' >
                <pre dangerouslySetInnerHTML={{ __html: t('profileSectionDescription:tracks') }}></pre>
            </Alert>

            <Divider sx={{ my: 3 }}></Divider>


            <Grid container spacing={5}>
                {
                    isLoading ?
                        [...Array(4)].map((loader, i) => (
                            <Grid key={i} item md={3} sm={6} xs={12}>
                                <TrackCardSkeleton></TrackCardSkeleton>
                            </Grid>
                        ))
                        : <>
                            {
                                isEmpty(tracks) ? <EmptyState></EmptyState> :
                                    tracks.map(track => (
                                        <Grid item md={3} sm={6} xs={12} key={track.id}>
                                            <div

                                                style={{ zIndex: 10 }}
                                                onClick={e => {
                                                    e.stopPropagation()
                                                    navigate(route(track.id))
                                                }}>
                                                <TrackCard surfaces={surfaces} item={track}></TrackCard>
                                            </div>
                                        </Grid>
                                    ))}
                        </>
                }



            </Grid>
        </>
    )
}

export default Tracks