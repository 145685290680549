import React, { memo } from 'react'
import { TTrainerHorse } from '../../shared/types/trainerHorse.type'
import { Box, Grid, Typography } from '@mui/material'
import HorseCard from './HorseCard'
import { t } from 'i18next'

function Horses({ horses, onChange }: { horses: TTrainerHorse[], onChange: (id: number, checked: boolean) => void }) {
    if(horses.length === 0){
        return <Box display={'flex'} justifyContent={'center'} alignItems={'center'} pt={2} >
            <Typography variant='subtitle2'>{t('filterTrainings:noResults')}</Typography>
        </Box>
    }
    return (
        <Grid container spacing={2}>
            {horses.map(horse => (
                <Grid key={horse.id} item md={4} sm={6} xs={12}>
                    <HorseCard onChange={onChange} horse={horse}></HorseCard>
                </Grid>
            ))}
        </Grid>
    )
}

export default memo(Horses)