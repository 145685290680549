import React, { useState } from 'react'
import Header from '../../components/dashboard/Header'
import { Box, Button, Divider, Tooltip } from '@mui/material'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { openModal, resetCheckedExam, setCategories, setData, setDataItems, updateCheckExam } from '../../store/reducers/comparaison.reducer'
import { RootState } from '../../store'
import { TExam } from '../../shared/types/exam.type'
import ComparaisonChips from '../../components/comparaison/ComparaisonChips'
import { Ttraining } from '../../shared/types/training.type'
import { useExam } from '../../hooks/useExam'
import { LoadingButton } from '@mui/lab'
import ComparaisonSection from '../../components/comparaison/ComparaisonSection'
import { TgroupKeyParam, groupKeyParamsByCategories } from '../../shared/utils/comparaison'
import { toast } from 'react-toastify'
import { TtrainingKeyParameter } from '../../shared/types/trainingKeyParameter.type'
import { CompareArrows, Download } from '@mui/icons-material'
import { TUser } from '../../shared/types/user.type'
import { isEmpty } from 'lodash'

function Comparaison() {
  const dispatch = useDispatch()
  const checkedExams = useSelector<RootState>(state => state.comparaison.checkedExams) as Ttraining[]
  const categories = useSelector<RootState>(state => state.comparaison.categories) as TgroupKeyParam[]
  const data = useSelector<RootState>(state => state.comparaison.data) as TtrainingKeyParameter
  const auth = useSelector<RootState>(state => state.user.user) as TUser | null

  const { compare } = useExam(false, auth?.['@id'])

  const openTrainingsModal = () => {
    dispatch(openModal(true))
  }

  const handleDelete = (exam: Ttraining) => {
    dispatch(updateCheckExam({ checked: false, exam }))
    removeData(exam)
  }
  const removeData = (exam: Ttraining) => {
    if (data) {
      let newData = [...data.items]
      newData = newData.filter(e => e.training.id !== exam?.id)
      dispatch(setDataItems(newData))
    }
  }

  const getUrlStrfromIDS = (ids: number[]) => {
    let str: string[] = []
    ids.forEach(id => {
      str.push(`training[]=${id}`)
    })

    return str.join('&')
  }

  const addTrainingFromResponse = (data: TtrainingKeyParameter) => {
    if (data) {
      dispatch(resetCheckedExam())
      dispatch(setData(data))
      if (!isEmpty(data?.items)) {
        data.items.forEach(({ training: exam }) => {
          dispatch(updateCheckExam({ checked: true, exam }))

        })
      }
    }
  }

  const compareTrainings = (useIds: boolean = true) => {
    let ids = checkedExams.map(e => e.id)
    compare.mutateAsync(useIds ? getUrlStrfromIDS(ids) : '')
      .then(({ data }) => {
        dispatch(setCategories(groupKeyParamsByCategories(data)))
        dispatch(setData(data))
        if (!useIds) {
          addTrainingFromResponse(data)
        }
      }).catch(err => {
        console.log({ err });
        toast(t('error:generic'), { type: "error" })
      })

  }

  return (
    <>
      <Header text='navbar:comparison'>
        <Box gap={1} display={'flex'}>


          <LoadingButton loading={compare.isLoading} onClick={() => compareTrainings(false)} variant='outlined'>{t('compareTodayTrainings')}</LoadingButton>

          <Button onClick={openTrainingsModal} variant='contained'>{t('compare:addTraining')}</Button>

        </Box>
      </Header>
      <ComparaisonChips handleDelete={handleDelete} checkedExams={checkedExams}>
        <>
          <Button disabled={true} startIcon={<Download></Download>}>{t('report:buttonText')}</Button>
          <Tooltip title={isEmpty(checkedExams) ? t('no_training') : ''} arrow>
            <span>
              <LoadingButton startIcon={<CompareArrows></CompareArrows>} loading={compare.isLoading} onClick={() => compareTrainings()} disabled={checkedExams.length === 0} variant='contained' >{t('compareHorseSelection:send')}</LoadingButton>
            </span>

          </Tooltip>
        </>
      </ComparaisonChips>
      <Divider sx={{ my: 2 }}></Divider>
      <ComparaisonSection compare={() => compareTrainings()} data={data} categories={categories} loading={compare.isLoading}></ComparaisonSection>
    </>
  )
}

export default Comparaison