import { Box, Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React from 'react'
import AvatarEditor from './AvatarEditor'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import type { TUser } from "../../shared/types/user.type"
import { useTranslation } from 'react-i18next'
import { MuiTelInput } from 'mui-tel-input'
import { DateField } from '@mui/x-date-pickers';
import dayjs from 'dayjs'
import { countries } from '../../enums/countries.enum'
import { LoadingButton } from '@mui/lab'
import { toast } from 'react-toastify'

type infoProps = {
    user: TUser,
    isLoading: boolean,
    onUpdate: (data: Partial<TUser>) => void,
    handleImageChange: (file: File) => void,
    imageIsLoading: boolean,
    imageLoadingFailed: boolean,
    avatar: string
}

function InfoForm({ user, onUpdate, isLoading, handleImageChange, imageIsLoading, imageLoadingFailed, avatar }: infoProps) {
    const { t } = useTranslation()
    const {
        register,
        handleSubmit,
        control,
        setValue,
        getValues,
        formState: { errors, touchedFields },
    } = useForm<TUser>({
        defaultValues: user
    })

    const onSubmit: SubmitHandler<TUser> = (data) => {
        let { firstname, lastname, telephone, email, birthdate, nationality, id_france_galop, stable_name, id } = data
        onUpdate({ firstname, lastname, telephone, email, birthdate, nationality, id_france_galop, stable_name, id })
    }

    const onImageChange = (file: File | null) => {
        if (!file) {
            toast(t('error:generic'), { type: 'error' })
            return
        }
        handleImageChange(file)
    }

    return (
        <Card sx={{ p: 3 }}>
            <AvatarEditor avatar={avatar} imageIsLoading={imageIsLoading} imageLoadingFailed={imageLoadingFailed} onImageChange={onImageChange}></AvatarEditor>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={5} mt={3}>
                    <Grid item sm={12} md={6} xs={12}>
                        <TextField
                            error={errors.hasOwnProperty('firstname')}
                            {...register('firstname', { required: true })}

                            fullWidth placeholder={t('equi:registration:firstname')} label={t('equi:registration:firstname')} variant='outlined' ></TextField>
                    </Grid>
                    <Grid item sm={12} md={6} xs={12}>
                        <TextField
                            error={errors.hasOwnProperty('lastname')}
                            {...register('lastname', { required: true })}
                            fullWidth placeholder={t('equi:registration:lastname')} label={t('equi:registration:lastname')} variant='outlined' ></TextField>
                    </Grid>
                    <Grid item sm={12} md={6} xs={12}>
                        <TextField {...register('email', { required: true })} error={errors.hasOwnProperty('email')} fullWidth placeholder={t('equi:registration:email')} label={t('equi:registration:email')} variant='outlined' ></TextField>
                    </Grid>
                    <Grid item xs={12} md={6} sm={12}>
                        <FormControl fullWidth>
                            <Controller
                                name="telephone"
                                control={control}

                                render={({ field, fieldState }) => (
                                    <MuiTelInput
                                        placeholder={t('equi:registration:phone')}
                                        {...field}
                                        variant='outlined'
                                        

                                        helperText={fieldState.invalid ? "Tel is invalid" : ""}
                                        error={errors.hasOwnProperty('telephone')}
                                    />
                                )}
                            />

                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} sm={12}>
                        <DateField format='DD-MM-YYYY' defaultValue={dayjs(getValues('birthdate'))} onChange={val => setValue('birthdate', val, { shouldValidate: true })} fullWidth variant='outlined'  label={t('equi:registration:birthdate')}></DateField>
                    </Grid>
                    <Grid item xs={12} md={6} sm={12}>
                        <FormControl fullWidth >
                            <InputLabel error={errors.hasOwnProperty('nationality')} id="country-select-label">{t('equi:registration:nationality')}</InputLabel>
                            <Select
                                {...register('nationality', { required: true })}
                                error={errors.hasOwnProperty('nationality')}
                                variant='outlined'
                                labelId="country-select-label"
                                id="demo-simple-select"
                                label={t('equi:registration:nationality')}
                                defaultValue={user?.nationality}
                            >
                                {countries.map(country => (
                                    <MenuItem key={country.code} value={country.code}>{country.name}</MenuItem>

                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField
                                {...register('id_france_galop')}
                                
                                variant='outlined'
                                label={t('equi:registration:fgid')}
                            >
                            </TextField>
                        </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField
                                    
                                    variant='outlined'
                                    label={t('equi:registration:address:address')}
                                >
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField
                                    
                                    variant='outlined'
                                    label={t('equi:registration:address:address_optional')}
                                >
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField
                                    
                                    variant='outlined'
                                    label={t('equi:registration:address:postal_code')}
                                >
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField
                                    
                                    variant='outlined'
                                    label={t('equi:registration:address:city')}
                                >
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel error={errors.hasOwnProperty('nationality')} id="country-select-label">{t('equi:registration:address:country')}</InputLabel>
                            <Select
                                error={errors.hasOwnProperty('country')}
                                
                                variant='outlined'
                                labelId="country-select-label"
                                id="demo-simple-select"
                                label={t('equi:registration:address:country')}
                            >
                                {countries.map(country => (
                                    <MenuItem key={country.code} value={country.code}>{country.name}</MenuItem>

                                ))}
                            </Select>
                        </FormControl>
                        </Grid> */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <TextField
                                error={errors.hasOwnProperty('stable_name')}
                                {...register('stable_name', { required: true })}
                                
                                variant='outlined'
                                label={t('info:stableName')}
                            >
                            </TextField>
                        </FormControl>
                    </Grid>

                </Grid>
                <Box display={'flex'} justifyContent={'end'} mt={3}>
                    <LoadingButton loading={isLoading} type='submit' variant='contained'>{t('info:save')}</LoadingButton>
                </Box>
            </form>

        </Card>
    )
}

export default InfoForm