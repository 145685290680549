import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { TAnalyticParams } from '../../shared/types/analyticParameters.type'
import { Box, Card, IconButton, Typography } from '@mui/material'
import { DragDropContext, DragUpdate, Draggable, DropResult, Droppable } from 'react-beautiful-dnd'
import { Delete, PushPin, PushPinOutlined, Reorder } from '@mui/icons-material'
import { cloneDeep } from 'lodash'
type DraggableParamsProps = {
    selectedParams: TAnalyticParams[],
    pinnedParams: TAnalyticParams[],
    onUpdateParam: (data: TAnalyticParams[]) => void,
    updatePin: (items: TAnalyticParams[]) => void
}

function DraggableParams({ selectedParams, onUpdateParam, pinnedParams, updatePin }: DraggableParamsProps) {

    //state
    const [pins, setPins] = useState<TAnalyticParams[]>([])
    const [pinsCount, setPinsCount] = useState(0)

    //fns


    const onDragEnd = useCallback((result: DropResult) => {
        // If dropped outside the list, do nothing
        if (!result.destination) {
            return;
        }

        const { source, destination } = result;

        // If the item is dropped back into its original position, do nothing
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        if (isItemPinned(selectedParams[source.index]) && destination.index >= pinsCount) {
            return
        }
        if (!isItemPinned(selectedParams[source.index]) && destination.index <= pinsCount) {
            return
        }


        // Perform the index swap
        const items = Array.from(selectedParams); // Replace 'yourItemsArray' with your actual array

        let item = cloneDeep(items[source.index])

        const removed = items.splice(source.index, 1)
        items.splice(destination.index, 0, item)
        onUpdateParam(items)

        // Update your state with the new order
        // onUpdateParam(items); // Replace 'setYourItemsArray' with your state setter
    }, [selectedParams, pinsCount])

    const removeParam = (index: number) => {
        let newSelectedParams = cloneDeep(selectedParams)
        newSelectedParams.splice(index, 1)
        onUpdateParam(newSelectedParams)

    }

    const isItemPinned = (item: TAnalyticParams): boolean => {
        return pinnedParams.map(e => e['@id']).includes(item['@id'])
    }


    const updatePins = (param: TAnalyticParams) => {
        let idx = pinnedParams.findIndex(e => e?.['@id'] === param['@id'])

        if (idx === -1) {
            updatePin([...pinnedParams, param])
        } else {
            updatePin(pinnedParams.filter((e, i) => i !== idx))
        }

    }

    //Effects
    useEffect(() => {
        setPinsCount(pinnedParams.length)
    }, [pinnedParams])






    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Box sx={{ borderBlock: '1px solid #eee', flex: 1, overflowY: 'auto', maxHeight: 500, p: 1 }} role="presentation">
                <Droppable droppableId={'droppable-params'} type="PARAM">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {selectedParams.map((param, i) => (
                                <Draggable
                                    key={param.id}
                                    draggableId={`drag-${param.id}`}
                                    index={i}
                                >
                                    {(provided, snapshot) => (
                                        <Card
                                            id='draggable'
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                            sx={{
                                                userSelect: "none",
                                                padding: 1,
                                                margin: "0 0 8px 0",
                                                minHeight: "50px",
                                                borderRadius: "4px",
                                                backgroundColor: snapshot.isDragging
                                                    ? "var(--active)" :"",
                                                    // : isItemPinned(param) ? 'whitesmoke' : 'white',
                                                ...provided.draggableProps.style,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Box display={'flex'} gap={1} >
                                                <Reorder color={"disabled"}></Reorder>
                                                <Typography>{param.name_translated}</Typography>
                                            </Box>
                                            <Box display={'flex'} gap={1}>
                                                <IconButton onClick={() => updatePins(param)}>
                                                    {isItemPinned(param) ? <PushPin color='primary'></PushPin> : <PushPinOutlined></PushPinOutlined>}
                                                </IconButton>
                                                <IconButton onClick={() => removeParam(i)} size='small' color='error'>
                                                    <Delete></Delete>
                                                </IconButton>

                                            </Box>

                                        </Card>
                                    )}
                                </Draggable>

                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>


            </Box >
        </DragDropContext >
    )
}

export default memo(DraggableParams)