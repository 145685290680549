import { AxiosResponse } from "axios";
import { TShoeing } from "../shared/types/shoeing.type";
import axiosInstance from "./base";

export class Shoeing {

    static getShoeing(trainer?: number): Promise<AxiosResponse<Array<TShoeing>>> {
        return axiosInstance.get(`horse_shoeings?trainer=${trainer}`)
    }

    static save(data: Partial<TShoeing>): Promise<AxiosResponse<TShoeing>> {
        return axiosInstance.post('horse_shoeings', data)
    }

    static update(data: Partial<TShoeing>): Promise<AxiosResponse<TShoeing>> {
        return axiosInstance.patch(`horse_shoeings/${data.id}`, data, {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            }
        })
    }

    static delete(id: number) {
        return axiosInstance.delete(`horse_shoeings/${id}`)

    }

}