import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TAnalyticParams } from '../../shared/types/analyticParameters.type'
import AvailableParams from './AvailableParams'
import DraggableParams from './DraggableParams'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { LoadingButton } from '@mui/lab'
import { t } from 'i18next'
import { Search } from '@mui/icons-material'
import { debounce, isEmpty, lowerCase } from 'lodash'

type ParamsSectionProps = {
    loading: boolean,
    groupedParams: Partial<Record<string, TAnalyticParams[]>>,
    selectedParams: TAnalyticParams[],
    pinnedParams: TAnalyticParams[],
    onUpdateParam: (data: TAnalyticParams[]) => void,
    allParams: TAnalyticParams[],
    onParamCheck: (item: TAnalyticParams, checked: boolean) => void,
    isUpdating?: boolean,
    saveSettings: () => void,
    updatePin: (item: TAnalyticParams[]) => void


}

function ParamsSection({ loading, groupedParams, selectedParams, onUpdateParam, allParams, onParamCheck, isUpdating, saveSettings, pinnedParams, updatePin }: ParamsSectionProps) {
    const [goto, setGoto] = useState('')
    //fns


    //Effects

    useEffect(() => {
        if (!goto) return
        let el = document.querySelector(`.${goto}`) as HTMLElement
        var parentContainer = el.parentElement as HTMLElement | null
        parentContainer && parentContainer.scrollIntoView();

        // Scroll to the target nested div
        el.scrollIntoView({ behavior: 'smooth' });
    }, [goto])


    return (
        <Accordion elevation={1} sx={{ mt: 2 }} defaultExpanded>
            <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Box display={'flex'} justifyContent={'end'} alignItems={'center'} width={'100%'}>
                    <LoadingButton loading={isUpdating} variant='contained' sx={{ mr: 2 }} onClick={e => {
                        e.stopPropagation()
                        saveSettings()
                    }}>{t('button:save')}</LoadingButton>
                </Box>

            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={1} mt={2}>
                    <Grid item md={12} sm={12} xs={12}>
                        <Grid item md={6} sm={12} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{t('settings:goTo')}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label={t('settings:goTo')}
                                    value={goto}
                                    onChange={(e) => {
                                        setGoto(e.target.value)
                                    }}
                                >
                                    {Object.keys(groupedParams).map((e, i) => (
                                        <MenuItem value={e} key={i}>{t('dataParameters:groups:' + e)}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <AvailableParams onParamCheck={onParamCheck} allParams={allParams} groupedParams={groupedParams} loading={loading} selectedParams={selectedParams}></AvailableParams>
                    </Grid>

                    <Grid item md={6} sm={12} xs={12}>
                        <DraggableParams updatePin={updatePin} pinnedParams={pinnedParams} selectedParams={selectedParams} onUpdateParam={onUpdateParam}></DraggableParams>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>

    )
}

export default ParamsSection