import { Skeleton } from '@mui/material'
import React from 'react'

function TrackCardSkeleton() {
    return (
        <>
            <Skeleton variant="rectangular" width={'100%'} height={'250px'} sx={{borderRadius:3}} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={120} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={120} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={120} />


        </>
    )
}

export default TrackCardSkeleton