import { Container, Divider } from '@mui/material'
import React, { useEffect } from 'react'
import Header from '../../components/dashboard/Header'
import { useExam } from '../../hooks/useExam'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { TUser } from '../../shared/types/user.type'
import { resetCheckedExam, setCategories, setData } from '../../store/reducers/comparaison.reducer'
import { TgroupKeyParam, groupKeyParamsByCategories } from '../../shared/utils/comparaison'
import { toast } from 'react-toastify'
import { t } from 'i18next'
import ComparaisonSection from '../../components/comparaison/ComparaisonSection'
import { TtrainingKeyParameter } from '../../shared/types/trainingKeyParameter.type'
import { useSearchParams } from 'react-router-dom'
import { toNumber } from 'lodash'
import { useAuth } from '../../hooks/useAuth'

function ExternalComparaison() {
    const user = useSelector<RootState>(state => state.user.user) as TUser | null
    const { compare } = useExam(false, user?.['@id'])
    const dispatch = useDispatch()
    const categories = useSelector<RootState>(state => state.comparaison.categories) as TgroupKeyParam[]
    const data = useSelector<RootState>(state => state.comparaison.data) as TtrainingKeyParameter
    let [searchParams, setSearchParams] = useSearchParams();
    const { setToken, getUser, isUserFetching } = useAuth()

    //query params
    const TOKEN = searchParams.get('token')
    const EXAMS = searchParams.get('exams')

    //end query params

    //effects

    useEffect(() => {
        if (user) {
            dispatch(resetCheckedExam())
            compareTrainings(true)
        }

    }, [user, EXAMS])


    useEffect(() => {
        //if token defined set 
        if (TOKEN) {
            setToken(TOKEN)

            if (!user) {
                getUser()
            }

        }
    }, [TOKEN, user])

    //fns
    const getUrlStrfromIDS = (ids: number[]) => {
        let str: string[] = []
        ids.forEach(id => {
            str.push(`training[]=${id}`)
        })

        return str.join('&')
    }




    const compareTrainings = (useIds: boolean = true) => {
        if (!EXAMS) return

        let ids: number[] = EXAMS.split(',').map(e => toNumber(e))
        compare.mutateAsync(useIds ? getUrlStrfromIDS(ids) : '')
            .then(({ data }) => {
                dispatch(resetCheckedExam())
                dispatch(setCategories(groupKeyParamsByCategories(data)))
                dispatch(setData(data))
            }).catch(err => {
                console.log({ err });
                toast(t('error:generic'), { type: "error" })
            })

    }

    return (
        <Container maxWidth='lg' sx={{ mt: 5 }}>
            <Header text='navbar:comparison'>
            </Header>

            <Divider sx={{ my: 2 }}></Divider>

            <ComparaisonSection compare={() => compareTrainings()} data={data} categories={categories} loading={compare.isLoading || isUserFetching}></ComparaisonSection>

        </Container>
    )
}

export default ExternalComparaison