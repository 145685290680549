import React from 'react'
import { ApiError } from '../../shared/types/apiError.type'
import { isString } from 'lodash'
import { Alert } from '@mui/material'

type errorProps = {
  isError: boolean,
  error: ApiError
}

function Errors({ isError, error }: errorProps) {

  if (isError) {
    if (isString(error?.message)) {
      return <Alert sx={{my:2}} severity='error'>{error.message}</Alert>
    } else {
      return <Alert sx={{my:2}} severity='error'>
        <ul>
          {error?.message?.map(message => (
            <li>{message}</li>
          ))}
        </ul>
      </Alert>
    }
  }
  return (
    <></>
  )
}

export default Errors