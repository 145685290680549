import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useExam } from '../../hooks/useExam'
import { useBeforeUnload, useParams } from 'react-router-dom';
import { get, isEmpty, isNumber, minBy, toNumber, uniqBy } from 'lodash';
import Header from '../../components/dashboard/Header';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { TUser } from '../../shared/types/user.type';
import TrackViewSkeleton from '../../components/tracks/TrackViewSkeleton';
import { formatDate } from '../../shared/utils/DateUtils';
import { Box, Button, Divider, Fab, Tooltip } from '@mui/material';
import { t } from 'i18next';
import { AdminPanelSettings, Download, Edit, Upgrade, Visibility } from '@mui/icons-material';
import InformationSection from '../../components/exams/InformationSection';
import KeyParamSection from '../../components/exams/KeyParamSection';
import GaitAndHrZone from '../../components/exams/GaitAndHrZone';
import MapSection from '../../components/exams/MapSection';
import ChartsSection from '../../components/exams/ChartsSection';
import { setEditTrainingModal, setTrainingToEdit } from '../../store/reducers/modal.reducer';
import Error from '../../components/global/Error';
import CheckpointsSection from '../../components/exams/CheckpointsSection';
import BaseAccordion from '../../components/MyTrainings/BaseAccordion';
import { TrainingSetting } from '../../shared/types/trainingSetting.type';
import Hideable from '../../components/global/Hideable';
import axios, { AxiosResponse } from 'axios';
import { TTransposedTraining } from '../../shared/types/transposedTraining.type';
import { useMutation } from 'react-query';
import { CheckPoint } from '../../shared/types/training.type';
import Banner from '../../components/admin/Banner';
import TabsGraphs from '../../components/exams/TabsGraphs';
import { useAuth } from '../../hooks/useAuth';
import { setUser } from '../../store/reducers/user.reducer';

export type Scale = 'DISTANCE' | 'TIME'
function ExamView() {
    let { id } = useParams();
    const [fabHovered, setFabHovered] = useState(false)
    const [showBanner, setShowBanner] = useState(false)
    const [scaleOption, setScaleOption] = useState<Scale>('DISTANCE')
    const [distance, setDistance] = useState<string>('0 mi')
    const [speed, setSpeed] = useState<number[]>([])
    const [bpm, setBpm] = useState<number[]>([])
    const [time, setTime] = useState<number[]>([])
    const [rawDistance, setDistanceRaw] = useState<number[]>([])
    const [strideFrequency, setStrideFrequency] = useState<number[]>([])
    const [strideLength, setStrideLength] = useState<number[]>([])
    const [currentIndex, setCurrentindex] = useState(0)
    const { exam, isExamLoading, examError } = useExam(false, null, toNumber(id))
    const isFinishLineActive = exam?.training?.use_finish_line ?? false
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const userSettings = useSelector<RootState>(state => state.user.user?.training_settings) as TrainingSetting
    const initialSpeedUnit = useSelector<RootState>(state => state.user.initialSpeedUnit) as string
    const initialSysUnit = useSelector<RootState>(state => state.user.initialUnitSystem) as string
    const transposedMutation = useMutation((): Promise<AxiosResponse<TTransposedTraining>> => axios.get(exam?.training?.moments_transposed_file_url as string))
    const { isAdmin, auth } = useAuth()


    const handleEdit = () => {
        exam && dispatch(setTrainingToEdit(exam))
        dispatch(setEditTrainingModal(true))
    }

    //Effects

    useEffect(() => {
        if (!userSettings.display_section_map && exam?.training?.moments_transposed_file_url) {
            transposedMutation.mutateAsync()
                .then((res) => {
                    setSpeed(res?.data?.speed ?? [])
                    setBpm(res?.data?.bpm ?? [])
                    setDistanceRaw(res.data?.distance ?? [])
                    setTime(res.data?.timestamp ?? [])
                    setStrideFrequency(res.data?.pace ?? [])
                    setStrideLength(res.data?.amplitude ?? [])

                }).catch(err => {
                    console.log({ err });

                })
        }
    }, [exam?.training?.moments_transposed_file_url, userSettings.display_section_map])


    useBeforeUnload(
        () => {
            if (isAdmin) {
                //Reset inital values
                dispatch(setUser({ ...auth, default_speed_unit: initialSpeedUnit }))
                dispatch(setUser({ ...auth, unit_system: initialSysUnit as "metric" | "imperial" }))

            }
        }
    )

    const filteredCheckPoints = useMemo(() => {
        let cps = exam?.training?.checkpoints
        if (cps && cps.length > 0) {

            let checkpoints = uniqBy(cps.map(cp => cp.checkpoint), '@id')

            let checkPointsMin: CheckPoint[] = []
            checkpoints.forEach(cp => {

                let getDataBydId = cps?.filter(c => c?.checkpoint?.['@id'] === cp?.['@id']) ?? []

                let data = minBy(getDataBydId, 'index_moment')

                data && checkPointsMin.push(data)
            })
            return checkPointsMin

        } else {
            return []
        }
    }, [exam?.training?.checkpoints])

    if (isExamLoading) {
        return <TrackViewSkeleton></TrackViewSkeleton>
    }

    return (
        <>
            <Banner exam={exam?.training} macAdress={exam?.training?.equimetre} horse={exam?.training?.horse_trainer?.horse} trainer={exam?.training?.rider} open={showBanner} handleClose={() => setShowBanner(false)}></Banner>
            <Header text={exam?.training?.horse_trainer?.horse?.name ?? "navbar:myTrainings"} date={`${formatDate(exam?.training?.creation_date ?? "", 'ddd MMM DD YYYY - LT')}`}>
                <Box display={'flex'} alignItems={'center'} gap={1} flexWrap={'wrap'}>
                    <Button onClick={handleEdit} color='warning' startIcon={<Edit></Edit>} disableElevation variant='outlined'>{t('firstSection:editTraining')}</Button>
                    <Button startIcon={<Upgrade></Upgrade>} disableElevation variant='contained'>{t('report:exportTraining')}</Button>
                    <Tooltip title={t('report:infoText')} arrow>
                        <Button startIcon={<Download></Download>} disableElevation variant='contained'>{t('report:buttonText')}</Button>
                    </Tooltip>
                </Box>
            </Header>
            <Divider sx={{ my: 2 }}></Divider>
            <InformationSection
                horse={exam?.training?.horse_trainer?.horse ?? null}
                rider={exam?.training?.rider?.name ?? null}
                intensity={exam?.training?.intensity ?? 0}
                duration={exam?.training?.duration ?? 0}
                trainingType={exam?.training?.training_type?.display_name ?? null}
                weather={exam?.training?.weather}
                horseShoe={exam?.training?.horse_shoeing?.display_name}
                track={{ id: exam?.training?.track_name?.id, name: exam?.training?.track_name?.name, trackCondition: exam?.training?.track_condition?.display_name, trackSurfaceKey: exam?.training?.track_surface?.translation_key, trackSurfaceName: exam?.training?.track_surface?.name }}
                insight={exam?.training?.insight}
            ></InformationSection>
            <Box my={3}></Box>
            <Hideable isVisible={userSettings.display_section_key_parameters}>
                <KeyParamSection keyParams={exam?.computed_key_parameters}></KeyParamSection>

            </Hideable>
            <Box my={3}></Box>
            <Hideable isVisible={userSettings.display_section_averages}>

                <GaitAndHrZone maxHr={exam?.hr_max} data={exam?.averages_and_hr_zones}></GaitAndHrZone>

            </Hideable>
            <Box my={3}></Box>
            <Hideable isVisible={userSettings.display_section_map}>
                <MapSection
                    enabled
                    setStrideFrequency={(val) => setStrideFrequency(val)}
                    setStrideLength={val => setStrideLength(val)}
                    setTime={val => setTime(val)}
                    setDistanceRaw={val => setDistanceRaw(val)}
                    setSpeed={value => setSpeed(value)}
                    setBpm={value => setBpm(value)}
                    setDistance={value => setDistance(value ?? "")}
                    transposedFile={exam?.training?.moments_transposed_file_url}
                    chronology={exam?.averages_and_hr_zones?.gait_chronology}
                    flIndex={exam?.training?.finish_line?.index_moment ?? null}
                    finishLine={exam?.training?.finish_line}
                    checkPoints={filteredCheckPoints}
                    onIndexChange={(value) => setCurrentindex(value)}
                    setIsLoading={(value) => setLoading(value)}
                    isFinishLineActive={isFinishLineActive}
                    enableCheckbox={false}
                    showSave={false}

                ></MapSection>
            </Hideable>
            <Box my={3}></Box>

            <TabsGraphs
                strideFrequency={strideFrequency}
                strideLength={strideLength}
                time={time}
                rawDistance={rawDistance}
                speed={speed}
                bpm={bpm}
                editable={false}
                distance={distance}
                scaleOption={scaleOption}
                onChangeScale={(val: Scale) => setScaleOption(val)}
                currentIndex={currentIndex}
                flIndex={exam?.training?.finish_line?.index_moment ?? null}
                loading={loading}
                heats={exam?.training?.heats ?? []}
                isFinishLineActive={isFinishLineActive}
                intervals={exam?.training?.training_sections ?? []}
                checkPoints={exam?.training?.checkpoints ?? []}
                id={exam?.training?.id}
            ></TabsGraphs>

            {
                isAdmin && <Fab onClick={() => setShowBanner(true)} color='secondary' variant={fabHovered ? 'extended' : 'circular'} size='small' onMouseEnter={() => setFabHovered(true)} onMouseLeave={() => setFabHovered(false)} sx={{ position: 'fixed', bottom: 16, right: 16 }}>
                    <AdminPanelSettings sx={{ mr: fabHovered ? 1 : 0 }}></AdminPanelSettings>
                    {fabHovered && 'Show admin banner'}
                </Fab>
            }


        </>
    )
}

export default ExamView