import { Alert, Autocomplete, Box, Button, Checkbox, Chip, Divider, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Slider, Stack, Switch, TextField, Typography } from '@mui/material'
import { t } from 'i18next'
import { cloneDeep, flatten, get, isArray, isEmpty, isNull, isNumber, toNumber, uniqBy, upperCase } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { TTrainerHorse } from '../../shared/types/trainerHorse.type'
import { Ttrack } from '../../shared/types/tracks.type'
import { TTrainingType } from '../../shared/types/training.type'
import { ScheduleType } from '../../enums/schedules.enums'
import { weekDays } from '../../enums/days.enums'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { TSchedule, schedules } from '../../shared/types/schedule.type'
import { LoadingButton } from '@mui/lab'
import Schedules from './Schedules'
import { THorse } from '../../shared/types/horse.type'
import ListSkeleton from '../exams/loader/ListSkeleton'
import { CheckBox } from '@mui/icons-material'
dayjs.extend(utc)
dayjs.extend(timezone)

type NewChannelStep1Props = {
    step: number,
    onSave: (data: Partial<TSchedule>) => void,
    horses: TTrainerHorse[],
    tracks: Ttrack[],
    trainingTypes: TTrainingType[],
    loading: boolean,
    onBack: () => void,
    itemToEdit: TSchedule | null,
    dataLoading: boolean,
    dataConfig?: Partial<TSchedule>,
    configType?: "SIMPLE" | "ADVANCED"

}

export type ScheduleValues = 'FIXED_TIME' | 'SYNCHRONISED_LAST_HOUR'

function NewConfigStep3({ configType, step, onSave, tracks, horses, trainingTypes, loading, onBack, itemToEdit, dataLoading, dataConfig }: NewChannelStep1Props) {

    const DEFAULT_BEST600: number = 46

    //Data State
    const [selectedTracks, setSelectedTracks] = useState<Ttrack[]>([])
    const [selectedTypes, setSelectedTypes] = React.useState<TTrainingType[]>([]);
    const [selectedHorses, setSelectedHorses] = React.useState<THorse[]>([]);
    const [selectedAges, setSelectedAges] = React.useState<string[]>([]);
    const [schedules, setSchedules] = useState<Partial<schedules>[]>([])
    const [best600, setBest600] = useState<number>(DEFAULT_BEST600)
    const [includePdf, setIncludePdf] = useState(false)
    const [mergePdf, setMergePdf] = useState(false)
    //
    const [checkedRadio, setCheckedRadio] = useState<'separated' | 'joined' | 'none'>('separated')


    useEffect(() => {

        if (!itemToEdit) {
            setSelectedTracks([...tracks].filter(e => dataConfig?.track_names?.includes(e['@id'])))
            console.log({ dataConfig });

            setSelectedHorses([...horses].filter(e => dataConfig?.horses?.includes(e.horse['@id'])).map(e => e?.horse))

            setSelectedTypes([...trainingTypes].filter(e => dataConfig?.training_types?.includes(e['@id'])))
            setBest600(dataConfig?.max_best600 ?? DEFAULT_BEST600)

            let include_pdfs = dataConfig?.include_pdfs ?? true
            let merge_pdfs = dataConfig?.merge_pdfs ?? false

            setRadioValue(include_pdfs, merge_pdfs)

            setIncludePdf(include_pdfs)
            setMergePdf(merge_pdfs)
        }
    }, [dataConfig, itemToEdit])

    useEffect(() => {
        if (!isNull(itemToEdit)) {

            setSelectedTracks(get(itemToEdit, 'track_names', []) as Ttrack[])

            setSelectedHorses(get(itemToEdit, 'horses', []) as THorse[])

            setSelectedTypes(get(itemToEdit, 'training_types', []) as TTrainingType[])

            // setSchedules(itemToEdit.schedules.map(s => ({ ...s, day_of_week: isNumber(s.day_of_week) ? [s.day_of_week] : s.day_of_week })))

            setBest600(itemToEdit?.max_best600)

            setIncludePdf(itemToEdit.include_pdfs)
            setMergePdf(itemToEdit.merge_pdfs)

            setRadioValue(itemToEdit.include_pdfs, itemToEdit.merge_pdfs)

        }
    }, [itemToEdit])


    const _horses = useMemo(() => {
        return uniqBy(horses.map(e => e.horse), '@id')
    }, [horses])


    useEffect(() => {
        if (configType === 'SIMPLE') {
            handleRadioChange('separated')
        }
    }, [configType])


    const dataObject: Partial<TSchedule> = {
        ...(configType === 'ADVANCED' ? {
            horses: selectedHorses.map(e => e?.['@id']),
            track_names: selectedTracks.map(e => e?.['@id']),
            training_types: selectedTypes.map(e => e?.['@id']),
        } : {}),
        max_best600: best600,
        include_pdfs: includePdf,
        merge_pdfs: mergePdf
    }



    const handleRadioChange = (key: 'separated' | 'joined' | 'none') => {
        setCheckedRadio(key)
        switch (key) {
            case 'separated':
                setIncludePdf(true)
                setMergePdf(false)
                break;
            case 'joined':
                setIncludePdf(true)
                setMergePdf(true)
                break;
            case 'none':
                setIncludePdf(false)
                setMergePdf(false)
                break;
            default:
                break;
        }
    }

    const setRadioValue = (include: boolean, merge: boolean) => {
        if (include && !merge) {
            setCheckedRadio('separated')
        }
        if (include && merge) {
            setCheckedRadio('joined')
        }
        if (!include && !merge) {
            setCheckedRadio('none')
        }
    }
    return (
        <>
            {
                dataLoading ?
                    <ListSkeleton height={50} length={5}></ListSkeleton> : <>
                        <Box mt={4} width={'100%'}>

                            {
                                configType === 'ADVANCED' && <>

                                    <Box my={2} >
                                        <Typography variant='subtitle2'>{t('editTrainingForm:trackName:label')}</Typography>

                                        <FormControl size='small' fullWidth>
                                            <Autocomplete
                                                id="demo-simple-select"
                                                options={tracks}
                                                multiple
                                                disableCloseOnSelect
                                                isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                                getOptionLabel={(option) => option.display_name ?? ' - '}
                                                getOptionKey={option => option.id}
                                                value={selectedTracks}
                                                renderInput={(params) => <TextField {...params} placeholder={t('editTrainingForm:trackName:label')} />}
                                                onChange={(_, values) => setSelectedTracks(values)}
                                            ></Autocomplete>

                                        </FormControl>
                                        <FormControlLabel
                                            control={<Checkbox
                                                onChange={(_, checked) => {
                                                    if (checked) {
                                                        setSelectedTracks([])
                                                    }
                                                }}
                                                color='default'
                                                checked={selectedTracks.length === 0}
                                                size='small' />} label={<Typography variant='body2'>{t('ownerSelection:selectAll')}</Typography>}></FormControlLabel>


                                    </Box>
                                    <Box my={2}>
                                        <Typography variant='subtitle2'>{t('editTrainingForm:trainingType:label')}</Typography>

                                        <FormControl size='small' fullWidth>
                                            <Autocomplete
                                                id="demo-simple-select"
                                                options={trainingTypes}
                                                isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                                multiple
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option.display_name ?? ' - '}
                                                getOptionKey={option => option.id}
                                                value={selectedTypes}
                                                renderInput={(params) => <TextField {...params} placeholder={t('editTrainingForm:trainingType:label')} />}
                                                onChange={(_, values) => setSelectedTypes(values)}
                                            ></Autocomplete>


                                        </FormControl>
                                        <FormControlLabel
                                            control={<Checkbox onChange={(_, checked) => {
                                                if (checked) {
                                                    setSelectedTypes([])
                                                }
                                            }}
                                                color='default'

                                                checked={selectedTypes.length === 0} size='small' />} label={<Typography variant='body2'>{t('ownerSelection:selectAll')}</Typography>}></FormControlLabel>

                                    </Box>

                                    <Box my={2}>
                                        <Typography variant='subtitle2'>{t('horses')}</Typography>
                                        <FormControl size='small' fullWidth>
                                            <Autocomplete
                                                id="demo-simple-select"
                                                options={_horses}
                                                multiple
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => (option?.display_name ?? option?.name) ?? ' - '}
                                                isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                                                getOptionKey={option => option.id}
                                                value={selectedHorses}
                                                renderInput={(params) => <TextField {...params} placeholder={t('horses')} />}
                                                onChange={(_, values) => {
                                                    setSelectedHorses(values)
                                                }}
                                            ></Autocomplete>
                                        </FormControl>
                                        <FormControlLabel
                                            control={<Checkbox onChange={(_, checked) => {
                                                if (checked) {
                                                    setSelectedHorses([])
                                                }
                                            }}
                                                color='default'
                                                checked={selectedHorses.length === 0} size='small' />} label={<Typography variant='body2'>{t('ownerSelection:selectAll')}</Typography>}></FormControlLabel>
                                    </Box>
                                </>
                            }

                            {
                                configType === 'SIMPLE' && <Alert variant='outlined' color='info'>
                                    {t('configuration:buildSimpleHint')}
                                </Alert>
                            }
                            <Box display={'flex'} flexDirection={'column'} my={2}>
                                <Box mb={1} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography variant='body2' fontWeight={500}>{t('notification:field:showBest600')}</Typography>
                                    <Typography variant='caption'>{best600} / 46</Typography>
                                </Box>
                                <Slider
                                    min={31}
                                    max={46}
                                    step={0.1}
                                    valueLabelDisplay="auto"
                                    value={best600}
                                    onChange={(_, value) => {
                                        setBest600(value as number)
                                    }}
                                ></Slider>
                            </Box>
                            {
                                configType === 'ADVANCED' && <Box >
                                    <Typography variant='body2' fontWeight={500}>{t('configuration:attachements')}</Typography>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={checkedRadio}
                                            name="radio-buttons-group"
                                            
                                            onChange={(_, value) => handleRadioChange(value as 'separated' | 'joined' | 'none')}
                                        >
                                            <FormControlLabel value="separated" control={<Radio color='default'  />} label={<Typography variant='subtitle2'>{t('configuration:attachements:separated')}</Typography>} />
                                            <FormControlLabel value="joined" control={<Radio color='default'  />} label={<Typography variant='subtitle2'>{t('configuration:attachements:joined')}</Typography>} />
                                            <FormControlLabel value="none" control={<Radio color='default'  />} label={<Typography variant='subtitle2'>{t('configuration:attachements:none')}</Typography>} />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            }

                        </Box>
                        <Divider sx={{ my: 3 }}></Divider>
                        <Box display={'flex'} justifyContent={'end'} >
                            <LoadingButton loading={loading} onClick={() => onSave(dataObject)} variant='contained'>{t('button:next')}</LoadingButton>
                        </Box>
                    </>
            }

        </>

    )
}

export default NewConfigStep3