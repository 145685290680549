import React from 'react'
import { TExam } from '../../shared/types/exam.type'
import { Box, Card, CircularProgress, Divider, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import { formatDate } from '../../shared/utils/DateUtils'
import { Ttraining } from '../../shared/types/training.type'

type ListExamProps = {
  exams: Ttraining[]
  isLoading: boolean,
  onExamClicked: (exam: Ttraining) => void
}
function ListExam({ exams, isLoading, onExamClicked }: ListExamProps) {

  return (
    <Card>
      {isLoading ?
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} my={3}>
          <CircularProgress></CircularProgress>
        </Box>
        : <Box p={3}>
          <List disablePadding>
            {exams.map(exam => (
              <React.Fragment key={exam.id}>
                <ListItem  disableGutters>
                  <ListItemButton
                    onClick={() => onExamClicked(exam)}
                  >
                    <ListItemText
                      primary={exam.horse_trainer?.horse?.name ?? ""}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {formatDate(exam.creation_date, 'LT')}
                          </Typography>

                        </React.Fragment>
                      }
                    />
                  </ListItemButton>

                </ListItem>
                <Divider></Divider>
              </React.Fragment>
            ))}

          </List>

        </Box>}
    </Card>
  )
}

export default ListExam