import React, { ReactElement, useEffect, memo } from 'react'
import { Navigate } from 'react-router-dom'
import { routes } from '../routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'

import { useQuery } from 'react-query'
import { User } from '../../api/user'
import { setInitialSpeedUnit, setInitialUnitSystem, setIsUserLoading, setUser } from '../../store/reducers/user.reducer'
import { TUser } from '../../shared/types/user.type'
import { isNull } from 'lodash'
import i18n from '../../locales'
import { CircularProgress } from '@mui/material'
import dayjs from 'dayjs'



function GuardRoute({ children }: { children: ReactElement }) {
    //get Auth user
    const isAuth = useSelector<RootState>(state => state.user.isAuthenticated) as boolean
    const user = useSelector<RootState>(state => state.user.user) as TUser
    const dispatch = useDispatch()
    const authQuery = useQuery(['auth'], () => User.getUser(), {
        enabled: isAuth === true && isNull(user),
        refetchOnWindowFocus: false,
        onSuccess: (response) => {
            dispatch(setUser(response.data))
            dayjs.locale(response.data.lang)
            window.localStorage.setItem('lang', response.data?.lang)
            dispatch(setInitialSpeedUnit(response.data.default_speed_unit))
            dispatch(setInitialUnitSystem(response.data.unit_system))
            dispatch(setIsUserLoading(false))
        },
        onError: () => {
            dispatch(setIsUserLoading(false))
        }
    })
    if (authQuery.isLoading) {
        dispatch(setIsUserLoading(true))
    }


    if (!isAuth) {
        return <Navigate to={routes.AUTH_ROUTES.LOGIN}></Navigate>
    }
    return (
        <>
            {children}
        </>
    )
}

export default GuardRoute