// ----------------------------------------------------------------------

export default function LinearProgress(theme) {
    const isLight = theme.palette.mode === 'light';

    return {
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                   
                }
            },
        },
    };
}
