import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { TrainingSetting } from '../../shared/types/trainingSetting.type'

function GraphsSectionsSettings({ updateSettings, loading }: { updateSettings: (key: string, checked: boolean) => void, loading: boolean }) {
    const userSettings = useSelector<RootState>(state => state.user?.user?.training_settings) as TrainingSetting

    const DISPLAY_SETTINGS = useMemo(() => ([{
        key: userSettings.display_graph_bpm,
        title: 'settings:displayGraphBPM',
        checkBoxKey: "display_graph_bpm",
        disabled: false,
    },
    {
        key: userSettings.display_graph_speed,
        title: 'settings:displayGraphSpeed',
        checkBoxKey: "display_graph_speed",
        disabled: false,
    },
    {
        key: userSettings.display_graph_stride_frequency,
        title: 'settings:displayGraphStrideFrequency',
        checkBoxKey: "display_graph_stride_frequency",
        disabled: false,

    },
    {
        key: userSettings.display_graph_stride_length,
        title: 'settings:displayGraphStrideLength',
        checkBoxKey: "display_graph_stride_length",
        disabled: false,

    },
    {
        key: userSettings.merge_graphs_speed_bpm,
        title: 'settings:mergeGraphsSpeedBpm',
        checkBoxKey: "merge_graphs_speed_bpm",
        disabled: !userSettings.display_graph_bpm || !userSettings.display_graph_speed
    },
    {
        key: userSettings.merge_graphs_stride_frequency_stride_length,
        title: 'settings:mergeGraphsStrideLengthStrideFrequency',
        checkBoxKey: "merge_graphs_stride_frequency_stride_length",
        disabled: !userSettings.display_graph_stride_length || !userSettings.display_graph_stride_frequency

    },

    ]), [userSettings])

    useEffect(() => {
        let speed = userSettings.display_graph_speed
        let bpm = userSettings.display_graph_bpm

        if ((!speed || !bpm) && userSettings.merge_graphs_speed_bpm) {
            updateSettings('merge_graphs_speed_bpm', false)
        }


        let frequency = userSettings.display_graph_stride_frequency
        let length = userSettings.display_graph_stride_length

        if ((!frequency || !length) && userSettings.merge_graphs_stride_frequency_stride_length) {
            updateSettings('merge_graphs_stride_frequency_stride_length', false)
        }

    }, [DISPLAY_SETTINGS])

    return (
        <Box display={'flex'} alignItems={'center'} gap={1}>
            <FormGroup row>
                {
                    DISPLAY_SETTINGS.map((setting, i) => (
                        <FormControlLabel
                            key={i}
                            control={
                                <Checkbox disabled={loading || setting.disabled} checked={setting.key} onChange={(e) => { updateSettings(setting.checkBoxKey, e.target.checked) }} size='small' />
                            }
                            label={<Typography variant='caption' fontWeight={500}>{t(setting.title)}</Typography>}
                        />
                    ))
                }
            </FormGroup>
        </Box>
    )
}

export default GraphsSectionsSettings