import { useMutation, useQuery } from "react-query"
import { Notification } from "../api/notification"
import { TChannel } from "../shared/types/Channel.type"
import { useState } from "react"
import { get } from "lodash"
import { TSchedule } from "../shared/types/schedule.type"
import { daDK } from "@mui/x-date-pickers"

export const useNotificationsSettings = (enableQuery = false, trainer?: string) => {

    const [channels, setChannels] = useState<TChannel[]>([])
    const [configs, setConfigs] = useState<TSchedule[]>([])

    //Mutations
    const saveChannelMutation = useMutation((data: Partial<TChannel>) => Notification.saveChannel(data))
    const saveWhatsappChannelMutation = useMutation((data: Partial<TChannel>) => Notification.saveWhatsappChannel(data))
    const saveConfigMutation = useMutation((data: Partial<TSchedule>) => Notification.saveConfig(data))
    const deleteChannelMutation = useMutation((id: number) => Notification.deleteChannel(id))
    const deleteWhatsappChannelMutation = useMutation((id: number) => Notification.deleteWhatsappChannel(id))
    const deleteConfigMutation = useMutation((id: number) => Notification.deleteConfig(id))
    const updateConfigMutation = useMutation(({ id, config }: { id: number, config: Partial<TSchedule> }) => Notification.updateConfig(id, config))
    const updateChannelMutation = useMutation(({ id, channel }: { id: number, channel: Pick<TChannel, 'lang' | 'name' | 'unit_system' | 'speed_unit' | 'targets' | 'user'> }) => Notification.updateChannel(id, channel))
    const updateWhatsappChannelMutation = useMutation(({ id, channel }: { id: number, channel: Pick<TChannel, 'lang' | 'name' | 'unit_system' | 'speed_unit' | 'targets' | 'user'> }) => Notification.updateWhatsappChannel(id, channel))
    const generateSchedulePhraseMutation = useMutation((data: Partial<TSchedule>) => Notification.generatePhrase(data))
    //query
    const query = useQuery(['channels'], () => Notification.getChannels(trainer), {
        refetchOnWindowFocus: false,
        cacheTime: 900000,
        enabled: enableQuery,
        onSuccess: (response) => {
            setChannels(get(response, 'data.hydra:member', []))
        }
    })

    const queryConfig = useQuery(['configs'], () => Notification.getConfigs(trainer), {
        refetchOnWindowFocus: false,
        cacheTime: 900000,
        enabled: enableQuery,
        onSuccess: (response) => {
            setConfigs(get(response, 'data.hydra:member', []))
        }
    })

    return {
        saveChannel: saveChannelMutation,
        saveConfig: saveConfigMutation,
        saveWhatsappChannel: saveWhatsappChannelMutation,
        updateWhatsappChannel: updateWhatsappChannelMutation,
        channels,
        configs,
        deleteConfig: deleteConfigMutation,
        deleteChannel: deleteChannelMutation,
        deleteWhatsappChannel: deleteWhatsappChannelMutation,
        updateConfig: updateConfigMutation,
        updateChannel: updateChannelMutation,
        channelsLoading: query.isLoading || query.isFetching,
        configsLoading: queryConfig.isLoading || queryConfig.isFetching,
        generatePhrase: generateSchedulePhraseMutation

    }
}