import React, { useEffect, useState } from 'react'
import { TSchedule, scheduleType, schedules } from '../../shared/types/schedule.type'
import { ScheduleType } from '../../enums/schedules.enums'
import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { t } from 'i18next'
import { weekDays } from '../../enums/days.enums'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { isEmpty, orderBy, sortBy, toNumber } from 'lodash'
import { Delete } from '@mui/icons-material'
import timezones from 'timezones-list';

dayjs.extend(utc)
dayjs.extend(timezone)

const initialObject: Partial<schedules> = {
    day_of_week: weekDays.map(e => e.value),
    hour: 24,
    minute: 15,
    type: 'FIXED_TIME',
    timezone: dayjs.tz.guess()

}

type SchedulesProps = {
    schedules: Partial<schedules>[]
    onScheduleChange: (values: Partial<schedules>[]) => void
}

function Schedules({ schedules, onScheduleChange }: SchedulesProps) {
    const [currentType, setCurrentType] = useState<scheduleType>('FIXED_TIME')


    useEffect(() => {
        if (isEmpty(schedules)) {
            addNewSchedule(currentType)
        } else {
            let type = schedules[0].type
            if (type) {
                setCurrentType(type)
            }
        }
    }, [currentType, schedules])


    //fns
    const addNewSchedule = (type: scheduleType) => {

        let schedule = [...schedules, { ...initialObject, type }]

        onScheduleChange(schedule)
    }
    const formatNumber = (num: number) => {
        if (String(num).length === 2) {
            return num
        } else {
            return String(num).padStart(2, '0')
        }
    }

    const updateState = (index: number, key: keyof schedules, newValue: any) => {
        let find = schedules[index]
        let _schedules = [...schedules]
        if (find) {
            _schedules[index] = { ...find, [key]: newValue }
            onScheduleChange(_schedules)
        }
    }

    const deleteLine = (i: number) => {
        let _schedules = [...schedules]
        _schedules.splice(i, 1)
        onScheduleChange(_schedules)

    }

    const timeArray = () => {
        let i: number[] = [0]
        let max = 45
        let step = 15
        while (i[i.length - 1] < max) {

            i.push(i[i.length - 1] + step)
        }
        return i
    }

    const isChecked = (schedule: Partial<schedules>) => {
        if (typeof schedule.day_of_week === 'number') {
            return false
        } else {
            return schedule.day_of_week?.length === weekDays.length
        }
    }

    const isTriggerMoreShown = () => {
        if (currentType === 'FIXED_TIME') return false

        if (isEmpty(schedules)) return false

        return schedules.some(e => {
            if (typeof e.day_of_week !== 'number') {
                return e.day_of_week?.length === weekDays.length
            }

        })

    }

    //
    return (

        <Box p={3} display={'flex'} flexDirection={'column'}>
            <Typography variant='subtitle2'>{t('configuration:triggers')}</Typography>
            <FormControl size='small'  >
                <RadioGroup

                    aria-labelledby="demo-radio-buttons-group-label"
                    value={currentType}
                    name="radio-buttons-group"
                    onChange={(_, value) => {
                        let typeValue = value as "FIXED_TIME" | "SYNCHRONISED_LAST_HOUR"
                        setCurrentType(typeValue)
                        onScheduleChange(schedules.filter(s => s?.type === value))
                    }}
                >
                    {
                        ScheduleType.map(type => (
                            <FormControlLabel value={type.value} control={<Radio size='small' />} label={<Typography variant='body2'>{t(type.key)}</Typography>} />

                        ))
                    }

                </RadioGroup>
            </FormControl>

            <Stack direction={'column'} mt={2}>
                {schedules.map((schedule, i) => (
                    <>
                        <Box key={i} justifyContent={'space-between'} display={'flex'} alignItems={'center'} flexWrap={'wrap'} gap={1}>


                            <Box display={'flex'} gap={1} flex={2} >
                                <FormControl size='small' sx={{ flex: 1 }}>
                                    <InputLabel id="demo-simple-select-label">{t('settings.downloadingDays.unit')}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        multiple
                                        value={schedule.day_of_week}
                                        onChange={(e) => { updateState(i, 'day_of_week', e.target.value) }}
                                        label={t('settings.downloadingDays.unit')}


                                    >

                                        {weekDays.map((item) =>
                                            <MenuItem value={item.value}>{t(item.key)}</MenuItem>

                                        )}
                                    </Select>


                                </FormControl>
                                {
                                    schedule.type === 'FIXED_TIME' && <>
                                        <FormControl size='small' sx={{ flex: 1 }}>
                                            <InputLabel id="demo-simple-select-label">{t('notification:field:hour')}</InputLabel>
                                            <Select

                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"

                                                value={schedule.hour}
                                                onChange={(e) => updateState(i, 'hour', toNumber(e.target.value))}

                                                label={t('notification:field:hour')}>

                                                {[...Array(24)].map((item, i) =>
                                                    <MenuItem key={i} value={i + 1}>{i === 23 ? '00' : formatNumber(i + 1)}</MenuItem>

                                                )}
                                            </Select>


                                        </FormControl>

                                        <FormControl size='small' sx={{ flex: 1 }}>
                                            <InputLabel id="demo-simple-select-label">{t('notification:field:minutes')}</InputLabel>
                                            <Select

                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"

                                                value={schedule.minute}
                                                onChange={(e) => updateState(i, 'minute', toNumber(e.target.value))}
                                                label={t('notification:field:minutes')}>

                                                {timeArray().map((item, i) =>
                                                    <MenuItem key={i} value={item}>{formatNumber(item)}</MenuItem>

                                                )}
                                            </Select>


                                        </FormControl>

                                    </>
                                }
                                <FormControl size='small' sx={{ flex: 2 }}>
                                    <Autocomplete
                                        size='small'
                                        disablePortal
                                        id="combo-box-demo"
                                        value={timezones.find(e => e.tzCode === schedule?.timezone) ?? undefined}
                                        options={sortBy(timezones, (item) => item.tzCode)}
                                        getOptionKey={tz => tz.tzCode}
                                        onChange={(_, value) => updateState(i, 'timezone', value?.tzCode)}
                                        getOptionLabel={tz => tz.tzCode}
                                        renderInput={(params) => <TextField {...params} label={t('notification:field:timezone')} />}
                                    />
                                </FormControl>


                                <Tooltip title={t('button:delete')} arrow>
                                    <IconButton onClick={() => deleteLine(i)} size='small' color='error'>
                                        <Delete></Delete>
                                    </IconButton>
                                </Tooltip>

                            </Box>

                        </Box>
                        <FormControlLabel
                            sx={{ mb: 2 }}
                            control={<Checkbox checked={isChecked(schedule)} onChange={(_, checked) => {
                                updateState(i, 'day_of_week', checked ? weekDays.map(e => e.value) : [])
                            }} size='small' />}
                            label={<Typography variant='body2'>{t('configuration:everyDay')}</Typography>}></FormControlLabel>
                    </>
                ))}

            </Stack>

            {
                !isTriggerMoreShown() && <Box>
                    <Button onClick={() => addNewSchedule(currentType)} variant='outlined'>{t('configuration:addMoreTriggers')}</Button>
                </Box>
            }





        </Box>
    )
}

export default Schedules