import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, Divider, Grid, Rating, Tooltip, Typography } from '@mui/material'
import React, { memo } from 'react'
import { THorse } from '../../shared/types/horse.type'
import { t } from 'i18next'
import DistanceSvg from '../../assets/distance.svg'
import DurationSvg from '../../assets/duration.svg'
import CitySvg from '../../assets/city.svg'
import ShoeSvg from '../../assets/shoe.svg'
import horseTraining from '../../assets/horseTraining.svg'
import { GridArrowDownwardIcon } from '@mui/x-data-grid'
import { TWeather } from '../../shared/types/training.type'
import { get, toNumber } from 'lodash'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { Link } from 'react-router-dom'
import { routes } from '../../pages/routes'
import { convertSpeed } from '../../shared/utils/conversion'
import { TUser } from '../../shared/types/user.type'
type InformationSectionProps = {
    horse: THorse | null,
    rider?: string | null
    trainingType?: string | null
    horseShoe?: string | null
    intensity?: number
    duration?: number,
    weather?: TWeather | null,
    track?: { id?: number, name?: string, trackSurfaceKey?: string, trackSurfaceName?: string, trackCondition?: string },
    insight?: Record<string, string | number>
}

function InformationSection({ horse, rider, intensity, duration, horseShoe, trainingType, weather, track, insight }: InformationSectionProps) {
    const user = useSelector<RootState>(state => state.user.user) as TUser | null
    const temperature = useSelector<RootState>(state => state.user.user?.temperature_unit) as "fahrenheit" | "celsius" | undefined
    const metric = useSelector<RootState>(state => state.user.user?.unit_system) as "metric" | "imperial" | undefined

    const getWheterImage = () => {
        try {
            let icon = get(weather, 'data[0].weather.icon', null)

            return icon ? require(`../../assets/${weather?.data[0].weather.icon}.png`) : require('../../assets/na.png')
        } catch {
            return require('../../assets/na.png')

        }
    }

    const getSurfaceImage = () => {
        if (track?.trackSurfaceName) {
            return require(`../../assets/track-surfaces/${track?.trackSurfaceName}.png`)
        }

    }

    const convertDistanceKm = () => {
        let distance = get(insight, 'distance')
        if (!distance) {
            return ' - '
        }

        return convertSpeed(metric, toNumber(distance))
    }

    const getTrackName = () => {
        return get(track, 'name', ' - ')
    }

    const getWeathercode = (): string => {
        try {
            let code = get(weather, 'data[0].weather.code', "")

            return code ? t(`weather:code:${code}`) : ' - '
        } catch {
            return ' - '

        }
    }

    const route = (id?: number) => {
        if (!id) return "#"
        return `${routes.DASHBOARD_ROUTES.TRACK_VIEW}`.replace(':id', String(id))

    }

    const getWeatherTemp = () => {
        try {
            let temp = get(weather, 'data[0].temp', null)
            if (!temp && !temperature) {
                return '- °C'
            }
            if (temp && temperature === 'fahrenheit') {
                let _temp = ((temp * 1.8) + 32).toLocaleString(
                    undefined, // leave undefined to use the visitor's browser 
                    // locale or a string like 'en-US' to override it.
                    { minimumFractionDigits: 1 }
                )
                return String(_temp).concat('° F')
            }
            if (temp && temperature === 'celsius') {
                let _temp = toNumber(temp).toLocaleString(
                    undefined, // leave undefined to use the visitor's browser 
                    // locale or a string like 'en-US' to override it.
                    { minimumFractionDigits: 1 }
                )
                return String(_temp).concat('° C')
            }

        } catch (err) {
            return '- °C'

        }
    }

    const requestAnalyse = () => {
        let link = t('ecg:requestAnalysis:formUrl').concat('?')
        if (user) {
            let queryParams: string[] = []
            queryParams.push(`training_ecg_to_be_analysed=${encodeURI(window.location.href)}`)
            queryParams.push(`firstname=${user.firstname}`)
            queryParams.push(`lastname=${user.lastname}`)
            queryParams.push(`email=${user.email}`)
            link += queryParams.join('&')
        }
        window.open(encodeURI(link), '_blank')
    }

    return (
        <Card >
            <Box p={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap'} gap={2} bgcolor={'var(--lightGray)'}>
                <Box display={'flex'} gap={2} alignItems={'center'} flexWrap={'wrap'}>
                    <Avatar sx={{ width: 60, height: 60 }} src={horse?.avatar_url}></Avatar>
                    <Box flexDirection={'column'}>
                        <Typography variant='subtitle2'>{horse?.name}</Typography>
                        <Box display={'flex'} gap={1} flexWrap={'wrap'}>
                            <Typography sx={{ display: 'flex', alignItems: 'center' }} fontWeight={500} variant='caption'>{t('qualification:rider')} : <Typography variant='caption'>{rider ?? '-'}</Typography></Typography>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Typography sx={{ display: 'flex', alignItems: 'center' }} fontWeight={500} variant='caption'>{t('eap:table:headers:intensity')} : <Rating size='small' value={intensity} readOnly></Rating></Typography>
                        </Box>
                    </Box>
                </Box>

                <Box display={'flex'} flexWrap={'wrap'} alignItems={'center'} gap={1}>
                    <Button onClick={requestAnalyse} variant='outlined' size='small'>{t('ecg:requestAnalysis:linkText')}</Button>
                    <Button size='small'>{t('firstSection:seeProfile')}</Button>
                </Box>

            </Box>

            <Accordion defaultExpanded >

                <AccordionSummary
                    sx={{ bgcolor: 'var(--lightGray)' }}
                    expandIcon={<GridArrowDownwardIcon />}
                >
                    <Typography variant='subtitle2'>{t('tracks:information')}</Typography>
                </AccordionSummary>
                <AccordionDetails >
                    <Box display={'flex'} alignItems={'center'} gap={1} flexWrap={"wrap"}>
                        <Box flex={1} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} sx={{ borderRight: '1px solid lightgray' }}>
                            <img className='invert-img' alt='' style={{ width: 150, height: 50, marginBottom: 10, }} src={DistanceSvg}></img>
                            <Typography color={'GrayText'} variant='body2' >{t('distance')}</Typography>
                            <Typography textAlign={'center'} variant='body2' fontWeight={500} color={'whitesmoke'}>{convertDistanceKm()}</Typography>
                        </Box>
                        <Box flex={1} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} sx={{ borderRight: '1px solid lightgray' }}>
                            <img className='invert-img' alt='' style={{ width: 150, height: 50, marginBottom: 10 }} src={DurationSvg}></img>
                            <Typography color={'GrayText'} variant='body2' >{t('blocks:duration')}</Typography>
                            <Typography textAlign={'center'} variant='body2' fontWeight={500} color={'whitesmoke'}>{duration} min</Typography>
                        </Box>
                        <Box flex={1} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} sx={{ borderRight: '1px solid lightgray' }}>
                            <img className='invert-img' alt='' style={{ width: 150, height: 50, marginBottom: 10 }} src={CitySvg}></img>
                            <Typography color={'GrayText'} variant='body2' >{t('blocks:location')}</Typography>
                            <Typography textAlign={'center'} variant='body2' fontWeight={500} color={'whitesmoke'}>{get(weather, 'city_name')}</Typography>
                        </Box>
                        <Box flex={1} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} sx={{ borderRight: '1px solid lightgray' }}>
                            <img className='invert-img' alt='' style={{ width: 150, height: 50, marginBottom: 10 }} src={ShoeSvg}></img>
                            <Typography color={'GrayText'} variant='body2' >{t('editTrainingForm:horseShoeing:label')}</Typography>
                            <Typography textAlign={'center'} variant='body2' fontWeight={500} color={'whitesmoke'}>{horseShoe ?? '-'}</Typography>
                        </Box>
                        <Box flex={1} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} sx={{ borderRight: '1px solid lightgray' }}>
                            <img className='invert-img' alt='' style={{ width: 150, height: 50, marginBottom: 10 }} src={horseTraining}></img>
                            <Typography color={'GrayText'} variant='body2' >{t('editTrainingForm:trainingType:label')}</Typography>
                            <Typography textAlign={'center'} variant='body2' fontWeight={500} color={'whitesmoke'}>{trainingType ? trainingType : '-'}</Typography>
                        </Box>
                        <Box flex={1} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} sx={{ borderRight: '1px solid lightgray' }}>
                            <img alt='' style={{ width: 150, maxHeight: 50, marginBottom: 10, objectFit: 'contain' }} src={getWheterImage()}></img>
                            <Typography color={'GrayText'} variant='body2' >{getWeathercode()}</Typography>
                            <Typography textAlign={'center'} variant='body2' fontWeight={500} color={'whitesmoke'}>{getWeatherTemp()}</Typography>
                        </Box>
                        <Box flex={1} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} >
                            <img alt='' style={{ width: 60, height: 60, borderRadius: '50%' }} src={getSurfaceImage()}></img>
                            <Link style={{ color: 'gray', fontSize: '0.875rem' }} to={route(track?.id)}>{getTrackName()}</Link>
                            <Typography textAlign={'center'} variant='body2' fontWeight={500} color={'whitesmoke'}>{trainingType ? track?.trackCondition : '-'}</Typography>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>

        </Card>
    )
}

export default memo(InformationSection)