import React, { useEffect } from 'react'
import { TTrainerHorse } from '../../shared/types/trainerHorse.type'
import { Avatar, Box, Checkbox, Collapse, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { TUser } from '../../shared/types/user.type';
import { useExam } from '../../hooks/useExam';
import ListSkeleton from '../exams/loader/ListSkeleton';
import { formatDate, getCorrectDateFormat } from '../../shared/utils/DateUtils';
import { t } from 'i18next';
import { TExam } from '../../shared/types/exam.type';
import { boolean } from 'zod';
import { Ttraining } from '../../shared/types/training.type';
import { get } from 'lodash';

function ComparaisonItem({ horse, handleExamClick }: { horse: TTrainerHorse, handleExamClick: (checked: boolean, exam: Ttraining) => void }) {
    const [open, setOpen] = React.useState(false);
    const auth = useSelector<RootState>(state => state.user.user) as TUser | null
    const dateFormat = useSelector<RootState>(state => state.user.user?.default_date_format) as string | undefined
    const metric = useSelector<RootState>(state => state.user.user?.unit_system) as "metric" | "imperial" | undefined
    const checkedExams = useSelector<RootState>(state => state.comparaison.checkedExams) as Ttraining[]
    const { getHorseExams, horseExams } = useExam(false, auth?.['@id'], null, horse.horse?.['@id'])
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        setOpen(!open);
    };

    useEffect(() => {
        if (open) {
            getHorseExams.mutateAsync()
                .then(result => {
                }).catch(err => {
                })

        }
    }, [open])

    const getDateFormat = (): string => {
        if (dateFormat) {
            return getCorrectDateFormat(dateFormat) + ' HH:mm'
        } else {
            return 'DD/MM/YYYY HH:mm'
        }
    }

    const isExamChecked = (exam: Ttraining): boolean => {
        let ids = checkedExams.map(e => e?.id)
        return ids.includes(exam?.id)
    }

    return (
        <>
            <ListItemButton onClick={handleClick}>
                <ListItemAvatar>
                    <Avatar src={horse?.horse?.avatar_url}></Avatar>
                </ListItemAvatar>
                <ListItemText primary={<Typography variant='subtitle2'>{horse.horse?.name}</Typography>} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {
                    getHorseExams.isLoading ? <ListSkeleton length={3} height={30}></ListSkeleton> :
                        <List component="div" disablePadding>
                            {
                                (horseExams && horseExams?.length > 0) ? horseExams.map(exam => (
                                    <ListItem
                                        onClick={e => e.stopPropagation()}
                                        sx={{ pl: 4 }} secondaryAction={
                                            <Checkbox checked={isExamChecked(exam)} onClick={e => e.stopPropagation()} onChange={(e) => {
                                                e.stopPropagation()
                                                handleExamClick(e.target.checked, exam)
                                            }}></Checkbox>
                                        }>

                                        <ListItemText
                                            primary={<Typography variant='subtitle2'>{formatDate(exam.creation_date, getDateFormat())}</Typography>}
                                            secondary={<Box display={'flex'} alignItems={'center'} gap={1} flexWrap={'wrap'}>
                                                <Typography color='GrayText' variant='caption'  display={'flex'} alignItems={'center'} gap={1} fontWeight={500}>{t('intervals:exercise')} : <span style={{fontWeight:400,color:'gray'}}>{exam?.training_type?.display_name ?? ' - '}</span> </Typography>
                                                <Typography color='GrayText' variant='caption'  display={'flex'} alignItems={'center'} gap={1} fontWeight={500}>{t(metric === 'metric' ? 'firstSection:best200' : 'firstSection:bestFurlong')} :<span style={{fontWeight:400,color:'gray'}}>{get(exam, 'insight.time_best_200') ?? '0'}s</span> </Typography>
                                            </Box>}
                                        />

                                    </ListItem>
                                ))
                                    :
                                    <Box display={'flex'} justifyContent={'center'} py={1}>
                                        <Typography variant='subtitle2'>{t('no_training')}</Typography>

                                    </Box>

                            }

                        </List>
                }

            </Collapse>
        </>
    )
}

export default ComparaisonItem