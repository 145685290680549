import React from 'react'
import Header from '../../components/dashboard/Header'
import ListExam from '../../components/exams/ListExam'
import { Divider } from '@mui/material'
import { useExam } from '../../hooks/useExam'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { TUser } from '../../shared/types/user.type'
import { get } from 'lodash'
import { TExam } from '../../shared/types/exam.type'
import { useNavigate } from 'react-router-dom'
import { routes } from '../routes'
import { Ttraining } from '../../shared/types/training.type'

function Trainings() {
  const isUserLoading = useSelector<RootState>(state => state.user.isUserLoading) as boolean
  const auth = useSelector<RootState>(state => state.user.user) as TUser | null
  const navigate = useNavigate()
  const { exams, isExamsLoading } = useExam(!isUserLoading, get(auth, '@id'), null)


  const onExamClicked = (exam: Ttraining) => {
    let route = routes.DASHBOARD_ROUTES.EXAM_VIEW.replace(':id', String(exam.id))
    navigate(route)
  }

  return (
    <>
      <Header text='navbar:myTrainings'></Header>
      <Divider sx={{ my: 2 }}></Divider>
      <ListExam onExamClicked={onExamClicked} exams={exams} isLoading={isExamsLoading}></ListExam>
    </>
  )
}

export default Trainings