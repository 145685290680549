import { Box, Divider, Step, StepButton, Stepper } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import NewConfigStep1 from './NewConfigStep1';
import { TChannel, TChannelType } from '../../shared/types/Channel.type';
import NewConfigStep2 from './NewConfigStep2';
import NewConfigStep3 from './NewConfigStep3';
import { TTrainerHorse } from '../../shared/types/trainerHorse.type';
import { Ttrack } from '../../shared/types/tracks.type';
import { TTrainingType } from '../../shared/types/training.type';
import { useNotificationsSettings } from '../../hooks/useNotificationSettings';
import { TSchedule, schedules } from '../../shared/types/schedule.type';
import { TUser } from '../../shared/types/user.type';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { flatten, get, groupBy, isArray, isEmpty, isNull, lowerCase } from 'lodash';
import NewConfigStep4 from './NewConfigStep4';

type NewConfigurationStepperProps = {
    channels: TChannel[],
    horses: TTrainerHorse[],
    tracks: Ttrack[],
    trainingTypes: TTrainingType[],
    onSaveSuccess: () => void,
    itemToEdit: TSchedule | null,
    dataLoading: boolean,
    createNewChannel: () => void,
    deleteChannel: (id: number,type:TChannelType) => void,
    editChannel: (item: TChannel) => void,
    isLoading: boolean,
    isDeleting: boolean,
    configType?: "SIMPLE" | "ADVANCED"
}

function NewConfigurationStepper({ configType, channels, horses, tracks, trainingTypes, onSaveSuccess, itemToEdit, dataLoading, createNewChannel, deleteChannel, editChannel, isLoading, isDeleting }: NewConfigurationStepperProps) {
    const steps = [t('configuration'), t('configuration:recipient'), t('configuration:scheduling'), t('configuration:recap')];
    const [activeStep, setActiveStep] = React.useState(0);
    const [resumePhrase, setResumePhrase] = useState('')
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});
    const user = useSelector<RootState>(state => state.user?.user) as TUser

    const { saveConfig, updateConfig, generatePhrase } = useNotificationsSettings()

    //config data
    const [name, setName] = React.useState('')
    const [selectedChannels, setSelectedChannels] = useState<string[]>([])
    const [schedules, setSchedules] = useState<Partial<schedules>[]>([])
    const [dataConfig, setDataConfig] = useState<Partial<TSchedule>>({})
    //

    const _schedules = useMemo(() => {
        return flatten(schedules.map(e => {
            let arr: any = []
            isArray(e.day_of_week) && e.day_of_week.forEach(i => {
                arr.push(e.type === 'FIXED_TIME' ? { type: e.type, timezone: e.timezone, day_of_week: i, hour: e.hour, minute: e.minute } : { type: e.type, timezone: e.timezone, day_of_week: i })
            })
            return arr
        }))
    }, [schedules])

    React.useEffect(() => {
        if (!isNull(itemToEdit)) {
            console.log({ itemToEdit });

            setName(itemToEdit.name)
            if (!isEmpty(itemToEdit.communication_channels)) {
                let ids = itemToEdit.communication_channels.map(e => get(e, '@id', ''))
                setSelectedChannels(ids)
            }

            updateSchedultes(itemToEdit.schedules)

        }
    }, [itemToEdit])




    //Fns
    const updateSchedultes = (schedules: Partial<schedules>[]) => {

        if (isEmpty(schedules)) return

        //Get Schedule Type

        let type = get(schedules, '[0].type', undefined) as "FIXED_TIME" | "SYNCHRONISED_LAST_HOUR" | undefined

        if (!type) return

        if (type === 'SYNCHRONISED_LAST_HOUR') {

            //we group by timezone
            let tz = groupBy(schedules, item => item?.timezone ?? "")

            Object.values(tz).forEach(i => {
                let item = i?.[0]
                if (!item) return

                let days = i.map(e => e.day_of_week) as number[]

                setSchedules(prev => ([...prev, { ...item, day_of_week: days }]))

            })

        } else {
            //We group in fixed time case by hour time and tz
            let tz = groupBy(schedules, item => String(item.hour) + String(item.minute) + String(lowerCase(item.timezone)))



            Object.values(tz).forEach(i => {
                let item = i?.[0]
                if (!item) return

                let days = i.map(e => e.day_of_week) as number[]

                setSchedules(prev => ([...prev, { ...item, day_of_week: days }]))

            })




        }

    }

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };
    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };
    const dto: Partial<TSchedule> = { ...dataConfig, user: user['@id'], name, communication_channels: selectedChannels, schedules: _schedules }

    useEffect(() => {
        if (activeStep === 3) {
            generatePhrase.mutateAsync(dto)
                .then(result => {
                    setResumePhrase(result.data?.preview_text ?? "")
                })

        }
    }, [activeStep])

    const saveConfiguration = () => {
        let promise = itemToEdit ? updateConfig.mutateAsync({ id: itemToEdit.id, config: dto }) : saveConfig.mutateAsync(dto)

        promise
            .then(async () => {
                toast(t('rights:success'), { type: 'success' })
                setName('')
                setSelectedChannels([])
                onSaveSuccess()
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })

            })
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Divider sx={{ mb: 2 }}></Divider>
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepButton color="inherit" >
                            {label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <div>
                {
                    activeStep === 3 && <NewConfigStep1
                        resumePhrase={resumePhrase}
                        isPhraseLoading={generatePhrase.isLoading}
                        onBack={handleBack}
                        step={activeStep} onSave={() => {
                            saveConfiguration()
                        }}
                        loading={saveConfig.isLoading || updateConfig.isLoading}
                        name={name} onNameChange={(value) => setName(value)}></NewConfigStep1>
                }
                {
                    activeStep === 1 && <NewConfigStep2
                        createNewChannel={createNewChannel}
                        isLoading={isLoading}
                        isDeleting={isDeleting}
                        editChannel={editChannel}
                        deleteChannel={deleteChannel}
                        onBack={handleBack} selectedChannels={selectedChannels} onChannelsChange={values => setSelectedChannels(values)} step={activeStep} onSave={() => { handleComplete() }} channels={channels}></NewConfigStep2>
                }
                {
                    activeStep === 0 && <NewConfigStep3 configType={configType} dataConfig={dataConfig} dataLoading={dataLoading} itemToEdit={itemToEdit} onBack={handleBack} loading={saveConfig.isLoading || updateConfig.isLoading} horses={horses} tracks={tracks} trainingTypes={trainingTypes} step={activeStep} onSave={(data) => {
                        setDataConfig(data)
                        handleComplete()
                    }} ></NewConfigStep3>
                }
                {
                    activeStep === 2 && <NewConfigStep4
                        schedules={schedules}
                        onScheduleChange={values => setSchedules(values)}
                        onBack={handleBack}
                        onSave={() => {
                            handleComplete()
                        }}
                        step={activeStep}
                    ></NewConfigStep4>
                }

            </div>
        </Box>
    )
}

export default NewConfigurationStepper