import { Alert, Box, Container, Divider, LinearProgress } from '@mui/material'
import { DataGrid, GridCellParams, GridColDef, GridToolbar, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import { t } from 'i18next'
import React from 'react'
import { useHorseShoeing } from '../../hooks/useHorseShoeing';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { TUser } from '../../shared/types/user.type';
import DataGridActions from '../../components/global/DataGridActions';
import { setRiderItemToEdit, setRiderModal, setShoeingItemToEdit, setShoeingModal, setTrackItemToEdit, setTrackModal } from '../../store/reducers/modal.reducer';
import { toNumber } from 'lodash';
import I18nDataGrid from '../../components/global/I18nDataGrid';
import { useTrackConditions } from '../../hooks/useTrackConditions';

function TracksConditions() {
  const dispatch = useDispatch()
  const auth = useSelector<RootState>(state => state.user.user) as TUser | null
  const isUserLoading = useSelector<RootState>(state => state.user.isUserLoading) as boolean
  const { tracks, isLoading, deleteTrack, isDeleting } = useTrackConditions(!isUserLoading, auth?.id)

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'name',
      headerName: t('editTrainingForm:trackCondition:label'),
      flex: 1,
      valueFormatter: (param: GridValueFormatterParams) => {
        return param.value ?? '-'
      }
    },
    {
      field: 'track_group',
      headerName: t('trackCondition:trackGroup'),
      flex: 1,
      valueFormatter: (param: GridValueFormatterParams) => {
        return t(param.value) ?? '-'
      }
    },
    {
      headerName: t('settings:actions'),
      field: '',
      flex: 1,
      align: 'center',
      renderCell: (params: GridCellParams) =>
        <DataGridActions
          deleteTitle='trackCondition:deleteTrackCondition'
          deleteSubtitle='trackCondition:deleteTrackConditionMessage'
          onEdit={() => {
            dispatch(setTrackItemToEdit(params.row))
            dispatch(setTrackModal(true))
          }}
          isDeleting={isDeleting}
          onDelete={() => { deleteTrack(toNumber(params.id)) }}
        ></DataGridActions>
    }
  ];



  return (
    <Box >
      <Alert severity='info' >
        <pre dangerouslySetInnerHTML={{ __html: t('profileSectionDescription:tracks') }}></pre>
      </Alert>
      <Divider sx={{ my: 3 }}></Divider>
      {(isLoading || isUserLoading) && <LinearProgress></LinearProgress>}
      <I18nDataGrid
        rows={tracks}
        loading={(isLoading || isUserLoading)}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
          columns: {
            columnVisibilityModel: {
              id: false
            }
          }
        }}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection={false}
        disableRowSelectionOnClick
      />
    </Box>
  )
}

export default TracksConditions