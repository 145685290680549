import { Grid, Skeleton } from '@mui/material'
import React from 'react'

function KeyItemsLoader() {
    return (
        <Grid container spacing={4} mt={2}>
            {[...Array(8)].map((i,index) => (
                <React.Fragment key={index}>
                    <Grid item md={3} sm={6} xs={6} key={index}>
                        <Skeleton  variant='rectangular' width={'100%'} height={120} sx={{borderRadius:1}}></Skeleton>
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    )
}

export default KeyItemsLoader