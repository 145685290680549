import { AxiosResponse } from "axios";
import { TChannel } from "../shared/types/Channel.type";
import axiosInstance from "./base";
import { TSchedule } from "../shared/types/schedule.type";

export class Notification {
    static saveChannel(data: Partial<TChannel>): Promise<AxiosResponse<TChannel>> {
        return axiosInstance.post('email_communication_channels', data)
    }

    static saveWhatsappChannel(data: Partial<TChannel>): Promise<AxiosResponse<TChannel>> {
        return axiosInstance.post('whatsapp_communication_channels', data)
    }

    static saveConfig(data: Partial<TSchedule>): Promise<AxiosResponse<TSchedule>> {
        return axiosInstance.post('send_training_configurations', data)
    }
    static generatePhrase(data: Partial<TSchedule>): Promise<AxiosResponse<TSchedule>> {
        return axiosInstance.post('send_training_configurations/preview', data)
    }

    static getConfigs(trainer?: string): Promise<AxiosResponse<TSchedule[]>> {
        return axiosInstance.get(`send_training_configurations?user=${trainer}`)
    }

    static updateConfig(id: number, config: Partial<TSchedule>): Promise<AxiosResponse<TSchedule>> {
        return axiosInstance.patch(`send_training_configurations/${id}`, config, {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            }
        })
    }

    static updateChannel(id: number, channel: Pick<TChannel, 'lang' | 'name' | 'unit_system' | 'speed_unit' | 'targets' | 'user'>): Promise<AxiosResponse<TChannel>> {
        return axiosInstance.patch(`email_communication_channels/${id}`, channel, {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            }
        })
    }
    static updateWhatsappChannel(id: number, channel: Pick<TChannel, 'lang' | 'name' | 'unit_system' | 'speed_unit' | 'targets' | 'user'>): Promise<AxiosResponse<TChannel>> {
        return axiosInstance.patch(`whatsapp_communication_channels/${id}`, channel, {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            }
        })
    }


    static getChannels(trainer?: string) {
        return axiosInstance.get(`communication_channels?user=${trainer}`)
    }
    static deleteChannel(id: number) {
        return axiosInstance.delete(`email_communication_channels/${id}`)
    }    
    static deleteWhatsappChannel(id: number) {
        return axiosInstance.delete(`whatsapp_communication_channels/${id}`)
    }

    static deleteConfig(id: number) {
        return axiosInstance.delete(`send_training_configurations/${id}`)
    }

    static getSubscribedChannels(email?: string, uuid?: string) {
        return axiosInstance.get(`contact_opt_ins?email=${email}&uuid=${uuid}`)
    }
}