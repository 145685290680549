import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

//improrts
import fr from './langs/fr.json'
import en from './langs/en.json'
import es from './langs/es.json'
import tr from './langs/tr.json'
import it from './langs/it.json'
import de from './langs/de.json'
import { store } from "../store";
//




i18n
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: { translation: en },
            fr: { translation: fr },
            de: { translation: de },
            tr: { translation: tr },
            it: { translation: it },
            es: { translation: es },
        },
        debug: false, // if you're using a language detector, do not define the lng option
        fallbackLng: "en",
        keySeparator: ':',
        interpolation: {
            prefix: '%',
            suffix: '%',
        },
        lng: String(localStorage.getItem('lang')),
    });

export default i18n