import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Stack, TextField } from '@mui/material';
import NewChannelStep1 from './NewChannelStep1';
import NewChannelStep2 from './NewChannelStep2';
import NewChannelStep3 from './NewChannelStep3';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { speedUnits } from '../../enums/register.enums';
import { useNotificationsSettings } from '../../hooks/useNotificationSettings';
import { TUser } from '../../shared/types/user.type';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { useQueryClient } from 'react-query';
import { TChannel, TContactOptIn } from '../../shared/types/Channel.type';
import { isEmpty, isNull } from 'lodash';
import { Check, MailOutline, WhatsApp } from '@mui/icons-material';
import WhatsappField from './WhatsappField';

type SendingMethod = 'email' | 'whatsapp'

export default function NewChannelStepper({ onSaveSuccess, itemToEdit }: { onSaveSuccess: () => void, itemToEdit: TChannel | null }) {

    const { saveChannel, updateChannel, saveWhatsappChannel, updateWhatsappChannel } = useNotificationsSettings()
    const user = useSelector<RootState>(state => state.user.user) as TUser

    const channelType: { text: string, value: SendingMethod, isDisabled: boolean, icon: React.ReactNode }[] = [
        {
            text: t('equimetre:table:email'),
            value: 'email',
            isDisabled: false,
            icon: <MailOutline></MailOutline>
        },
        {
            text: t('Whatsapp'),
            value: 'whatsapp',
            isDisabled: false,
            icon: <WhatsApp></WhatsApp>
        },
    ]
    React.useEffect(() => {
        if (!isNull(itemToEdit)) {
            if (itemToEdit['@type'] === 'EmailCommunicationChannel') {
                setType('email')
                setEmails(itemToEdit.contact_opt_ins)
            } else {
                setType('whatsapp')
                setPhones(itemToEdit.targets)
            }
            setName(itemToEdit.name)
            setMetric(itemToEdit.unit_system)
            setLang(itemToEdit.lang)
            setSpeedUnit(itemToEdit.speed_unit)
        }
    }, [itemToEdit])

    //steps data
    const [type, setType] = React.useState<SendingMethod>('email')
    const [name, setName] = React.useState('')
    const [emails, setEmails] = React.useState<Partial<TContactOptIn>[]>([])
    const [phones, setPhones] = React.useState<string[]>([])

    const [metric, setMetric] = React.useState<string | null>('')
    const [lang, setLang] = React.useState<string | null>('')
    const [speedUnit, setSpeedUnit] = React.useState<string | null>('')
    //
    const [errorName, setErrorName] = React.useState(false)
    const [errorList, setErrorList] = React.useState(false)
    const [errorPhones, setErrorPhones] = React.useState(false)

    const queryClient = useQueryClient()


    const checkForError = () => {
        let hasError = false
        if (isEmpty(name)) {
            setErrorName(true)
            hasError = true

        }
        if (type === 'email' && isEmpty(emails)) {
            setErrorList(true)
            hasError = true

        }
        if (type === 'whatsapp' && isEmpty(phones)) {
            setErrorPhones(true)
            hasError = true

        }
        return hasError
    }

    const postChannel = () => {

        if (checkForError()) {
            return
        }

        let dataObj = {
            name,
            targets: type === 'email' ? emails.map(e => e?.target ?? "") : phones,
            lang: isEmpty(lang) ? null : lang,
            unit_system: isEmpty(metric) ? null : metric,
            speed_unit: isEmpty(speedUnit) ? null : speedUnit,
            user: user['@id']
        }

        let promise = itemToEdit ? (type === 'email' ? updateChannel : updateWhatsappChannel).mutateAsync({ id: itemToEdit.id, channel: dataObj }) : (type === 'email' ? saveChannel : saveWhatsappChannel).mutateAsync(dataObj)
        promise.then(() => {
            //TODO: back and refetch
            toast(t('rights:success'), { type: 'success' })
            setName('')
            setEmails([])
            setPhones([])
            setMetric(null)
            setLang(null)
            setSpeedUnit(null)
            onSaveSuccess()
            queryClient.refetchQueries(['channels'])
        }).catch(err => {
            toast(t('error:generic'), { type: 'error' })

        })
    }

    const mappedSpeedUnits = React.useMemo(() => {
        return speedUnits.filter(speed => speed.enabled === "" || speed.enabled === (metric ?? 'metric'))
    }, [metric])


    return (
        <Box sx={{ width: '100%' }}>
            <Divider sx={{ my: 2 }}></Divider>
            <Stack direction={'column'} spacing={2}>
                <List dense>
                    <Typography variant='subtitle2'>{t('channel:sendingMethod')}</Typography>
                    <Divider></Divider>
                    {channelType.map(_type => (
                        <ListItemButton onClick={() => setType(_type.value)} selected={_type.value === type} disabled={_type.isDisabled} key={_type.value}>
                            <ListItemIcon>
                                {_type.icon}
                            </ListItemIcon>
                            <ListItemText primary={_type.text} secondary={_type.isDisabled && ` (${t('notAvailable')})`} />
                            {_type.value === type &&
                                <ListItemIcon>
                                    <Check color='primary'></Check>
                                </ListItemIcon>
                            }
                        </ListItemButton>
                    ))}
                </List>

                <Box>
                    <Typography variant='subtitle2'>{t('channel:name')}</Typography>
                    <TextField error={errorName} fullWidth size='small' value={name} onChange={e => {
                        setErrorName(false)
                        setName(e.target.value)
                    }} placeholder={t('channel:name')}></TextField>
                </Box>
                <Box>
                    {
                        type === 'email' ? <>
                            <Typography variant='subtitle2'>{t('info:email')}</Typography>
                            <NewChannelStep2 errorEmail={errorList} handleEmails={val => {
                                setErrorList(false)
                                setEmails(val)
                            }} onSave={() => { }} emails={emails} ></NewChannelStep2>
                        </> : <>
                            <Typography variant='subtitle2'>{t('equi:registration:phone')}</Typography>
                            <WhatsappField errorPhones={errorPhones} phones={phones} onChange={values => setPhones(values)}></WhatsappField>
                        </>
                    }

                </Box>
                <Box>
                    <Typography variant='subtitle2'>Options</Typography>
                    <NewChannelStep3 loading={saveChannel.isLoading || updateChannel.isLoading || saveWhatsappChannel.isLoading || updateWhatsappChannel.isLoading} onUnitChange={val => setSpeedUnit(val)} speedUnit={speedUnit} speedUnits={mappedSpeedUnits} currentLang={lang} onLangChange={val => setLang(val)} onMetricChange={val => setMetric(val)} currentMetric={metric} onSave={() => { postChannel() }}  ></NewChannelStep3>
                </Box>

            </Stack>

        </Box>
    );
}