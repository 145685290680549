import React, { useEffect, useMemo, useState } from 'react'
import { AnalyticsData } from '../../shared/types/analyticData.type'
import { TAnalyticView } from '../../shared/types/analyticView.type'
import { t } from 'i18next';
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import styled from '@emotion/styled';
import { TAnalyticData } from '../../pages/analytics';
import { toNumber } from 'lodash';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { DensityMedium } from '@mui/icons-material';

type DatatableProps = {
  analyticsData?: AnalyticsData,
  view?: TAnalyticView,
  loading: boolean,
  updateFilter: (key: keyof TAnalyticData, value: any) => void
}

function Datatable({ analyticsData, view, loading, updateFilter }: DatatableProps) {

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(100)


  const columns = useMemo((): TableColumn<string[]>[] => {
    if (!view) return []
    return [
      { name: t('eap:table:headers:date'), sortable: true, cell: row => <div dangerouslySetInnerHTML={{ __html: row[0] }}></div> },
      { name: t('eap:table:headers:horse'), sortable: true, cell: row => <div dangerouslySetInnerHTML={{ __html: row[1] }}></div> },
      ...view.analytics_view_insight_parameters.map((e, index) => ({ name: e.insight_parameter.name_translated, selector: (row: string[]) => row[index + 2], sortable: true }))]
  }, [view])


  //Fns
  const handleSort = async (column: TableColumn<string[]>, dir: SortOrder) => {
    /// reach out to some API and get new data using or sortField and sortDirection
    // e.g. https://api.github.com/search/repositories?q=blog&sort=${column.sortField}&order=${sortDirection}
    updateFilter('order', {
      dir,
      column: toNumber(column?.id ?? 1) - 1
    })

  };
  const paginationChanged = async (currentRowsPerPage: number, currentPage: number) => {
    //Function fired when we changes the length of the rows per page
    setPageSize(currentRowsPerPage)
    setCurrentPage(currentPage)

    updateFilter('length', currentRowsPerPage === (analyticsData?.records_filtered ?? 0) ? -1 : currentRowsPerPage)
  };
  const onChangePage = async (currentPage: number, totalRows: number) => {
    //Function fired when we naviagte between pages
    setCurrentPage(currentPage)
    updateFilter('start', (currentPage * pageSize) - pageSize)
  };


  return (
    <>

      <DataTable
        style={{ overflowX: "auto", position: 'relative' }}
        fixedHeader
        columns={columns}
        data={analyticsData?.data ?? []}
        theme='dark'
        sortServer
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={analyticsData?.records_filtered}
        onSort={handleSort}
        fixedHeaderScrollHeight='500px'

        onChangeRowsPerPage={paginationChanged}
        onChangePage={onChangePage}
        paginationRowsPerPageOptions={[10, 25, 50, 75, 100, analyticsData?.records_filtered ?? 0]}
        paginationPerPage={pageSize}
        progressComponent={<CircularProgress sx={{ my: 3 }}></CircularProgress>}

      />
    </>
  )
}

export default Datatable