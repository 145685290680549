import React, { ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import { routes } from '../routes'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

function GuestGuard({ children }: { children: ReactElement }) {
    //get Auth user
    const isAuth = useSelector<RootState>(state => state.user.isAuthenticated)
    if (isAuth) {
        return <Navigate to={routes.DASHBOARD_ROUTES.HOME}></Navigate>
    }
    return (
        <>
            {children}
        </>
    )
}

export default GuestGuard