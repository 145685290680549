import React from 'react'

function Hideable({ isVisible, children,  }: { isVisible: boolean, children: React.ReactNode}) {
    if (!isVisible) {
        return <></>
    }
    return (
        <>
            {children}
        </>
    )
}

export default Hideable