import { LatLng } from "use-places-autocomplete";
import { directions } from "../enums/direction.enum";
import { TCheckPoint } from "../shared/types/checkPoint.type";
import { TFinishLine } from "../shared/types/finishLine.type";
import { get, isEmpty, set, toNumber, uniqueId } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { finishLine64 } from "../assets/icons/finishLineBase64";
import { CheckPoint } from "../shared/types/training.type";

var flMarker: any = []

export const useMap = (map?: google.maps.Map | null, onFinishLinePosChanged?: (cords: google.maps.LatLngLiteral[]) => void, onCheckPointPosChanged?: (cords: google.maps.LatLngLiteral[], id: number | null) => void) => {

    const [finishLineMarkers, setFinishLineMarkers] = useState<google.maps.Marker[]>()
    const [checkPointsMarkers, setCheckPointsMarkers] = useState<Array<google.maps.Marker[]>>()
    const [lineFl, setLineFl] = useState<google.maps.Polyline>()
    const [linesCp, setLinesCp] = useState<google.maps.Polyline[]>()
    const [linesDir, setLinesDir] = useState<google.maps.Polyline[]>()
    const [color, setColor] = useState('#ff0000')


    useEffect(() => {
        if (finishLineMarkers?.length === 2) {
            addMarkerListener(finishLineMarkers)
            addLine(getPosFromMarkers(finishLineMarkers), 'FL')
        }
    }, [finishLineMarkers, color])

    useEffect(() => {
        let latlngs: google.maps.LatLngLiteral[] = []
        if (finishLineMarkers) {
            latlngs.push(...getPosFromMarkers(finishLineMarkers))
        }
        if (checkPointsMarkers) {
            checkPointsMarkers.forEach(cp => {
                latlngs.push(...getPosFromMarkers(cp))
            })
        }
        // fitMarkersOnMap(latlngs)
    }, [finishLineMarkers?.length, checkPointsMarkers?.length])

    useEffect(() => {
        if (!isEmpty(checkPointsMarkers)) {
            removeCpLines()
            checkPointsMarkers && addCpMarkersListeners(checkPointsMarkers)

            checkPointsMarkers?.forEach(cp => {
                addLine(getPosFromMarkers(cp), 'CP')
            })
        }
    }, [checkPointsMarkers, color])



    function getCoordinatesThirdPointOfTriangle(latLngArray: google.maps.LatLngLiteral[], direction: number = 1) {
        var point1 = latLngArray[0];
        var point2 = latLngArray[1];

        var D2 = google.maps.geometry.spherical.computeDistanceBetween(point1, point2);
        var angle = google.maps.geometry.spherical.computeHeading(point1, point2);

        var newAngle = angle + 60;

        if (direction == -1) {
            newAngle = angle - 60
        }



        var point = google.maps.geometry.spherical.computeOffset(point1, D2, newAngle);


        return point;
    }

    function getCenter(adressCenter?: google.maps.LatLngLiteral, finishLine?: Partial<TFinishLine> | null, checkPoints?: TCheckPoint[] | null) {


        if (finishLine || checkPoints) {

            let latlng = getlatlngFromItem(finishLine as TFinishLine)
            addMarkers(latlng, 'FL')
            removeCheckPoints()
            setCheckPointsMarkers([])
            if (checkPoints) {
                removeDirectionLine()
                checkPoints.forEach((checkpoint, i) => {

                    let latlng = getlatlngFromItem(checkpoint)

                    addMarkers(latlng, 'CP', checkpoint.name, checkpoint.id)
                    if (checkpoint.direction === 0) {
                        addDirectionLine(latlng, 1)
                        addDirectionLine(latlng, -1)

                    } else {
                        addDirectionLine(latlng, checkpoint.direction)

                    }
                })
            }
        } else {
            adressCenter && setMapCenter(adressCenter)
            return
        }

    }

    const removeDirectionLine = () => {
        if (linesDir) {
            linesDir.forEach(line => line.setMap(null))
            setLinesDir([])
        }
    }
    const addDirectionLine = (latlng: google.maps.LatLngLiteral[], direction = 1) => {

        const lineSymbol = {
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        };

        let center = getMarkersCenter(latlng)
        let third = getCoordinatesThirdPointOfTriangle(latlng, direction)
        const line = new google.maps.Polyline({
            path: [
                center,
                third,
            ],
            icons: [
                {
                    icon: lineSymbol,
                    offset: "100%",
                },
            ],
            map: map,
        });
        setLinesDir(prev => prev ? [...prev, line] : [line])
    }

    const getMarkersCenter = (latlng: google.maps.LatLngLiteral[]): google.maps.LatLngLiteral => {
        const lat = (latlng[0].lat + latlng[1].lat) / 2
        const lng = (latlng[0].lng + latlng[1].lng) / 2
        return { lat, lng }
    }

    const fitMarkersOnMap = (markers: google.maps.LatLngLiteral[]) => {
        var latlngbounds = new google.maps.LatLngBounds();

        for (var i = 0; i < markers.length; i++) {
            latlngbounds.extend(markers[i]);
        }
        map?.fitBounds(latlngbounds);
    }

    const setMapCenter = (center: google.maps.LatLngLiteral) => {
        map?.setCenter(center)

    }

    useEffect(() => {
        if (isEmpty(finishLineMarkers)) {
            finishLineMarkers?.forEach(marker => (
                marker?.setMap(null)
            ))

            lineFl?.setMap(null)
            setLineFl(undefined)
        }
    }, [finishLineMarkers])

    const removeFinishLine = () => {

        finishLineMarkers?.forEach(m => m?.setMap(null))

        setFinishLineMarkers([])

    }

    const removeCheckPoints = () => {
        if (!isEmpty(checkPointsMarkers)) {

            checkPointsMarkers?.forEach(checkpoint => (
                checkpoint.forEach(cp => (
                    cp.setMap(null)
                ))
            ))
            removeCpLines()
            setCheckPointsMarkers([])
        }
    }


    const addMarkers = (positions: google.maps.LatLngLiteral[], type: 'FL' | 'CP', labelText?: string, itemId?: number, editable = true) => {
        removeFinishLine()
        let tempMarker: google.maps.Marker[] = []


        positions.forEach((position, index) => {

            let _marker = new google.maps.Marker({
                position,
                draggable: editable,
                label: type === 'CP' ? { text: String(labelText), color: 'white', fontSize: '10px' } : "",
                icon: type === 'FL' ? {
                    anchor: editable ? new google.maps.Point(0, 0) : new google.maps.Point(32, 24),
                    url: editable ? require('../assets/finish-line-marker.png') : finishLine64,
                    scaledSize: new google.maps.Size(50, 30),
                } : null,
                map
            })

            set(_marker, 'id', uniqueId())
            set(_marker, 'item_id', itemId)



            tempMarker.push(_marker)
        })


        setColor('#ff0000')
        type === 'FL' ? setFinishLineMarkers(tempMarker) : setCheckPointsMarkers(prev => prev ? [...prev, tempMarker] : [tempMarker])

    }

    const addFinishLine = (positions: google.maps.LatLngLiteral[] | null) => {
        if (positions && (positions.length === finishLineMarkers?.length)) {
            return
        }

        if (!positions) {
            removeFinishLine()
            return
        }
        removeFinishLine()
        let tempMarker: google.maps.Marker[] = []


        positions.forEach((position, index) => {

            let _marker = new google.maps.Marker({
                position,
                draggable: false,

                icon: index === 0 ? {
                    anchor: new google.maps.Point(32, 24),
                    url: finishLine64,
                    scaledSize: new google.maps.Size(50, 30),
                } : {
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 0
                },
                map
            })

            set(_marker, 'id', uniqueId())



            tempMarker.push(_marker)
        })
        setColor('#000')
        setFinishLineMarkers(tempMarker)

    }

    const addCheckPoints = (checkPoints: CheckPoint[]) => {
        if (checkPoints.length === checkPointsMarkers?.length) {
            return
        }
        removeCheckPoints()
        setCheckPointsMarkers([])
        if (isEmpty(checkPoints)) return

        let tempMarker: google.maps.Marker[] = []
        setColor('#000')
        checkPoints.forEach(({ checkpoint }, i) => {


            let positions = getlatlngFromItem(checkpoint)


            positions.forEach((position, index) => {

                let _marker = new google.maps.Marker({
                    position,
                    draggable: false,
                    label: index !== 0 ? { text: String(checkpoint.name), color: 'white', fontSize: '10px' } : "",
                    icon: {
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 0
                    },
                    map
                })

                set(_marker, 'id', uniqueId())
                set(_marker, 'item_id', checkpoint.id)



                tempMarker.push(_marker)
            })
            setCheckPointsMarkers(prev => prev ? [...prev, tempMarker] : [tempMarker])

        })
    }
    const addMarkerListener = (markers: google.maps.Marker[]) => {
        markers.forEach((_marker, index) => {
            _marker.addListener('dragend', (event: google.maps.MapMouseEvent) => {
                const position = event.latLng?.toJSON() as google.maps.LatLngLiteral

                let others = finishLineMarkers && finishLineMarkers.length > 0 ? [...finishLineMarkers].filter(e => get(e, 'id') !== get(_marker, 'id')) : []
                others.push(_marker)
                let otherMarker = get(others, "[0]", null) as google.maps.Marker | null
                let otherPos = otherMarker && otherMarker.getPosition()?.toJSON()
                if (otherPos) {
                    typeof onFinishLinePosChanged === 'function' && onFinishLinePosChanged([position, otherPos])
                }
                setFinishLineMarkers(others)

            });

        })

    }

    const addCpMarkersListeners = (markers: Array<google.maps.Marker[]>) => {

        markers.forEach((marker, index) => {
            marker.forEach(_marker => {
                _marker.addListener('dragend', (event: google.maps.MapMouseEvent) => {

                    const position = event.latLng?.toJSON() as google.maps.LatLngLiteral

                    let others = marker && marker.length > 0 ? [...marker].filter(e => get(e, 'id') !== get(_marker, 'id')) : []
                    others.push(_marker)
                    let otherMarker = get(others, "[0]", null) as google.maps.Marker | null
                    let otherPos = otherMarker && otherMarker.getPosition()?.toJSON()
                    let temCpMarkers = markers.filter((e, i) => i !== index)
                    temCpMarkers.splice(index, 0, others)
                    if (otherPos) {
                        typeof onCheckPointPosChanged === 'function' && onCheckPointPosChanged([position, otherPos], get(_marker, 'item_id', null))
                    }
                    setCheckPointsMarkers(temCpMarkers)

                });

            })
        })

    }

    const removeCpLines = () => {
        linesCp?.forEach(line => line?.setMap(null))
        setLinesCp([])
    }


    const addLine = (positions: google.maps.LatLngLiteral[], type: 'FL' | 'CP') => {
        if (type == 'FL') {
            lineFl?.setMap(null)
        }

        let currentZoom = map?.getZoom()

        let _line = new google.maps.Polyline({
            path: positions,
            geodesic: true,
            strokeColor: color,
            strokeOpacity: 1,
            strokeWeight: 2
        })
        if (type == 'FL') {
            setLineFl(_line)
        }
        if (type === 'CP') {
            setLinesCp(prev => prev && [...prev, _line])
        }

        map && _line.setMap(map)
        currentZoom && map?.setZoom(currentZoom)

    }
    const getlatlngFromItem = (item: Partial<TCheckPoint> | Partial<TFinishLine>): google.maps.LatLngLiteral[] => {
        let cords: google.maps.LatLngLiteral = { lat: 0, lng: 0 }
        let cordsb: google.maps.LatLngLiteral = { lat: 0, lng: 0 }
        cords.lat = toNumber(item?.latitude_a ?? 0)
        cords.lng = toNumber(item?.longitude_a ?? 0)

        //Second point
        cordsb.lat = toNumber(item?.latitude_b ?? 0)
        cordsb.lng = toNumber(item?.longitude_b ?? 0)
        return [cords, cordsb]
    }

    const getPosFromMarkers = (markers: google.maps.Marker[]) => {
        let pos: google.maps.LatLngLiteral[] = []
        markers.forEach(marker => {
            let json = marker.getPosition()?.toJSON()
            if (json) {
                pos.push(json)
            }
        })


        return pos
    }





    return {
        getCoordinatesThirdPointOfTriangle,
        getCenter,
        getlatlngFromItem,
        addFinishLine,
        removeFinishLine,
        addCheckPoints,
        removeCheckPoints,
        fitMarkersOnMap
    }
}