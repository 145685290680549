import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TExam } from "../../shared/types/exam.type";
import { cloneDeep, isEmpty } from "lodash";
import { Ttraining } from "../../shared/types/training.type";
import { TtrainingKeyParameter } from "../../shared/types/trainingKeyParameter.type";
import { TgroupKeyParam } from "../../shared/utils/comparaison";
import { TtrainingKeyParameterItem } from "../../shared/types/TrainingKeyParameterItem.type";


type initalStateType = {
    open: boolean,
    checkedExams: Ttraining[],
    data?: TtrainingKeyParameter | null,
    categories: TgroupKeyParam[],
}

const initialState: initalStateType = {
    open: false,
    checkedExams: [],
    categories: []
}

export const comparaisonReducer = createSlice({
    name: 'comparaison',
    initialState,
    reducers: {
        openModal: (state, action: PayloadAction<boolean>) => {
            state.open = action.payload
        },
        setData: (state, action: PayloadAction<TtrainingKeyParameter | null>) => {
            state.data = action.payload
        },
        setCategories: (state, action: PayloadAction<TgroupKeyParam[]>) => {
            state.categories = action.payload
        },
        resetCheckedExam: (state) => {
            state.checkedExams = []
        },
        updateCheckExam: (state, action: PayloadAction<{ checked: boolean, exam: Ttraining }>) => {
            let copy = cloneDeep(state.checkedExams)
            if (action.payload.checked) {
                state.checkedExams = isEmpty(copy) ? [action.payload.exam] : [...copy, action.payload.exam]
            } else {
                let index = copy.findIndex(e => e.id === action.payload.exam.id)
                if (index !== -1) {

                    copy.splice(index, 1)
                    state.checkedExams = copy
                }
            }
        },
        setDataItems: (state, action: PayloadAction<TtrainingKeyParameterItem[]>) => {
            let newState = cloneDeep(state.data)
            if (!newState) return
            newState.items = action.payload
            state.data = newState
            if (isEmpty(newState.items)) {
                state.categories = []
            }
        }
    }
})
export const { openModal, updateCheckExam, setData, setCategories, setDataItems, resetCheckedExam } = comparaisonReducer.actions
export default comparaisonReducer.reducer