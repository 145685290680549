import { toNumber } from 'lodash';

const SIGN_REDKM_MINUTES = "′";
const SIGN_REDKM_SECONDS = "″";

const UNIT_RED_KM = "Red. km";
const UNIT_MIL_RED = "Mil. red.";
export const convertSpeed = (metric?: "metric" | 'imperial', distance: number = 0) => {

    if (metric === 'imperial') {
        return String((distance * 5 / 1000).toFixed(1)).concat(`  mi`)
    }
    if (metric === 'metric') {
        return String((distance * 5 / 1609).toFixed(1)).concat(` km`)

    }
}

export function getSpeedForChart(speed: number, unit: string) {

    switch (unit) {
        case "min/km":
            return (speed > 0) ? (200 / speed).toFixed(1) : null;
        case "min/mi":
            return (speed > 0) ? (200 / (speed * 0.621371)).toFixed(1) : null;
        case "m/min":
            return (speed > 0) ? (speed * 5).toFixed(0) : null;
        case "km/h":
            return (speed > 0) ? (0.3 * speed).toFixed(1) : null;
        case "m/s":
            return (speed > 0) ? (speed / 12).toFixed(1) : null;
        case "Red. km":
            return (speed > 0) ? (200 / speed).toFixed(2) : null;
        case "mph":
            return (speed > 0) ? (0.3 * speed * 0.621371).toFixed(1) : null;
        case "Mil. red.":
            return (speed > 0) ? (200 / (speed * 0.621371)).toFixed(2) : null;
        default:
            return null

    }
}
export function getSpeedTooltipForChart(speedConverted: number, unit: string) {
    let SIGN_REDKM_MINUTES = "′";
    let SIGN_REDKM_SECONDS = "″";
    let sFormatted = ''

    let translationsUnits: any = [];
    translationsUnits["km/h"] = "km/h";
    translationsUnits["mph"] = "mph";




    switch (unit) {
        case "min/km":
            return speedConverted + " min/km";
        case "m/min":
            return speedConverted + " m/min";
        case "min/mi":
            return speedConverted + " min/mi";
        case "km/h":
            return speedConverted + " " + translationsUnits["km/h"];
        case "m/s":
            return speedConverted + " m/s";
        case "Red. km":

            if (speedConverted % 1 == 0) {
                sFormatted = Math.floor(speedConverted) + "′00″0";
            } else {
                var seconds = (speedConverted - Math.floor(speedConverted)) * 60;
                var secondsStr = Math.floor(seconds).toString().padStart(2, "0");
                var secondTenths = 0;
                if (seconds % 1 != 0) {
                    secondTenths = Math.round((seconds - Math.floor(seconds)) * 10);
                }
                sFormatted = Math.floor(speedConverted) + SIGN_REDKM_MINUTES + secondsStr + SIGN_REDKM_SECONDS + secondTenths;
            }
            return sFormatted;

        case "mph":
            return speedConverted + " " + translationsUnits["mph"];

        case "Mil. red.":

            if (speedConverted % 1 == 0) {
                return Math.floor(speedConverted) + "′00″";
            } else {
                var seconds = (speedConverted - Math.floor(speedConverted)) * 60;
                var secondsStr = Math.floor(seconds).toString().padStart(2, "0");
                var secondTenths = 0;
                if (seconds % 1 != 0) {
                    secondTenths = Math.round((seconds - Math.floor(seconds)) * 10);
                }
                sFormatted = Math.floor(speedConverted) + SIGN_REDKM_MINUTES + secondsStr + SIGN_REDKM_SECONDS + secondTenths;
            }
            return sFormatted;


    }
}

export function getConvertedSpeed(speed: number, unit: string) {
    let vitesse = toNumber(getSpeedForChart(speed, unit))
    return getSpeedTooltipForChart(vitesse, unit)
}

export function formatMetricIntoImperialDistance(meters: number, imperialDistanceMilesOnly = false) {

    // miles = Math.floor(meters /1609.34); 
    // furlongs = Math.floor((meters - (miles * 1609.34)) / 201.168 ) ; 
    // yards = Math.floor( (meters - miles * 1609 - furlongs * 201.168)/0.9144);
    if (imperialDistanceMilesOnly) {
        return (meters / 1600).toFixed(2) + ' m ';
    }

    let miles = Math.floor(meters / 1600);
    let furlongs = Math.floor((meters - (miles * 1600)) / 200);
    let yards = Math.floor((meters - miles * 1600 - furlongs * 200) / 0.9);

    let res = "";
    if (miles > 0) res += miles + 'm ';
    if (furlongs > 0) res += furlongs + 'f ';
    if (yards > 0) res += yards + 'y ';

    return res;
}

export function convertStrideLength(unitSystem?: 'imperial' | 'metric', value?: number | null) {
    if (!value || value === 0) {
        return 0;
    }
    if (unitSystem == "imperial") {
        return ((value * 3.28) / 20).toFixed(2);
    } else {
        return (value / 20).toFixed(2);
    }
}
export function getDatabaseSpeed(speedConverted: number, unit: string, unitSystem: string = "metric") {

    if (unitSystem == "imperial") {
        if (unit == "km/h") unit = "mph";
        if (unit == "min/km") unit = "min/mi";
        if (unit == "Red. km") unit = "Mil. red.";
    }


    switch (unit) {
        case "min/km":
        case "km/h":
            return Math.round(speedConverted / 0.3);

        case "Red. km":
            let value = String(speedConverted)
            value.trim();
            var minutes = parseInt(value.split(SIGN_REDKM_MINUTES)[0]);
            var seconds = parseInt((value.split(SIGN_REDKM_MINUTES)[1]).split(SIGN_REDKM_SECONDS)[0]);
            var secondTenths = (value.split(SIGN_REDKM_MINUTES)[1]).split(SIGN_REDKM_SECONDS).length == 2 && '' != (value.split(SIGN_REDKM_MINUTES)[1]).split(SIGN_REDKM_SECONDS)[1] ? (value.split(SIGN_REDKM_MINUTES)[1]).split(SIGN_REDKM_SECONDS)[1] : 0;
            var minPerKm = (minutes * 60 + seconds + toNumber(secondTenths) / 100) / 60;
            return 200 / minPerKm;


        case "min/mi":
        case "mph":
            return Math.round(speedConverted / (0.3 * 0.621371));

        case "Mil. red.":
            let valueMil = String(speedConverted)

            valueMil.trim();
            var _minutes = valueMil.split(SIGN_REDKM_MINUTES)[0];
            var _seconds = (valueMil.split(SIGN_REDKM_MINUTES)[1]).split(SIGN_REDKM_SECONDS)[0];
            var _secondTenths = (valueMil.split(SIGN_REDKM_MINUTES)[1]).split(SIGN_REDKM_SECONDS).length == 2 && '' != (valueMil.split(SIGN_REDKM_MINUTES)[1]).split(SIGN_REDKM_SECONDS)[1] ? (valueMil.split(SIGN_REDKM_MINUTES)[1]).split(SIGN_REDKM_SECONDS)[1] : 0;

            var _minPerKm = ((toNumber(_minutes) * 60 + toNumber(_seconds) + toNumber(_secondTenths)) / 100) / 60;

            return 200 / (_minPerKm * 0.621371);

        case "m/min":
            return (speedConverted / 5);
        default:
            return 0
    }
}