import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import { t } from 'i18next'
import IntervalsGrid from '../IntervalsGrid'
import { TTrainingSection } from '../../../shared/types/training.type'
import { get, isEmpty, result, sortBy, toNumber } from 'lodash'
import { useMutation } from 'react-query'
import { Exams } from '../../../api/exams'
import { toast } from 'react-toastify'
import { formatMetricIntoImperialDistance } from '../../../shared/utils/conversion'
import { Delete, Favorite, FavoriteBorder } from '@mui/icons-material'
import { useAuth } from '../../../hooks/useAuth'
import { LoadingButton } from '@mui/lab'
type sectionalsProps = {
  intervals: TTrainingSection[],
  id?: number,
  currentIndex: number,
  time: number[],
  editable?: boolean,
  isFinishLineActive: boolean,
}
function Sectional({ intervals, id, currentIndex, time, editable, isFinishLineActive }: sectionalsProps) {
  const { updateUserTraingSetting, settingsUpdating, getSpeedUnit } = useAuth()
  const sectional_settings = useSelector<RootState>(state => state.user.user?.training_settings?.interval_length_array ?? []) as number[]
  const defaultInterval = useSelector<RootState>(state => state.user.user?.training_settings?.default_interval_length) as number
  const speedUnit = useSelector<RootState>(state => state.user.user?.default_speed_unit) as string
  const getIntervalMutation = useMutation((interval: number) => Exams.changeInterval(interval, id))
  const metric = useSelector<RootState>(state => state.user.user?.unit_system) as "metric" | "imperial" | undefined
  const imperial_distance_miles_only = useSelector<RootState>(state => state.user.user?.imperial_distance_miles_only) as boolean
  const [currentInterval, setCurrentInterval] = useState<number>()
  const [Intervals, setIntervals] = useState<TTrainingSection[]>([])
  let settingsId = useSelector<RootState>(state => state.user.user?.training_settings?.id) as number
  const [newInterval, setNewInterval] = useState('')
  useEffect(() => {
    setCurrentInterval(defaultInterval)
  }, [defaultInterval])


  const deleteInterval = async () => {
    let filtered = sectional_settings.filter(e => e !== currentInterval)
    let nextIndex = sectional_settings.findIndex(e => e === currentInterval)
    try {
      await updateUserTraingSetting(settingsId, { interval_length_array: filtered })
      if (nextIndex !== -1) {
        setCurrentInterval(sectional_settings[nextIndex + 1])
        await setFavourite()
      }
    } catch (err) {
      toast(t('error:generic'), { type: 'error' })
    }
  }

  const setFavourite = async () => {
    try {
      await updateUserTraingSetting(settingsId, { default_interval_length: currentInterval })
    } catch (err) {
      toast(t('error:generic'), { type: 'error' })
    }
  }

  useEffect(() => {
    setIntervals(intervals)
  }, [intervals])

  const onIntervalChange = (interval: number) => {
    setCurrentInterval(interval)
    getIntervalMutation.mutateAsync(interval)
      .then(result => {
        setIntervals(get(result, 'data.hydra:member', []))
      }).catch(err => {
        toast(t('error:generic'), { type: 'error' })


      })
  }

  const addNewInterval = async () => {
    let convertedValue = metric === 'metric' ? toNumber(newInterval) : Math.round((toNumber(newInterval) * 200))

    let newIntervals = sortBy([...sectional_settings, convertedValue])

    try {
      await updateUserTraingSetting(settingsId, { interval_length_array: newIntervals })
      setNewInterval("")
    } catch (err) {
      toast(t('error:generic'), { type: 'error' })
    }
  }

  return (
    <Box mt={2} width={'100%'}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'} mb={2} gap={1}>
        {
          editable &&
          <Box display={'flex'} gap={1} alignItems={'center'}>
            <TextField
              InputProps={{
                endAdornment: <InputAdornment position="start">
                  <Typography variant='caption'>{metric === 'metric' ? 'M' : 'F'}</Typography>
                </InputAdornment>,
              }}
              value={newInterval}
              onChange={e => setNewInterval(e.target.value)}
               label={t('settings:addIntervalLength')} placeholder={t('settings:addIntervalLength')}></TextField>

            <LoadingButton disabled={isEmpty(newInterval)} onClick={addNewInterval} loading={settingsUpdating}>{t('button:add')}</LoadingButton>
          </Box>
        }
        {
          editable &&
          <Box>
            <FormControl >
              <InputLabel id="demo-simple-select-label">{t('dataParameters:groups:sectionals')}</InputLabel>
              <Select
                sx={{ width: '200px' }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={t('dataParameters:groups:sectionals')}
                defaultValue={defaultInterval}
                onChange={e => onIntervalChange(toNumber(e.target.value))}
                disabled={getIntervalMutation.isLoading || settingsUpdating}
              >
                {sortBy(sectional_settings).map((item: number, index: number) =>
                  <MenuItem key={index} value={item}>{metric === 'metric' ? item + ' m' : formatMetricIntoImperialDistance(item, imperial_distance_miles_only)}</MenuItem>
                )}
              </Select>

            </FormControl>
            <Tooltip title={t('button:delete')} arrow>
              <IconButton disabled={settingsUpdating || getIntervalMutation.isLoading} onClick={deleteInterval}  color='error'>
                <Delete></Delete>
              </IconButton>
            </Tooltip>
            <Tooltip title={t('layout:help:markConfigurationAsDefault')} arrow>
              <IconButton onClick={setFavourite} disabled={settingsUpdating || getIntervalMutation.isLoading}  color='primary'>
                {currentInterval !== defaultInterval ? <FavoriteBorder></FavoriteBorder> : <Favorite></Favorite>}
              </IconButton>
            </Tooltip>
          </Box>
        }


      </Box>
      <IntervalsGrid isFinishLineActive={isFinishLineActive} time={time} currentIndex={currentIndex} loading={getIntervalMutation.isLoading} intervals={Intervals} ></IntervalsGrid>
    </Box>
  )
}

export default Sectional