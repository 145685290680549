import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import LoginForm, { loginForm } from '../../components/auth/LoginForm';
import { Alert, Box, Card, Container, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { routes } from '../routes';
import { useDispatch } from 'react-redux';
import { setAuth } from '../../store/reducers/user.reducer';
import { useAuth } from '../../hooks/useAuth';
import { ApiError } from '../../shared/types/apiError.type';
import Errors from '../../components/global/Errors';

function Login() {
  const { t } = useTranslation();
  const { login, isLoading, isError, error } = useAuth()
  const navigation = useNavigate()
  const err = error?.response?.data as ApiError



  return (
    <Grid container height={'100vh'}>

      <Grid item md={12} sm={12} xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'} p={4} >
        <Container maxWidth="xs" >
          <Typography variant='h6' fontWeight={'500'} mb={2}>{t('security:login:submit')} Equimetre</Typography>

          <Errors isError={isError} error={err}></Errors>
          <LoginForm
            isLoading={isLoading}
            onSubmitLogin={login}
            onRegisterClicked={() => navigation(routes.AUTH_ROUTES.REGISTER)}
            onForgotPasswordClicked={() => navigation(routes.AUTH_ROUTES.FORGOT_PASSWORD)}
          ></LoginForm>
        </Container>
      </Grid>

    </Grid>
  )
}

export default Login