import { Box, Skeleton } from '@mui/material'
import React from 'react'

function SideBarSkeleton() {
    return (
        <Box >
            <Skeleton variant="circular" width={40} height={40} />
        </Box>
    )
}

export default SideBarSkeleton