import React from 'react'
import InfoForm from '../../components/user/InfoForm'
import { useAuth } from '../../hooks/useAuth'
import ResetPasswordForm from '../../components/auth/resetPasswordForm'
import { Card, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFileUpload } from '../../hooks/useFileUpload'
import MirrorAccounts from '../../components/user/MirrorAccounts'


function Profile() {
  const { auth, updateUser, isLoading, isUpdateUserSuccess,user_avatar_uri } = useAuth()
  const { uploadImage, imageIsLoading, imageLoadingFailed } = useFileUpload()
  const { t } = useTranslation()

  return (
    <div>
      <InfoForm avatar={user_avatar_uri} imageIsLoading={imageIsLoading} imageLoadingFailed={imageLoadingFailed} handleImageChange={(file) => uploadImage({ file, isPublic: true, category: 'USER_AVATAR' })} isLoading={isLoading} user={auth} onUpdate={updateUser}></InfoForm>
      <Card sx={{ mt: 3, p: 3 }}>
        <Typography variant='h6' mb={2}>{t('info:password')}</Typography>
        <ResetPasswordForm variant='outlined' isUpdateUserSuccess={isUpdateUserSuccess} spacing={5} fullWidth={false} md={6} isLoading={isLoading} onSubmitReset={(password: string) => { updateUser({ plain_password: password, id: auth?.id }) }}></ResetPasswordForm>
      </Card>
      <Card sx={{ mt: 3, p: 3 }} style={{backgroundColor:'#29262c'}}>
        <Typography variant='h6' color={'white'} mb={2}>{t('parentsChildren:sectionTitle')}</Typography>
        <MirrorAccounts></MirrorAccounts>
      </Card>
    </div>
  )
}

export default Profile