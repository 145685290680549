import React, { Suspense, useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import { router } from './pages'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import 'react-toastify/dist/ReactToastify.css';
import { Slide, ToastContainer } from 'react-toastify'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Shoeing from './components/modals/Shoeing'
import Rider from './components/modals/Rider'
import Track from './components/modals/Track'
import TrackName from './components/modals/TrackName'
import { useJsApiLoader } from '@react-google-maps/api'
import Loading from './components/global/Loading'
import EditTrainingModal from './components/modals/EditTrainingModal'
import AddHorseModal from './components/modals/AddHorseModal'
import { useLocales } from './hooks/useLocales'
import ComparaisonModal from './components/modals/ComparaisonModal'
import EditTrainingsModal from './components/modals/EditTrainingsModal'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { ConfigProvider,  theme } from 'antd'
import Theme from './theme'


// Create a client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        }
    }
})
function App() {
    //Load google maps
    const { getTimePickerLocales } = useLocales()

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? "",
        libraries: ['places', 'maps', 'marker', 'geometry']

    })

    if (!isLoaded) {
        return <Loading></Loading>
    }
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <LocalizationProvider
                    localeText={getTimePickerLocales()}
                    dateAdapter={AdapterDayjs}>
                    <ThemeProvider theme={Theme}>
                        <CssBaseline />
                        <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
                            <Suspense fallback={<Loading></Loading>}>
                                <RouterProvider router={router} />
                            </Suspense>
                        </ConfigProvider>
                        {/* enable react query devtools in dev env only */}
                        {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
                        {/* Modals */}
                        <Shoeing></Shoeing>
                        <Rider></Rider>
                        <Track></Track>
                        <TrackName></TrackName>
                        <EditTrainingModal></EditTrainingModal>
                        <EditTrainingsModal></EditTrainingsModal>
                        <AddHorseModal></AddHorseModal>
                        <ComparaisonModal></ComparaisonModal>
                        {/* end Modals */}
                    </ThemeProvider>
                </LocalizationProvider>
            </QueryClientProvider>
            <ToastContainer
                position='top-right'
                pauseOnHover={false}
                closeOnClick={true}
                transition={Slide}
                autoClose={2000}
                theme='colored'
            ></ToastContainer>



        </>

    )
}

export default App