import { cloneDeep, groupBy, isEmpty } from "lodash";
import { TcomputedKeyParam } from "../types/computedKeyParam.type";
import { TKeyParamLayout } from "../types/exam.type";
import { TtrainingKeyParameter } from "../types/trainingKeyParameter.type";

export function mapTypeToTrainingKeys(alltrainingKeys: TtrainingKeyParameter, preselects: TKeyParamLayout[]) {

    let data: TcomputedKeyParam[] = []
    if (!isEmpty(alltrainingKeys?.items) && !isEmpty(preselects)) {

        let computedKeyParams = alltrainingKeys.items?.[0]?.computed_key_parameters ?? []

        preselects.forEach(preselect => {
            let id = preselect.insight_parameter?.["@id"]

            let findTarget = computedKeyParams.find(key => key.key_parameter_layout?.insight_parameter?.["@id"] === id)
            if (findTarget) {
                data.push(findTarget)
            }
        })
    }
    return data
}

export const groupByCategory = (alltrainingKeys: TtrainingKeyParameter) => {
    let computedKeyParams = alltrainingKeys.items?.[0]?.computed_key_parameters ?? []
    let data: Record<string, TcomputedKeyParam[]> = {}
    if (computedKeyParams.length > 0) {
        data = groupBy(computedKeyParams, function (key) {
            return key.key_parameter_layout.insight_parameter.category
        })
    }
    return data
}

export const replaceWithNewKey = (alltrainingKeys: TtrainingKeyParameter, newId: string, oldKey: TcomputedKeyParam, currentKeys: TcomputedKeyParam[]) => {
    let computedKeyParams = alltrainingKeys.items?.[0]?.computed_key_parameters ?? []
    let data: TcomputedKeyParam[] = []
    if (isEmpty(computedKeyParams)) return data

    data = cloneDeep(currentKeys)

    let newKey = computedKeyParams.find(e => e.key_parameter_layout?.insight_parameter?.["@id"] === newId)

    let oldIndex = currentKeys.findIndex(e => e.key_parameter_layout?.insight_parameter?.["@id"] === oldKey.key_parameter_layout?.insight_parameter?.["@id"])
    if (newKey && oldIndex !== -1) {

        newKey.key_parameter_layout.order_number = data[oldIndex].key_parameter_layout.order_number

        data[oldIndex] = newKey
    }

    return data
}

export type TsavePreselects = { "trainer"?: string, "insight_parameter": string, "order_number": number }

export const formatComputedKeyToSave = (computedKeys: TcomputedKeyParam[], trainer?: string): TsavePreselects[] => {
    
    return computedKeys.map((key,i) => ({
        trainer,
        insight_parameter: key.key_parameter_layout.insight_parameter["@id"],
        order_number: key.key_parameter_layout?.order_number ?? i
    }))
}
export function getRandomElements(array: TcomputedKeyParam[], numElements: number) {
    const randomElements = [];

    // Generate random indexes and push corresponding elements into randomElements array
    for (let i = 0; i < numElements; i++) {
        const randomIndex = Math.floor(Math.random() * array.length);
        randomElements.push(array[randomIndex]);
    }

    return randomElements;
}

export const reoderElements = (elements: TcomputedKeyParam[], lastIndex: number) => {
    return elements.map((element, index) => ({
        ...element,
        key_parameter_layout: {
            ...element.key_parameter_layout,
            order_number: lastIndex + (index + 1)
        }
    }))
}