import styled from '@emotion/styled'
import React from 'react'

type rangeProps = {
  bg: string
}

export const Range = styled.input<rangeProps>`
flex:1;
position:relative;
width:100% !important;
& {
    background-image: ${props => props.bg};
    border: solid 1px #82CFD0;
    border-radius: 8px;
    height: 12px;
    width: 356px;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none
  }
`