import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { GoogleMap } from '@react-google-maps/api';
import { LatLng } from 'use-places-autocomplete';
import { TFinishLine } from '../../shared/types/finishLine.type';
import { useMap } from '../../hooks/useMap';
import { MapForwardRefType } from '../../pages/user/TrackView';
import { TCheckPoint } from '../../shared/types/checkPoint.type';
import { isEmpty } from 'lodash';
import { CheckPoint } from '../../shared/types/training.type';


type MapProps = {
    center?: LatLng,
    height?: string
    finishLine?: Partial<TFinishLine> | null,
    onFinishLinePosChanged?: (positions: google.maps.LatLngLiteral[]) => void,
    onCheckPointPosChanged?: (positions: google.maps.LatLngLiteral[], id: number | null) => void,
    checkPoints?: TCheckPoint[]
}


const Map = forwardRef<MapForwardRefType, MapProps>(({ center, height, finishLine, onFinishLinePosChanged, checkPoints, onCheckPointPosChanged }: MapProps, ref: any) => {

    const [map, setMap] = React.useState<google.maps.Map | null>(null)
    const { getCenter, getlatlngFromItem, addFinishLine, removeFinishLine, addCheckPoints, removeCheckPoints, fitMarkersOnMap } = useMap(map, onFinishLinePosChanged, onCheckPointPosChanged)
    const [path, setPaths] = useState<google.maps.Polyline[]>()
    const [horseMarker, setHorseMarker] = useState<google.maps.Marker | null>()
    const [isMapLoaded, setIsMapLoaded] = useState(false)
    useImperativeHandle(ref, () => {
        return {
            drawTraject: (positions: (google.maps.LatLngLiteral & { color: string })[]) => {
                let arr: google.maps.Polyline[] = []
                removePaths()
                setPaths([])
                positions.forEach((pos, i) => {
                    let horsePath = new google.maps.Polyline({
                        path: [{ lat: pos.lat, lng: pos.lng }, { lat: positions[i + 1]?.lat ?? pos.lat, lng: positions[i + 1]?.lng ?? pos.lng }],
                        geodesic: false,
                        strokeColor: pos.color,
                        strokeOpacity: 1.0,
                        strokeWeight: 3,
                    });
                    arr.push(horsePath)
                })
                setPaths(arr)
                drawMarker(positions)

            },
            drawFinishLine: (finishLine: TFinishLine | null) => {
                if (!finishLine) {
                    addFinishLine(null)
                    return
                }
                let cords = getlatlngFromItem(finishLine)
                addFinishLine(cords)

            },
            removeFinishLine: () => {
                removeFinishLine()
            },
            addCheckPoints: (checkpoints: CheckPoint[]) => {
                addCheckPoints(checkpoints)
            },
            removeCheckPoints: () => {
                removeCheckPoints()
            },
            fitMarkersOnMap: (markers: google.maps.LatLngLiteral[]) => {
                fitMarkersOnMap(markers)
            },
            getCenter: (positions: google.maps.LatLngLiteral[]) => {
                var bounds = new google.maps.LatLngBounds();
                if (isEmpty(positions)) return
                positions.forEach(pos => {
                    bounds.extend(pos)
                })

                let center = bounds.getCenter()
                return {
                    lat: center.lat(),
                    lng: center.lng()
                }



            },



        }
    }, [path, isMapLoaded])


    useEffect(() => {
        if (path && map) {
            path.forEach(path => path.setMap(map))
        }
    }, [path])

    const drawMarker = (positions: (google.maps.LatLngLiteral & { color: string })[]) => {
        if (positions.length > 0) {
            let lastEl = positions[positions.length - 1]
            horseMarker?.setMap(null)
            setHorseMarker(null)

            let _marker = new google.maps.Marker({
                position: { lat: lastEl.lat, lng: lastEl.lng },
                draggable: false,
                icon: {
                    path: 'M5,10a5,5 0 1,0 10,0a5,5 0 1,0 -10,0',
                    fillColor: '#99141A',
                    fillOpacity: 1,
                    scale: 0.8,
                    strokeColor: '#FFFFFF',
                    strokeWeight: 1,
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(10, 10),


                }
            })
            _marker.setMap(map)
            setHorseMarker(_marker)
        }

    }


    const removePaths = () => {
        if (!isEmpty(path)) {

            path?.forEach(p => p?.setPath([]))
        }
    }


    const containerStyle = {
        width: 'auto',
        height,
        borderRadius: 10
    };



    useEffect(() => {
        getCenter(center, finishLine, checkPoints)
    }, [center, finishLine, checkPoints])

    const onLoad = useCallback(async (map: google.maps.Map) => {

        setMap(map)

        setIsMapLoaded(true)


    }, [])

    const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
        setMap(null)
    }, [])

    return <GoogleMap
        id='gMap'
        mapContainerStyle={containerStyle}
        onLoad={onLoad}
        onUnmount={onUnmount}
        center={center}
        options={{
            mapTypeId: google.maps.MapTypeId.SATELLITE,
            zoom: 16,
            mapTypeControl: false,
            zoomControl: true,
            streetViewControl: false,
            scrollwheel: true,
            maxZoom: 16,
            disableDefaultUI: true,
            keyboardShortcuts:true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR
            },
        }}
    >

        { /* Child components, such as markers, info windows, etc. */}
        <></>
    </GoogleMap>

})

Map.defaultProps = {
    center: {
        lat: -3.745,
        lng: -38.523
    },
    height: '250px',
}

export default memo(Map)

