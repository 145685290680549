import dayjs from 'dayjs';
import { get, isEmpty, orderBy, sortBy } from "lodash";
import { TSortFilter } from "../types/SortType";
import { TTrainerHorse } from '../types/trainerHorse.type';

export function OrderArray(filter: TSortFilter, data: any) {
    return filter.isDate ?
        data.sort((a: any, b: any) => {
            let date1 = dayjs(get(a, filter.objectPath)).year()
            let date2 = dayjs(get(b, filter.objectPath)).year()
            return filter.value === 'asc' ? date1 - date2 : date2 - date1
        })
        : orderBy(data, filter.objectPath, filter.value)
}

export function filterByGroup(groups: { key: string, values: any[], objectPath: string, isDate?: boolean }[], allHorses: TTrainerHorse[]): TTrainerHorse[] {
    if (isEmpty(groups)) return allHorses
    let initialdata: TTrainerHorse[] = allHorses
    console.log({ groups, allHorses });

    groups.forEach(group => {
        initialdata = initialdata.filter(e =>
            isEmpty(group.values) ?
                e :
                group.values.includes(group.isDate ? get(e, group?.objectPath) ? dayjs(get(e, group?.objectPath)).year() : '' : get(e, group?.objectPath, ''))
        )
    })

    return initialdata
}