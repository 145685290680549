// ----------------------------------------------------------------------

export default function CircularProgress(theme) {
    const isLight = theme.palette.mode === 'light';

    return {
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: 'white'
                }
            },
        },
    };
}
