import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { get, isNumber } from 'lodash'
import { TRider } from "../shared/types/rider.type"
import { Riders } from "../api/riders"
import { TrackName } from "../api/trackName"
import { Ttrack, trackSurface } from "../shared/types/tracks.type"
import { TFinishLine } from "../shared/types/finishLine.type"
import { useAuth } from "./useAuth"
import { AxiosError } from "axios"
import { RootState } from "../store"
import { TUser } from "../shared/types/user.type"
import { TTrainingType } from "../shared/types/training.type"
export const useTracks = (enableQuery: boolean, trainer?: number, allAvailable: boolean = false, enableTrainingTypesQuery = false) => {
    const dispach = useDispatch()
    const queryClient = useQueryClient()
    const User = useSelector<RootState>(state => state.user.user) as TUser
    //Mutations
    const saveTrackMutation = useMutation((data: Partial<Ttrack>) => TrackName.save(data))
    const saveFLMutation = useMutation((data: Partial<TFinishLine>) => TrackName.saveFinishLine(data))
    const deleteTrackMutation = useMutation((id: number) => TrackName.delete(id))
    const deleteFLMutation = useMutation((id: number) => TrackName.deleteFL(id))
    const getTrackByIdMutation = useMutation((id: number) => TrackName.getTrack(id))
    const updateTrackMutation = useMutation((data: { id: number, finish_line: string } | Partial<Ttrack>) => TrackName.update(data))
    const getTracksSurfacesMutation = useMutation(() => TrackName.getSuraces())
    const getTrainingTypesMutation = useMutation((trainer: string) => TrackName.getTrainingTypes(trainer))
    //End mutations
    const [tracks, setTracks] = useState<Array<Ttrack> | []>([])
    const [trainingTypes, setTrainingTypes] = useState<Array<TTrainingType> | []>([])

    const query = useQuery(['tracksNames'], () => TrackName.getTracks(trainer, allAvailable), {
        refetchOnWindowFocus: false,
        cacheTime: 900000,
        enabled: enableQuery,
        onSuccess: (response) => {
            setTracks(get(response, 'data.hydra:member', []))
        }
    })

    const trainingsTypesQuery = useQuery(['training_types'], () => TrackName.getTrainingTypes(User?.["@id"]), {
        refetchOnWindowFocus: false,
        cacheTime: 900000,
        enabled: (enableQuery && enableTrainingTypesQuery),
        onSuccess: (response) => {
            setTrainingTypes(get(response, 'data.hydra:member', []))
        }
    })

    const getTrackSurfaces = async (): Promise<Array<trackSurface> | []> => {
        try {
            let response = await getTracksSurfacesMutation.mutateAsync()

            return get(response, 'data.hydra:member', [])
        } catch (err) {
            console.log({ err });

            return []
        }
    }

    const getTrackById = async (id: number): Promise<Ttrack | null> => {
        try {
            let response = await getTrackByIdMutation.mutateAsync(id)

            return get(response, 'data', null)
        } catch (err) {
            console.log({ err });

            return null
        }
    }

    const saveTrack = async (data: Partial<Ttrack>) => {
        return saveTrackMutation.mutateAsync(data)
            .then(({ data }) => {
                // dispach(seTRiderModal(false))
                queryClient.refetchQueries(['tracksNames'])
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
            })
    }

    const saveFL = async (data: Partial<TFinishLine>): Promise<TFinishLine> => {
        return saveFLMutation.mutateAsync(data)
            .then(({ data }) => {
                // dispach(seTRiderModal(false))
                return data
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
                return {} as TFinishLine
            })
    }

    const updateTrack = async (data: { id: number, finish_line: string } | Partial<Ttrack>): Promise<Ttrack> => {
        return updateTrackMutation.mutateAsync(data)
            .then(({ data }) => {
                // dispach(seTRiderModal(false))
                queryClient.refetchQueries(['tracksNames'])
                toast(t('defaultSuccessMessage'), { type: 'success' })

                return data
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
                return err
            })
    }

    const deleteFinishLine = (id: number) => {

        return deleteFLMutation.mutateAsync(id)
            .then(({ data }) => {
                // dispach(seTRiderModal(false))
                queryClient.refetchQueries(['tracksNames'])
                return data
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
                return err
            })

    }

    const deleteTrack = (id: number) => {
        return deleteTrackMutation.mutateAsync(id)
            .then(({ data }) => {
                // dispach(seTRiderModal(false))
                queryClient.refetchQueries(['tracksNames'])
                return data
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
                return err
            })

    }



    return {
        saveTrack,
        updateTrack,
        deleteTrack,
        getTrackSurfaces,
        getTrackById,
        saveFL,
        deleteFinishLine,
        isDeletingFL: deleteFLMutation.isLoading,
        isTrackLoading: getTrackByIdMutation.isLoading,
        isTrackError: getTrackByIdMutation.isError,
        isDeleting: deleteTrackMutation.isLoading,
        isDeleted: deleteTrackMutation.isSuccess,
        isSaving: saveTrackMutation.isLoading,
        isSavingFL: saveFLMutation.isLoading,
        isSurfacesLoading: getTracksSurfacesMutation.isLoading,
        isUpdating: updateTrackMutation.isLoading,
        tracks,
        isLoading: query.isLoading || query.isFetching,
        trainingTypes,
        isLoadingTrainingTypes: trainingsTypesQuery.isLoading || trainingsTypesQuery.isFetching
    }
}