import { Grid, TextField } from '@mui/material'
import React, { useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import './MirrorAccounts.css'
function MirrorAccounts() {
    const { t } = useTranslation()


    useLayoutEffect(() => {
        try {
            /* @ts-ignore */
            window?.hbspt.forms.create({
                region: process.env.REACT_APP_HBSPT_REGION,
                portalId: process.env.REACT_APP_HBSPT_PORTALID,
                formId: process.env.REACT_APP_HBSPT_FORMID,
                target: '#hbsp-form',
                onFormReady: (form: any) => {

                }
            })
        } catch (err) {
            console.log('Error while creating hubspot form')
        }


    }, [])
    return (
        <div id='hbsp-form'>

        </div>
    )
}

export default MirrorAccounts