import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Checkbox, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Skeleton, Typography } from '@mui/material'
import { GridArrowDownwardIcon } from '@mui/x-data-grid'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import BaseAccordion from './BaseAccordion'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { TUser, TuserAccountType } from '../../shared/types/user.type'
import { preselects } from '../../enums/preselects.enum'
import { useMyTrainings } from '../../hooks/useMyTrainings'
import { TcomputedKeyParam } from '../../shared/types/computedKeyParam.type'
import KeyParamSection from '../exams/KeyParamSection'
import ParamSection from './ParamSection'
import { TtrainingKeyParameter } from '../../shared/types/trainingKeyParameter.type'
import KeyItemsLoader from './KeyItemsLoader'
import AvailableParameters from './AvailableParameters'
import { formatComputedKeyToSave } from '../../shared/utils/myTrainings'
import { useAuth } from '../../hooks/useAuth'
import { isEmpty } from 'lodash'

type KeyParamProps = {
    enabled: boolean,
    onPreselectChange: (value: TuserAccountType) => void,
    deleteLine: () => void,
    addLine: () => void,
    loading: boolean,
    trainingKeysLoading: boolean,
    computedKeys: TcomputedKeyParam[],
    groupedKeys: Record<string, TcomputedKeyParam[]>,
    onChangekey: (newId: string, oldParam: TcomputedKeyParam) => void,
    trainingKeys?: TtrainingKeyParameter
}

const CHECKBOX_KEY = 'display_section_key_parameters'

function KeyParameters({ trainingKeys, enabled, onPreselectChange, loading, computedKeys, groupedKeys, onChangekey, trainingKeysLoading, deleteLine, addLine }: KeyParamProps) {
    const auth = useSelector<RootState>(state => state.user.user) as TUser | null
    const [openDrawer, setOpenDrawer] = useState(false)
    const { updateUserTraingSetting } = useAuth()
    const { saveKeyParams, isSavingPreselects } = useMyTrainings(auth?.['@id'])
    let id = useSelector<RootState>(state => state.user.user?.training_settings?.id) as number
    const saveKeys = () => {
        console.log({ computedKeys });

        let data = formatComputedKeyToSave(computedKeys, auth?.['@id'])
        saveKeyParams(data)

    }




    return (
        <BaseAccordion
            title='keyParameters:title'
            enableCheckBox
            defaultChecked={enabled}
            hint='profileSectionDescription:keyParameters'
            onChange={value => { updateUserTraingSetting(id, { [CHECKBOX_KEY]: value }) }}
            onSave={saveKeys}
            showInfo
            showSave
            loading={isSavingPreselects}
            tooltipInfo={t('settings:availableParameters')}
            infoClicked={() => { setOpenDrawer(true) }}
        >
            <>
                <AvailableParameters groupedKeys={groupedKeys} open={openDrawer} loading={trainingKeysLoading || loading} handleClose={() => setOpenDrawer(false)} ></AvailableParameters>
                {
                    trainingKeysLoading ?
                        <Skeleton width={'100%'} height={50}></Skeleton>
                        : <Box display={'flex'} my={2} width={'100%'} justifyContent={'end'}>
                            <FormControl fullWidth >
                                <InputLabel id="demo-simple-select-label">{t('settings:preselectLabel:preselects')}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    disabled={loading}

                                    label={t('settings:preselectLabel:preselects')}
                                    onChange={(e) => onPreselectChange(e.target.value as TuserAccountType)}
                                >
                                    {
                                        preselects.map((prs, i) => (
                                            <MenuItem key={prs.value} value={prs.value}>{t(prs.title)}</MenuItem>

                                        ))
                                    }

                                </Select>
                            </FormControl>
                        </Box>
                }

            </>
            {
                loading ? <KeyItemsLoader></KeyItemsLoader>
                    :
                    <Box>

                        <Grid container spacing={4} mt={2}>
                            <Box display={'flex'} my={1} gap={1} width={'100%'} justifyContent={'end'}>
                                <Button disabled={isEmpty(trainingKeys) || computedKeys.length <= 4} onClick={deleteLine}  color='error'>{t('settings:removeLine')}</Button>
                                <Button disabled={isEmpty(trainingKeys) || computedKeys.length >= 12} onClick={addLine} >{t('settings:addLine')}</Button>
                    </Box>
                            {computedKeys?.map((key, i) => (
                        <React.Fragment key={i}>
                            <Grid item md={3} sm={6} xs={6}>
                                <ParamSection onChangekey={onChangekey} groupedKeys={groupedKeys} key={key['@id']} keyParam={key}></ParamSection>
                            </Grid>
                        </React.Fragment>
                    ))}
        </Grid>
                    </Box >

            }

        </BaseAccordion >

    )
}

export default KeyParameters