import React, { forwardRef, useCallback, useMemo, useRef } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { t } from 'i18next'
import { RootState } from '../../../store'
import { useSelector } from 'react-redux'
import { TrainingSetting } from '../../../shared/types/trainingSetting.type'
import { CheckPoint } from '../../../shared/types/training.type'
import { Scale } from '../../../pages/trainings/ExamView'
import { convertSpeed, convertStrideLength, getConvertedSpeed, getSpeedForChart } from '../../../shared/utils/conversion'
import dayjs from 'dayjs'
import Duration from 'dayjs/plugin/duration'
import { get, isEmpty, toNumber } from 'lodash'
import { mergeArr, startTimeFrom0 } from '../../../shared/utils/MapSection'
import { useAuth } from '../../../hooks/useAuth'
dayjs.extend(Duration)
export const CheckPointsOptionsChart = forwardRef<any, { checkPoints: CheckPoint[], time: number[], distance: number[], defaultScale: Scale, onHover: (checkPointId: number | null) => void }>(({ checkPoints, time, distance, defaultScale, onHover }, ref) => {
    let settings = useSelector<RootState>(state => state.user.user?.training_settings) as TrainingSetting
    const metric = useSelector<RootState>(state => state.user.user?.unit_system) as "metric" | "imperial" | undefined
    const chartRef = useRef<HighchartsReact.RefObject>(null)

    React.useImperativeHandle(ref, () => ({
        highlightPoint(cpId: number | null) {
            hoverOnPoints(cpId)

        },
    }));

    const hoverOnPoints = (cpId: number | null) => {
        let chart = chartRef.current?.chart
        if (!chart) return
        let serie = chart.series?.[0]
        if (!serie) return
        let points = serie.points
        if (isEmpty(points)) return

        let findPointsWithCpId = points.filter((point: Highcharts.Point & { cpId?: number }) => point.cpId === cpId)

        if (isEmpty(findPointsWithCpId)) return

        findPointsWithCpId.forEach(p => {
            p.onMouseOver()
        })

    }

    const { getSpeedUnit } = useAuth()
    const formattedTime = useMemo(() => {
        return startTimeFrom0(time)
    }, [time])


    //format data 



    //heart rate
    const bpmData = useMemo(() => {
        if (isEmpty(checkPoints)) return []
        // let bpms = checkPoints.map(cp => cp.heart_rate)
        // return mergeArr(defaultScale === 'DISTANCE' ? indexDistance : indexMoment, bpms)
        let data: any = []
        if (defaultScale === 'DISTANCE') {
            distance.forEach((d, i) => {
                let find = checkPoints.find(e => e.index_moment === i)
                if (find) {
                    data.push({ x: d, y: find.heart_rate, cpId: find.id })
                } else {
                    data.push({ x: d, y: undefined })
                }
            })
        }
        if (defaultScale === 'TIME') {
            formattedTime.forEach((d, i) => {
                let find = checkPoints.find(e => e.index_moment === i)
                if (find) {
                    data.push({ x: d, y: find.heart_rate, cpId: find.id })
                } else {
                    data.push({ x: d, y: undefined })
                }
            })
        }

        return data.filter((e: any) => get(e, 'y'))

    }, [checkPoints, distance, formattedTime, defaultScale])



    //speed
    const speedData = useMemo(() => {
        if (isEmpty(checkPoints)) return []
        let data: any = []
        if (defaultScale === 'DISTANCE') {
            distance.forEach((d, i) => {
                let find = checkPoints.find(e => e.index_moment === i)
                if (find) {
                    data.push({ x: d, y: find.speed, cpId: find.id })

                } else {
                    data.push({ x: d, y: undefined })
                }
            })
        }
        if (defaultScale === 'TIME') {
            formattedTime.forEach((d, i) => {
                let find = checkPoints.find(e => e.index_moment === i)
                if (find) {
                    data.push({ x: d, y: find.speed, cpId: find.id })

                } else {
                    data.push({ x: d, y: undefined })

                }
            })
        }
        return data.filter((e: any) => get(e, 'y'))

    }, [checkPoints, distance, formattedTime, defaultScale])

    //stride length
    const slData = useMemo(() => {
        if (isEmpty(checkPoints)) return []
        let data: any = []
        if (defaultScale === 'DISTANCE') {
            distance.forEach((d, i) => {
                let find = checkPoints.find(e => e.index_moment === i)
                if (find) {
                    data.push({ x: d, y: find.stride_length, cpId: find.id })
                } else {
                    data.push({ x: d, y: undefined })

                }
            })
        }
        if (defaultScale === 'TIME') {
            formattedTime.forEach((d, i) => {
                let find = checkPoints.find(e => e.index_moment === i)
                if (find) {
                    data.push({ x: d, y: find.stride_length, cpId: find.id })
                } else {
                    data.push({ x: d, y: undefined })

                }
            })
        }
        return data.filter((e: any) => get(e, 'y'))


    }, [checkPoints, distance, formattedTime, defaultScale])

    //stride length
    const sqData = useMemo(() => {
        if (isEmpty(checkPoints)) return []
        let data: any = []
        if (defaultScale === 'DISTANCE') {
            distance.forEach((d, i) => {
                let find = checkPoints.find(e => e.index_moment === i)
                if (find) {
                    data.push({ x: d, y: find.stride_frequency, cpId: find.id })
                } else {
                    data.push({ x: d, y: undefined })
                }
            })
        }
        if (defaultScale === 'TIME') {
            formattedTime.forEach((d, i) => {
                let find = checkPoints.find(e => e.index_moment === i)
                if (find) {
                    data.push({ x: d, y: find.stride_frequency, cpId: find.id })
                } else {
                    data.push({ x: d, y: undefined })
                }
            })
        }
        return data.filter((e: any) => get(e, 'y'))

    }, [checkPoints, distance, formattedTime, defaultScale])

    //end format data


    const getTooltipText = useCallback((currentChart: Highcharts.TooltipFormatterContextObject) => {

        let str = ''
        if (settings.display_speed_checkpoints_graph) {
            let point = currentChart.points?.find(point => {
                return point.series.name === 'speed'
            })
            str += `${t('intervals:pace')} : ${point ? getConvertedSpeed(point.y ?? 0, getSpeedUnit()?.value ?? 'km/h') : 0} </br> `
        }

        if (settings.display_heart_rate_checkpoints_graph) {
            let point = currentChart.points?.find(point => {
                return point.series.name === 'bpm'
            })
            str += `BPM : ${point?.y ?? 0} Bpm </br>`
        }


        if (settings.display_stride_length_checkpoints_graph) {
            let point = currentChart.points?.find(point => {
                return point.series.name === 'sl'
            })
            str += `${t('intervals:averageAmpl')} : ${convertStrideLength(metric, point?.y ?? 0)} ${t(metric === 'imperial' ? 'graph:pieds' : 'graph:metres')}</br>`
        }
        if (settings.display_stride_frequency_checkpoints_graph) {
            let point = currentChart.points?.find(point => {
                return point.series.name === 'sq'
            })
            str += `${t('intervals:averageCad')} : ${point?.y ?? 0} str/sec </br>`
        }

        return str
    }, [settings])



    const options = {
        chart: {
            type: 'line', backgroundColor: 'transparent',
        },
        title: { text: '' },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                point: {
                    events: {
                        mouseOver: function (this: Highcharts.SeriesMouseOverCallbackFunction, event: any) {
                            let cpId = get(event, 'target.cpId')
                            if (cpId) {
                                onHover(cpId)
                            }


                        },
                        mouseOut: () => {
                            onHover(null)

                        }
                    }
                }

            }
        },
        xAxis: {
            crosshair: {
                width: 1,
            },
            type: defaultScale === 'DISTANCE' ? 'linear' : 'datetime',
            labels: {
                style: {
                    color: 'white'
                },
                /* @ts-ignore */
                formatter: function () {
                    /* @ts-ignore */
                    return defaultScale === 'DISTANCE' ? convertSpeed(metric, this.value) : dayjs.duration(this.value, 'seconds').minutes() + ' mn'
                }
            }
        },
        yAxis: [{ // Primary yAxis
            title: {
                text: t('graph:bpm'),
                style: {
                    color: "rgba(245, 39, 39, 0.8)"
                }
            },
            labels: {
                style: {
                    color: 'white'
                },
            },
            visible: settings.display_heart_rate_checkpoints_graph,
            opposite: false
        },
        { // Primary yAxis
            title: {
                text: t('graph:amplitude'),
                style: {
                    color: "#b3a400"
                }
            },
            visible: settings.display_stride_length_checkpoints_graph,
            labels: {

                style: {
                    color: 'white'
                },

                /* @ts-ignore */
                formatter: function () {
                    /* @ts-ignore */
                    return Math.round(toNumber(convertStrideLength(metric, toNumber(this.value))))
                }
            },
            opposite: false
        },
        { // Primary yAxis
            title: {
                text: t('graph:vitesse') + ` (${t(getSpeedUnit()?.key ?? "")})`,
                style: {
                    color: '#1976D2'
                },
            },
            visible: settings.display_speed_checkpoints_graph,
            labels: {
                style: {
                    color: 'white'
                },
                /* @ts-ignore */
                formatter: function () {
                    /* @ts-ignore */
                    return getSpeedForChart(toNumber(this.value), getSpeedUnit()?.value ?? "")
                }
            },
            opposite: true
        }, { // Primary yAxis
            title: {
                text: t('graph:cadence'),
                style: {
                    color: "#f7911a"
                }
            },
            labels: {
                style: {
                    color: 'white'
                },
            },
            visible: settings.display_stride_frequency_checkpoints_graph,
            opposite: true
        }
        ],
        tooltip: {
            shared: true,
            useHTML: true,
            formatter: function (this: Highcharts.TooltipFormatterContextObject) {
                return getTooltipText(this)
            }
        },
        legend: {
            enabled: false
        },
        series: [
            {

                yAxis: 0,
                data: bpmData,
                type: 'line',
                color: "rgba(245, 39, 39, 0.8)",

                visible: settings.display_heart_rate_checkpoints_graph,
                name: 'bpm'
            },
            {
                yAxis: 1,
                data: slData,
                color: "#b3a400",
                visible: settings.display_stride_length_checkpoints_graph,
                name: 'sl'

            },
            {
                yAxis: 2,
                data: speedData,
                color: '#1976D2',
                visible: settings.display_speed_checkpoints_graph,
                name: 'speed'

            },
            {
                yAxis: 3,
                data: sqData,
                color: "#f7911a",
                visible: settings.display_stride_frequency_checkpoints_graph,
                name: 'sq'
            },

        ]
    }


    return (
        <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            options={options}
            containerProps={{ style: { height: '220px' } }}
        />
    )
}

)