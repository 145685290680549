import dayjs from "dayjs"
import duration from 'dayjs/plugin/duration'
import { isEmpty } from "lodash"
dayjs.extend(duration)


export const getTime = (seconds: number): string => {
    return dayjs.duration(seconds, 'seconds').format('mm:ss')
}

export const startTimeFrom0 = (time: number[]): number[] => {
    if (isEmpty(time)) return []
    let length = time[time.length - 1] - time[0]
    let arr: number[] = [0]
    while (arr[arr.length - 1] < length) {
      arr.push(arr[arr.length - 1] + 1)
    }
    return arr
}
export const mergeArr = (xArr: number[], yArr: number[]) => {
    return xArr.map((item, index) => [item, yArr[index]])
  }