import React from 'react'
import BaseAccordion from './BaseAccordion'
import { t } from 'i18next'
import { useAuth } from '../../hooks/useAuth'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { TExam } from '../../shared/types/exam.type'
import { Alert, Grid, Tooltip } from '@mui/material'
import DonutActivityZone from '../exams/DonutActivityZone'
import HrActivityZone from '../exams/HrActivityZone'
import { Info } from '@mui/icons-material'

type GaitAverageProps = {
    enabled: boolean,
    exam?: TExam
}


const CHECKBOX_KEY = 'display_section_averages'

function GaitAverage({ enabled, exam }: GaitAverageProps) {

    const { updateUserTraingSetting } = useAuth()

    let id = useSelector<RootState>(state => state.user.user?.training_settings?.id) as number


    return (
        <BaseAccordion
            title='averagesAndHrZones:title'
            enableCheckBox
            defaultChecked={enabled}
            onChange={value => { updateUserTraingSetting(id, { [CHECKBOX_KEY]: value }) }}
            onSave={() => { }}
            showInfo={false}
            tooltipInfo={t('settings:availableParameters')}
        >
            <Grid container spacing={4}>
                <Grid item md={6} sm={12} xs={12}>
                    <DonutActivityZone data={exam?.averages_and_hr_zones?.gait_averages}></DonutActivityZone>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    <HrActivityZone params={{ includeInactivity: exam?.averages_and_hr_zones.hr_zones_with_inactivity_and_walk, excludeInactivity: exam?.averages_and_hr_zones?.hr_zones_without_inactivity_and_walk }}></HrActivityZone>
                    <Alert icon={
                        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: t('parameters:tooltipInfoTrainingPageMaxHeartRate', { path: '#' }) }}></div>} arrow>
                            <Info sx={{ width: 20, height: 20 }}></Info>
                        </Tooltip>
                    } variant='outlined' severity='info'>
                        {t('parameters:individualMaxHeartRate')} :  {exam?.hr_max ?? ' - '} Bpm
                    </Alert>
                </Grid>
            </Grid>

        </BaseAccordion>

    )
}

export default GaitAverage