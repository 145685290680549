import { useEffect, useState } from "react"
import { useAuth } from "./useAuth"
import { frFR as french, enUS as english, trTR as Turkish, itIT as Italian, deDE as German, esES as spanish } from '@mui/x-data-grid'

import { deDE, frFR, enUS, trTR, itIT, esES } from '@mui/x-date-pickers/locales';
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { TUser } from "../shared/types/user.type";

export const useLocales = () => {
    const [currentLang, setCurrentLang] = useState('en')
    const auth = useSelector<RootState>(state => state.user.user) as TUser 
    useEffect(() => {
        setCurrentLang(auth?.lang ?? localStorage.getItem('lang'))
    }, [auth])


    const getTimePickerLocales = () => {
        switch (currentLang) {
            case 'fr':
                return frFR.components.MuiLocalizationProvider.defaultProps.localeText
            case 'en':
                return enUS.components.MuiLocalizationProvider.defaultProps.localeText
            case 'it':
                return itIT.components.MuiLocalizationProvider.defaultProps.localeText
            case 'de':
                return deDE.components.MuiLocalizationProvider.defaultProps.localeText
            case 'tr':
                return trTR.components.MuiLocalizationProvider.defaultProps.localeText
            case 'es':
                return esES.components.MuiLocalizationProvider.defaultProps.localeText
            default:
                return enUS.components.MuiLocalizationProvider.defaultProps.localeText

        }
    }

    const getDataGridLocales = () => {
        switch (currentLang) {
            case 'fr':
                return french.components.MuiDataGrid.defaultProps.localeText
            case 'en':
                return english.components.MuiDataGrid.defaultProps.localeText
            case 'it':
                return Italian.components.MuiDataGrid.defaultProps.localeText
            case 'de':
                return German.components.MuiDataGrid.defaultProps.localeText
            case 'tr':
                return Turkish.components.MuiDataGrid.defaultProps.localeText
            case 'es':
                return spanish.components.MuiDataGrid.defaultProps.localeText
            default:
                return english.components.MuiDataGrid.defaultProps.localeText

        }
    }

    return {
        currentLang,
        getDataGridLocales,
        getTimePickerLocales
    }
}