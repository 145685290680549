import { Delete, Edit, Favorite, FavoriteBorderRounded, FavoriteOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, IconButton, TextField, Tooltip } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { TAnalyticView } from '../../shared/types/analyticView.type'
import { isEmpty } from 'lodash'
import { useAuth } from '../../hooks/useAuth'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

type ConfigActionProps = {
    onDelete: () => void,
    isDeleting?: boolean,
    view: TAnalyticView | null,
    updateName: (name: string) => void,
    isUpdating: boolean
}

function ConfigAction({ onDelete, isDeleting, view, updateName, isUpdating }: ConfigActionProps) {
    const [name, setName] = useState("")

    //Hooks
    const { auth, updateUser, isLoading } = useAuth()
    const default_view = useSelector<RootState>(state => state.user.user?.default_analytics_view) as string
    useEffect(() => {
        if (view) {
            setName(view.name)
        }
    }, [view])


    //fns
    const setAsFavourite = () => {

        let value = default_view === view?.['@id'] ? null : view?.['@id']

        updateUser({ default_analytics_view: value, id: auth.id })

    }

    return (
        <Box display={'flex'} gap={0.5}>
            <TextField value={name} onChange={e => setName(e.target.value)} size='small' label={t('configuration')}></TextField>
            <LoadingButton disabled={isEmpty(name)} loading={isUpdating} onClick={() => updateName(name)} variant='text' color='info'>{t('button:edit')}</LoadingButton>
            <Tooltip title={t('layout:help:markConfigurationAsDefault')}>
                <IconButton sx={{ cursor: isLoading ? 'wait' : 'pointer' }} disabled={isLoading} onClick={setAsFavourite} color='primary'>
                    {
                        default_view === view?.['@id'] ? <FavoriteOutlined></FavoriteOutlined> : <FavoriteBorderRounded></FavoriteBorderRounded>
                    }

                </IconButton>
            </Tooltip>
            <Tooltip title={t('button:delete')}>
                <IconButton onClick={onDelete} color='error'>
                    <Delete></Delete>
                </IconButton>
            </Tooltip>
        </Box>
    )
}

export default ConfigAction