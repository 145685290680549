import { Autocomplete, Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { t } from 'i18next'
import React from 'react'
import { TQualifyChoices } from '../../shared/types/qualifyChoices.type'
import { TQualifyData } from '../modals/EditTrainingsModal'
import { TTrainingType } from '../../shared/types/training.type'
import { sortBy } from 'lodash'
type GlobalEditorProps = {
    qualifyChoices: TQualifyChoices | null,
    onChangeSelect: (key: string, value?: string) => void,
    qualifyData: TQualifyData
}
function GlobalEditor({ qualifyChoices, onChangeSelect, qualifyData }: GlobalEditorProps) {


    if (!qualifyChoices) {
        return <></>
    }

    return (
        <Grid container>
            <Grid item md={2} sm={12} xs={12} sx={{ placeSelf: 'center' }}>
                <Typography sx={{ alignSelf: 'center' }} variant='body2'>{t('editTrainingForm:forTheSelection')}</Typography>
            </Grid>
            <Grid item md={10} sm={12} xs={12}>
                <Box display={'flex'} gap={2} alignItems={'center'} flexWrap={'wrap'}>

                    {/* Training Type */}
                    <FormControl sx={{ flex: 1 }}>
                        <Autocomplete
                            options={sortBy(qualifyChoices.training_types, i => i.display_name)}
                            id="demo-simple-select"
                            getOptionLabel={e => e.display_name}
                            getOptionKey={e => e['@id']}
                            value={qualifyChoices.training_types.find(e => e['@id'] === qualifyData.training_types)}
                            onChange={(e, value) => { onChangeSelect('training_types', value?.['@id']) }}
                            renderInput={(params) => <TextField {...params} label={t('editTrainingForm:trainingType:label')} />}

                        />
                    </FormControl>

                    {/* Track */}
                    <FormControl sx={{ flex: 1 }}>
                        <Autocomplete
                            options={sortBy(qualifyChoices.track_names, i => i.display_name)}
                            id="demo-simple-select"
                            getOptionLabel={e => e.display_name}
                            getOptionKey={e => e['@id']}
                            value={qualifyChoices.track_names.find(e => e['@id'] === qualifyData.track_names)}
                            onChange={(e, value) => { onChangeSelect('track_names', value?.['@id']) }}
                            renderInput={(params) => <TextField {...params} label={t('editTrainingForm:trackName:label')} />}

                        />
                    </FormControl>

                    {/* Track surface */}
                    <FormControl sx={{ flex: 1 }}>
                        <Autocomplete
                            options={sortBy(qualifyChoices.track_surfaces, i => i.display_name)}
                            id="demo-simple-select"
                            getOptionLabel={e => e.display_name}
                            getOptionKey={e => e['@id']}
                            value={qualifyChoices.track_surfaces.find(e => e['@id'] === qualifyData.track_surfaces)}
                            onChange={(e, value) => { onChangeSelect('track_surfaces', value?.['@id']) }}
                            renderInput={(params) => <TextField {...params} label={t('editTrainingForm:trackSurface:label')} />}

                        />

                    </FormControl>

                    {/* Track condition */}
                    <FormControl sx={{ flex: 1 }}>
                        <Autocomplete
                            options={sortBy(qualifyChoices.track_conditions, i => i.display_name)}
                            id="demo-simple-select"
                            getOptionLabel={e => e.display_name}
                            getOptionKey={e => e['@id']}
                            value={qualifyChoices.track_conditions.find(e => e['@id'] === qualifyData.track_conditions)}
                            onChange={(e, value) => { onChangeSelect('track_conditions', value?.['@id']) }}
                            renderInput={(params) => <TextField {...params} label={t('editTrainingForm:trackCondition:label')} />}

                        />

                    </FormControl>

                    {/* Riedr name */}
                    <FormControl sx={{ flex: 1 }}>
                    <Autocomplete
                            options={sortBy(qualifyChoices.riders, i => i.display_name)}
                            id="demo-simple-select"
                            getOptionLabel={e => e.display_name}
                            getOptionKey={e => e['@id']}
                            value={qualifyChoices.riders.find(e => e['@id'] === qualifyData.riders)}
                            onChange={(e, value) => { onChangeSelect('riders', value?.['@id']) }}
                            renderInput={(params) => <TextField {...params} label={t('editTrainingForm:riderName:label')} />}

                        />  
                    </FormControl>
                </Box>
            </Grid>
        </Grid >
    )
}

export default GlobalEditor