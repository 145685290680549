import { AxiosResponse } from "axios";
import axiosInstance from "./base";
import { TRider } from "../shared/types/rider.type";

export class Riders {

    static getRiders(trainer?: number): Promise<AxiosResponse<Array<TRider>>> {
        return axiosInstance.get(`riders?trainer=${trainer}`)
    }

    static save(data: Partial<TRider>): Promise<AxiosResponse<TRider>> {
        return axiosInstance.post('riders', data)
    }

    static update(data: Partial<TRider>): Promise<AxiosResponse<TRider>> {
        return axiosInstance.patch(`riders/${data.id}`, data, {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            }
        })
    }

    static delete(id: number) {
        return axiosInstance.delete(`riders/${id}`)

    }

}