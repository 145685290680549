import { MoreHoriz } from '@mui/icons-material'
import { Box, Button, Chip, IconButton, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'

function ShowMore({ items, maxLength, renderItem }: { items: string[], maxLength: number, renderItem: (item: string, index: number) => React.ReactNode }) {
    const [showMore, setShowMore] = useState(false)

    const spliced = useMemo(() => {
        return !showMore ? items.slice(0, maxLength) : items
    }, [items, maxLength, showMore])

    return (
        <Box display={'flex'} gap={1} flexWrap={'wrap'} textAlign={'center'}>
            {
                spliced.map((item, i) => (
                    renderItem(item, i)
                ))
            }
            {
                (items.length > maxLength) &&
                <Tooltip arrow title={items.slice(maxLength, items.length).join(' - ')} placement='top'>
                    <Typography sx={{ cursor: 'pointer' }} variant='subtitle2'>...</Typography>
                </Tooltip>

            }

        </Box>
    )
}

export default ShowMore