import { useMutation } from "react-query"
import { User } from "../api/user"
import { useAuth } from "./useAuth"
import type { FileCategory } from "../shared/types/fileCategory.type"
import { toast } from "react-toastify"
import { t } from "i18next"



type fileUploadProps = {
    file: File,
    isPublic: boolean,
    category: FileCategory
}
export const useFileUpload = () => {

    const { auth, updateUser } = useAuth()


    //request new password Mutation
    const uploadFileMutation = useMutation((data: FormData) => User.uploadImage(data))



    //Handle Image Upload

    const uploadImage = ({ file, isPublic, category }: fileUploadProps) => {

        let formData = new FormData()
        formData.append('file', file)
        formData.append('public', String(isPublic))
        formData.append('category', category)


        uploadFileMutation.mutateAsync(formData)
            .then(result => {
                let avatar_file  = result.data["@id"]
                updateUser({ id: auth?.id, avatar_file  })
            }).catch(err => {
                toast(t('equimetre.ota.failed'), { type: 'error' })
            })

    }

    return {
        uploadImage,
        imageIsLoading: uploadFileMutation.isLoading,
        imageLoadingFailed: uploadFileMutation.isError,
    }
}