import { Alert, Box, Container, Divider, LinearProgress } from '@mui/material'
import { DataGrid, GridCellParams, GridColDef, GridToolbar, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import { t } from 'i18next'
import React from 'react'
import { useHorseShoeing } from '../../hooks/useHorseShoeing';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { TUser } from '../../shared/types/user.type';
import DataGridActions from '../../components/global/DataGridActions';
import { setShoeingItemToEdit, setShoeingModal } from '../../store/reducers/modal.reducer';
import { toNumber } from 'lodash';
import I18nDataGrid from '../../components/global/I18nDataGrid';

function Shoeing() {
  const dispatch = useDispatch()
  const auth = useSelector<RootState>(state => state.user.user) as TUser | null
  const isUserLoading = useSelector<RootState>(state => state.user.isUserLoading) as boolean
  const { shoeings, isLoading, deleteShoeing, isDeleting } = useHorseShoeing(!isUserLoading, auth?.id)

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'name',
      headerName: t('editTrainingForm:horseShoeing:label'),
      flex: 1,
      valueFormatter: (param: GridValueFormatterParams) => {
        return param.value ?? '-'
      }
    },
    {
      field: 'brand',
      headerName: t('horseShoeing:brand'),
      flex: 1,
      valueFormatter: (param: GridValueFormatterParams) => {
        return param.value ?? '-'
      }
    },
    {
      field: 'material',
      headerName: t('horseShoeing:material'),
      flex: 1,
      valueFormatter: (param: GridValueFormatterParams) => {
        return param.value ?? '-'
      }
    },
    {
      field: 'weight',
      headerName: t('parameters:weight') + '(G)',
      type: 'number',
      flex: 1,
      valueFormatter: (param: GridValueFormatterParams) => {
        return param.value ? param.value + ' g' : ' - '
      },
    },
    {
      headerName: t('settings:actions'),
      field: '',
      flex: 1,
      align: 'center',
      renderCell: (params: GridCellParams) =>
        <DataGridActions
          deleteTitle='horseShoeing:deleteHs'
          deleteSubtitle='horseShoeing:deleteHsMessage'
          onEdit={() => {
            dispatch(setShoeingItemToEdit(params.row))
            dispatch(setShoeingModal(true))
          }}
          isDeleting={isDeleting}
          onDelete={() => { deleteShoeing(toNumber(params.id)) }}
        ></DataGridActions>
    }
  ];



  return (
    <Box >
      <Alert severity='info'>
        {t('profileSectionDescription:horseShoeings')}
      </Alert>
      <Divider sx={{ my: 3 }}></Divider>
      {(isLoading || isUserLoading) && <LinearProgress></LinearProgress>}
      <I18nDataGrid
        rows={shoeings}
        loading={(isLoading || isUserLoading)}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
          columns: {
            columnVisibilityModel: {
              id: false
            }
          }
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection={false}
        disableRowSelectionOnClick
      />
    </Box>
  )
}

export default Shoeing