import { isEmpty, uniq, uniqBy } from "lodash";
import { TtrainingKeyParameter } from "../types/trainingKeyParameter.type";

export type TgroupKeyParam = { key?: string, title?: string, detail?: string }

export function groupKeyParamsByCategories(data: TtrainingKeyParameter): TgroupKeyParam[] {
    let categories: TgroupKeyParam[] = []

    if (!isEmpty(data.items)) {
        data.items.forEach(item => {

            //check if computed key params is empty
            if (!isEmpty(item.computed_key_parameters)) {
                item.computed_key_parameters.forEach(keyParam => {
                    categories.push({
                        key: keyParam.key_parameter_layout?.insight_parameter?.category,
                        title: keyParam?.key_parameter_layout?.insight_parameter?.name_translated,
                        detail: keyParam?.key_parameter_layout?.insight_parameter?.details_translated
                    })
                })

            }


        })
    }
    categories = uniqBy(categories, 'key')

    return categories

}