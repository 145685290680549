import React, { useEffect, useRef, useState } from 'react'
import { TComptuedKey } from '../../shared/types/exam.type'
import { Box, Typography } from '@mui/material';
import '../../eq-icons.css'
import { get } from 'lodash';
function ParamSection({ param }: { param: TComptuedKey }) {


    const getText = () => {
        let value = get(param, 'formatted_value', "")
        let unit = get(param, 'key_parameter_layout.insight_parameter.unit_translated', "")

        if (!value) {
            return '-'
        }
        return value.concat(' ').concat(unit !== "mm:ss.ms" ? unit : '')

    }

    return <>

        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} flex={1}>
            <div className="icon" >
                <span className={`eq-${param?.key_parameter_layout?.insight_parameter?.icon} eq-icon-2`}>
                    <span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span></span>
            </div>
            <Typography variant='body2' fontWeight={500}  >{getText()}</Typography>
            <Typography variant='body2' color={'GrayText'} textAlign={'center'}>{get(param, 'key_parameter_layout.insight_parameter.name_translated', "")}</Typography>
        </Box>

    </>
}

export default ParamSection