import { useEffect, useState } from "react"
import { useMutation } from "react-query"
import { Notification } from "../api/notification"
import { TContactOptIn } from "../shared/types/Channel.type"
import { get } from "lodash"
import { toast } from "react-toastify"
import { t } from "i18next"

export const useMailSubscribtion = ({ email, uuid }: { email?: string, uuid?: string }) => {

    const [channels, setChannels] = useState<TContactOptIn[]>([])

    //Mutations
    const getChannels = useMutation(() => Notification.getSubscribedChannels(email, uuid))


    useEffect(() => {
        if (!email || !uuid) return

        getChannelFn()
    }, [email, uuid])

    const getChannelFn = () => {
        getChannels.mutateAsync()
            .then(result => {
                setChannels(get(result.data, 'hydra:member', []) as TContactOptIn[])
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
            })
    }


    return {
        channels,
        channelsLoading: getChannels.isLoading,
        refetch: getChannelFn
    }



}