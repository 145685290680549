import { Box, IconButton, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridRowClassNameParams, GridValueGetterParams } from '@mui/x-data-grid';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react'
import I18nDataGrid from '../global/I18nDataGrid';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { TTrainingSection } from '../../shared/types/training.type';
import { convertStrideLength, formatMetricIntoImperialDistance, getSpeedForChart } from '../../shared/utils/conversion';
import dayjs from 'dayjs';
import { ChevronRight, KeyboardArrowDown } from '@mui/icons-material';
import type { TIntervals } from '../../shared/types/training.type'
import { get, isUndefined, uniqBy, uniqueId } from 'lodash';
import { useAuth } from '../../hooks/useAuth';

import '../../eq-icons.css'
import Icon from '../global/Icon';

type intervalGridProps = {
    intervals: TTrainingSection[],
    currentIndex: number,
    loading: boolean,
    time: number[],
    isFinishLineActive: boolean

}
function IntervalsGrid({ intervals, loading, currentIndex, time, isFinishLineActive }: intervalGridProps) {
    const metric = useSelector<RootState>(state => state.user.user?.unit_system) as "metric" | "imperial" | undefined
    const imperial_distance_miles_only = useSelector<RootState>(state => state.user.user?.imperial_distance_miles_only) as boolean
    const [selectedRows, setSelectedRows] = useState<number[]>([])
    const [Intervals, setIntervals] = useState<any>([])
    const { getSpeedUnit, getMetric } = useAuth()



    useEffect(() => {

        if (selectedRows.length > 0) {
            let data: any = [...intervals]
            selectedRows.forEach(row => {
                let find = data.findIndex((e: TTrainingSection) => e.id === row)

                if (find !== -1) {
                    let itv: TIntervals[] = data[find]?.intervals ?? []
                    itv = itv.map((i) => ({ ...i, '@id': uniqueId() }))
                    data.splice(find + 1, 0, ...itv)

                }
            })

            setIntervals(data)
        } else {
            setIntervals(intervals)
        }
    }, [selectedRows, intervals])

    useEffect(() => {
        setSelectedRows([])
        setIntervals(intervals)

    }, [intervals])
    const handleRowClick = (id: number) => {
        let selected = [...selectedRows]
        if (selected.includes(id)) {
            let idx = selected.findIndex(e => e === id)
            selected.splice(idx, 1)
        } else {
            selected.push(id)
        }

        setSelectedRows(selected)
    }

    const columns: GridColDef[] = [
        {
            field: 'gait',
            headerAlign: 'center',
            renderHeader: () => (
                <Box position={'relative'} width={'100%'} height={'100%'} padding={0} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Icon name='direction'></Icon>
                    <Typography >{t('map:allure')}</Typography>
                </Box>
            ), flex: 1,
            sortable: false,

            renderCell: (val: GridRenderCellParams<TTrainingSection>) => {
                return (<Box padding={0} display={'flex'} gap={1} alignItems={'center'} position={'relative'} flex={1} height={'100%'}>
                    {
                        get(val, 'row.parentId', null) ? <></> : <>
                            {
                                (val.row.intervals && val.row.intervals.length > 0) &&
                                <IconButton onClick={() => handleRowClick(val.row.id)}>
                                    {selectedRows.includes(val.row.id) ? <KeyboardArrowDown></KeyboardArrowDown> : <ChevronRight></ChevronRight>}
                                </IconButton>
                            }

                            <Typography variant='inherit'>
                                {(isFinishLineActive && val.row.finish_section) && '🏁'}
                                {isUndefined(val.row.gait) ? "" : t("intervals:gait:" + val.row.gait)}</Typography>
                        </>
                    }

                    {
                        (isFinishLineActive && val.row.finish_section) &&
                        <div className='finish_section' style={{ position: 'absolute', bottom: 0, left: 0, right: 0, width: '100%' }}>

                        </div>
                    }
                </Box>)
            }
        },

        {
            field: 'distance',
            headerAlign: 'center',
            renderHeader: () => (
                <Box position={'relative'} width={'100%'} height={'100%'} padding={0} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Icon name='direction'></Icon>
                    <Typography >{t('intervals:distance')}</Typography>
                    <Typography variant='caption'>({metric === 'metric' ? t('intervals:meters') : t('intervals:furlongs')})</Typography>

                </Box>
            ),
            flex: 1,
            sortable: false,
            renderCell: (val: GridRenderCellParams<TTrainingSection>) => {
                return (
                    <Box padding={0} display={'flex'} gap={1} alignItems={'center'} position={'relative'} flex={1} height={'100%'}>
                        <Typography>
                            {metric === 'metric' ? val.row.distance : formatMetricIntoImperialDistance(val.row.distance, imperial_distance_miles_only)}
                        </Typography>

                        {
                            (isFinishLineActive && val.row.finish_section) &&
                            <div className='finish_section' style={{ position: 'absolute', bottom: 0, left: 0, right: 0, width: '100%' }}>

                            </div>
                        }
                    </Box>
                )
            }

        },
        {
            field: 'time',
            headerAlign: 'center',
            renderHeader: () => (
                <Box position={'relative'} width={'100%'} height={'100%'} padding={0} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Icon name='partial-clock'></Icon>
                    <Typography >{t('intervals:time')}</Typography>
                    <Typography variant='caption'>({t('intervals:min')})</Typography>
                </Box>
            ), flex: 1,
            sortable: false,
            renderCell: (val: GridRenderCellParams<TTrainingSection>) => {
                return (
                    <Box padding={0} display={'flex'} gap={1} alignItems={'center'} position={'relative'} flex={1} height={'100%'}>
                        <Typography>
                            {get(val, 'row.parentId', null) ? get(val, 'row.time_formatted', ' - ') : val.row.duration_seconds ? dayjs.duration(val.row.duration_seconds, 'seconds').format('mm:ss') : '-'}
                        </Typography>

                        {
                            (isFinishLineActive && val.row.finish_section) &&
                            <div className='finish_section' style={{ position: 'absolute', bottom: 0, left: 0, right: 0, width: '100%' }}>

                            </div>
                        }
                    </Box>
                )
            }

        },
        {
            field: 'pace',
            headerAlign: 'center',
            renderHeader: () => (
                <Box position={'relative'} width={'100%'} height={'100%'} padding={0} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Icon name='speedometer'></Icon>
                    <Typography >{t('intervals:pace')}</Typography>
                    <Typography variant='caption'>(t{getSpeedUnit()?.key ?? ''})</Typography>

                </Box>
            ),
            flex: 1,
            sortable: false,
            renderCell: (val: GridRenderCellParams<TTrainingSection>) => {
                return (
                    <Box padding={0} display={'flex'} gap={1} alignItems={'center'} position={'relative'} flex={1} height={'100%'}>
                        <Typography>
                            {get(val, 'row.parentId', null) ? getSpeedForChart(get(val, 'row.average_speed', 0), getSpeedUnit()?.value ?? "") : getSpeedForChart(val.row.mean_speed, getSpeedUnit()?.value ?? "")}
                        </Typography>

                        {
                            (isFinishLineActive && val.row.finish_section) &&
                            <div className='finish_section' style={{ position: 'absolute', bottom: 0, left: 0, right: 0, width: '100%' }}>

                            </div>
                        }
                    </Box>
                )
            }

        },

        {
            field: 'mean_heart_rate',
            headerAlign: 'center',
            renderHeader: () => (
                <Box position={'relative'} width={'100%'} height={'100%'} padding={0} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Icon name='heartbeat'></Icon>
                    <Typography >{t('intervals:averageFC')}</Typography>
                    <Typography variant='caption'>({t('intervals:bpm')})</Typography>
                </Box>
            ),
            flex: 1,
            sortable: false,
            renderCell: (val: GridRenderCellParams<TTrainingSection>) => {
                return (
                    <Box padding={0} display={'flex'} gap={1} alignItems={'center'} position={'relative'} flex={1} height={'100%'}>
                        <Typography>
                            {get(val, 'row.parentId', null) ? get(val, 'row.average_hr', '-') : get(val, 'row.mean_heart_rate', '-')}
                        </Typography>

                        {
                            (isFinishLineActive && val.row.finish_section) &&
                            <div className='finish_section' style={{ position: 'absolute', bottom: 0, left: 0, right: 0, width: '100%' }}>

                            </div>
                        }
                    </Box>
                )
            }

        },
        {
            field: 'mean_tilt',
            headerAlign: 'center',
            sortable: false,
            renderHeader: () => (
                <Box position={'relative'} width={'100%'} height={'100%'} padding={0} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Icon name='weird-target'></Icon>
                    <Typography >{t('intervals:averageInclin')}</Typography>
                    <Typography variant='caption'>%</Typography>
                </Box>
            ),
            flex: 1,
            renderCell: (val: GridRenderCellParams<TTrainingSection>) => {
                return (
                    <Box padding={0} display={'flex'} gap={1} alignItems={'center'} position={'relative'} flex={1} height={'100%'}>
                        <Typography>
                            {get(val, 'row.parentId', null) ? get(val, 'row.average_tilt', '-') : get(val, 'row.mean_tilt', '-')}
                        </Typography>

                        {
                            (isFinishLineActive && val.row.finish_section) &&
                            <div className='finish_section' style={{ position: 'absolute', bottom: 0, left: 0, right: 0, width: '100%' }}>

                            </div>
                        }
                    </Box>
                )
            }
        },
        {
            field: 'mean_stride_frequency',
            headerAlign: 'center',
            sortable: false,
            renderHeader: () => (
                <Box position={'relative'} width={'100%'} height={'100%'} padding={0} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Icon name='horseshoe'></Icon>
                    <Typography >{t('intervals:averageCad')}</Typography>
                    <Typography variant='caption'>({t('intervals:stepPerSecond')})</Typography>
                </Box>
            ),
            flex: 1,
            renderCell: (val: GridRenderCellParams<TTrainingSection>) => {
                return (
                    <Box padding={0} display={'flex'} gap={1} alignItems={'center'} position={'relative'} flex={1} height={'100%'}>
                        <Typography>
                            {get(val, 'row.parentId', null) ? get(val, 'row.average_stride_frequency', '-') : get(val, 'row.mean_stride_frequency', '-')}
                        </Typography>

                        {
                            (isFinishLineActive && val.row.finish_section) &&
                            <div className='finish_section' style={{ position: 'absolute', bottom: 0, left: 0, right: 0, width: '100%' }}>

                            </div>
                        }
                    </Box>
                )
            }

        },
        {
            field: 'mean_stride_length',
            headerAlign: 'center',
            renderHeader: () => (
                <Box position={'relative'} width={'100%'} height={'100%'} padding={0} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Icon name='horseshoe-refresh'></Icon>
                    <Typography >{t('intervals:averageAmpl')}</Typography>
                    <Typography variant='caption'>({getMetric()})</Typography>
                </Box>
            ),
            flex: 1,
            sortable: false,
            renderCell: (val: GridRenderCellParams<TTrainingSection>) => {
                return (
                    <Box padding={0} display={'flex'} gap={1} alignItems={'center'} position={'relative'} flex={1} height={'100%'}>
                        <Typography>
                            {get(val, 'row.parentId', null) ? convertStrideLength(metric, get(val, 'row.average_stride_length', 0)) : convertStrideLength(metric, get(val, 'row.mean_stride_length', 0))}
                        </Typography>

                        {
                            (isFinishLineActive && val.row.finish_section) &&
                            <div className='finish_section' style={{ position: 'absolute', bottom: 0, left: 0, right: 0, width: '100%' }}>

                            </div>
                        }
                    </Box>
                )
            }
        },
    ];

    
    return (
        <div>
            <I18nDataGrid
                columns={columns}
                columnHeaderHeight={100}
                sx={{
                    '& .MuiDataGrid-cell': {
                        padding: '0px',
                    },
                }}
                disableColumnMenu

                rows={uniqBy(Intervals, '@id')}
                getRowId={item => item?.['@id']}
                loading={loading}
                onRowClick={params => handleRowClick(params.row.id)}
                disableRowSelectionOnClick
                getRowClassName={(param: GridRowClassNameParams<TTrainingSection>) => {
                    if (get(param, 'row.parentId', null)) {
                        return (time[currentIndex] >= get(param, 'row.start_timestamp', 0) && time[currentIndex] <= get(param, 'row.end_timestamp', 0)) ? 'row-active' : 'row-inactive'
                    } else {
                        return (currentIndex >= param.row?.index_start && currentIndex <= param.row?.index_end) ? 'row-active' : ''
                    }
                }}
            />
        </div>
    )
}

export default IntervalsGrid