import { Button, Container, Typography } from '@mui/material'
import Lottie from 'lottie-react'
import React from 'react'
import AnimatedEmail from '../../lottie/animationEmail.json'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { routes } from '../routes'
function ResetPassword() {
    const { state } = useLocation()


    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <Container maxWidth='sm' sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 2, justifyContent: 'center' }}>
            <Lottie animationData={AnimatedEmail} style={{ width: '250px', height: '250px' }}></Lottie>
            <Typography textAlign={'center'} fontSize={'18px'} variant='h6'>{t('resetting:success:email.subject')}</Typography>
            <Typography textAlign={'center'} variant='body1'>{t('resetting:check_email', { email: state?.email })}</Typography>
            <Button variant='contained' onClick={() => navigate(routes.AUTH_ROUTES.LOGIN)}>{t('registration:back')}</Button>
        </Container>
    )
}

export default ResetPassword