import { Box, Button, TextField, Typography } from '@mui/material'
import React, { memo, useEffect, useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { TFinishLine } from '../../shared/types/finishLine.type'
import { t } from 'i18next'
import { CompareArrows, Delete } from '@mui/icons-material'
import { useTracks } from '../../hooks/useTracks'
import { useAuth } from '../../hooks/useAuth'
import { LoadingButton } from '@mui/lab'
import { debounce, get, isEmpty, set, toNumber } from 'lodash'
import { toast } from 'react-toastify'
type FinishLineFormType = {
    onFlSaved: (item: TFinishLine) => void,
    onLatsChanged: ({ latitude_a, latitude_b, longitude_a, longitude_b }: Partial<TFinishLine>) => void,
    lats: Partial<TFinishLine> | null,
    deleteFinishLine: () => void,
    initLats: () => void,
    deleting?: boolean,
    finishLine?: TFinishLine | null
}

const FLOAT = "any"

function FinishLineForm({ deleting, onFlSaved, onLatsChanged, lats, deleteFinishLine, initLats, finishLine }: FinishLineFormType) {

    const { saveFL, isSavingFL } = useTracks(false)
    const { auth } = useAuth()
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors, touchedFields },

    } = useForm<Partial<TFinishLine>>()

    useEffect(() => {
        if (!lats) return

        setValue('latitude_a', lats.latitude_a)
        setValue('latitude_b', lats.latitude_b)
        setValue('longitude_a', lats.longitude_a)
        setValue('longitude_b', lats.longitude_b)
    }, [JSON.stringify(lats)])


    const getDistance = useMemo(() => {
        return google.maps.geometry.spherical.computeDistanceBetween({ lat: toNumber(lats?.latitude_a), lng: toNumber(lats?.longitude_a) }, { lat: toNumber(lats?.latitude_b), lng: toNumber(lats?.longitude_b) })
    }, [lats])



    ////Watch fields to update map
    const watchFields = watch()
    const debounced = debounce((fields) => !isEmpty(fields) && onLatsChanged(formatToNumbers(fields)), 400)
    useEffect(() => {
        debounced(watchFields)
        return () => debounced.cancel()
    }, [JSON.stringify(watchFields)])


    const formatToNumbers = (data: Partial<TFinishLine>) => {
        let formatted = {}

        for (const [key, value] of Object.entries(data)) {
            set(formatted, key, toNumber(value))
        }
        return formatted
    }

    const onSubmit: SubmitHandler<Partial<TFinishLine>> = (data) => {

        let formatted: Partial<TFinishLine> = { ...formatToNumbers(data), trainer: get(auth, '@id') }

        saveFL(formatted).then(
            (result) => {
                onFlSaved(result)
                toast(t('defaultSuccessMessage'), { type: 'success' })
            }
        ).catch(err => {
            toast(t('error:generic'), { type: 'error' })


        })
    }


    return (
        <Box>
            {
                !lats ?
                    <Box display={'flex'} justifyContent={'end'} mb={1}>
                        <Button variant='outlined' size='small' onClick={initLats}>{t('tracks:addFinishLine')}</Button>
                    </Box>
                    : <form onSubmit={handleSubmit(onSubmit)}>
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap'} my={2}>
                            <Box display={'flex'} alignItems={'center'} gap={3}>
                                <TextField inputProps={{
                                    step: FLOAT
                                }} type='number' label={t('tracks:latitudeA')} size='small' {...register('latitude_a')}></TextField>
                                <TextField inputProps={{
                                    step: FLOAT
                                }} type='number' label={t('tracks:longitudeA')} size='small' {...register('longitude_a')}></TextField>
                            </Box>
                            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} flex={1}>
                                <CompareArrows sx={{ color: 'gray' }}></CompareArrows>
                                <Typography variant='caption' color={'GrayText'}>{getDistance.toFixed(3)}  m</Typography>
                            </Box>
                            <Box display={'flex'} alignItems={'center'} gap={3}>
                                <TextField inputProps={{
                                    step: FLOAT
                                }} type='number' label={t('tracks:latitudeB')} size='small' {...register('latitude_b')}></TextField>
                                <TextField inputProps={{
                                    step: FLOAT
                                }} type='number' label={t('tracks:longitudeB')} size='small' {...register('longitude_b')}></TextField>
                            </Box>

                        </Box>
                        <Box display={'flex'} justifyContent={'end'} mb={1} gap={1}>
                            <LoadingButton loading={deleting} onClick={deleteFinishLine} size='small' variant='outlined' color='error' startIcon={<Delete></Delete>}>{t('tracks:deleteFinishLine')}</LoadingButton>
                            <LoadingButton size='small' loading={isSavingFL} variant='contained' type='submit'>{t('button:save')}</LoadingButton>
                        </Box>
                    </form>
            }


        </Box>

    )
}

export default memo(FinishLineForm)