import { Box, Skeleton, Stack } from '@mui/material'
import React from 'react'


ListSkeleton.defaultProps = {
    length: 8,
    height: 40,
}
function ListSkeleton({ length, height }: { length?: number, height?: number }) {
    return (
        <Stack spacing={1} p={2}>
            {[...Array(length)].map((e,i) => (
                <Skeleton variant="rectangular" width={'100%'} height={height} key={i}></Skeleton>
            ))}
        </Stack>
    )
}

export default ListSkeleton