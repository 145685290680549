import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { openModal, updateCheckExam } from '../../store/reducers/comparaison.reducer';
import { useHorses } from '../../hooks/useHorses';
import { TUser } from '../../shared/types/user.type';
import { Button, CircularProgress, OutlinedInput, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import { Search } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { TTrainerHorse } from '../../shared/types/trainerHorse.type';
import ComparaisonList from '../comparaison/ComparaisonList';
import ListSkeleton from '../exams/loader/ListSkeleton';
import { TExam } from '../../shared/types/exam.type';
import { Ttraining } from '../../shared/types/training.type';
import { isEmpty, lowerCase } from 'lodash';
import { useExam } from '../../hooks/useExam';

export default function ComparaisonModal() {
    const open = useSelector<RootState>(state => state.comparaison.open) as boolean
    const isUserLoading = useSelector<RootState>(state => state.user.isUserLoading) as boolean
    const auth = useSelector<RootState>(state => state.user.user) as TUser | null
    const [Horses, setHorses] = React.useState<TTrainerHorse[]>([])
    const dispatch = useDispatch()
    const { horses, isLoadingHorses } = useHorses(open && !isUserLoading, auth?.['@id'])
    const [search, setSearch] = React.useState('')
    React.useEffect(() => {
        setHorses(horses)
    }, [horses])

    React.useEffect(() => {
        let filtered = horses.filter(e => lowerCase(e.horse?.name).indexOf(lowerCase(search)) !== -1)

        setHorses(filtered);

    }, [search, horses])

    const closeDrawer = () => {
        dispatch(openModal(false))
    }


    const handleExamClick = (checked: boolean, exam: Ttraining) => {
        dispatch(updateCheckExam({ checked, exam }))
    }

    const DrawerList = (
        <Box sx={{ width: 400, borderBlock: '1px solid #eee', flex: 1, overflowY: 'auto' }} role="presentation" onClick={closeDrawer}>
            {
                isLoadingHorses ?
                    <ListSkeleton></ListSkeleton> :
                    <ComparaisonList handleExamClick={handleExamClick} horses={Horses}></ComparaisonList>
            }
        </Box>
    );

    return (
        <div>
            <Drawer open={open} anchor='right' onClose={(e, r) => {
                closeDrawer()
            }} >
                <Box display={'flex'} flexDirection={'column'} sx={{ height: '100vh' }}>
                    <Box p={2} display={'flex'} flexDirection={'column'}>
                        <Typography variant='subtitle1'>{t('compareHorseSelection:title')}</Typography>
                        <OutlinedInput onChange={e => setSearch(e.target.value)} size='small' fullWidth placeholder={t('eap:table:filters:search')} startAdornment={<Search color='disabled'></Search>} ></OutlinedInput>
                    </Box>
                    {DrawerList}
                    <Box display={'flex'} justifyContent={'flex-end'} gap={1} p={2}>

                        <LoadingButton onClick={closeDrawer} variant='contained'> {t('button:close')}</LoadingButton>
                    </Box>
                </Box>

            </Drawer>
        </div>
    );
}