import { Alert, Stack } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { useMyTrainings } from '../../hooks/useMyTrainings'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { MapColorSpeedConfig, TrainingSetting } from '../../shared/types/trainingSetting.type'
import KeyParameters from '../../components/MyTrainings/KeyParameters'
import { TuserAccountType } from '../../shared/types/user.type'
import { TcomputedKeyParam, TkeyParamLayout } from '../../shared/types/computedKeyParam.type'
import { get, isEmpty, minBy, orderBy, uniqBy, upperCase } from 'lodash'
import { getRandomElements, groupByCategory, mapTypeToTrainingKeys, reoderElements, replaceWithNewKey } from '../../shared/utils/myTrainings'
import { useExam } from '../../hooks/useExam'
import TrackViewSkeleton from '../../components/tracks/TrackViewSkeleton'
import GaitAverage from '../../components/MyTrainings/GaitAverage'
import MapSection from '../../components/exams/MapSection'
import MapMode from '../../components/MyTrainings/MapMode'
import ChartsSection from '../../components/exams/ChartsSection'
import { Scale } from '../trainings/ExamView'
import CheckpointsSection from '../../components/exams/CheckpointsSection'
import BaseAccordion from '../../components/MyTrainings/BaseAccordion'
import { useAuth } from '../../hooks/useAuth'
import { CheckPoint } from '../../shared/types/training.type'

const SAMPLE_EXAM_ID = 96836

function MyTrainings() {
    const { trainingKeys, getPreselects, trainingKeysLoading, groupedTrainingKeys } = useMyTrainings()
    const userSettings = useSelector<RootState>(state => state.user.user?.training_settings) as TrainingSetting
    const mapColorType = useSelector<RootState>(state => state.user.user?.training_settings?.map_colors_type) as 'gait' | 'speed'
    const defaultScale = useSelector<RootState>(state => state.user.user?.training_settings?.default_graph_unit_type) as Scale
    const mapSpeedConfig = useSelector<RootState>(state => state.user.user?.training_settings?.map_colors_speed_configuration) as MapColorSpeedConfig
    const [keyParamsLayout, setKeyParamsLayout] = useState<TcomputedKeyParam[]>([])
    const [deletedKeyParamsLayout, setDeletedKeyParamsLayout] = useState<TcomputedKeyParam[]>([])
    const { exam, isExamLoading, examError } = useExam(false, null, SAMPLE_EXAM_ID)
    const isFinishLineActive = exam?.training?.use_finish_line ?? false
    const [distance, setDistance] = useState<string>('0 mi')
    const [speed, setSpeed] = useState<number[]>([])
    const [bpm, setBpm] = useState<number[]>([])
    const [time, setTime] = useState<number[]>([])
    const [rawDistance, setDistanceRaw] = useState<number[]>([])
    const [strideFrequency, setStrideFrequency] = useState<number[]>([])
    const [strideLength, setStrideLength] = useState<number[]>([])
    const [currentIndex, setCurrentindex] = useState(0)
    const [loading, setLoading] = useState(false)
    const [mapMode, setMapMode] = useState<'gait' | 'speed' | undefined>(undefined)
    const [scaleOption, setScaleOption] = useState<Scale>('DISTANCE')
    let id = useSelector<RootState>(state => state.user.user?.training_settings?.id) as number
    const { updateUserTraingSetting } = useAuth()

    useEffect(() => {
        setScaleOption(upperCase(defaultScale) as Scale)
    }, [defaultScale])

    useEffect(() => {
        setMapMode(mapColorType)
    }, [mapColorType])
    useEffect(() => {
        if (exam) {
            setKeyParamsLayout(get(exam, 'computed_key_parameters', []) as TcomputedKeyParam[])
        }
    }, [exam])

    const fetchPreselection = (preselect: TuserAccountType) => {
        getPreselects.mutateAsync(preselect)
            .then(({ data }) => {
                if (trainingKeys) {
                    setKeyParamsLayout(mapTypeToTrainingKeys(trainingKeys, get(data, 'hydra:member', [])))
                }
            }).catch(err => {
                console.log({ err });
            })
    }

    const onChangekey = (newId: string, oldParam: TcomputedKeyParam) => {
        if (!trainingKeys) return
        setKeyParamsLayout(replaceWithNewKey(trainingKeys, newId, oldParam, keyParamsLayout))
    }

    const deleteLine = () => {
        let length = keyParamsLayout.length
        if (length === 0) return
        let lengthMinusFour = length - 4
        setDeletedKeyParamsLayout(prev => [...prev, ...keyParamsLayout.slice(lengthMinusFour, length)])
        setKeyParamsLayout(prev => prev.slice(0, lengthMinusFour))


    }



    const getLastElementOrder = () => {
        let element = keyParamsLayout[keyParamsLayout.length - 1]
        if (element) {
            return element.key_parameter_layout.order_number
        } else {
            return 0
        }
    }


    const addLine = () => {
        if (isEmpty(deletedKeyParamsLayout) && !isEmpty(trainingKeys?.items[0].computed_key_parameters)) {

            let keyParamsLength = keyParamsLayout.length

            let MAX_KEYS_TO_DISPLAY = 12

            let itemsToAdd = 0

            let substraction = MAX_KEYS_TO_DISPLAY - keyParamsLength

            if (substraction > 0) {
                itemsToAdd = substraction
            } else {
                itemsToAdd = 0
            }


            let elements = getRandomElements(trainingKeys?.items[0].computed_key_parameters ?? [], itemsToAdd)
            let newElements = reoderElements(elements, getLastElementOrder())
            setKeyParamsLayout(prev => [...prev, ...newElements])

            return
        }
        let orderDeletedKeys = orderBy(deletedKeyParamsLayout, (item) => item?.key_parameter_layout?.order_number)
        let slice = orderDeletedKeys.slice(0, 4)
        setKeyParamsLayout(prev => [...prev, ...slice])
        setDeletedKeyParamsLayout(prev => prev.slice(4, prev.length))
    }

    const onModeChange = (value?: "gait" | "speed") => {
        setMapMode(value)
    }

    const filteredCheckPoints = useMemo(() => {
        let cps = exam?.training?.checkpoints
        if (cps && cps.length > 0) {

            let checkpoints = uniqBy(cps.map(cp => cp.checkpoint), '@id')

            let checkPointsMin: CheckPoint[] = []
            checkpoints.forEach(cp => {

                let getDataBydId = cps?.filter(c => c?.checkpoint?.['@id'] === cp?.['@id']) ?? []

                let data = minBy(getDataBydId, 'index_moment')

                data && checkPointsMin.push(data)
            })
            return checkPointsMin

        } else {
            return []
        }
    }, [exam?.training?.checkpoints])




    if (isExamLoading) {
        return <TrackViewSkeleton></TrackViewSkeleton>
    }

    return (
        <Stack direction={'column'} spacing={2}>
            <Alert severity='info'>{t('trainingSettings:header')}</Alert>

            <KeyParameters
                loading={getPreselects.isLoading}
                trainingKeysLoading={trainingKeysLoading}
                computedKeys={keyParamsLayout}
                onPreselectChange={fetchPreselection}
                groupedKeys={groupedTrainingKeys}
                enabled={userSettings.display_section_key_parameters}
                onChangekey={onChangekey}
                trainingKeys={trainingKeys}
                deleteLine={deleteLine}
                addLine={addLine}
            ></KeyParameters>
            <GaitAverage
                enabled={userSettings.display_section_averages}
                exam={exam}
            ></GaitAverage>
            <MapSection
                enabled={userSettings.display_section_map}
                enableCheckbox={true}
                setStrideFrequency={(val) => setStrideFrequency(val)}
                setStrideLength={val => setStrideLength(val)}
                setTime={val => setTime(val)}
                setDistanceRaw={val => setDistanceRaw(val)}
                setSpeed={value => setSpeed(value)}
                setBpm={value => setBpm(value)}
                setDistance={value => setDistance(value ?? "")}
                onIndexChange={(value) => setCurrentindex(value)}
                setIsLoading={(value) => setLoading(value)}
                transposedFile={exam?.training?.moments_transposed_file_url}
                chronology={exam?.averages_and_hr_zones?.gait_chronology}
                flIndex={exam?.training?.finish_line?.index_moment ?? null}
                isFinishLineActive={isFinishLineActive}
                finishLine={exam?.training?.finish_line}
                checkPoints={filteredCheckPoints}
                showSave>
                <MapMode mapSpeedConfig={mapSpeedConfig} value={mapMode} onChange={onModeChange}></MapMode>
            </MapSection>
            <ChartsSection
                editable
                strideFrequency={strideFrequency}
                strideLength={strideLength}
                time={time}
                rawDistance={rawDistance}
                speed={speed}
                bpm={bpm}
                distance={distance}
                scaleOption={scaleOption}
                onChangeScale={(val: Scale) => setScaleOption(val)}
                currentIndex={currentIndex}
                flIndex={exam?.training?.finish_line?.index_moment ?? null}
                loading={loading}
                heats={exam?.training?.heats ?? []}
                isFinishLineActive={isFinishLineActive}
                intervals={exam?.training?.training_sections ?? []}
                id={exam?.training?.id}
            ></ChartsSection>
            <BaseAccordion
                title='checkpointsCrossings:title'
                enableCheckBox
                defaultChecked={userSettings.display_section_checkpoints}
                onChange={(checked: boolean) => updateUserTraingSetting(id, { display_section_checkpoints: checked })}
            >
                <CheckpointsSection
                    onChangeScale={(val: Scale) => setScaleOption(val)}
                    scaleOption={scaleOption}
                    editable
                    isFinishlineActive={isFinishLineActive}
                    flIndex={exam?.training?.finish_line?.index_moment}

                    checkPoints={exam?.training?.checkpoints ?? []}
                    time={time}
                    rawDistance={rawDistance}
                    speed={speed}
                    bpm={bpm}
                ></CheckpointsSection>
            </BaseAccordion>

        </Stack>
    )
}

export default MyTrainings