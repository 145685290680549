import { Edit } from '@mui/icons-material'
import { Avatar, Box, Fab, IconButton, Skeleton } from '@mui/material'
import { get } from 'lodash'
import React, { ChangeEvent, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

type avatarProps = {
    onImageChange: (file: File | null) => void,
    imageIsLoading: boolean,
    imageLoadingFailed: boolean,
    avatar: string
}

function AvatarEditor({ onImageChange, imageIsLoading, imageLoadingFailed, avatar }: avatarProps) {
    const { t } = useTranslation()
    let inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        imageLoadingFailed && toast(t('equimetre.ota.failed'), { type: 'error' })
    }, [imageLoadingFailed])

    const inputChanged = (e: ChangeEvent<HTMLInputElement>) => {
        let file = get(e, 'target.files[0]', null) as File | null
        onImageChange(file)
    }
    return (
        <Box position={'relative'} sx={{ width: 'max-content' }}>
            <input onChange={inputChanged} ref={inputRef} type='file' style={{ display: 'none' }} accept="image/*"></input>
            {
                imageIsLoading ? <Skeleton variant="circular" width={100} height={100} />
                    : <Avatar
                        sx={{ width: 100, height: 100, position: 'relative' }}
                        src={avatar}
                    >
                    </Avatar>
            }

            <Fab disabled={imageIsLoading} onClick={() => inputRef.current?.click()} size='small' color='default' sx={{ position: 'absolute', right: -20, top: -8, zIndex: 10 }}><Edit></Edit></Fab>

        </Box>

    )
}

export default AvatarEditor