import { Alert, Box, CircularProgress, Divider, Tab, Tabs, Typography } from '@mui/material'
import { t } from 'i18next'
import React, { useCallback, useEffect, useState } from 'react'
import NewConfig from '../../components/analyticTable/NewConfig';
import ConfigAction from '../../components/analyticTable/ConfigAction';
import { useAnalyticTable } from '../../hooks/useAnalyticTable';
import Preselects from '../../components/analyticTable/Preselects';
import { TUser, TuserAccountType } from '../../shared/types/user.type';
import ParamsSection from '../../components/analyticTable/ParamsSection';
import { TAnalyticParams } from '../../shared/types/analyticParameters.type';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useQueryClient } from 'react-query';
import { isEmpty, isNumber, toNumber } from 'lodash';
import { TAnalyticView } from '../../shared/types/analyticView.type';
import DeleteModal from '../../components/modals/DeleteModal';
import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/useAuth';
import Settings from '../../components/analyticTable/Settings';
import { useHorses } from '../../hooks/useHorses';
import { useTracks } from '../../hooks/useTracks';
import { useRiders } from '../../hooks/useRiders';
import { useTrackConditions } from '../../hooks/useTrackConditions';
import { useHorseShoeing } from '../../hooks/useHorseShoeing';
import { trackSurface } from '../../shared/types/tracks.type';


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}



function AnalyticTable() {
    const [value, setValue] = React.useState(0);
    const [name, setName] = useState('')
    const [selectedParams, setSelectedParams] = useState<TAnalyticParams[]>([])
    const [pinnedParams, setPinnedParams] = useState<TAnalyticParams[]>([])
    const [selectedView, setSelectedView] = useState<TAnalyticView | null>(null)
    const [open, openDeleteModal] = useState(false)
    const [trackSurfaces, setTrackSurfaces] = useState<trackSurface[]>([])
    //Hooks
    const isUserLoading = useSelector<RootState>(state => state.user.isUserLoading) as boolean
    const user = useSelector<RootState>(state => state.user.user) as TUser
    const { getParameters, groupedParams, getInsightParametersNames, allParams, saveNewConfig, AnalyticViews, getViewById, viewsLoading, deleteView, updateView } = useAnalyticTable(!isUserLoading, user?.['@id'])
    const accountType = useSelector<RootState>(state => state.user.user?.type) as TuserAccountType | undefined
    const query = useQueryClient()
    const { auth, isUserFetching } = useAuth()
    //Data for settings
    const { tracks, trainingTypes, isLoading } = useTracks(!isUserFetching, auth?.id, true, true)
    const { horses, isLoadingHorses } = useHorses(!isUserFetching, auth?.['@id'])
    const { riders, isLoading: isRidersLoading } = useRiders(!isUserFetching, auth.id)
    const { shoeings, isLoading: isShoeingLoading } = useHorseShoeing(!isUserFetching, auth.id)
    const { tracks: TrackConditions, isLoading: isTrackConditionLoading } = useTrackConditions(!isUserFetching, auth.id)
    const { getTrackSurfaces } = useTracks(false)
    const loadingData = isLoading || isLoadingHorses || isRidersLoading || isShoeingLoading || isTrackConditionLoading
    //fns
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const getView = (id: number) => {
        getViewById.mutateAsync(id)
            .then(({ data }) => {
                setSelectedView(data)
                setSelectedParams(data.analytics_view_insight_parameters.map(e=>e.insight_parameter))
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })

            })

    }

    const delView = (id: number) => {
        deleteView.mutateAsync(id)
            .then(result => {
                setSelectedView(null)
                setValue(0)
                query.refetchQueries('table_config')

                openDeleteModal(false)
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })

            })
    }

    const onPreselectChange = (prs: TuserAccountType) => {
        let paramsNames = getInsightParametersNames(prs)

        //get Current params by name 
        let filtered = allParams.filter(e => paramsNames.includes(e.name))
        setSelectedParams(filtered)
        setPinnedParams([])
    }

    const createNewConfig = () => {
        let getParamsByUser = getInsightParametersNames(accountType)
        let filtered = allParams.filter(e => getParamsByUser.includes(e.name))

        let userId = user['@id']


        saveNewConfig.mutateAsync({ name, insight_parameters: filtered.map(e => e?.['@id']), user: userId })
            .then(result => {
                setName('')
                query.refetchQueries('table_config')
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })

            })

    }

    const updateName = (name: string) => {
        if (!selectedView) return
        updateView.mutateAsync({ id: selectedView.id, data: { name, user: user?.['@id'] } })
            .then(result => {
                query.refetchQueries('table_config')
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
            })
    }


    const updateViewSetting = (data: any) => {
        if (!selectedView) return

        updateView.mutateAsync({ id: selectedView.id, data })
            .then(result => {
                setSelectedView(result.data)
                toast(t('rights:success'), { type: 'success' })
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
            })
    }

    const onParamCheck = useCallback((item: TAnalyticParams, checked: boolean) => {
        if (checked) {
            setSelectedParams(prev => [...prev, item])
        } else {
            let index = selectedParams.findIndex(e => e.id === item.id)
            if (index !== -1) {
                setSelectedParams(prev => prev.filter((e, i) => i !== index))
            }
        }

    }, [selectedParams])


    const saveParams = useCallback(() => {
        let ids = selectedParams.map((e, i) => ({ insight_parameter: e['@id'], order_index: i + 1 }))

        updateViewSetting({ analytics_view_insight_parameters: ids, count_columns_locked: pinnedParams.length })

    }, [selectedParams, pinnedParams])


    //effects
    useEffect(() => {
        getParameters.mutateAsync()
    }, [])

    useEffect(() => {
        if (isEmpty(AnalyticViews)) return
        getView(toNumber(AnalyticViews[value]?.id))
    }, [AnalyticViews, value])

    useEffect(() => {
        (async () => {
            try {
                let data = await getTrackSurfaces()

                setTrackSurfaces(data)
            } catch (error) {
                console.warn('failed to load track surfaces');

            }
        })()
    }, [])

    useEffect(() => {
        let temp = [...selectedParams]
        if (isEmpty(temp) && isEmpty(pinnedParams)) return

        let tempData: TAnalyticParams[] = []

        pinnedParams.forEach(p => {
            let i = temp.findIndex(e => e['@id'] === p['@id'])

            if (i !== -1) {
                tempData.push(temp[i])
                temp.splice(i, 1)
            }
        })

        temp = [...tempData, ...temp]
        setSelectedParams(temp)
    }, [pinnedParams])

    useEffect(() => {
        if (selectedView) {
            let count = selectedView.count_columns_locked
            if (count === 0) return

            setPinnedParams(selectedView.analytics_view_insight_parameters.map(e=>e.insight_parameter).slice(0, count))
        }
    }, [selectedView])

    return (
        <>
            <Alert severity='info'>{t('profileSectionDescription:analyticsParameters')}</Alert>

            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap'}>
                {/* Add new Configuration */}
                <NewConfig loading={saveNewConfig.isLoading} onSave={createNewConfig} name={name} onNameChange={value => setName(value)}></NewConfig>


            </Box>

            <Divider></Divider>

            <Box
                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', minHeight: 500 }}
            >
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                    {
                        AnalyticViews.map(view => (
                            <Tab label={view.name} />
                        ))
                    }
                </Tabs>
                <Box p={2} width={'100%'}>
                    {
                        (getViewById.isLoading || viewsLoading) ?
                            <Box display={'flex'} justifyContent={'center'} mt={2}>
                                <CircularProgress></CircularProgress>
                            </Box>

                            : isEmpty(AnalyticViews) ?
                                <Box display={'flex'} justifyContent={'center'} mt={2}>
                                    <Typography>{t('notification:noConfig')}</Typography>
                                </Box> : <>
                                    <Box display={'flex'} justifyContent={'end'} alignItems={'center'} mb={2}>
                                        {/* crud on selected config */}
                                        <ConfigAction isUpdating={updateView.isLoading} updateName={updateName} view={selectedView} onDelete={() => openDeleteModal(true)} isDeleting={deleteView.isLoading}></ConfigAction>
                                    </Box>
                                    <Settings
                                        trainingTypes={trainingTypes}
                                        tracks={tracks}
                                        horses={horses}
                                        riders={riders}
                                        surfaces={trackSurfaces}
                                        trackConditions={TrackConditions}
                                        shoeing={shoeings}
                                        updateViewSetting={updateViewSetting}
                                        isUpdating={updateView.isLoading}
                                        dataLoading={loadingData}
                                        selectedView={selectedView}
                                    ></Settings>
                                    <Box my={2}></Box>
                                    <Preselects onPreselectChange={onPreselectChange}></Preselects>

                                    <ParamsSection updatePin={(pins) => setPinnedParams(pins)} pinnedParams={pinnedParams} isUpdating={updateView.isLoading} saveSettings={saveParams} onParamCheck={onParamCheck} allParams={allParams} onUpdateParam={(params) => setSelectedParams(params)} loading={getParameters.isLoading} groupedParams={groupedParams} selectedParams={selectedParams}></ParamsSection>
                                </>
                    }

                </Box>

                {/* Modals */}
                <DeleteModal
                    handleClose={() => openDeleteModal(false)}
                    open={open}
                    onDelete={() => {
                        if (selectedView) {
                            delView(selectedView.id)
                        }
                    }}
                    title='deleteConfiguration'
                    subtitle='deleteConfigurationMessage'
                    isDeleting={deleteView.isLoading}
                ></DeleteModal>
            </Box>
        </>
    )
}

export default AnalyticTable