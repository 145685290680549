import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { capitalize } from 'lodash'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

type headerProps = {
    text: string,
    date?: string,
    children?: ReactNode,
    dateEnabled?: boolean
}

Header.defaultProps = {
    dateEnabled: true
}

function Header({ text, children, date, dateEnabled }: headerProps) {
    const { t } = useTranslation()
    return (

        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={2} flexWrap={'wrap'}>
            <Box>
                <Typography variant='h4'>{capitalize(t(text))}</Typography>
                {
                    dateEnabled && <Typography variant='caption' >{date ? date : dayjs().format('DD MMM YYYY | H:m')}</Typography>
                }
            </Box>
            {children}
        </Box>
    )
}

export default Header