import React from 'react'
import { TExam } from '../../shared/types/exam.type'
import { Avatar, Box, Chip } from '@mui/material'
import { Ttraining } from '../../shared/types/training.type'
import { formatDate, getCorrectDateFormat } from '../../shared/utils/DateUtils'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

function ComparaisonChips({ checkedExams, handleDelete, children }: { checkedExams: Ttraining[], handleDelete: (exam: Ttraining) => void, children?: React.ReactNode }) {
    const dateFormat = useSelector<RootState>(state => state.user.user?.default_date_format) as string | undefined

    const getLabelText = (exam: Ttraining) => {
        let infos = []
        infos.push(exam?.horse_trainer?.horse?.name)
        infos.push(formatDate(exam.creation_date, getDateFormat()))
        return infos.join(' - ')
    }

    const getDateFormat = (): string => {
        if (dateFormat) {
            return getCorrectDateFormat(dateFormat) + ' HH:mm'
        } else {
            return 'DD/MM/YYYY HH:mm'
        }
    }

    return (
        <Box display={'flex'} mt={2} alignItems={'center'} gap={1}>
            <Box flex={1} display={'flex'} gap={1} flexWrap={'wrap'}>
                {checkedExams.length > 0 && checkedExams.map(exam => (
                    <Chip key={exam.id} avatar={<Avatar src={exam?.horse_trainer?.horse?.avatar_url}></Avatar>} sx={{ borderRadius: 1 }} label={getLabelText(exam)} onDelete={() => handleDelete(exam)}></Chip>
                ))}
            </Box>
            {children}
        </Box>
    )
}

export default ComparaisonChips