import React, { useEffect, useState } from 'react'
import BaseModal from './BaseModal'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { setRiderItemToEdit, setRiderModal, setShoeingItemToEdit, setShoeingModal, setTrackItemToEdit, setTrackModal } from '../../store/reducers/modal.reducer'
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { t } from 'i18next'
import { LoadingButton } from '@mui/lab'
import { SubmitHandler, useForm } from 'react-hook-form'
import { TShoeing } from '../../shared/types/shoeing.type'
import { get, isEqual, toNumber } from 'lodash'
import { TUser } from '../../shared/types/user.type'
import { useRiders } from '../../hooks/useRiders'
import { TRider } from '../../shared/types/rider.type'
import { TtrackCondition } from '../../shared/types/trackCondition.type'
import { trackGroup } from '../../enums/trackGroup.enums'
import { useTrackConditions } from '../../hooks/useTrackConditions'
import { useTracks } from '../../hooks/useTracks'
import { trackSurface } from '../../shared/types/tracks.type'

function Track() {
    const open = useSelector<RootState>(state => state.modals.tracksModal) as boolean
    const itemToEdit = useSelector<RootState>(state => state.modals.trackItemToEdit, isEqual) as TtrackCondition | null
    const auth = useSelector<RootState>(state => state.user.user) as TUser | null
    const { saveTrack, isSaving, updateTrack, isUpdating} = useTrackConditions(false)
    const dispatch = useDispatch()


    useEffect(() => {
        if (itemToEdit) {
            setValue('id', itemToEdit.id)
            setValue('name', itemToEdit?.name)
            setValue('track_group', itemToEdit?.track_group)
        }
    }, [itemToEdit])

    const {
        register,
        handleSubmit,
        reset,
        setValue,

        formState: { errors, touchedFields },
    } = useForm<Partial<TtrackCondition>>({
    })

    const onSubmit: SubmitHandler<Partial<TtrackCondition>> = (data) => {
        const tData: Partial<TtrackCondition> =
        {
            ...data,
            trainer: get(auth, '@id')
        }
        let promise = itemToEdit ? updateTrack(tData) : saveTrack(tData)

        promise
            .then(() => {
                //On Success reset Form
                reset()
                dispatch(setTrackItemToEdit(null))
                dispatch(setTrackModal(false))

            }).catch(err => { })
    }

    return (
        <BaseModal title={itemToEdit ? 'tracks:information' : 'trackCondition:addTrackCondition'} open={open} handleClose={() => {
            dispatch(setTrackModal(false))
            dispatch(setTrackItemToEdit(null))
            reset()
        }}>
            <Box>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <Grid container spacing={3}>

                        <Grid item md={12} xs={12}>
                            <TextField error={errors?.hasOwnProperty('name')} {...register('name', { required: true })}  fullWidth label={t('equimetre:table:name')}></TextField>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <FormControl fullWidth >
                                <InputLabel error={errors.hasOwnProperty('lang')} >{t('trackCondition:trackGroup')}</InputLabel>
                                <Select defaultValue={itemToEdit?.track_group} label={t('trackCondition:trackGroup')} error={errors?.hasOwnProperty('track_group')} fullWidth  {...register('track_group', { required: true })}>
                                    {trackGroup.map(track => (
                                        <MenuItem key={track.key} value={track.value}>{t(track.key)}</MenuItem>

                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Box display={'flex'} mt={2} justifyContent={'end'}>
                        <LoadingButton loading={isSaving || isUpdating} type='submit' variant='contained'>{itemToEdit ? t('button:edit') : t('button:save')}</LoadingButton>
                    </Box>
                </form>
            </Box>

        </BaseModal>
    )
}

export default Track