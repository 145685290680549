import { BackHandOutlined, Delete, Edit, GrassOutlined, KeyboardBackspace } from '@mui/icons-material';
import { Alert, Box, Button, Chip, Divider, IconButton, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from '../routes';
import { useTracks } from '../../hooks/useTracks';
import { get, isEmpty, isNull, isNumber, toNumber } from 'lodash';
import { Ttrack, trackSurface } from '../../shared/types/tracks.type';
import TrackViewSkeleton from '../../components/tracks/TrackViewSkeleton';
import { t } from 'i18next';
import { LoadingButton } from '@mui/lab';
import { hands } from '../../enums/trackGroup.enums';
import Map from '../../components/tracks/Map';
import { LatLng, getGeocode, getLatLng } from 'use-places-autocomplete';
import FinishLineForm from '../../components/tracks/FinishLineForm';
import { TFinishLine } from '../../shared/types/finishLine.type';
import { useDispatch, useSelector } from 'react-redux';
import { setTrackNameItemToEdit, setTrackNameModal } from '../../store/reducers/modal.reducer';
import { useQuery } from 'react-query';
import { useAuth } from '../../hooks/useAuth';
import { RootState } from '../../store';
import { TUser } from '../../shared/types/user.type';
import CheckPointsForm from '../../components/tracks/CheckPointsForm';
import { toast } from 'react-toastify';
import { TCheckPoint } from '../../shared/types/checkPoint.type';
import DeleteModal from '../../components/modals/DeleteModal';
import Error from '../../components/global/Error';

const GAP = 0.0001

export type MapForwardRefType = {
    updateMarker: (lats: Partial<TFinishLine>) => void
}

function TrackView() {
    let { id } = useParams();
    const auth = useSelector<RootState>(state => state.user.user) as TUser | null

    const { getTrackById, isTrackLoading, deleteTrack, isDeleting, isDeleted, getTrackSurfaces, updateTrack, isUpdating, deleteFinishLine: deleteFl, isDeletingFL } = useTracks(false, auth?.id)
    const [item, setItem] = useState<Ttrack | null>()
    const [openDelete, setOpenDelete] = useState(false)
    const [surfaces, setSurfaces] = useState<[] | trackSurface[]>()
    const [active, setActive] = useState<'FL' | 'CP' | null>(null)
    const [cords, setCords] = useState<LatLng>()
    const [lats, setLats] = useState<Partial<TFinishLine> | null>(null)
    const [fl, setFl] = useState<Partial<TFinishLine> | null>()
    const [checkPoints, setCheckPoints] = useState<Array<TCheckPoint>>()
    const dispatch = useDispatch()
    const mapRef = useRef<MapForwardRefType>(null)
    const query = useQuery(['trackName', toNumber(id)], () => getTrackById(toNumber(id)), {
        enabled: isNumber(parseInt(id ?? '')),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: (data) => {
            setItem(data)
        }
    })


    useEffect(() => {
        getTrackSurfaces()
            .then(response => {
                setSurfaces(response)
            }).catch(err => {
                console.error('failed to load track surfaces');
            })
    }, [])

    useEffect(() => {
        if (!item?.address) return
        (async () => {
            let res = await getGeocode({ address: item?.address })
            const { lat, lng } = getLatLng(res[0]);
            setCords({ lat, lng })
        })()
    }, [item?.address])

    useEffect(() => {
        if (item?.finish_line) {
            setLats({ latitude_a: item.finish_line?.latitude_a, longitude_a: item.finish_line?.longitude_a, latitude_b: item.finish_line?.latitude_b, longitude_b: item.finish_line?.longitude_b })
            setFl({ latitude_a: item.finish_line?.latitude_a, longitude_a: item.finish_line?.longitude_a, latitude_b: item.finish_line?.latitude_b, longitude_b: item.finish_line?.longitude_b })
        }
        // setLats({ latitude_a: cords.lat, longitude_a: cords.lng, latitude_b: cords.lat + GAP, longitude_b: cords.lng + GAP })
    }, [item?.finish_line])


    useEffect(() => {
        if (!isEmpty(item?.checkpoints)) {
            setCheckPoints(item?.checkpoints)
        }
    }, [item?.checkpoints])

    const initLats = useCallback(() => {
        setLats({ latitude_a: cords?.lat ?? 0, longitude_a: cords?.lng ?? 0, latitude_b: cords?.lat ?? 0 + GAP, longitude_b: cords?.lng ?? 0 + GAP })

    }, [cords])

    const navigate = useNavigate()

    const backToTracks = () => {
        navigate(routes.DASHBOARD_ROUTES.TRACKS)
    }

    useEffect(() => {
        if (isDeleted) {
            backToTracks()
        }
    }, [isDeleted])

    //Get Selected Surface
    const getSurface = (): string => {
        if (!isEmpty(surfaces)) {
            let surface = surfaces?.find(e => get(e, '@id') === get(item, 'track_surface[@id]'))
            if (surface) {
                return surface.translation_key?.replaceAll('.', ':') ?? ''
            } else {
                return '-'
            }

        } else {
            return '-'
        }

    }
    //Get selected hand turn
    const getHandTurn = (): string => {
        let hand = hands.find(e => e.value === item?.hand_turn)
        if (hand) {
            return hand.key
        } else {
            return '-'
        }
    }

    const deleteFinishLine = () => {
        let id = item?.finish_line?.id
        if (id) {
            deleteFl(id)
                .then(result => {
                    setLats(null)
                    query.refetch()
                }).catch(err => {
                    toast(t('error:generic'))
                })
        } else {
            setLats(null)
        }
    }

    const updateTrackFl = useCallback((fl: TFinishLine) => {

        updateTrack({ id: toNumber(item?.id), finish_line: get(fl, '@id', "") })
            .then(result => {
                result && setItem(result)
            }).catch(err => {

            })
    }, [item?.id])



    const editTrack = () => {
        dispatch(setTrackNameModal(true))
        if (item) dispatch(setTrackNameItemToEdit(item))

    }

    const onFinishLinePosChanged = useCallback((positions: google.maps.LatLngLiteral[]) => {
        setLats({ latitude_a: positions[0].lat, longitude_a: positions[0].lng, latitude_b: positions[1].lat, longitude_b: positions[1].lng })
    }, [])

    const onCheckPointPosChanged = (positions: google.maps.LatLngLiteral[], id: number | null) => {
        let item = checkPoints?.find(e => e.id === id)
        let index = checkPoints?.findIndex(e => e.id === id)

        if (item && isNumber(index)) {
            item.latitude_a = positions[0].lat
            item.latitude_b = positions[1].lat
            item.longitude_a = positions[0].lng
            item.longitude_b = positions[1].lng
            let filtered = checkPoints && checkPoints.filter((e, i) => e.id !== id)

            filtered?.push(item)

            setCheckPoints(filtered)
        }


    }

    const updateMapFinishLineMarkers = (l: Partial<TFinishLine>) => {
        setLats(l)
        setFl(l)
    }

    return query.isLoading ? <TrackViewSkeleton></TrackViewSkeleton> : (
        <Box>
            <Divider sx={{ my: 2 }}></Divider>
            <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} alignItems={'center'}>
                <Box display={'flex'} gap={2} alignItems={'center'}>
                    <IconButton onClick={backToTracks}>
                        <KeyboardBackspace></KeyboardBackspace>
                    </IconButton>
                    <Typography variant='subtitle2'>{item?.name}</Typography>
                </Box>
                <Box display={'flex'} gap={1} flexWrap={'wrap'}>
                    <Button disableElevation variant={active === 'FL' ? 'contained' : 'outlined'} onClick={() => active === 'FL' ? setActive(null) : setActive('FL')} size='small' color='info'>{t('tracks:finishLine')}</Button>
                    <Button disableElevation variant={active === 'CP' ? 'contained' : 'outlined'} onClick={() => active === 'CP' ? setActive(null) : setActive('CP')} size='small' color='info'>{t('tracks:checkpoints')}</Button>
                    <Button variant='outlined' onClick={editTrack} size='small' startIcon={<Edit></Edit>} color='warning'>{t('button:edit')}</Button>


                    <LoadingButton variant='outlined' loading={isDeleting} onClick={() => setOpenDelete(true)} size='small' startIcon={<Delete></Delete>} color='error'>{t('tracks:deleteTrack')}</LoadingButton>
                    {/*Delete Modal*/}
                    <DeleteModal title='tracks:deleteTrack' subtitle='tracks:deleteTrackMessage' open={openDelete} handleClose={() => setOpenDelete(false)}
                        isDeleting={isDeleting}
                        onDelete={() => deleteTrack(toNumber(id))}
                    ></DeleteModal>

                </Box>
            </Box>
            <Box display={'flex'} gap={2} sx={{ my: 3 }}>
                <Chip sx={{ display: 'flex', alignItems: 'center' }} variant='outlined' color='success' icon={<GrassOutlined></GrassOutlined>} size='small' label={t(getSurface())}></Chip>
                <Chip sx={{ display: 'flex', alignItems: 'center' }} variant='outlined' icon={<BackHandOutlined></BackHandOutlined>} size='small' label={t(getHandTurn())}></Chip>
            </Box>
            {/* If finish line button activated */}
            {active === 'FL' &&
                <>
                    <Alert severity='info' sx={{ my: 2 }}>
                        {t('tracks:explanationMoveMarkers')}
                    </Alert>

                    <FinishLineForm finishLine={item?.finish_line} initLats={initLats} deleting={isDeletingFL} deleteFinishLine={deleteFinishLine} lats={lats} onLatsChanged={l => updateMapFinishLineMarkers(l)} onFlSaved={(tl) => updateTrackFl(tl)}></FinishLineForm>
                </>
            }
            {/* If checkpoints button activated */}
            {active === 'CP' &&
                <>

                    <CheckPointsForm track={item} checkpoints={checkPoints} finishLine={fl}></CheckPointsForm>
                </>
            }
            <Map ref={mapRef} onFinishLinePosChanged={onFinishLinePosChanged} onCheckPointPosChanged={onCheckPointPosChanged} height='350px' center={cords} finishLine={fl} checkPoints={checkPoints} ></Map>
        </Box>

    )
}

export default TrackView