import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { t } from 'i18next'
import { Scale } from '../../../pages/trainings/ExamView'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { convertSpeed, convertStrideLength, getSpeedForChart, getSpeedTooltipForChart } from '../../../shared/utils/conversion'
import { get, isArray, isEmpty, max, mean, min, set, toNumber } from 'lodash'
import dayjs from 'dayjs'
import Duration from 'dayjs/plugin/duration'
import { Box, Button, Stack } from '@mui/material'
import { useChart } from '../../../hooks/useChart'
import { mergeArr, startTimeFrom0 } from '../../../shared/utils/MapSection'
import { TrainingSetting } from '../../../shared/types/trainingSetting.type'
import Hideable from '../../global/Hideable'
import { ZoomOutMap } from '@mui/icons-material'
import { useAuth } from '../../../hooks/useAuth'
import HighchartsBoost from "highcharts/modules/boost";
HighchartsBoost(Highcharts);

dayjs.extend(Duration)



type GraphsProps = {
  speed: number[]
  bpm: number[],
  distance: number[],
  time: number[],
  scaleOption: Scale,
  strideFrequency: number[],
  strideLength: number[],
  currentIndex: number,
  flIndex?: number | null,
  isFinishLineActive: boolean,
  onExtremesChanged: (value: number) => void

}


/**
 * Override the reset function, we don't need to hide the tooltips and crosshairs.
 */
Highcharts.Pointer.prototype.reset = function () {
  return undefined;
};
/* @ts-ignore */
Highcharts.Point.prototype.highlight = function highlight(event) {
  event = this.series.chart.pointer.normalize(event);
  this.onMouseOver(); // Show the hover marker
  this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
};

function Graphs({ bpm, distance, speed, scaleOption, time, strideFrequency, strideLength, currentIndex, flIndex, isFinishLineActive, onExtremesChanged }: GraphsProps) {
  const metric = useSelector<RootState>(state => state.user.user?.unit_system) as "metric" | "imperial" | undefined
  const { addFinishLine } = useChart()
  const bpmRef = useRef<HighchartsReact.RefObject>(null)
  const bpmSpeedRef = useRef<HighchartsReact.RefObject>(null)
  const speedRef = useRef<HighchartsReact.RefObject>(null)
  const userSettings = useSelector<RootState>(state => state.user?.user?.training_settings) as TrainingSetting

  const strideRef = useRef<HighchartsReact.RefObject>(null)
  const freqRef = useRef<HighchartsReact.RefObject>(null)
  const lengthRef = useRef<HighchartsReact.RefObject>(null)
  const divRef = useRef<HTMLDivElement | null>(null)
  const [svgs, setSvgs] = useState<any>([])
  const { getSpeedUnit } = useAuth()
  const [isZoomActive, setIsZoomActive] = useState(false)

  const BaseOptions = {
    chart: {
      type: 'area',
      zoomType: "x",
      backgroundColor: 'transparent',

      animation: false,
      credits: {
        enabled: false
      },
      events: {
        selection: function (this: Highcharts.Chart, event: Highcharts.SelectEventObject) {


          let charts = getCharts()

          if (charts.length > 0) {
            charts.forEach(chart => chart?.xAxis[0].setExtremes(get(event, 'xAxis[0].min', undefined), get(event, 'xAxis[0].max', undefined)))

            onExtremesChanged(get(event, 'xAxis[0].max', 0) - get(event, 'xAxis[0].min', 0))
          }


        },
      }
    },
    boost: {
      useGPUTranslations: true,
      // Chart-level boost when there are more than 5 series in the chart
      seriesThreshold: 5
    },
    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    },

    title: {
      align: 'left',
      style:{
        color:'white'
      }
    },
    yAxis: {
      title: "",

    },
    legend: { enabled: false },
    tooltip: {
      enabled: true,
      shared: true,
      positioner: function () {
        return { x: get(this, 'chart.plotWidth', 0) - (!isZoomActive ? 100 : 200), y: 30 };
      },
      shadow: false,
      style: {
        fontWeight: 'bold',
        fontSize: '14px'
      }
    },


  }


  const formattedTime = useMemo(() => {
    return startTimeFrom0(time)
  }, [time])


  const getCharts = () => {
    let chart1 = bpmRef.current?.chart
    let chart2 = speedRef.current?.chart
    let chart3 = strideRef.current?.chart
    let chart4 = freqRef.current?.chart
    let chart5 = lengthRef.current?.chart
    let chart6 = bpmSpeedRef.current?.chart

    let charts = [chart1, chart2, chart3, chart4, chart5, chart6]
    return charts
  }

  const mouseOverCallback = useCallback((e: MouseEvent | TouchEvent) => {


    getCharts().forEach((chart, i) => {
      let event = chart?.pointer.normalize(e);
      if (!event) return
      let series = chart?.series[0]
      const point = event && series?.searchPoint(event, true);
      if (point) {
        /* @ts-ignore */
        point.highlight(e);
      }

    })



  }, [])

  useEffect(() => {

    getCharts().forEach((chart, i) => {
      chart?.series.forEach(function (series) {
        const point = series.points[currentIndex]
        if (point) {
          /* @ts-ignore */
          point.onMouseOver()
        }
      });

    })


  }, [currentIndex])


  useEffect(() => {

    if (!divRef.current) return
    divRef.current.addEventListener('mousemove', mouseOverCallback)

    return () => divRef.current?.removeEventListener('mousemove', mouseOverCallback)
  }, [])

  useEffect(() => {
    if (!isFinishLineActive) return

    addFinishLines()

  }, [flIndex, scaleOption, formattedTime, distance, isFinishLineActive, userSettings, isFinishLineActive])

  const removeFinishLines = () => {
    try {
      let charts = getCharts()
      charts.forEach(chart => {
        let svgs = get(chart, 'svgs', [])

        if (!isEmpty(svgs)) {
          /* @ts-ignore */
          svgs.forEach(svg => svg?.destroy())
        }
        /* @ts-ignore */
        set(chart, 'svgs', [])

      })
    } catch (err) {
      console.warn('cannot delete svgs')
    }
  }
  const addFinishLines = () => {
    if (!isFinishLineActive) return
    removeFinishLines()

    addFinishLine(flIndex ?? null, scaleOption, distance, formattedTime, bpmRef.current?.chart)


    addFinishLine(flIndex ?? null, scaleOption, distance, formattedTime, speedRef.current?.chart)


    addFinishLine(flIndex ?? null, scaleOption, distance, formattedTime, strideRef.current?.chart)
    addFinishLine(flIndex ?? null, scaleOption, distance, formattedTime, freqRef.current?.chart)
    addFinishLine(flIndex ?? null, scaleOption, distance, formattedTime, lengthRef.current?.chart)
    addFinishLine(flIndex ?? null, scaleOption, distance, formattedTime, bpmSpeedRef.current?.chart)


  }


  const optionsBpm = useMemo(() => ({
    ...BaseOptions,
    chart: {
      ...BaseOptions.chart,

    },
    title: { ...BaseOptions.title, text: t('graph:bpm') },
    yAxis: {
      labels: {
        style: {
          color: '#fff'
        }
      }
    },
    xAxis: {
      events: {
        afterSetExtremes: function (event: Highcharts.AxisSetExtremesEventObject) {
          let min = event.min
          let max = event.max

          if (isFinishLineActive) {
            addFinishLines()
          }
          if (min < 0) {
            max -= Math.abs(min)
            min = 0
          }

          if (event.dataMin == min && event.dataMax == max)
            setIsZoomActive(false)
          else
            setIsZoomActive(true)
        }

      },
      crosshair: {
        width: 1,
      },
      type: scaleOption === 'DISTANCE' ? 'linear' : 'datetime',
      labels: {
        style: {
          color: '#fff'
        },
        /* @ts-ignore */
        formatter: function () {
          /* @ts-ignore */
          return scaleOption === 'DISTANCE' ? convertSpeed(metric, this.value) : dayjs.duration(this.value, 'seconds').minutes() + ' mn'
        }
      }
    },
    tooltip: {
      ...BaseOptions.tooltip, shared: true,
      useHTML: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        let data = get(this, 'series.processedYData', [])
        if (isZoomActive) {
          if (data.length > 0) {
            let minVal = min(data)
            let maxVal = max(data)
            let avg = Math.round(mean(data))

            return `${this.y} BPM
            <br/>
           <span style="font-size:10px;font-weight:400"> (${t('minimumAbbreviation')}: ${minVal} -  ${t('meanAbbreviation')}: ${avg} -  ${t('maximumAbbreviation')}: ${maxVal})</span>`

          } else {
            return this.y + ' BPM'
          }
        } else {
          return this.y + ' BPM'
        }
      }
    },
    series: [
      {
        data: mergeArr(scaleOption === 'DISTANCE' ? distance : formattedTime, bpm),
        color: 'rgba(245, 39, 39, 0.8)',
        point: {

        },
        nextChartData: true,
      }
    ]
  }), [bpm, distance, formattedTime, scaleOption, flIndex, isZoomActive, isFinishLineActive])

  const optionsSpeed = useMemo(() => {
    return {
      ...BaseOptions,

      title: { ...BaseOptions.title, text: t('graph:vitesse') },
      yAxis: {

        labels: {
          style: {
            color: '#fff'
          },
          /* @ts-ignore */
          formatter: function () {
            /* @ts-ignore */
            return getSpeedForChart(toNumber(this.value), getSpeedUnit()?.value ?? "")
          }
        }
      },
      tooltip: {
        ...BaseOptions.tooltip,
        formatter: function (this: Highcharts.TooltipFormatterContextObject) {
          let data = get(this, 'series.processedYData', [])
          if (isZoomActive) {
            if (data.length > 0) {
              let minVal = getSpeedTooltipForChart(toNumber(getSpeedForChart(toNumber(min(data)), getSpeedUnit()?.value ?? "")), getSpeedUnit()?.value ?? "")
              let maxVal = getSpeedTooltipForChart(toNumber(getSpeedForChart(toNumber(max(data)), getSpeedUnit()?.value ?? "")), getSpeedUnit()?.value ?? "")
              let avg = getSpeedTooltipForChart(toNumber(getSpeedForChart(toNumber(Math.round(mean(data))), getSpeedUnit()?.value ?? "")), getSpeedUnit()?.value ?? "")

              return `${getSpeedTooltipForChart(toNumber(getSpeedForChart(toNumber(this.y), getSpeedUnit()?.value ?? "")), getSpeedUnit()?.value ?? "")} 
              <br/>
             <span style="font-size:10px;font-weight:400"> (${t('minimumAbbreviation')}: ${minVal} -  ${t('meanAbbreviation')}: ${avg} -  ${t('maximumAbbreviation')}: ${maxVal})</span>`

            } else {
              /* @ts-ignore */
              return getSpeedTooltipForChart(getSpeedForChart(toNumber(this.y), getSpeedUnit()?.value ?? ""), getSpeedUnit()?.value ?? "")

            }
          } else {
            /* @ts-ignore */
            return getSpeedTooltipForChart(getSpeedForChart(toNumber(this.y), getSpeedUnit()?.value ?? ""), getSpeedUnit()?.value ?? "")

          }
        }
      },
      xAxis: {
        events: {
          afterSetExtremes: function (event: Highcharts.AxisSetExtremesEventObject) {
            let min = event.min
            let max = event.max

            if (isFinishLineActive) {
              addFinishLines()
            }
            if (min < 0) {
              max -= Math.abs(min)
              min = 0
            }

            if (event.dataMin == min && event.dataMax == max)
              setIsZoomActive(false)
            else
              setIsZoomActive(true)
          }
        },
        crosshair: {
          width: 1,
        },
        type: scaleOption === 'DISTANCE' ? 'linear' : 'datetime',
        labels: {
          style: {
            color: '#fff'
          },
          /* @ts-ignore */
          formatter: function () {
            /* @ts-ignore */
            return scaleOption === 'DISTANCE' ? convertSpeed(metric, this.value) : dayjs.duration(this.value, 'seconds').minutes() + ' mn'
          }
        }
      },
      series: [
        {
          data: mergeArr(scaleOption === 'DISTANCE' ? distance : formattedTime, speed),
          point: {
          },
          nextChartData: true,
        }
      ]
    }
  }, [speed, distance, formattedTime, scaleOption, isZoomActive, isFinishLineActive])

  const optionsBpmSpeed = useMemo(() => {
    return {
      ...BaseOptions,
      chart: { ...BaseOptions.chart, type: 'line' },
      title: { ...BaseOptions.title, text: t('graph:bpm') + ' - ' + t('graph:vitesse') },
      yAxis: [{ // Primary yAxis
        title: {
          text: t('graph:bpm'),
          style: {
            color: "rgba(245, 39, 39, 0.8)"
          }
        },
        labels: {
          style: {
            color: '#fff'
          }
        },
        opposite: false
      }, { // Primary yAxis
        title: {
          text: t('graph:vitesse'),
          style: {
          },

        },
        labels: {
          style: {
            color: '#fff'
          },
          /* @ts-ignore */
          formatter: function () {
            /* @ts-ignore */
            return getSpeedForChart(toNumber(this.value), getSpeedUnit()?.value ?? "")
          }
        },
        opposite: true
      }],
      xAxis: {
        events: {
          afterSetExtremes: function (event: Highcharts.AxisSetExtremesEventObject) {
            let min = event.min
            let max = event.max

            if (isFinishLineActive) {
              addFinishLines()
            }
            if (min < 0) {
              max -= Math.abs(min)
              min = 0
            }

            if (event.dataMin == min && event.dataMax == max)
              setIsZoomActive(false)
            else
              setIsZoomActive(true)
          }
        },
        crosshair: {
          width: 1,
        },
        type: scaleOption === 'DISTANCE' ? 'linear' : 'datetime',
        labels: {
          style: {
            color: '#fff'
          },
          /* @ts-ignore */
          formatter: function () {
            /* @ts-ignore */
            return scaleOption === 'DISTANCE' ? convertSpeed(metric, this.value) : dayjs.duration(this.value, 'seconds').minutes() + ' mn'
          }
        }
      },
      tooltip: {
        ...BaseOptions.tooltip, shared: true,
        formatter: function (this: Highcharts.TooltipFormatterContextObject) {
          let ySpeed = get(this, 'points[1].y', 0)
          let data = get(this, 'points[0].series.processedYData', [])
          let data1 = get(this, 'points[1].series.processedYData', [])

          if (isZoomActive) {
            let minVal = min(data)
            let maxVal = max(data)
            let avg = Math.round(mean(data))

            let minVal1 = getSpeedTooltipForChart(toNumber(getSpeedForChart(toNumber(min(data1)), getSpeedUnit()?.value ?? "")), getSpeedUnit()?.value ?? "")
            let maxVal1 = getSpeedTooltipForChart(toNumber(getSpeedForChart(toNumber(max(data1)), getSpeedUnit()?.value ?? "")), getSpeedUnit()?.value ?? "")
            let avg1 = getSpeedTooltipForChart(toNumber(getSpeedForChart(toNumber(Math.round(mean(data1))), getSpeedUnit()?.value ?? "")), getSpeedUnit()?.value ?? "")

            return `${this?.points && this?.points[0]?.y + ' BPM' + ' - BPM'}  <span style="font-size:10px;font-weight:400"> (${t('minimumAbbreviation')}: ${minVal} -  ${t('meanAbbreviation')}: ${avg} -  ${t('maximumAbbreviation')}: ${maxVal})</span> </br>
            ${getSpeedTooltipForChart(toNumber(getSpeedForChart(ySpeed, getSpeedUnit()?.value ?? "")), getSpeedUnit()?.value ?? "")} 
             <span style="font-size:10px;font-weight:400"> (${t('minimumAbbreviation')}: ${minVal1} -  ${t('meanAbbreviation')}: ${avg1} -  ${t('maximumAbbreviation')}: ${maxVal1})</span>
            `
          } else {
            let str = ''
            str += this?.points && this?.points[0]?.y + ' BPM' + ' - '
            /* @ts-ignore */
            str += this?.points && getSpeedTooltipForChart(getSpeedForChart(ySpeed, getSpeedUnit()?.value ?? ""), getSpeedUnit()?.value ?? "")
            return str
          }



        },
      },
      series: [
        {
          yAxis: 0,
          data: mergeArr(scaleOption === 'DISTANCE' ? distance : formattedTime, bpm),
          color: "rgba(245, 39, 39, 0.8)",
          point: {

          },
          nextChartData: true,
        },
        {
          yAxis: 1,
          data: mergeArr(scaleOption === 'DISTANCE' ? distance : formattedTime, speed),
          point: {
          },
          nextChartData: true,
        },
      ]
    }
  }, [bpm, speed, distance, formattedTime, scaleOption, isZoomActive, isFinishLineActive])

  const optionsStride = useMemo(() => {
    return {
      ...BaseOptions,
      chart: { ...BaseOptions.chart, type: 'line' },
      title: { ...BaseOptions.title, text: t('graph:amplitude') + ' - ' + t('graph:cadence') },
      yAxis: [{ // Primary yAxis
        title: {
          text: t('graph:cadence'),
          style: {
            color: "#f7911a"
          }
        },
        labels: {
          style: {
            color: '#fff'
          }
        },
        opposite: false
      }, { // Primary yAxis
        title: {
          text: t('graph:amplitude'),
          style: {
            color: "#b3a400"
          }
        },
        labels: {
          style: {
            color: '#fff'
          },
          /* @ts-ignore */
          formatter: function () {
            /* @ts-ignore */
            return Math.round(toNumber(convertStrideLength(metric, toNumber(this.value))))
          }
        },
        opposite: true
      }],
      xAxis: {
        events: {
          afterSetExtremes: function (event: Highcharts.AxisSetExtremesEventObject) {
            let min = event.min
            let max = event.max

            if (isFinishLineActive) {
              addFinishLines()
            }
            if (min < 0) {
              max -= Math.abs(min)
              min = 0
            }

            if (event.dataMin == min && event.dataMax == max)
              setIsZoomActive(false)
            else
              setIsZoomActive(true)
          }
        },
        crosshair: {
          width: 1,
        },
        type: scaleOption === 'DISTANCE' ? 'linear' : 'datetime',
        labels: {
          style: {
            color: '#fff'
          },
          /* @ts-ignore */
          formatter: function () {
            /* @ts-ignore */
            return scaleOption === 'DISTANCE' ? convertSpeed(metric, this.value) : dayjs.duration(this.value, 'seconds').minutes() + ' mn'
          }
        }
      },
      tooltip: {
        ...BaseOptions.tooltip, shared: true,
        formatter: function (this: Highcharts.TooltipFormatterContextObject) {
          let data = get(this, 'points[0].series.processedYData', [])
          let data1 = get(this, 'points[1].series.processedYData', [])

          if (isZoomActive) {
            let minVal = min(data)
            let maxVal = max(data)
            let avg = Math.round(mean(data))

            let minVal1 = convertStrideLength(metric, min(data1))
            let maxVal1 = convertStrideLength(metric, max(data1))
            let avg1 = convertStrideLength(metric, Math.round(mean(data1)))

            return `${this?.points && this?.points[0]?.y + " " + t('graph:foulees') + ' - '} <span style="font-size:10px;font-weight:400"> (${t('minimumAbbreviation')}: ${minVal} -  ${t('meanAbbreviation')}: ${avg} -  ${t('maximumAbbreviation')}: ${maxVal})</span> </br>
            ${this?.points && convertStrideLength(metric, this?.points[1]?.y) + " " + t(metric === 'imperial' ? 'graph:pieds' : 'graph:metres')}
            <span style="font-size:10px;font-weight:400"> (${t('minimumAbbreviation')}: ${minVal1} -  ${t('meanAbbreviation')}: ${avg1} -  ${t('maximumAbbreviation')}: ${maxVal1})</span>
            `

          } else {
            let str = ''
            str += this?.points && this?.points[0]?.y + " " + t('graph:foulees') + ' - '
            str += this?.points && convertStrideLength(metric, this?.points[1]?.y) + " " + t(metric === 'imperial' ? 'graph:pieds' : 'graph:metres')
            return str
          }


        },
      },
      series: [
        {
          yAxis: 0,
          data: mergeArr(scaleOption === 'DISTANCE' ? distance : formattedTime, strideFrequency),
          color: "#f7911a",
          point: {

          },
          nextChartData: true,
        },
        {
          yAxis: 1,
          data: mergeArr(scaleOption === 'DISTANCE' ? distance : formattedTime, strideLength),
          color: "#b3a400",
          point: {
          },
          nextChartData: true,
        },
      ]
    }
  }, [strideLength, strideFrequency, distance, formattedTime, scaleOption, isZoomActive, isFinishLineActive])

  const optionsStrideFreq = useMemo(() => {
    return {
      ...BaseOptions,
      chart: { ...BaseOptions.chart },
      title: { ...BaseOptions.title, text: t('graph:cadence') },
      yAxis: [{ // Primary yAxis
        title: {
          text: t('graph:cadence'),
          style: {
            color: "#f7911a"
          }
        },

        labels: {
          style: {
            color: '#fff'
          }
        },

        opposite: false
      }],
      xAxis: {
        events: {
          afterSetExtremes: function (event: Highcharts.AxisSetExtremesEventObject) {
            let min = event.min
            let max = event.max

            if (isFinishLineActive) {
              addFinishLines()
            }
            if (min < 0) {
              max -= Math.abs(min)
              min = 0
            }

            if (event.dataMin == min && event.dataMax == max)
              setIsZoomActive(false)
            else
              setIsZoomActive(true)
          }
        },
        crosshair: {
          width: 1,
        },
        type: scaleOption === 'DISTANCE' ? 'linear' : 'datetime',
        labels: {
          style: {
            color: '#fff'
          },
          /* @ts-ignore */
          formatter: function () {
            /* @ts-ignore */
            return scaleOption === 'DISTANCE' ? convertSpeed(metric, this.value) : dayjs.duration(this.value, 'seconds').minutes() + ' mn'
          }
        }
      },
      tooltip: {
        ...BaseOptions.tooltip, shared: true,
        formatter: function (this: Highcharts.TooltipFormatterContextObject) {
          let data = get(this, 'series.processedYData', [])
          if (isZoomActive) {
            if (data.length > 0) {
              let minVal = min(data)
              let maxVal = max(data)
              let avg = Math.round(mean(data))
              let str = ''
              str += this?.points && this?.points[0]?.y + " " + t('graph:foulees')
              return `${str}
              <br/>
             <span style="font-size:10px;font-weight:400"> (${t('minimumAbbreviation')}: ${minVal} -  ${t('meanAbbreviation')}: ${avg} -  ${t('maximumAbbreviation')}: ${maxVal})</span>`

            } else {
              let str = ''
              str += this?.points && this?.points[0]?.y + " " + t('graph:foulees')
              return str
            }

          } else {
            let str = ''
            str += this?.points && this?.points[0]?.y + " " + t('graph:foulees')
            return str
          }


        },
      },
      series: [
        {
          data: mergeArr(scaleOption === 'DISTANCE' ? distance : formattedTime, strideFrequency),
          color: "#f7911a",
          point: {

          },
        },

      ]
    }
  }, [strideFrequency, distance, formattedTime, scaleOption, isZoomActive, isFinishLineActive])

  const optionsStrideLength = useMemo(() => {
    return {
      ...BaseOptions,
      chart: { ...BaseOptions.chart },
      title: { ...BaseOptions.title, text: t('graph:amplitude') },
      yAxis: [{ // Primary yAxis
        title: {
          text: t('graph:amplitude'),
          style: {
            color: "#b3a400"
          },

        },
        labels: {
          style: {
            color: '#fff'
          },
          /* @ts-ignore */
          formatter: function () {
            /* @ts-ignore */
            return Math.round(toNumber(convertStrideLength(metric, toNumber(this.value))))
          }
        },
      }],
      xAxis: {
        events: {
          afterSetExtremes: function (event: Highcharts.AxisSetExtremesEventObject) {
            let min = event.min
            let max = event.max

            if (isFinishLineActive) {
              addFinishLines()
            }
            if (min < 0) {
              max -= Math.abs(min)
              min = 0
            }

            if (event.dataMin == min && event.dataMax == max)
              setIsZoomActive(false)
            else
              setIsZoomActive(true)
          }
        },
        crosshair: {
          width: 1,
        },
        type: scaleOption === 'DISTANCE' ? 'linear' : 'datetime',
        labels: {
          style: {
            color: '#fff'
          },
          /* @ts-ignore */
          formatter: function () {
            /* @ts-ignore */
            return scaleOption === 'DISTANCE' ? convertSpeed(metric, this.value) : dayjs.duration(this.value, 'seconds').minutes() + ' mn'
          }
        }
      },
      tooltip: {
        ...BaseOptions.tooltip, shared: true,
        formatter: function (this: Highcharts.TooltipFormatterContextObject) {
          let data = get(this, 'series.processedYData', [])
          if (isZoomActive) {
            if (data.length > 0) {
              let minVal = convertStrideLength(metric, min(data))
              let maxVal = convertStrideLength(metric, max(data))
              let avg = convertStrideLength(metric, Math.round(mean(data)))
              let str = ''
              str += this?.points && convertStrideLength(metric, this?.points[0]?.y) + " " + t(metric === 'imperial' ? 'graph:pieds' : 'graph:metres')

              return `${str}
              <br/>
             <span style="font-size:10px;font-weight:400"> (${t('minimumAbbreviation')}: ${minVal} -  ${t('meanAbbreviation')}: ${avg} -  ${t('maximumAbbreviation')}: ${maxVal})</span>`
            } else {
              let str = ''
              str += this?.points && convertStrideLength(metric, this?.points[0]?.y) + " " + t(metric === 'imperial' ? 'graph:pieds' : 'graph:metres')
              return str
            }
          } else {
            let str = ''
            str += this?.points && convertStrideLength(metric, this?.points[0]?.y) + " " + t(metric === 'imperial' ? 'graph:pieds' : 'graph:metres')
            return str
          }


        },
      },
      series: [

        {
          data: mergeArr(scaleOption === 'DISTANCE' ? distance : formattedTime, strideLength),
          color: "#b3a400",
          point: {
          },
        },
      ]
    }
  }, [strideLength, distance, formattedTime, scaleOption, isZoomActive, isFinishLineActive])

  const resetZoom = () => {
    let charts = getCharts()
    charts.forEach(chart => chart?.xAxis[0].setExtremes(undefined, undefined))
  }

  return (
    <Box display={'flex'} flexDirection={'column'} gap={3} ref={divRef} position={'relative'} >
      {
        isZoomActive && <Box display={'flex'} justifyContent={'end'} mt={2}>
          <Button onClick={resetZoom} startIcon={<ZoomOutMap></ZoomOutMap>} size='small' variant='contained'>{t('resetZoom')}</Button>
        </Box>
      }

      <Hideable isVisible={userSettings.merge_graphs_speed_bpm}>
        <HighchartsReact
          ref={bpmSpeedRef}
          highcharts={Highcharts}
          options={optionsBpmSpeed}
          containerProps={{ style: { height: '220px' } }}
        />
      </Hideable>

      <Hideable isVisible={userSettings.display_graph_speed && !userSettings.merge_graphs_speed_bpm}>
        <HighchartsReact
          ref={speedRef}
          highcharts={Highcharts}
          options={optionsSpeed}
          containerProps={{ style: { height: '220px' } }}

        />
      </Hideable>
      <Hideable isVisible={userSettings.display_graph_bpm && !userSettings.merge_graphs_speed_bpm}>

        <HighchartsReact
          ref={bpmRef}
          highcharts={Highcharts}
          options={optionsBpm}
          containerProps={{ style: { height: '220px' } }}

        />
      </Hideable>


      <Hideable isVisible={userSettings.merge_graphs_stride_frequency_stride_length}>
        <HighchartsReact
          ref={strideRef}
          highcharts={Highcharts}
          options={optionsStride}
          containerProps={{ style: { height: '220px' } }}

        />

      </Hideable>


      <Hideable isVisible={userSettings.display_graph_stride_frequency && !userSettings.merge_graphs_stride_frequency_stride_length}>
        <HighchartsReact
          ref={freqRef}
          highcharts={Highcharts}
          options={optionsStrideFreq}
          containerProps={{ style: { height: '220px' } }}

        />
      </Hideable>
      <Hideable isVisible={userSettings.display_graph_stride_length && !userSettings.merge_graphs_stride_frequency_stride_length}>

        <HighchartsReact
          ref={lengthRef}
          highcharts={Highcharts}
          options={optionsStrideLength}
          containerProps={{ style: { height: '220px' } }}

        />
      </Hideable>


    </Box>


  )
}

export default Graphs