import { AppBar, Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, Divider, IconButton, Stack, Toolbar, Typography } from '@mui/material'
import { GridCloseIcon } from '@mui/x-data-grid'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useDispatch } from 'react-redux'
import { setEditTrainingsModal, setEditedExamQualifyData } from '../../store/reducers/modal.reducer'
import { t } from 'i18next'
import { useExam } from '../../hooks/useExam'
import { capitalize, get, groupBy, isEmpty, uniqBy } from 'lodash'
import { TUser } from '../../shared/types/user.type'
import ListSkeleton from '../exams/loader/ListSkeleton'
import GlobalEditor from '../editTrainings/GlobalEditor'
import dayjs from 'dayjs'
import { useAuth } from '../../hooks/useAuth'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import 'dayjs/locale/tr'
import { Ttraining } from '../../shared/types/training.type'
import ExamRow from '../editTrainings/ExamRow'
import { TQualifyChoices } from '../../shared/types/qualifyChoices.type'
import { LoadingButton } from '@mui/lab'
import { toast } from 'react-toastify'
import toggleItemInArray from '../../shared/utils/arrays'
import { useQuery } from 'react-query'
import { Exams as ApiExam } from '../../api/exams'
import EditTrainingsSkeleton from '../exams/loader/EditTrainingsSkeleton'

export type TQualifyData = {
    "horse_shoeings"?: string,
    "riders"?: string,
    "track_conditions"?: string,
    "track_names"?: string,
    "track_surfaces"?: string,
    "training_types"?: string
}
function EditTrainingsModal() {
    //States
    const [selectedExams, setSelectedExams] = useState<Ttraining[]>([])
    const [Exams, setExams] = useState<Ttraining[]>([])
    const [exams, _setExams] = useState<Ttraining[]>([])
    const [qualifyChoices, setQualifyChoices] = useState<TQualifyChoices | null>(null)
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(0)
    const [examsLength, setExamsLength] = useState(0)
    const itemsPerPage = 10
    const [qualifyData, setQualifyData] = useState<TQualifyData>({
        "horse_shoeings": "",
        "riders": "",
        "track_conditions": "",
        "track_names": "",
        "track_surfaces": "",
        "training_types": ""
    })
    //Hooks
    const open = useSelector<RootState>(state => state.modals.editTrainingsModal) as boolean
    const lastEditedExam = useSelector<RootState>(state => state.modals.editedExamQualifyData) as TQualifyData | null
    const dispatch = useDispatch()
    const isUserLoading = useSelector<RootState>(state => state.user.isUserLoading) as boolean
    const auth = useSelector<RootState>(state => state.user.user) as TUser | null
    const { getQualifyChoices, editTrainings } = useExam(false, get(auth, '@id'), null, undefined, ['&additional-groups[]=qualificationDetails'])


    //Queries
    const query = useQuery(['exams'], () => ApiExam.getExams(get(auth, '@id'), undefined, ['&additional-groups[]=qualificationDetails', 'include-total-count=true', 'itemsPerPage=10'], page), {
        enabled: false,
        refetchOnWindowFocus: false,
        cacheTime: 900000,
        onSuccess: ({ data }) => {
            _setExams(prev => get(data, 'hydra:member', []))
            let total_items = get(data, 'hydra:totalItems', 0)
            setExamsLength(total_items)
            setMaxPage(Math.round(total_items / itemsPerPage))

        }
    })
    //Effects

    useEffect(() => {
        if (!open) return
        query.refetch()
    }, [page, open])

    useEffect(() => {
        if (lastEditedExam) {
            setQualifyData(lastEditedExam)
        }
    }, [lastEditedExam])

    const groupedExams = useMemo(() => {
        if (isEmpty(Exams)) return []
        return groupBy(Exams, (item: Ttraining) => dayjs(item.creation_date).locale(auth?.lang ?? 'en').format('dddd D MMMM'))

    }, [Exams])

    useEffect(() => {
        if (!isEmpty(exams) && !isEmpty(qualifyChoices)) {
            let trackConditions = exams.map(e => e?.track_condition).filter(e => e)
            let trackSurface = exams.map(e => e?.track_surface).filter(e => e)
            let trackName = exams.map(e => e?.track_name).filter(e => e)
            let rider = exams.map(e => e?.rider).filter(e => e)
            let trainingType = exams.map(e => e?.training_type).filter(e => e)

            let tempQualify: any = qualifyChoices

            tempQualify.track_conditions = uniqBy([...tempQualify.track_conditions, ...trackConditions], '@id')
            tempQualify.track_surfaces = uniqBy([...tempQualify.track_surfaces, ...trackSurface], '@id')
            tempQualify.track_names = uniqBy([...tempQualify.track_names, ...trackName], '@id')
            tempQualify.riders = uniqBy([...tempQualify.riders, ...rider], '@id')
            tempQualify.training_types = uniqBy([...tempQualify.training_types, ...trainingType], '@id')

            setQualifyChoices(tempQualify)

        }
    }, [exams, qualifyChoices])


    useEffect(() => {
        setExams(exams)
    }, [exams])


    useEffect(() => {
        if (!open) {
            dispatch(setEditedExamQualifyData(null))
            setSelectedExams([])
            clearQualifyData()
            return
        }
        getQualifyChoices.mutateAsync(auth?.id)
            .then(({ data }) => {
                setQualifyChoices(data)
            }).catch(err => {
                console.log({ err });
            })
    }, [open])

    useEffect(() => {

        if (!isEmpty(selectedExams)) updateExams(selectedExams)

    }, [selectedExams, qualifyData])


    const updateExams = (selectedExams: Ttraining[]) => {
        if (isEmpty(selectedExams)) return
        let selectedIds = selectedExams.map(e => e.id)

        let tempExams: any = Exams

        // Track Conditions 
        let trackCondition = qualifyData.track_conditions
        if (trackCondition) {
            let findCondition = qualifyChoices?.track_conditions.find(e => e['@id'] === trackCondition)

            if (findCondition) {
                tempExams = tempExams.map((e: Ttraining) => selectedIds.includes(e.id) ? { ...e, track_condition: findCondition } : e)
            }
        }

        // Track Name 
        let trackName = qualifyData.track_names
        if (trackName) {
            let findCondition = qualifyChoices?.track_names.find(e => e['@id'] === trackName)

            if (findCondition) {
                tempExams = tempExams.map((e: Ttraining) => selectedIds.includes(e.id) ? { ...e, track_name: findCondition } : e)
            }
        }

        // Track Name 
        let trainingType = qualifyData.training_types
        if (trainingType) {
            let findCondition = qualifyChoices?.training_types.find(e => e['@id'] === trainingType)

            if (findCondition) {
                tempExams = tempExams.map((e: Ttraining) => selectedIds.includes(e.id) ? { ...e, training_type: findCondition } : e)
            }
        }

        // Rider
        let rider = qualifyData.riders
        if (rider) {
            let findCondition = qualifyChoices?.riders.find(e => e['@id'] === rider)

            if (findCondition) {
                tempExams = tempExams.map((e: Ttraining) => selectedIds.includes(e.id) ? { ...e, rider: findCondition } : e)
            }
        }

        // Surface
        let surface = qualifyData.track_surfaces
        if (surface) {
            let findCondition = qualifyChoices?.track_surfaces.find(e => e['@id'] === surface)

            if (findCondition) {
                tempExams = tempExams.map((e: Ttraining) => selectedIds.includes(e.id) ? { ...e, track_surface: findCondition } : e)
            }
        }

        setExams(tempExams)
    }

    const handleClose = () => {
        dispatch(setEditTrainingsModal(false))
    }

    const handleDateCheck = (checked: boolean, data: [string, Ttraining[]]) => {
        if (checked) {

            let tempSelectedExams: Ttraining[] = [...selectedExams, ...data[1]]
            setSelectedExams(tempSelectedExams)

        } else {
            let ids = data[1].map(e => e?.id)
            setSelectedExams(prev => prev.filter(e => !ids.includes(e.id)))
        }
    }

    const isDateChecked = (exams: Ttraining[]) => {
        let ids = exams.map(e => e?.id)
        return selectedExams.filter(e => ids.includes(e.id)).length === ids.length
    }

    const isExamChecked = (exam: Ttraining) => {

        return selectedExams.find(e => e?.id === exam?.id) ? true : false
    }


    const HandleExamClick = (checekd: boolean, exam: Ttraining) => {
        setSelectedExams(toggleItemInArray(selectedExams, exam))
    }


    const updateExam = (key: string, value: any, id: number) => {
        setExams(prev => prev.map(e => e.id === id ? { ...e, [key]: value } : e))
    }



    const clearQualifyData = () => {
        setQualifyData({
            "horse_shoeings": "",
            "riders": "",
            "track_conditions": "",
            "track_names": "",
            "track_surfaces": "",
            "training_types": ""
        })
    }



    const prepareData = () => {
        if (isEmpty(Exams)) return []
        let data = Exams.map((e: Ttraining & any) => {
            return {
                training_id: e.id,
                input: {
                    ...!isEmpty(e?.new_training_type) ? { new_training_type: e?.new_training_type } : { training_type: e.training_type?.['@id'] },
                    ...!isEmpty(e?.new_track_condition) ? { new_track_condition: e?.new_track_condition } : { track_condition: e.track_condition?.['@id'] },
                    ...!isEmpty(e?.new_track_name) ? { new_track_name: e?.new_track_name } : { track_name: e.track_name?.['@id'] },
                    ...!isEmpty(e?.new_rider) ? { new_rider: e?.new_rider } : { rider: e.rider?.['@id'] },
                    track_surface: e.track_surface?.['@id'],
                }
            }
        })


        return { items: data.filter(e => Object.values(e.input).some(e => e)) }

    }

    const saveData = () => {
        let data = prepareData()

        editTrainings.mutateAsync(data)
            .then(result => {
                toast(t('rights:success'), { type: 'success' })
                handleClose()
                setSelectedExams([])
                clearQualifyData()
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
            })
    }

    const handleScroll = (event: any) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        if (maxPage === page) return
        if (Math.floor(clientHeight + scrollTop) === scrollHeight) {
            setPage(prev => prev + 1)
        }
    };



    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box display={'flex'} alignItems={'center'}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <GridCloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" fontWeight={400} component="div">
                            {t('editTrainingForm:editMultipleTrainings')}
                        </Typography>
                    </Box>

                    <LoadingButton onClick={saveData} loading={editTrainings.isLoading} color='inherit'>{t('button:save')}</LoadingButton>

                </Toolbar>

            </AppBar>

            <DialogContent
                sx={{ maxHeight: 'calc(100vh-64px)', overflowY: 'auto' }}
                onScroll={handleScroll}
            >
                <Stack direction={'column'} spacing={2}>
                    <GlobalEditor qualifyData={qualifyData} onChangeSelect={(key, val) => setQualifyData(prev => ({ ...prev, [key]: val }))} qualifyChoices={qualifyChoices}></GlobalEditor>
                    {
                        Object.entries(groupedExams).map((date, index) => (
                            <React.Fragment key={index}>
                                <Typography variant='body2'>
                                    <Checkbox checked={isDateChecked(date[1])} onChange={(_, checked) => handleDateCheck(checked, date)} size='small'></Checkbox>
                                    {capitalize(get(date, '[0]', ''))}
                                </Typography>
                                <Divider></Divider>

                                <Stack spacing={2}>
                                    {date[1].map(exam => (
                                        <ExamRow key={exam.id} updateExam={updateExam} handleInputChange={(checked, exam) => HandleExamClick(checked, exam)} qualifyChoices={qualifyChoices} isExamChecked={isExamChecked(exam)} exam={exam}></ExamRow>
                                    ))}
                                </Stack>
                            </React.Fragment>
                        ))
                    }
                </Stack>
                {(query.isLoading || query.isFetching) && <EditTrainingsSkeleton></EditTrainingsSkeleton>}

            </DialogContent>
        </Dialog>
    )
}

export default EditTrainingsModal