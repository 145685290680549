import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Grid, Tooltip, Typography } from '@mui/material'
import { GridArrowDownwardIcon } from '@mui/x-data-grid'
import { t } from 'i18next'
import React from 'react'
import { ThrZones } from '../../shared/types/exam.type'
import HrActivityZone from './HrActivityZone'
import DonutActivityZone from './DonutActivityZone'
import { Info } from '@mui/icons-material'
type gaitProps = {
  data?: ThrZones,
  maxHr?: number
}
function GaitAndHrZone({ data, maxHr }: gaitProps) {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<GridArrowDownwardIcon />}
      >
        <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant='subtitle2'>{t('averagesAndHrZones:title')}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails className="slider-container">

        <Grid container spacing={4}>
          <Grid item md={6} sm={12} xs={12}>
            <DonutActivityZone data={data?.gait_averages}></DonutActivityZone>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <HrActivityZone params={{ includeInactivity: data?.hr_zones_with_inactivity_and_walk, excludeInactivity: data?.hr_zones_without_inactivity_and_walk }}></HrActivityZone>
            <Alert icon={
              <Tooltip title={<div dangerouslySetInnerHTML={{ __html: t('parameters:tooltipInfoTrainingPageMaxHeartRate', { path: '#' }) }}></div>} arrow>
                <Info sx={{ width: 20, height: 20 }}></Info>
              </Tooltip>
            } variant='outlined' severity='info'>
              {t('parameters:individualMaxHeartRate')} :  {maxHr ?? ' - '} Bpm
            </Alert>
          </Grid>
        </Grid>



      </AccordionDetails>
    </Accordion>
  )
}

export default GaitAndHrZone