export const preselects = [
    {
        title: 'settings:accountType:gallop',
        value: 'GALLOP'
    },
    {
        title: 'settings:accountType:trot',
        value: 'TROT'
    },
    {
        title: 'settings:accountType:vet',
        value: 'VET'
    },
    {
        title: 'settings:accountType:sport',
        value: 'SPORT'
    },
]