import dayjs from "dayjs"

export const formatDate = (date: string, format = 'DD/MM/YYYY') => {
    return dayjs(date).format(format)
}

export const getCorrectDateFormat = (format: string) => {
    let newFormat = format
    newFormat = newFormat.replace('Y', 'YYYY')
    newFormat = newFormat.replace('m', 'MM')
    newFormat = newFormat.replace('d', 'DD')

    return newFormat
}