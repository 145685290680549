import { Button, Container, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { routes } from '../routes'
import ResetPasswordForm from '../../components/auth/resetPasswordForm'
import { useAuth } from '../../hooks/useAuth'
import Errors from '../../components/global/Errors'
import { ApiError } from '../../shared/types/apiError.type'
function GeneratePassword() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const { resetPassword, isLoading, isError, error } = useAuth()
    let token = searchParams.get('token') as string
    const err = error?.response?.data as ApiError
    return (
        <Container maxWidth='xs' sx={{ display: 'flex', alignItems: 'start', flexDirection: 'column', gap: 2, justifyContent: 'center', height: '100vh' }}>
            <Typography textAlign={'center'} fontSize={'18px'} variant='h6'>{t('equi:registration:generate_password:title')}</Typography>
            <Errors isError={isError} error={err}></Errors>
            <ResetPasswordForm isLoading={isLoading} onSubmitReset={(password) => resetPassword({ password, token })}></ResetPasswordForm>
            <Button variant='text' size='small' onClick={() => navigate(routes.AUTH_ROUTES.LOGIN)}>{t('registration:back')}</Button>
        </Container>
    )
}

export default GeneratePassword