import { Email, Password } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Card, Checkbox, Divider, FormControlLabel, Grid, InputAdornment, TextField, TextFieldVariants, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Link from '@mui/material/Link';

export type resetForm = {
    password: string,
    confirmPassword: string
}

type loginProps = {
    onSubmitReset: (password: string) => void,
    isLoading: boolean,
    md?: number,
    fullWidth?: boolean,
    spacing?: number,
    isUpdateUserSuccess: boolean,
    variant?: TextFieldVariants

}
ResetPasswordForm.defaultProps = {
    md: 12,
    spacing: 3,
    fullWidth: true,
    isUpdateUserSuccess: false,
    variant:'standard'
}
function ResetPasswordForm({ onSubmitReset, isLoading, md, fullWidth, spacing, isUpdateUserSuccess,variant }: loginProps) {
    const { t } = useTranslation()
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors, touchedFields },
    } = useForm<resetForm>()

    const onSubmit: SubmitHandler<resetForm> = (data) => onSubmitReset(data.password)

    useEffect(() => {
        isUpdateUserSuccess && reset()
    }, [isUpdateUserSuccess])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {/* include validation with required or other outlined HTML validation rules */}
            <Grid container spacing={spacing} >
                <Grid item md={md} sm={12} xs={12}>
                    <TextField variant={variant}  FormHelperTextProps={{ variant, error: true }} helperText={errors.password?.message} error={errors.hasOwnProperty('password')} fullWidth label={t('security:login:password')} type='password' {...register("password", {
                        required: {
                            value: true,
                            message: 'Password field is required'
                        }
                    })} />
                </Grid>
                <Grid item md={md} sm={12} xs={12}>
                    <TextField variant={variant}  FormHelperTextProps={{ variant, error: true }} helperText={errors.confirmPassword?.message} error={errors.hasOwnProperty('confirmPassword')} fullWidth label={t('info:confirmPassword')} type='password'
                        {...register("confirmPassword", {
                            required: true,
                            validate: (val: string) => {
                                if (watch('password') != val) {
                                    return "Your passwords do no match";
                                }
                            },
                        })} />
                </Grid>
                <Grid item md={12} sm={12}>
                    <Box display={'flex'} justifyContent={'end'}>
                        <LoadingButton loading={isLoading} fullWidth={fullWidth} variant='contained' type="submit" >{t('change_password:submit')}</LoadingButton>
                    </Box>

                </Grid>
            </Grid>





        </form>



    )
}

export default ResetPasswordForm