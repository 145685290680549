import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { get } from 'lodash'
import { TRider } from "../shared/types/rider.type"
import { Riders } from "../api/riders"
export const useRiders = (enableQuery: boolean, trainer?: number) => {
    const dispach = useDispatch()
    const queryClient = useQueryClient()
    //Mutations
    const saveRiderMutation = useMutation((data: Partial<TRider>) => Riders.save(data))
    const deleteRiderMutation = useMutation((id: number) => Riders.delete(id))
    const updateRiderMutation = useMutation((data: Partial<TRider>) => Riders.update(data))

    //End mutations
    const [riders, setRiders] = useState<Array<TRider> | []>([])
    const query = useQuery(['riders'], () => Riders.getRiders(trainer), {
        refetchOnWindowFocus: false,
        cacheTime: 900000,
        enabled: enableQuery,
        onSuccess: (response) => {
            setRiders(get(response, 'data.hydra:member', []))
        }
    })

    const saveRider = async (data: Partial<TRider>) => {
        return saveRiderMutation.mutateAsync(data)
            .then(({ data }) => {
                // dispach(seTRiderModal(false))
                queryClient.refetchQueries(['riders'])
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
            })
    }

    const updateRider = async (data: Partial<TRider>) => {
        return updateRiderMutation.mutateAsync(data)
            .then(({ data }) => {
                // dispach(seTRiderModal(false))
                queryClient.refetchQueries(['riders'])
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
            })
    }

    const deleteRider = (id: number) => {
        return deleteRiderMutation.mutateAsync(id)
            .then(({ data }) => {
                // dispach(seTRiderModal(false))
                queryClient.refetchQueries(['riders'])
                return data
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
                return err
            })

    }
    return {
        saveRider,
        updateRider,
        deleteRider,
        isDeleting: deleteRiderMutation.isLoading,
        isSaving: saveRiderMutation.isLoading,
        isUpdating: updateRiderMutation.isLoading,
        riders,
        isLoading: query.isLoading || query.isFetching
    }
}