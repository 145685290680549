import { Close } from '@mui/icons-material';
import { Box, IconButton, Modal, Typography } from '@mui/material'
import { t } from 'i18next';
import React, { ReactNode } from 'react'
import { JsxElement } from 'typescript'


type BaseModalProps = {
    open: boolean,
    handleClose: () => void,
    children: ReactNode,
    title?: string,
    width?: number | string
}



BaseModal.defaultProps = {
    width: 400
}
function BaseModal({ open, handleClose, children, title, width }: BaseModalProps) {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width ?? 400,
        maxHeight: '80%',
        overflowY: 'auto',
        bgcolor: 'var(--bgGray)',
        boxShadow: 24,
        p: 3,
        borderRadius: 1,
    }

    return (
        <Modal
            keepMounted={false}
            open={open}
            onClose={(event, reason) => {
                if (reason && reason === 'backdropClick') {
                    return
                }
                handleClose()
            }}
        >
            <Box sx={style}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                    <Typography variant='h6'>
                        {title && t(title)}
                    </Typography>
                    <IconButton size='small' onClick={handleClose}>
                        <Close></Close>
                    </IconButton>
                </Box>
                {children}
            </Box>
        </Modal >
    )
}

export default BaseModal