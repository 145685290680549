import { Box, Button, Divider, FormControlLabel, InputAdornment, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { MapColorSpeedConfig } from '../../shared/types/trainingSetting.type'
import { getConvertedSpeed, getDatabaseSpeed, getSpeedForChart, getSpeedTooltipForChart } from '../../shared/utils/conversion'
import { useAuth } from '../../hooks/useAuth'
import { toNumber } from 'lodash'
import { LoadingButton } from '@mui/lab'

function MapMode({ value, onChange, mapSpeedConfig }: { value?: string, onChange: (val?: 'gait' | 'speed') => void, mapSpeedConfig: MapColorSpeedConfig }) {

    let id = useSelector<RootState>(state => state.user.user?.training_settings?.id) as number
    const { updateUserTraingSetting } = useAuth()

    const saveMapMode = (val?: 'gait' | 'speed') => {
        onChange(val)
        updateUserTraingSetting(id, { map_colors_type: val })
    }

    return (
        <Box bgcolor={"var(--lightGray)"} p={1} pl={2} mb={2} borderRadius={3} display={'flex'} flexWrap={'wrap'}>
            <Box alignItems={'flex-start'} flexDirection={'column'} display={'flex'}>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={(_, val) => saveMapMode(val as 'gait' | 'speed')}
                >
                    <FormControlLabel value="gait" control={<Radio />} label={<Typography variant='subtitle2'>{t('settings:mapColorsType:gait')}</Typography>} />
                    <FormControlLabel value="speed" control={<Radio />} label={<Typography variant='subtitle2'>{t('settings:mapColorsType:speed')}</Typography>} />
                </RadioGroup>
            </Box>
            <Divider flexItem orientation='vertical'></Divider>
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} ml={2}>
                {value === 'gait' && <GaitInfo></GaitInfo>}
                {value === 'speed' && <ColorInfo mapSpeedConfig={mapSpeedConfig}></ColorInfo>}
            </Box>
        </Box>
    )
}

export default MapMode
const GaitInfo = () => {
    const colors = ["#e0e0e0", "#b3a400", "#f7911a", "#99141a"]

    return (
        <Box display={'flex'} alignItems={'center'} gap={1}>
            {colors.map((color, i) => (
                <Box key={color} display={'flex'} alignItems={'center'} gap={1}>
                    <Box width={15} height={15} borderRadius={3} bgcolor={color}></Box>
                    <Typography variant='caption'>{t(`intervals:gait:${i}`)}</Typography>
                </Box>
            ))}
        </Box>
    )
}

const ColorInfo = ({ mapSpeedConfig }: { mapSpeedConfig: MapColorSpeedConfig }) => {
    const metric = useSelector<RootState>(state => state.user.user?.unit_system) as "metric" | "imperial" | undefined
    const [max, setMax] = useState<string | null>("")
    let id = useSelector<RootState>(state => state.user.user?.training_settings?.id) as number
    const { updateUserTraingSetting, settingsUpdating, getSpeedUnit } = useAuth()


    useEffect(() => {
        setMax(String(Math.round(toNumber(getSpeedForChart(mapSpeedConfig.max_value, getSpeedUnit()?.value ?? "")))))
    }, [mapSpeedConfig])

    const saveMax = () => {
        updateUserTraingSetting(id, { map_colors_speed_configuration: { ...mapSpeedConfig, max_value: getDatabaseSpeed(toNumber(max), getSpeedUnit()?.value ?? "", metric) } })
    }

    return (


        <Box display={'flex'} alignItems={'center'} gap={1}>
            <TextField
                variant='standard'
                disabled
                InputProps={{
                    endAdornment: <InputAdornment position="start">
                        <Typography variant='caption'>{t(getSpeedUnit()?.key ?? "")}</Typography>
                    </InputAdornment>,
                }}
                sx={{ width: 120 }}
                inputProps={{ style: { fontSize: '12px' } }} // font size of input text
                InputLabelProps={{ style: { fontSize: '12px' } }} // font size of input label
                type='number'
                size='small' value={0}></TextField>
            <img alt='colormap' src='https://equimetre-assets.s3.eu-west-3.amazonaws.com/build/img/jet_colormap.png?38.1.0' style={{ height: '20px', borderRadius: '5px' }} />
            <TextField
                variant='standard'
                InputProps={{
                    endAdornment: <InputAdornment position="start">
                        <Typography variant='caption'>{t(getSpeedUnit()?.key ?? "")}</Typography>
                    </InputAdornment>,
                }}
                sx={{ width: 120 }}
                inputProps={{ style: { fontSize: '12px' } }} // font size of input text
                InputLabelProps={{ style: { fontSize: '12px' } }} // font size of input label
                type='number'
                size='small' onChange={e => setMax(e.target.value)} defaultValue={getSpeedForChart(mapSpeedConfig.max_value, getSpeedUnit()?.value ?? "")}></TextField>
            <LoadingButton loading={settingsUpdating} onClick={saveMax} size='small'>{t('button:save')}</LoadingButton>

        </Box>
    )
}