import { HelpOutline, Info } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Checkbox, IconButton, Tooltip, Typography } from '@mui/material'
import { GridArrowDownwardIcon } from '@mui/x-data-grid'
import { t } from 'i18next'
import React from 'react'

type BaseAccordionProps = {
    title?: string,
    onChange?: (value: boolean) => void,
    onSave?: () => void,
    enableCheckBox: boolean,
    defaultChecked?: boolean,
    hint?: string,
    children?: React.ReactNode,
    showInfo?: boolean,
    loading?: boolean,
    showSave?: boolean,
    tooltipInfo?: string,
    infoClicked?: () => void
}

function BaseAccordion({ showSave, title, enableCheckBox, hint, onChange, children, defaultChecked, onSave, showInfo, tooltipInfo, infoClicked, loading }: BaseAccordionProps) {
    return (
        <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<GridArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Box alignItems={'center'} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                    <Box display={'flex'} alignItems={'center'}>
                        {
                            enableCheckBox && <Checkbox
                                defaultChecked={defaultChecked}
                                onClick={e => e.stopPropagation()}
                                onChange={e => typeof onChange === 'function' && onChange(e.target.checked)} size='small'></Checkbox>
                        }

                        {
                            title && <Typography variant='subtitle2'>{t(title)}</Typography>
                        }
                        {
                            showInfo &&
                            <Tooltip title={tooltipInfo ?? ""} arrow>
                                <IconButton onClick={(e) => {
                                    e.stopPropagation()
                                    typeof infoClicked === 'function' && infoClicked()
                                }} size='small' color='primary' sx={{ ml: 1 }}>
                                    <Info sx={{ width: 18, height: 18 }}></Info>
                                </IconButton>
                            </Tooltip>
                        }

                    </Box>
                    {
                        showSave && <LoadingButton sx={{ mr: 2 }} variant='contained'
                            disableElevation
                            loading={loading}
                            onClick={e => {
                                e.stopPropagation()
                                typeof onSave === 'function' && onSave()
                            }}
                        >{t('button:save')}</LoadingButton>
                    }

                </Box>

            </AccordionSummary>
            <AccordionDetails>
                {
                    hint && <Alert severity='info'>{t(hint)}</Alert>
                }
                {
                    children
                }

            </AccordionDetails>
        </Accordion>
    )
}

export default BaseAccordion