import { Box, Collapse, Divider, Drawer, List, ListItemButton, ListItemText, ListSubheader, OutlinedInput, Typography } from '@mui/material'
import React from 'react'
import { boolean } from 'zod'
import ListSkeleton from '../exams/loader/ListSkeleton'
import { t } from 'i18next'
import { TcomputedKeyParam } from '../../shared/types/computedKeyParam.type'
import { capitalize } from 'lodash'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import ParamSection from './ParamSection'

type AvailableParametersProps = {
    open: boolean,
    handleClose: () => void,
    loading: boolean,
    groupedKeys: Record<string, TcomputedKeyParam[]>,

}
function AvailableParameters({ open, handleClose, loading, groupedKeys }: AvailableParametersProps) {

    const DrawerList = (
        <Box sx={{ width: 400, borderBlock: '1px solid #eee', flex: 1, overflowY: 'auto' }} role="presentation" onClick={handleClose}>
            {
                loading ?
                    <ListSkeleton></ListSkeleton> :
                    <List>
                        {groupedKeys && (Object.keys(groupedKeys) as string[]).map(category => (
                            <React.Fragment key={category}>
                                <ListSubheader onClick={e => e.stopPropagation()}>{capitalize(category)}</ListSubheader>
                                <Divider></Divider>
                                {
                                    groupedKeys[category].map(key => (
                                        <AvailableParameterItem param={key} key={key.key_parameter_layout?.insight_parameter?.['@id']}></AvailableParameterItem>
                                    ))
                                }
                            </React.Fragment>
                        ))}
                    </List>
            }
        </Box>
    );


    return (
        <Drawer open={open} anchor='right' onClose={(e, r) => {
            handleClose()
        }} >
            <Box display={'flex'} flexDirection={'column'} sx={{ height: '100vh' }}>
                <Box p={2} display={'flex'} flexDirection={'column'}>
                    <Typography variant='subtitle1'>{t('settings:availableParameters')}</Typography>
                </Box>
                {DrawerList}

            </Box>
        </Drawer>
    )
}

const AvailableParameterItem = ({ param }: { param: TcomputedKeyParam }) => {
    const [open, setOpen] = React.useState(false);

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        setOpen(!open);
    };

    return (
        <>
            <ListItemButton onClick={handleClick}>

                <ListItemText
                    secondary={<div dangerouslySetInnerHTML={{ __html: param?.key_parameter_layout?.insight_parameter?.details_translated }}></div>}
                    primary={<Typography variant='subtitle2' >{param?.key_parameter_layout?.insight_parameter?.name_translated}</Typography>} >
                </ListItemText>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse onClick={e => { e.stopPropagation() }} in={open} timeout="auto" unmountOnExit sx={{ py: 2 }}>
                <ParamSection keyParam={param} hideSelect></ParamSection>
            </Collapse>
        </>
    )
}

export default AvailableParameters