import { AppBar, Box, Button, Checkbox, Dialog, DialogContent, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, List, ListItemButton, ListItemText, ListSubheader, MenuItem, OutlinedInput, Rating, Select, TextField, Toolbar, Tooltip, Typography } from '@mui/material'
import { GridCloseIcon } from '@mui/x-data-grid'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { setEditTrainingModal, setEditTrainingsModal, setEditedExamQualifyData, setTrainingToEdit } from '../../store/reducers/modal.reducer'
import { t } from 'i18next'
import { TExam } from '../../shared/types/exam.type'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useExam } from '../../hooks/useExam'
import { TQualifyChoice } from '../../shared/types/qualifyChoices.type'
import { toast } from 'react-toastify'
import { get, toNumber } from 'lodash'
import { TShoeing } from '../../shared/types/shoeing.type'
import { Ttrack, trackSurface } from '../../shared/types/tracks.type'
import { TtrackCondition } from '../../shared/types/trackCondition.type'
import { TTrainingType } from '../../shared/types/training.type'
import { TRider } from '../../shared/types/rider.type'
import TrainingEditSkeleton from '../exams/loader/TrainingEditSkeleton'
import { Create, Delete, ListAlt } from '@mui/icons-material'
import { useQueryClient } from 'react-query'
import { LoadingButton } from '@mui/lab'
import DeleteModal from './DeleteModal'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../pages/routes'
import { THorse } from '../../shared/types/horse.type'

function EditTrainingModal() {
    const open = useSelector<RootState>(state => state.modals.editTrainingModal) as boolean
    const item = useSelector<RootState>(state => state.modals.trainingToEdit) as TExam | undefined
    const [data, setData] = useState<any>()
    const [horseShoeing, setHorseShoeing] = useState<TShoeing[]>([])
    const [trackNames, setTrackNames] = useState<Ttrack[]>([])
    const [trackConditions, setTrackConditions] = useState<TtrackCondition[]>([])
    const [trackSurfaces, setTrackSurfaces] = useState<trackSurface[]>([])
    const [trainingTypes, setTrainingTypes] = useState<TTrainingType[]>([])
    const [riders, setRiders] = useState<TRider[]>([])
    const [horses, setHorses] = useState<THorse[]>([])
    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    //booleans for wheater show select or text field
    const [useCustomTrainingType, setCustomTrainingType] = useState(false)
    const [useCustomTrackName, setCustomTrackName] = useState(false)
    const [useCustomTrackCondition, setCustomTrackCondition] = useState(false)
    const [useCustomRider, setCustomRider] = useState(false)
    const [useCustomShoeing, setCustomShoeing] = useState(false)

    const { getChoices, isItemToEditLoading, editTrainingMutation, isItemEditing, deleteExam } = useExam(false)
    const dispatch = useDispatch()
    const queryClient = useQueryClient()

    useEffect(() => {
        if (item?.training?.id && open) {
            getChoices(item.training.id)
                .then(data => {
                    setValue('comment', get(data, 'current_comment', ''))
                    setValue('horse_shoeing', get(data, 'current_horse_shoeing.@id', ''))
                    setValue('intensity', get(data, 'current_intensity', ''))
                    setValue('track_name', get(data, 'current_track_name["@id"]', ''))
                    setValue('trainer_horse', get(data, 'current_trainer_horse["@id"]', ''))
                    setValue('track_condition', get(data, 'current_track_condition["@id"]', ''))
                    setValue('training_type', get(data, 'current_training_type["@id"]', ''))
                    setValue('track_surface', get(data, 'current_track_surface["@id"]', ''))
                    setValue('rider', get(data, 'current_rider["@id"]', ''))
                    setValue('use_finish_line', get(data, 'current_use_finish_line', false))
                    setValue('horse_shoeing', get(data, 'current_horse_shoeing["@id"]', ""))
                    setData(data)
                    setHorseShoeing(get(data, 'horse_shoeings', []))
                    setTrackNames(get(data, 'track_names', []))
                    setTrackConditions(get(data, 'track_conditions', []))
                    setTrackSurfaces(get(data, 'track_surfaces', []))
                    setTrainingTypes(get(data, 'training_types', []))
                    setRiders(get(data, 'riders', []))
                    setHorses(get(data, 'trainer_horses', []))

                }).catch(err => {
                    toast(t('error:generic'), { type: 'error' })
                })
        }
    }, [item?.training?.id, open])

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        watch,
        formState: { errors, touchedFields },
    } = useForm<TQualifyChoice>({
    })

    const watchTrack = watch('track_name')

    const showFinishLine = useMemo((): boolean => {
        if (!watchTrack) return false
        let findTrack = trackNames.find(e => get(e, '@id') === watchTrack)

        if (findTrack) {
            let finishLine = findTrack?.finish_line

            return finishLine ? true : false
        } else {

            return false
        }

    }, [watchTrack])


    const onSubmit: SubmitHandler<TQualifyChoice> = (data) => {
        let id = item?.training?.id
        if (!id) {
            toast(t('error:generic'), { type: 'error' })
            return
        }

        //check if new value are created we remove the selected one from the list
        data.new_rider && delete data.rider
        data.new_horse_shoeing && delete data.horse_shoeing
        data.new_track_name && delete data.track_name
        data.new_training_type && delete data.training_type
        data.new_track_condition && delete data.track_condition

        console.log({ data });


        editTrainingMutation.mutateAsync({ id, data })
            .then(result => {
                toast(t('rights:success'), { type: 'success' })
                queryClient.refetchQueries(['exam', toNumber(id)])
                handleClose()
                reset()
                setCustomTrainingType(false)
                setCustomTrackName(false)
                setCustomTrackCondition(false)
                setCustomRider(false)
                setCustomShoeing(false)
                dispatch(setTrainingToEdit(null))
                dispatch(setEditTrainingsModal(true))
                dispatch(setEditedExamQualifyData({
                    horse_shoeings: data.horse_shoeing,
                    riders: data.rider,
                    track_conditions: data.track_condition,
                    track_names: data.track_name,
                    track_surfaces: data.track_surface,
                    training_types: data.training_type,
                }))
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
            })


    }
    const handleClose = () => {
        dispatch(setEditTrainingModal(false))
    }
    const deleteTraining = () => {

        if (item?.training.id) {
            deleteExam.mutateAsync(item.training.id)
                .then(result => {
                    toast(t('rights:success'), { type: 'success' })
                    handleClose()
                    queryClient.refetchQueries(['exams'])
                    window.location.pathname = routes.DASHBOARD_ROUTES.TRAININGS;
                }).catch(err => {
                    toast(t('error:generic'), { type: 'error' })

                })
        }

    }

    return (
        <>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <GridCloseIcon />
                        </IconButton>
                        <Typography fontWeight={400} sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {t('editTrainingForm:editTraining')}
                        </Typography>


                    </Toolbar>
                </AppBar>
                {isItemToEditLoading ? <TrainingEditSkeleton></TrainingEditSkeleton> :
                    <DialogContent>
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <Grid container spacing={3}>
                                <Grid item md={12} sm={12} xs={12}>
                                    <Typography variant='subtitle2' color={'GrayText'} >
                                        {t('horse')}
                                    </Typography>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>

                                    <FormControl fullWidth >
                                        <InputLabel id="demo-simple-select-label">{t('editTrainingForm:changeHorse:label')}</InputLabel>
                                        <Select
                                            error={errors?.hasOwnProperty('trainer_horse')}
                                            {...register('trainer_horse', { required: true })}
                                            defaultValue={get(data, 'current_trainer_horse["@id"]', "")}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label={t('editTrainingForm:changeHorse:label')}

                                        >
                                            {horses.map((item?: THorse) =>
                                                <MenuItem value={get(item, '@id')}>{item?.display_name ?? ' - '}</MenuItem>

                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <FormControl fullWidth >
                                        <InputLabel id="demo-simple-select-label">{t('editTrainingForm:horseShoeing:label')}</InputLabel>
                                        {
                                            !useCustomShoeing ?
                                                <>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        error={errors?.hasOwnProperty('horse_shoeing')}
                                                        {...register('horse_shoeing', { required: true })}
                                                        defaultValue={get(data, 'current_horse_shoeing["@id"]', "")}
                                                        id="demo-simple-select"
                                                        label={t('editTrainingForm:horseShoeing:label')}
                                                        endAdornment={
                                                            <InputAdornment position="start">
                                                                <Tooltip title={t('otherHorseShoeing')}>
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            setCustomShoeing(true)
                                                                        }}
                                                                        sx={{ mr: 1 }}
                                                                        edge="end"
                                                                    >
                                                                        <Create></Create>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        }
                                                    >
                                                        {horseShoeing.map((item?: TShoeing) =>
                                                            <MenuItem value={get(item, '@id')}>{item?.display_name ?? ' - '}</MenuItem>

                                                        )}

                                                    </Select>
                                                </> :
                                                <OutlinedInput 
                                                    {...register('new_horse_shoeing', { required: false })}
                                                    endAdornment={
                                                        <InputAdornment position="start">
                                                            <Tooltip title={t('navbar:myShoeing')}>
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setCustomShoeing(false)
                                                                    }}
                                                                    edge="end"
                                                                >
                                                                    <ListAlt></ListAlt>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    }
                                                    label={t('editTrainingForm:horseShoeing:label')}
                                                    placeholder={t('otherHorseShoeing')}
                                                ></OutlinedInput>
                                        }

                                    </FormControl>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <Typography variant='subtitle2' color={'GrayText'}>
                                        {t('eap:table:headers:track')}
                                    </Typography>
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <FormControl fullWidth >
                                        <InputLabel id="demo-simple-select-label">{t('editTrainingForm:trackName:label')}</InputLabel>

                                        {
                                            !useCustomTrackName ? <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                error={errors?.hasOwnProperty('track_name')}
                                                {...register('track_name', { required: true })}
                                                defaultValue={get(data, 'current_track_name["@id"]', "")}
                                                label={t('editTrainingForm:trackName:label')}
                                                endAdornment={
                                                    <InputAdornment position="start">
                                                        <Tooltip title={t('newTrackName')}>
                                                            <IconButton
                                                                onClick={() => {
                                                                    setCustomTrackName(true)
                                                                }}
                                                                sx={{ mr: 1 }}
                                                                edge="end"
                                                            >
                                                                <Create></Create>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                }
                                            >
                                                {trackNames.map((item) =>
                                                    <MenuItem value={get(item, '@id')}>{item?.display_name ?? ' - '}</MenuItem>

                                                )}
                                            </Select> : <OutlinedInput 
                                                {...register('new_track_name', { required: false })}
                                                endAdornment={
                                                    <InputAdornment position="start">
                                                        <Tooltip title={t('navbar:myTracks')}>
                                                            <IconButton
                                                                onClick={() => {
                                                                    setCustomTrackName(false)
                                                                }}
                                                                edge="end"
                                                            >
                                                                <ListAlt></ListAlt>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                }
                                                label={t('editTrainingForm:horseShoeing:label')}
                                                placeholder={t('newTrackName')}
                                            ></OutlinedInput>
                                        }

                                    </FormControl>
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <FormControl fullWidth >
                                        <InputLabel id="demo-simple-select-label">{t('editTrainingForm:trackCondition:label')}</InputLabel>

                                        {
                                            !useCustomTrackCondition ?
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    error={errors?.hasOwnProperty('track_condition')}
                                                    {...register('track_condition', { required: true })}
                                                    defaultValue={get(data, 'current_track_condition["@id"]', "")}
                                                    label={t('editTrainingForm:trackCondition:label')}
                                                    endAdornment={
                                                        <InputAdornment position="start">
                                                            <Tooltip title={t('trackCondition:newTrackCondition')}>
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setCustomTrackCondition(true)
                                                                    }}
                                                                    sx={{ mr: 1 }}
                                                                    edge="end"
                                                                >
                                                                    <Create></Create>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    }
                                                >
                                                    {trackConditions.map((item) =>
                                                        <MenuItem value={get(item, '@id')}>{item?.display_name ?? ' - '}</MenuItem>

                                                    )}
                                                </Select>

                                                : <OutlinedInput 
                                                    {...register('new_track_condition', { required: false })}
                                                    endAdornment={
                                                        <InputAdornment position="start">
                                                            <Tooltip title={t('navbar:myTrackConditions')}>
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setCustomTrackCondition(false)
                                                                    }}
                                                                    edge="end"
                                                                >
                                                                    <ListAlt></ListAlt>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    }
                                                    label={t('editTrainingForm:trackCondition:label')}
                                                    placeholder={t('trackCondition:newTrackCondition')}
                                                ></OutlinedInput>
                                        }

                                    </FormControl>
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <FormControl fullWidth >
                                        <InputLabel id="demo-simple-select-label">{t('editTrainingForm:trackSurface:label')}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            error={errors?.hasOwnProperty('track_surface')}
                                            {...register('track_surface', { required: true })}
                                            defaultValue={get(data, 'current_track_surface["@id"]', "")}
                                            label={t('editTrainingForm:trackSurface:label')}

                                        >
                                            {trackSurfaces.map((item) =>
                                                <MenuItem value={get(item, '@id')}>{item?.display_name ?? ' - '}</MenuItem>

                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <Typography variant='subtitle2' color={'GrayText'} >
                                        {t('timeline:trainings')}
                                    </Typography>
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <FormControl fullWidth >
                                        <InputLabel id="demo-simple-select-label">{t('editTrainingForm:trainingType:label')}</InputLabel>
                                        {
                                            !useCustomTrainingType ? <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                error={errors?.hasOwnProperty('training_type')}
                                                {...register('training_type', { required: true })}
                                                defaultValue={get(data, 'current_training_type["@id"]', "")}
                                                label={t('editTrainingForm:trainingType:label')}
                                                endAdornment={
                                                    <InputAdornment position="start">
                                                        <Tooltip title={t('otherTrainingType')}>
                                                            <IconButton
                                                                onClick={() => {
                                                                    setCustomTrainingType(true)
                                                                }}
                                                                sx={{ mr: 1 }}
                                                                edge="end"
                                                            >
                                                                <Create></Create>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                }
                                            >
                                                {trainingTypes.map((item) =>
                                                    <MenuItem value={get(item, '@id')}>{item?.display_name ?? ' - '}</MenuItem>

                                                )}
                                            </Select> : <OutlinedInput 
                                                {...register('new_training_type', { required: false })}
                                                endAdornment={
                                                    <InputAdornment position="start">
                                                        <Tooltip title={t('editTrainingForm:trainingType:label')}>
                                                            <IconButton
                                                                onClick={() => {
                                                                    setCustomTrainingType(false)
                                                                }}
                                                                edge="end"
                                                            >
                                                                <ListAlt></ListAlt>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                }
                                                label={t('editTrainingForm:trainingType:label')}
                                                placeholder={t('otherTrainingType')}
                                            ></OutlinedInput>
                                        }

                                    </FormControl>
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <FormControl fullWidth >
                                        <InputLabel id="demo-simple-select-label">{t('editTrainingForm:riderName:label')}</InputLabel>
                                        {
                                            !useCustomRider ? <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                error={errors?.hasOwnProperty('rider')}
                                                {...register('rider', { required: true })}
                                                defaultValue={get(data, 'current_rider["@id"]', "")}
                                                label={t('editTrainingForm:riderName:label')}
                                                endAdornment={
                                                    <InputAdornment position="start">
                                                        <Tooltip title={t('otherRider')}>
                                                            <IconButton
                                                                onClick={() => {
                                                                    setCustomRider(true)
                                                                }}
                                                                sx={{ mr: 1 }}
                                                                edge="end"
                                                            >
                                                                <Create></Create>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                }
                                            >
                                                {riders.map((item) =>
                                                    <MenuItem value={get(item, '@id')}>{item?.display_name ?? ' - '}</MenuItem>

                                                )}
                                            </Select> : <OutlinedInput 
                                                {...register('new_rider', { required: false })}
                                                endAdornment={
                                                    <InputAdornment position="start">
                                                        <Tooltip title={t('navbar:myRiders')}>
                                                            <IconButton
                                                                onClick={() => {
                                                                    setCustomRider(false)
                                                                }}
                                                                edge="end"
                                                            >
                                                                <ListAlt></ListAlt>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                }
                                                label={t('editTrainingForm:riderName:label')}
                                                placeholder={t('otherRider')}
                                            ></OutlinedInput>
                                        }

                                    </FormControl>
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            
                                            {...register('comment', { required: true })}
                                            multiline label={t('editTrainingForm:comment:label')}></TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <Typography color={'gray'} component="legend" variant='body2'>{t('editTrainingForm:intensity:label')}</Typography>
                                    <Rating name="intensity" defaultValue={getValues('intensity')} size='large' onChange={(_, value) => setValue('intensity', value ?? 0)} />
                                </Grid>
                                {
                                    showFinishLine && <Grid item md={12} sm={12} xs={12}>
                                        <FormControl>
                                            <FormControlLabel label={
                                                <Typography color={'gray'} component="legend" variant='body2'>{t('editTrainingForm:useFinishLine')}</Typography>} control={<Checkbox defaultChecked={getValues('use_finish_line')} onChange={e => setValue('use_finish_line', e.target.checked)} />} />
                                        </FormControl>
                                    </Grid>
                                }

                                <Grid item md={12} sm={12} xs={12}>
                                    <Box display={'flex'} justifyContent={'end'} alignItems={'center'} gap={2}>
                                        <LoadingButton onClick={() => setOpenDeleteModal(true)} loading={isItemEditing || deleteExam.isLoading} variant='outlined' startIcon={<Delete></Delete>} color='error'>{t('button:delete')}</LoadingButton>
                                        <LoadingButton loading={isItemEditing || deleteExam.isLoading} type='submit' variant='contained'>{t('button:save')}</LoadingButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <DeleteModal
                            open={openDeleteModal}
                            handleClose={() => setOpenDeleteModal(false)}
                            isDeleting={deleteExam.isLoading}
                            title='deleteModal:title'
                            subtitle='deleteModal:body'
                            onDelete={deleteTraining}
                        ></DeleteModal>
                    </DialogContent>
                }



            </Dialog>
        </>
    )
}

export default EditTrainingModal