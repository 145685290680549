import axios from 'axios'
import { store } from '../store';
import { logout } from '../store/reducers/user.reducer';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URI, // Replace with your API base URL
    headers: {
        // Accept: 'application/json'
    }
});

// Request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        // Modify the request config here (e.g., add headers, authentication tokens)
        const accessToken = localStorage.getItem("token");
        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
            if (config.headers) config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        // Handle request errors here

        return Promise.reject(error);
    }
);

// Response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        // Modify the response data here (e.g., parse, transform)
        return response;
    },
    (error) => {
        // Handle response errors here
        if (error?.response?.status === 401) {
            store.dispatch(logout())
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;