import { AxiosResponse } from "axios"
import { Ttrack, trackSurface } from "../shared/types/tracks.type"
import axiosInstance from "./base"
import { TFinishLine } from "../shared/types/finishLine.type"
import { TTrainingType } from "../shared/types/training.type"

export class TrackName {

    static getTracks(trainer?: number, allAvailable = false): Promise<AxiosResponse<Array<Ttrack>>> {
        return axiosInstance.get(`track_names?trainer=${trainer}&all-available=${allAvailable}`)
    }
    static getSuraces(): Promise<AxiosResponse<Array<trackSurface>>> {
        return axiosInstance.get(`track_surfaces`)
    }
    static getTrack(id: number): Promise<AxiosResponse<Ttrack>> {
        return axiosInstance.get(`track_names/${id}`)
    }
    static getTrainingTypes(trainer: string): Promise<AxiosResponse<TTrainingType[]>> {
        return axiosInstance.get(`training_types?trainer=${trainer}`)
    }


    static saveFinishLine(data: Partial<TFinishLine>): Promise<AxiosResponse<TFinishLine>> {
        return axiosInstance.post('finish_lines', data)
    }

    static save(data: Partial<Ttrack>): Promise<AxiosResponse<Ttrack>> {
        return axiosInstance.post('track_names', data)
    }

    static update(data: { id: number, finish_line: string } | Partial<Ttrack>): Promise<AxiosResponse<Ttrack>> {
        return axiosInstance.patch(`track_names/${data.id}`, data, {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            }
        })
    }

    static delete(id: number) {
        return axiosInstance.delete(`track_names/${id}`)

    }
    static deleteFL(id: number) {
        return axiosInstance.delete(`finish_lines/${id}`)

    }

}