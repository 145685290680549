import { Grid, Skeleton } from '@mui/material'
import React from 'react'

function HorsesLoaderSkeleton() {
    return (
        <Grid container spacing={1}>
            {[...Array(9)].map((e, i) => (
                <Grid item key={i} md={4} sm={6} xs={12} mb={0} pb={0}>
                    <Skeleton width={'100%'} height={180}></Skeleton>
                </Grid>
            ))}
        </Grid>
    )
}

export default HorsesLoaderSkeleton