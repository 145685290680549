import { Box } from '@mui/material'
import React from 'react'
import MapDetailCard from './MapDetailCard'
import { DetailInfoProps } from './MapSection'
import { isEmpty } from 'lodash'

function MapDetails({ details }: { details?: DetailInfoProps[] }) {
    return (
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} flexWrap={'wrap'} gap={1}>
            {
                details && details.map((detail, i) => (
                    <MapDetailCard key={i} detail={detail}></MapDetailCard>

                ))
            }

        </Box>
    )
}

export default MapDetails