import { Box, CircularProgress } from '@mui/material'
import Lottie from 'lottie-react'
import React from 'react'
import animationHorse from '../../lottie/animationHorse.json'
function Loading() {
    return (
        <Box position={'absolute'} width={'100vw'} height={'100vh'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Lottie animationData={animationHorse} style={{ width: '250px', height: '250px' }}></Lottie>

        </Box>
    )
}

export default Loading