import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { t } from 'i18next';
import { CheckBox, ExpandLess, ExpandMore, KeyboardArrowDown, KeyboardArrowUp, Tune } from '@mui/icons-material';
import { Badge, Box, Checkbox, Chip, Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import { TGroupFilter, TSortFilter } from '../../shared/types/SortType';
import { isEmpty } from 'lodash';

type filterProps = {
    sortValues: TSortFilter[],
    groupValues: TGroupFilter[],
    onSortChange: (key: string, value: 'asc' | 'desc') => void,
    activeSort: string,
    activeGroups: { key: string, values: any[], objectPath: string }[]
    resetFilters: () => void,
    handleOpen: (key: string, value: boolean) => void,
    onGroupChange: (key: string, value: any, objectPath: string, isDate?: boolean) => void
}

export default function Filters({ sortValues, onSortChange, activeSort, resetFilters, groupValues, handleOpen, onGroupChange, activeGroups }: filterProps) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isFiltersActive = React.useMemo(() => {
        if (activeSort) return true
        if (!isEmpty(activeGroups)) {
            return activeGroups.some(group => !isEmpty(group.values))
        }
        return false
    }, [activeSort, activeGroups])

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const isChecked = (key: string, value: any): boolean => {
        let findGroup = activeGroups.find(e => e?.key === key)
        if (!findGroup) return false
        let values = findGroup.values
        return values.includes(value)

    }

    return (
        <div>
            <Badge variant='dot' anchorOrigin={{ horizontal: 'left', vertical: 'top' }} color='primary' invisible={!isFiltersActive}>
                <Button aria-describedby={id} variant="outlined" startIcon={<Tune></Tune>} onClick={handleClick}>
                    {t('filters')}
                </Button>
            </Badge>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box p={2} width={'400px'}>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant='caption' color={'GrayText'}>
                            {t('filters')}
                        </Typography>
                        <Button size='small' variant='text' onClick={resetFilters}>
                            {t('resetFilter')}
                        </Button>
                    </Box>

                    <Divider ></Divider>
                    <List disablePadding
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                {t('sortBy')}
                            </ListSubheader>
                        }
                    >
                        {
                            sortValues.map(sortItem => (
                                <ListItem selected={sortItem.key === activeSort} alignItems='center' disablePadding key={sortItem.key} disableGutters secondaryAction={
                                    sortItem.value === 'asc' ? <KeyboardArrowUp></KeyboardArrowUp> : <KeyboardArrowDown></KeyboardArrowDown>
                                }>
                                    <ListItemButton onClick={() => onSortChange(sortItem.key, sortItem.value === 'asc' ? 'desc' : 'asc')}>
                                        <ListItemText primary={<Typography variant='subtitle2'>{t(sortItem.text)}</Typography>} />
                                    </ListItemButton>
                                </ListItem>
                            ))
                        }

                    </List>
                    <Divider></Divider>
                    <List disablePadding
                    >
                        {groupValues.map(filter => (
                            <>
                                <ListItemButton onClick={() => handleOpen(filter.key, !filter.open)}>
                                    <ListItemText primary={<Typography variant='subtitle2'>{t(filter.text)}</Typography>} />
                                    <Chip size='small' label={filter.values.length}></Chip>

                                    {filter.open ? <ExpandLess /> : <ExpandMore />}

                                </ListItemButton>
                                <Collapse in={filter.open} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>

                                        {
                                            filter.values.map(v => (
                                                <ListItemButton sx={{ pl: 4 }} onClick={() => onGroupChange(filter.key, v.value, filter.objectPath, filter.isDate)}>
                                                    <ListItemText primary={<Typography variant='subtitle2'>{t(v.display_value)}</Typography>} />
                                                    <Checkbox checked={isChecked(filter.key, v.value)}></Checkbox>
                                                </ListItemButton>
                                            ))
                                        }
                                    </List>
                                </Collapse>
                            </>
                        ))}
                    </List>
                </Box>

            </Popover>
        </div>
    );
}