import { sortBy, uniq } from "lodash";
import { TTrainerHorse } from "../types/trainerHorse.type";
import dayjs from "dayjs";

export type ValuesType = {
    value: boolean | number | string,
    display_value: string
}

export const getStatus = (horses: TTrainerHorse[]): ValuesType[] => {
    let available_status = uniq(horses.map(horse => horse?.horse_in_training))
    let data: ValuesType[] = []
    available_status.forEach(e => data.push({ value: e, display_value: e ? 'trainingStatus:1' : 'trainingStatus:0' }))
    return data
}

export const getAges = (horses: TTrainerHorse[]): ValuesType[] => {
    let available_ages = uniq(horses.map(horse => horse.horse?.birthday ? dayjs(horse.horse.birthday).year() : undefined))
    let data: ValuesType[] = []
    available_ages.forEach(e => data.push({ value: e ?? '', display_value: e ? String(e) : 'myTrainings.unknownDate' }))
    return sortBy(data, 'value', 'desc')

}

export const getGenders = (horses: TTrainerHorse[]): ValuesType[] => {
    let available_genders = uniq(horses.map(horse => horse?.horse?.gender))
    let data: ValuesType[] = []
    available_genders.forEach(e => data.push({ value: e ?? '', display_value: e ? 'genders:byKey:' + e : 'genders:byKey:unknown' }))
    return data
}

export const getValuesBaseOnKey = (key: string, horses: TTrainerHorse[]) => {
    switch (key) {
        case 'GENDER':
            return getGenders(horses)
        case 'AGE':
            return getAges(horses)
        case 'STATUS':
            return getStatus(horses)

        default:
            return []
            break;
    }
}