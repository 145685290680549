import React, { useEffect, useMemo, useState } from 'react'
import { TSchedule } from '../../shared/types/schedule.type'
import { Box, Card, Collapse, Divider, IconButton, ListItemIcon, Menu, MenuItem, Pagination, Stack, TextField, Typography } from '@mui/material'
import ListSkeleton from '../exams/loader/ListSkeleton'
import ConfigItem from './ConfigItem'
import { ArrowDownward, ArrowUpward, Delete, Edit, MoreVert, Search } from '@mui/icons-material'
import { t } from 'i18next'
import { chunk, isEmpty, lowerCase } from 'lodash'

function Configurations({ configs, loading, isDeleting, onDelete, onEdit, onDuplicate }: { configs: TSchedule[], loading?: boolean, isDeleting?: boolean, onDelete: (id: number) => void, onEdit: (item: TSchedule) => void, onDuplicate?: (item: TSchedule) => void }) {

    const [open, setOpen] = useState(true)

    const [search, setSearch] = useState('')
    const [filteredconfigs, setFilteredconfigs] = useState<TSchedule[]>([])
    const [currentConfig, setCurrentConfig] = useState<TSchedule[]>([])
    const [page, setPage] = React.useState(1);
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    useEffect(() => {
        setFilteredconfigs(configs)
    }, [configs])

    const filterconfigs = (query: string) => {
        let filtered = configs.filter(e => lowerCase(e.name).indexOf(lowerCase(query)) !== -1)
        setFilteredconfigs(filtered)
    }

    useEffect(() => {
        if (!search) {
            setFilteredconfigs(configs)
        } else {
            filterconfigs(search)
        }
    }, [search])

    const paginatedChannels = useMemo(() => {
        return chunk(filteredconfigs, 4)
    }, [filteredconfigs])

    useEffect(() => {
        if (isEmpty(filteredconfigs)) return
        setCurrentConfig(paginatedChannels[page - 1])
    }, [page, filteredconfigs])



    return (
        <Card sx={{ p: 3 }}>

            {
                loading ? <ListSkeleton length={4} height={120}></ListSkeleton> :
                    <>
                        <Stack direction={'column'} spacing={3} mt={3}>
                            {

                                isEmpty(filteredconfigs) ?
                                    <Box display={'flex'} justifyContent={'center'} py={1}>
                                        <Typography variant='subtitle2'>{t('notification:noConfig')}</Typography>
                                    </Box>
                                    :
                                    configs?.map((config, i) => (
                                        <React.Fragment key={config.id}>
                                            <ConfigItem onDuplicate={onDuplicate} config={config} key={config.id} isDeleting={isDeleting} onEdit={onEdit} onDelete={onDelete}>

                                            </ConfigItem>

                                        </React.Fragment>
                                    ))}

                        </Stack>
                    </>
            }
            {/* <Box display={'flex'} justifyContent={'center'} p={2}>
                <Pagination onChange={handleChange} count={paginatedChannels.length}></Pagination>
            </Box> */}
        </Card>
    )
}

export default Configurations