import { AxiosResponse } from "axios";
import axiosInstance from "./base";
import { TTrainerHorse } from "../shared/types/trainerHorse.type";
import { THorse } from "../shared/types/horse.type";
import { TBreeds } from "../shared/types/breed.type";

export type saveTrainerType = {
    "trainer": string,
    "horse": string,
    "download_ecg_automatically": boolean
}
export class Horse {

    static getHorses(trainer?: string | null): Promise<AxiosResponse<Array<TTrainerHorse>>> {
        return axiosInstance.get(`trainer_horses?trainer=${trainer}`)
    }

    static updateHorseStatus(id: number, data: Partial<TTrainerHorse>): Promise<AxiosResponse<TTrainerHorse>> {
        return axiosInstance.patch(`trainer_horses/${id}`, data, {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            }
        })

    }

    static checkMicroship(microship: number): Promise<AxiosResponse<THorse>> {
        return axiosInstance.get(`horses/microchip/${microship}`)
    }

    static saveTrainerHorse(data: saveTrainerType): Promise<AxiosResponse<TTrainerHorse>> {
        return axiosInstance.post('trainer_horses', data)
    }
    static getBreeds(): Promise<AxiosResponse<TBreeds>> {
        return axiosInstance.get('breeds')
    }

    static updateHorse(id: number, data: Partial<THorse>): Promise<AxiosResponse<THorse>> {
        return axiosInstance.patch(`horses/${id}`, data, {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            }
        })
    }
    static saveHorse(data: Partial<THorse>): Promise<AxiosResponse<THorse>> {
        return axiosInstance.post('horses', data)
    }
}