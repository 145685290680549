import { Box, Chip, Divider, FormControl, FormHelperText } from '@mui/material'
import { t } from 'i18next'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import React from 'react'
import { Controller } from 'react-hook-form'

type TwhatsappField = {
    phones: string[]
    onChange: (values: string[]) => void,
    errorPhones: boolean
}

function WhatsappField({ phones, onChange,errorPhones }: TwhatsappField) {

    const [phone, setPhone] = React.useState('')
    const [phoneError, setPhoneError] = React.useState(false)
    const handleChange = (newPhone: string) => {
        setPhoneError(false)
        setPhone(newPhone)
    }

    const addPhoneToList = () => {
        if (matchIsValidTel(phone)) {
            onChange([...phones, phone])
            setPhone('')
        } else {
            setPhoneError(true)
        }
    }

    const deletePhone = (index: number) => {
        let phns = [...phones]
        phns.splice(index, 1)
        onChange(phns)
    }

    return (
        <>
            <MuiTelInput
                value={phone}
                onKeyUp={e => {
                    if (e.key === 'Enter' || e.keyCode === 13) {
                        addPhoneToList()
                    }
                }}
                defaultCountry='FR'
                error={phoneError || errorPhones}
                onChange={handleChange}
                placeholder={t('equi:registration:phone')}
                variant='outlined'
                size='small'
                sx={{ mt: '2px' }}
                fullWidth
            />
            {phoneError ? <FormHelperText sx={{ color: 'red' }}>{t('form:error:telephone')}</FormHelperText> : <FormHelperText>{t('notification:phoneHint')}</FormHelperText>}

            {
                phones.length > 0 && <>
                    <Divider sx={{ my: 1 }}></Divider>
                    <Box display={'flex'} gap={1} flexWrap={'wrap'}>
                        {phones.map((phone, i) => <Chip color='default' sx={{ borderRadius: 1 }} label={phone} key={phone} size='small' onDelete={() => deletePhone(i)}></Chip>)}
                    </Box>
                </>
            }
        </>
    )

}

export default WhatsappField