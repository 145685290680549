import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, CircularProgress, Tab } from '@mui/material';
import React, { useEffect } from 'react'
import { TAnalyticView } from '../../shared/types/analyticView.type';
import { isEmpty, toNumber } from 'lodash';
import { useAnalyticTable } from '../../hooks/useAnalyticTable';
import { AnalyticsData } from '../../shared/types/analyticData.type';
import Datatable from './Datatable';
import { TAnalyticData } from '../../pages/analytics';

type TabConfig = {
    analyticViews: Partial<TAnalyticView>[],
    setView: (view: TAnalyticView) => void,
    loading: boolean,
    dataLoading: boolean,
    analyticsData?: AnalyticsData,
    view?: TAnalyticView,
    updateFilter: (key: keyof TAnalyticData, value: any) => void

}

function ConfigTabs({ analyticViews, setView, loading, view, analyticsData, updateFilter,dataLoading }: TabConfig) {

    //State
    const [value, setValue] = React.useState('');

    //Hooks
    const { getViewById } = useAnalyticTable(false)

    //Fns

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    //Effects
    useEffect(() => {
        if (isEmpty(value)) return
        getViewById.mutateAsync(toNumber(value))
            .then(result => {
                setView(result.data)
            }).catch(err => {
                console.log({ err });
            })
    }, [value])

    useEffect(() => {
        if (isEmpty(analyticViews)) return

        setValue(String(analyticViews[0].id))
    }, [analyticViews])

    if (loading || getViewById.isLoading) {
        return <Box display={'flex'} justifyContent={'center'} mt={3}>
            <CircularProgress></CircularProgress>
        </Box>
    }

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        {
                            analyticViews.map(view => (
                                <Tab key={view.id} label={view.name} value={String(view.id)} />
                            ))
                        }

                    </TabList>
                </Box>
                <TabPanel value={value}>
                    <Datatable updateFilter={updateFilter} loading={dataLoading} view={view} analyticsData={analyticsData}></Datatable>
                </TabPanel>
            </TabContext>
        </Box>
    )
}

export default ConfigTabs