import { Box, Typography } from '@mui/material'
import gsap from 'gsap'
import React, { useEffect } from 'react'


function AnimatedLogo() {


    useEffect(() => {

        let timeline = gsap.timeline()

        timeline.fromTo('#logo', {
            opacity: 0,
        }, {
            opacity: 1,
            duration: 1,
        })
        timeline.fromTo('#logo', {
            x: 50,
            duration: 1,
        }, {
            x: 0,
            ease: 'power1'
        })

        timeline.fromTo('#letter', {
            opacity: 0,
        }, {
            opacity: 1,
            ease: 'power1',
            duration: 1,
            stagger: 0.1

        }, '-=0.2')

    }, [])

    return (
        <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'} gap={2}>
            <img alt='Arioneo Logo' id='logo' src='/logoArioneo.png' style={{ objectFit: 'contain', zIndex: 2 }} width={50} height={50} ></img>
            <Typography className='arioneo' sx={{ zIndex: 0, overflow: 'hidden', letterSpacing: 1 }}>{'Equimetre'.split('').map(e => <span id='letter'>{e}</span>)}</Typography>
        </Box>
    )
}

export default AnimatedLogo