import React, { useMemo, useState } from 'react'
import { TAnalyticsFilters } from '../../shared/types/analyticsFilters.type'
import { TAnalyticView } from '../../shared/types/analyticView.type'
import { Box, Checkbox, Chip, Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField, Typography } from '@mui/material'
import { t } from 'i18next'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { capitalize, isNull } from 'lodash'
import { TAnalyticData } from '../../pages/analytics'
import toggleItemInArray from '../../shared/utils/arrays'
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs'
import { hands } from '../../enums/trackGroup.enums'
import { harness } from '../../enums/harness.enum'
import { useSelector } from 'react-redux'
import { TuserAccountType } from '../../shared/types/user.type'
import { RootState } from '../../store'
const { RangePicker } = DatePicker;

type TFilters = {
  filters?: TAnalyticsFilters,
  currentView?: TAnalyticView,
  analyticData: TAnalyticData,
  updateAnalyticData: (items: (number | null)[] | boolean, key: keyof TAnalyticData) => void,
  batchUpdate: (data: any) => void,
  loading: boolean
}
function Filters({ filters, currentView, analyticData, updateAnalyticData, batchUpdate, loading }: TFilters) {

  const accountType = useSelector<RootState>(state => state.user.user?.type) as TuserAccountType | undefined


  const updateFilters = (items: (number | null)[] | boolean, key: keyof TAnalyticData) => {
    updateAnalyticData(items, key)

  }

  const isDateUnset = useMemo(() => {
    return [analyticData.date_start, analyticData.date_end].every(e => isNull(e) || !e)
  }, [analyticData.date_end, analyticData.date_start])

  return (
    <Box display={'flex'} alignItems={'center'} gap={1} flexWrap={'wrap'}>
      <List sx={{ width: '350px' }} >
        <ListExpander loading={loading} objectKey='horses' updateFilters={updateFilters} checkedValues={analyticData.horses} allSelected={analyticData.horses.length === (filters?.horses.length ?? 0) + 1} label={t('eap:table:headers:horse')} values={filters?.horses.map(i => ({ id: i.id, name: i.name }))}></ListExpander>
        <ListExpander loading={loading} objectKey='horse_ages' updateFilters={updateFilters} checkedValues={analyticData.horse_ages} allSelected={analyticData.horse_ages.length === (filters?.horse_ages.length ?? 0) + 1} label={t('age')} values={filters?.horse_ages.map(i => ({ id: i, name: String(i) }))}></ListExpander>
        <ListExpander loading={loading} objectKey='track_names' updateFilters={updateFilters} checkedValues={analyticData.track_names} allSelected={analyticData.track_names.length === (filters?.track_names.length ?? 0) + 1} label={t('editTrainingForm:trackName:label')} values={filters?.track_names.map(i => ({ id: i.id, name: i.display_name }))}></ListExpander>
        <ListExpander loading={loading} objectKey='training_types' updateFilters={updateFilters} checkedValues={analyticData.training_types} allSelected={analyticData.training_types.length === (filters?.training_types.length ?? 0) + 1} label={t('editTrainingForm:trainingType:label')} values={filters?.training_types.map(i => ({ id: i.id, name: i.display_name }))}></ListExpander>
        <ListExpander loading={loading} objectKey='track_conditions' updateFilters={updateFilters} checkedValues={analyticData.track_conditions} allSelected={analyticData.track_conditions.length === (filters?.track_conditions.length ?? 0) + 1} label={t('editTrainingForm:trackCondition:label')} values={filters?.track_conditions.map(i => ({ id: i.id, name: i.display_name }))}></ListExpander>
        <ListExpander loading={loading} objectKey='track_surfaces' updateFilters={updateFilters} checkedValues={analyticData.track_surfaces} allSelected={analyticData.track_surfaces.length === (filters?.track_surfaces.length ?? 0) + 1} label={t('editTrainingForm:trackSurface:label')} values={filters?.track_surfaces.map(i => ({ id: i.id, name: i.display_name }))}></ListExpander>
        <ListExpander loading={loading} objectKey='riders' updateFilters={updateFilters} checkedValues={analyticData.riders} allSelected={analyticData.riders.length === (filters?.riders.length ?? 0) + 1} label={t('editTrainingForm:riderName:label')} values={filters?.riders.map(i => ({ id: i.id, name: i.display_name }))}></ListExpander>
        <ListExpander loading={loading} objectKey='hands' updateFilters={updateFilters} checkedValues={analyticData.hands} allSelected={analyticData.hands.length === (hands.length ?? 0) + 1} label={t('units:hands')} values={hands.map(i => ({ id: i.value, name: t(i.key) }))}></ListExpander>

        {
          accountType === 'TROT' && <ListExpander loading={loading} objectKey='harnessed_mounteds' updateFilters={updateFilters} checkedValues={analyticData.harnessed_mounteds} allSelected={analyticData.harnessed_mounteds.length === (harness.length ?? 0) + 1} label={t('qualification:harnessedMounted:harnessedMounted')} values={harness.map(i => ({ id: i.value, name: t(i.key) }))}></ListExpander>
        }



        {/* CheckBox Item */}
        <ListItem dense>
          <ListItemText>{t('eap:includeHorsesOutOfTraining')}</ListItemText>
          <ListItemIcon>
            <Checkbox onChange={(_, checked) => updateFilters(checked, 'include_horses_out_of_training')} size='small' checked={analyticData?.include_horses_out_of_training}></Checkbox>
          </ListItemIcon>
        </ListItem>

        <ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'self-start', gap: 1 }}>
          <Typography variant='body2'>{t('eap:table:headers:date')}</Typography>
          <RangePicker
            onChange={(d, v) => batchUpdate({
              'date_start': d?.[0]?.toISOString() ?? null,
              'date_end': d?.[1]?.toISOString() ?? null
            },
            )}
            defaultValue={isDateUnset ? undefined : [dayjs(analyticData.date_start), dayjs(analyticData.date_end)]}
            format='DD/MM/YYYY'
            style={{ width: '100%' }}></RangePicker>
        </ListItem>
      </List>
    </Box>
  )
}

function ListExpander({ label, values, allSelected, checkedValues, updateFilters, objectKey, loading }: { label: string, values?: { id: number | string, name?: string }[], allSelected?: boolean, checkedValues: any[], updateFilters: (items: any[], key: keyof TAnalyticData) => void, objectKey: keyof TAnalyticData, loading: boolean }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <ListItem disableGutters disablePadding dense>
        <ListItemButton onClick={() => setOpen(!open)} dense>
          <ListItemText>{label}</ListItemText>
          <ListItemIcon>
            {
              open ? <ExpandLess></ExpandLess> : <ExpandMore></ExpandMore>
            }

          </ListItemIcon>

        </ListItemButton>
      </ListItem >
      <Divider></Divider>
      <Collapse in={open} sx={{ maxHeight: '200px', overflowY: 'auto' }}>
        <ListItem dense>
          <ListItemText >
            <Typography variant='caption'>
              {t('eap:table:filters:selectAll')}
            </Typography>
          </ListItemText>
          <ListItemIcon>
            <Checkbox disabled={loading}  onChange={(_, checked) => updateFilters(checked ? [...(values?.map(e => e.id) ?? []), null] : [], objectKey)} checked={allSelected} size='small'></Checkbox>
          </ListItemIcon>
        </ListItem>
        {values?.map(value => (
          <ListItem dense key={value.id}>
            <ListItemText >
              <Typography variant='caption'>
                {capitalize(value?.name ?? "")}
              </Typography>
            </ListItemText>
            <ListItemIcon>
              <Checkbox disabled={loading} onChange={(_, checked) => { updateFilters(toggleItemInArray(checkedValues, value.id), objectKey) }} checked={checkedValues.includes(value.id)} size='small'></Checkbox>
            </ListItemIcon>
          </ListItem>
        ))}
        {
          objectKey !== "horses" && <ListItem dense >
            <ListItemText >
              <Typography variant='caption'>
                N/A
              </Typography>
            </ListItemText>
            <ListItemIcon>
              <Checkbox disabled={loading} onChange={(_, checked) => { updateFilters(toggleItemInArray(checkedValues, null), objectKey) }} checked={checkedValues.includes(null)} size='small'></Checkbox>
            </ListItemIcon>
          </ListItem>
        }

      </Collapse>
    </>
  )
}

export default Filters