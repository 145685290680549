import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, ButtonGroup, Checkbox, CircularProgress, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { GridArrowDownwardIcon } from '@mui/x-data-grid'
import { t } from 'i18next'
import React, { ChangeEvent, useEffect, useState } from 'react'
import Graphs from './charts/Graphs'
import IntervalTraining from './charts/IntervalTraining'
import Sectional from './charts/Sectional'
import { DisplaySettings, Layers } from '@mui/icons-material'
import { scale } from '../../enums/scale.enum'
import { Scale } from '../../pages/trainings/ExamView'
import { TTrainingSection, Theats } from '../../shared/types/training.type'
import { convertSpeed, getSpeedTooltipForChart } from '../../shared/utils/conversion'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import dayjs from 'dayjs'
import { getTime } from '../../shared/utils/MapSection'
import { TrainingSetting } from '../../shared/types/trainingSetting.type'
import { useAuth } from '../../hooks/useAuth'
import { isEmpty, lowerCase } from 'lodash'
import GraphsSectionsSettings from '../MyTrainings/GraphsSectionsSettings'
import AddNewInterval from '../MyTrainings/AddNewInterval'
import Hideable from '../global/Hideable'

type ChartsSectionProps = {
    scaleOption: Scale
    onChangeScale: (val: Scale) => void,
    distance: string,
    speed: number[]
    bpm: number[],
    rawDistance: number[],
    time: number[],
    strideFrequency: number[],
    strideLength: number[],
    currentIndex: number,
    flIndex?: number | null,
    loading: boolean,
    isFinishLineActive: boolean,
    heats?: Theats[],
    intervals: TTrainingSection[],
    id?: number,
    editable?: boolean
}

function ChartsSection({ editable, id, heats, loading, scaleOption, onChangeScale, distance, speed, bpm, rawDistance, time, strideFrequency, strideLength, currentIndex, flIndex, isFinishLineActive, intervals }: ChartsSectionProps) {
    const metric = useSelector<RootState>(state => state.user.user?.unit_system) as "metric" | "imperial" | undefined
    const userSettings = useSelector<RootState>(state => state.user?.user?.training_settings) as TrainingSetting
    const [hintValue, setHintValue] = useState<string | undefined>("")
    let settingsId = useSelector<RootState>(state => state.user.user?.training_settings?.id) as number
    const { updateUserTraingSetting, settingsUpdating } = useAuth()


    useEffect(() => {
        if (time.length > 0 && scaleOption && distance) {
            if (scaleOption === 'DISTANCE') {
                setHintValue(distance)
            } else {
                let _time = time[time.length - 1] - time[0]
                setHintValue(getTime(_time) + ' min')
            }
        }

    }, [scaleOption, time, distance])

    const onExtremesChanged = (value: number) => {
        if (value === 0) {
            if (scaleOption === 'DISTANCE') {
                setHintValue(distance)
            } else {
                let _time = time[time.length - 1] - time[0]
                setHintValue(getTime(_time) + ' min')
            }
            return
        }
        if (scaleOption === 'DISTANCE') {

            setHintValue(convertSpeed(metric, value))
        } else {
            setHintValue(getTime(value) + ' min')
        }
    }


    const buttonGroup: { title: string, value: 'GRAPHS' | 'ITA' | 'SECTIONALS', component: JSX.Element, enabled: boolean, checkBoxKey: string, isVisible: boolean }[] = [
        {
            title: 'forthSection:graph',
            value: 'GRAPHS',
            enabled: userSettings.display_section_graphs,
            checkBoxKey: "display_section_graphs",
            isVisible: editable ? true : userSettings.display_section_graphs,
            component: <Graphs onExtremesChanged={onExtremesChanged} flIndex={flIndex} isFinishLineActive={isFinishLineActive} currentIndex={currentIndex} speed={speed} bpm={bpm} distance={rawDistance} scaleOption={scaleOption} time={time} strideFrequency={strideFrequency} strideLength={strideLength} ></Graphs>
        },
        {
            title: 'intervalTrainingAnalysis:title',
            value: 'ITA',
            enabled: userSettings.display_section_interval_training_analysis,
            checkBoxKey: "display_section_interval_training_analysis",
            isVisible: editable ? true : (userSettings.display_section_interval_training_analysis && !isEmpty(heats)),
            component: <IntervalTraining flIndex={flIndex} isFinishLineActive={isFinishLineActive} distance={rawDistance} time={time} heats={heats} speed={speed} bpm={bpm} scaleOption={scaleOption}></IntervalTraining>
        },
        {
            title: 'intervals:title',
            value: 'SECTIONALS',
            enabled: userSettings.display_section_intervals,
            checkBoxKey: "display_section_intervals",
            isVisible: editable ? true : userSettings.display_section_intervals,
            component: <Sectional isFinishLineActive={isFinishLineActive} editable={editable} time={time} currentIndex={currentIndex} intervals={intervals} id={id}></Sectional>
        },
    ]

    const updateUserSettings = (_: ChangeEvent, checked: boolean, checkboxKey: string) => {

        updateUserTraingSetting(settingsId, { [checkboxKey]: checked })
    }

    return (
        <>
            {buttonGroup.map(btn => (
                btn.isVisible &&
                <Accordion key={btn.value} defaultExpanded>
                    <AccordionSummary
                        expandIcon={<GridArrowDownwardIcon />}
                    >

                        <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Box display={'flex'} alignItems={'center'}>
                                {editable && <Checkbox size='small' onClick={e => e.stopPropagation()} onChange={(_, checked) => updateUserSettings(_, checked, btn.checkBoxKey)} checked={btn.enabled}></Checkbox>}
                                <Typography variant='subtitle2'>{t(btn.title)}</Typography>
                            </Box>
                        </Box>
                    </AccordionSummary>

                    <AccordionDetails>
                        <AccordionDetails className="slider-container">
                            {loading ?
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <CircularProgress size={18} sx={{ mt: 5 }}></CircularProgress>
                                </Box>
                                : <>
                                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap'} gap={1}>
                                        <Typography variant='body2' sx={{ display: 'flex', gap: 1 }}>{t(scaleOption === 'DISTANCE' ? 'graph:distance' : 'graph:time')} : <Typography variant='body2' fontWeight={500}>{hintValue}</Typography></Typography>
                                        <Box display={'flex'} alignItems={'center'} gap={1} flexWrap={'wrap'}>

                                            {/* Change What to display on graph Sections */}
                                            {
                                                editable &&
                                                <>
                                                    {
                                                        btn.value === 'GRAPHS' &&
                                                        <Box display={'flex'} flexDirection={'column'} bgcolor={'var(--lightGray)'} borderRadius={3} p={1}>
                                                            <Typography variant='caption' color={'GrayText'} display={'flex'} alignItems={'center'} gap={'3px'}>
                                                                <DisplaySettings sx={{ width: 14, height: 14 }}></DisplaySettings>
                                                                {t('settings:title')} {settingsUpdating && <CircularProgress sx={{ ml: 1 }} size={12}></CircularProgress>}</Typography>
                                                            <GraphsSectionsSettings loading={settingsUpdating} updateSettings={(key: string, checked: boolean) => updateUserTraingSetting(settingsId, { [key]: checked })}></GraphsSectionsSettings>
                                                        </Box>
                                                    }
                                                    {
                                                        btn.value === 'ITA' && <Alert severity='info'>{t('trainingSettings:warningIntervalTrainingAnalysis')}</Alert>
                                                    }
                                                </>
                                            }



                                            <Box display={'flex'} flexDirection={'column'} bgcolor={'var(--lightGray)'} borderRadius={3} p={1}>
                                                <Typography variant='caption' color={'GrayText'} display={'flex'} alignItems={'center'} gap={'3px'}>
                                                    <Layers sx={{ width: 14, height: 14 }}></Layers>
                                                    {t(!editable ? 'graph:scale' : 'settings:defaultGraphUnitType')} {settingsUpdating && <CircularProgress sx={{ ml: 1 }} size={12}></CircularProgress>}</Typography>
                                                <RadioGroup

                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                >
                                                    {scale.map(s => (
                                                        <FormControlLabel key={s.value} value={s.value} checked={scaleOption === s.value} control={<Radio onChange={e => {
                                                            if (editable) {
                                                                updateUserTraingSetting(settingsId, { 'default_graph_unit_type': lowerCase(e.target.value) })
                                                            }
                                                            onChangeScale(e.target.value as Scale)
                                                        }} size='small' />} label={<Typography variant='caption' fontWeight={500}>{t(s.key)}</Typography>} />

                                                    ))}

                                                </RadioGroup>
                                            </Box>
                                        </Box>


                                    </Box>
                                    {
                                        btn.component
                                    }
                                </>
                            }
                        </AccordionDetails>
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    )
}

export default ChartsSection