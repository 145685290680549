import { DataGrid, DataGridProps } from '@mui/x-data-grid'
import React, { useEffect, useRef } from 'react'
import { useLocales } from '../../hooks/useLocales'

function I18nDataGrid(props: DataGridProps) {
    const { getDataGridLocales } = useLocales()
 
    return (
        <DataGrid sx={{ position: 'relative' }}  {...props} localeText={getDataGridLocales()}></DataGrid>
    )
}

export default I18nDataGrid