export const weekDays = [
  
    {
        key: 'weekdays:Lundi',
        value: 1
    },
    {
        key: 'weekdays:Mardi',
        value: 2
    },
    {
        key: 'weekdays:Mercredi',
        value: 3
    },
    {
        key: 'weekdays:Jeudi',
        value: 4
    },
    {
        key: 'weekdays:Vendredi',
        value: 5
    },
    {
        key: 'weekdays:Samedi',
        value: 6
    },
    {
        key: 'weekdays:Dimanche',
        value: 7
    },
]