import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { TUser } from '../../shared/types/user.type'
import { metrics, speedUnits } from '../../enums/register.enums'
import { useAuth } from '../../hooks/useAuth'
import { isEmpty } from 'lodash'
import { Box, Button, Divider, Typography } from '@mui/material'
import { t } from 'i18next'
import { useDispatch } from 'react-redux'
import { setInitialSpeedUnit, setInitialUnitSystem, setUser } from '../../store/reducers/user.reducer'

function UnitChanger() {
    const { auth } = useAuth()
    const dispatch = useDispatch()
    const initialSpeed = useSelector<RootState>(state => state.user.initialSpeedUnit) as string
    const initialUnit = useSelector<RootState>(state => state.user.initialUnitSystem) as string
    const [currentSpeed, setCurrentSpeed] = useState('')
    const [currentUnit, setCurrentUnit] = useState('')


    useEffect(() => {
        setCurrentSpeed(initialSpeed)
        setCurrentUnit(initialUnit)
    }, [])


    const availableMetrics = useMemo(() => {
        return speedUnits.filter(e => e.value !== currentSpeed && [currentUnit, ''].includes(e.enabled))
    }, [currentSpeed, currentUnit])

    const availableUnit = useMemo(() => {
        return metrics.filter(e => e.value !== currentUnit)
    }, [currentUnit])




    //Fns
    const changeSpeedUnit = (speedUnit: string) => {
        dispatch(setUser({ ...auth, default_speed_unit: speedUnit }))
        setCurrentSpeed(speedUnit)
    }

    const changeMetric = (metric: "metric" | "imperial") => {
        dispatch(setUser({ ...auth, unit_system: metric }))
        setCurrentUnit(metric)
    }


    return (
        <Box display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} gap={1}>
                {
                    availableMetrics.map((metric, i) => (
                        <>
                            <Typography onClick={() => changeSpeedUnit(metric.value)} key={metric.key} variant='caption' sx={{ color: 'white', cursor: 'pointer' }}>{t(metric.key)}</Typography>
                            {i !== availableMetrics.length - 1 && <Divider flexItem orientation='vertical' sx={{ backgroundColor: 'white' }}></Divider>}
                        </>
                    ))
                }
            </Box>

            <Divider sx={{ backgroundColor: 'white' }}></Divider>

            <Box display={'flex'}>
                {
                    availableUnit.map(unit => (
                        <Typography onClick={() => changeMetric(unit.value)} key={unit.value} variant='caption' sx={{ color: 'white', cursor: 'pointer' }}>{t(unit.key)}</Typography>

                    ))
                }
            </Box>
        </Box>
    )
}

export default UnitChanger