import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { get } from 'lodash'
import { TtrackCondition } from "../shared/types/trackCondition.type"
import { TrackCondition } from "../api/track"
export const useTrackConditions = (enableQuery: boolean, trainer?: number) => {
    const dispach = useDispatch()
    const queryClient = useQueryClient()
    //Mutations
    const saveTrackMutation = useMutation((data: Partial<TtrackCondition>) => TrackCondition.save(data))
    const deleteTrackMutation = useMutation((id: number) => TrackCondition.delete(id))
    const updateTrackMutation = useMutation((data: Partial<TtrackCondition>) => TrackCondition.update(data))

    //End mutations
    const [tracks, setTracks] = useState<Array<TtrackCondition> | []>([])
    const query = useQuery(['tracks'], () => TrackCondition.geTtrackConditions(trainer), {
        refetchOnWindowFocus: false,
        cacheTime: 900000,
        enabled: enableQuery,
        onSuccess: (response) => {
            setTracks(get(response, 'data.hydra:member', []))
        }
    })

    const saveTrack = async (data: Partial<TtrackCondition>) => {
        return saveTrackMutation.mutateAsync(data)
            .then(({ data }) => {
                // dispach(seTTrackModal(false))
                queryClient.refetchQueries(['tracks'])
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
            })
    }

    const updateTrack = async (data: Partial<TtrackCondition>) => {
        return updateTrackMutation.mutateAsync(data)
            .then(({ data }) => {
                // dispach(seTTrackModal(false))
                queryClient.refetchQueries(['tracks'])
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
            })
    }

    const deleteTrack = (id: number) => {
        return deleteTrackMutation.mutateAsync(id)
            .then(({ data }) => {
                // dispach(seTTrackModal(false))
                queryClient.refetchQueries(['tracks'])
                return data
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
                return err
            })

    }
    return {
        saveTrack,
        updateTrack,
        deleteTrack,
        isDeleting: deleteTrackMutation.isLoading,
        isSaving: saveTrackMutation.isLoading,
        isUpdating: updateTrackMutation.isLoading,
        tracks,
        isLoading: query.isLoading || query.isFetching
    }
}