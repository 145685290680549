import { Box, Button, ButtonGroup, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { t } from 'i18next'
import { isEmpty } from 'lodash'
import React from 'react'
import { metrics } from '../../enums/register.enums'
import { languages } from '../../enums/languages.enum'
import { LoadingButton } from '@mui/lab'

type NewChannelStep1Props = {
    onSave: () => void,
    currentMetric: string | null,
    currentLang: string | null,
    onMetricChange: (val: string | null) => void
    onLangChange: (val: string | null) => void,
    speedUnits: { key: string; value: string; enabled: string; }[],
    speedUnit: string | null,
    onUnitChange: (val: string | null) => void,
    loading: boolean
}

function NewChannelStep3({ onSave, currentMetric, onMetricChange, currentLang, onLangChange, speedUnits, speedUnit, onUnitChange, loading }: NewChannelStep1Props) {
    return (
        <>
            <Box width={'100%'}>

                <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} my={2} alignItems={'center'}>
                    <Typography variant='body2' fontWeight={400}>{t('equi:registration:unit_system:label')} <Typography variant='caption' > ({t('addHorse:optional')})</Typography></Typography>
                    <ButtonGroup disableElevation variant="outlined" aria-label="outlined button group">
                        {metrics.map(metric => (
                            <Button onClick={(e) => { onMetricChange(metric.value) }} variant={metric.value === currentMetric ? 'contained' : 'outlined'} key={metric.value}>{t(metric.key)}</Button>
                        ))}

                    </ButtonGroup>
                </Box>
                <Divider></Divider>
                <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} my={2} alignItems={'center'}>
                    <Typography variant='body2' fontWeight={400}>{t('info:language')} <Typography variant='caption' > ({t('addHorse:optional')})</Typography></Typography>
                    <FormControl sx={{ width: '200px' }} size='small'>
                        <InputLabel id="lag-select-label">{t('equi:registration:lang:label')}</InputLabel>
                        <Select
                            sx={{ width: '200px' }}
                            labelId="lag-select-label"
                            id="demo-simple-select"
                            label={t('equi:registration:lang:label')}
                            value={currentLang}
                            onChange={(e) => onLangChange(e.target.value)}
                        >
                            {languages.map(lang => (
                                <MenuItem key={lang.key} value={lang.key}> {t(lang.value)}</MenuItem>

                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Divider></Divider>
                <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} my={2} alignItems={'center'}>
                    <Typography variant='body2' fontWeight={400}>{t('info:defaultSpeedUnit')} <Typography variant='caption' > ({t('addHorse:optional')})</Typography></Typography>
                    <ButtonGroup disableElevation variant="outlined" aria-label="outlined button group">
                        {speedUnits.map(su => (
                            <Button onClick={(e) => onUnitChange(su.value)} variant={su.value === speedUnit ? 'contained' : 'outlined'} key={su.key}>{t(su.key)}</Button>
                        ))}
                    </ButtonGroup>
                </Box>
            </Box>
            <Divider sx={{ my: 3 }}></Divider>
            <Box display={'flex'} justifyContent={'end'} >
                <LoadingButton loading={loading} onClick={onSave} variant='contained'>{t('button:save')}</LoadingButton>
            </Box>
        </>

    )
}

export default NewChannelStep3