import { Box, TextField } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import { LoadingButton } from '@mui/lab'
import { toNumber } from 'lodash'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { getDatabaseSpeed, getSpeedForChart } from '../../shared/utils/conversion'

function AddMinSpeed() {
    const { getSpeedUnit, updateUserTraingSetting, settingsUpdating } = useAuth()
    const [speed, setSpeed] = useState(0)
    let id = useSelector<RootState>(state => state.user.user?.training_settings?.id) as number
    let default_speed = useSelector<RootState>(state => state.user.user?.training_settings?.min_speed_display_checkpoint) as number
    const metric = useSelector<RootState>(state => state.user.user?.unit_system) as "metric" | "imperial" | undefined

    useEffect(() => {
        setSpeed(Math.round(toNumber(getSpeedForChart(default_speed, getSpeedUnit()?.value ?? ""))))
    }, [default_speed])

    return (
        <Box display={'flex'} flexDirection={'column'} justifyContent={'end'} alignItems={'end'}>
            <TextField
                helperText={t('checkpointsCrossings:displayCheckpointsCrossedAtSpeedGreaterThan') + speed + ' ' + t(getSpeedUnit()?.key ?? '')}
                label={t('dash:title:minimum') + ' ' + t('settings:displayGraphSpeed')}
                value={speed}
                size='small'
                type='number'
                onChange={e => setSpeed(toNumber(e.target.value))}
            >

            </TextField>
            <LoadingButton onClick={() => updateUserTraingSetting(id, { min_speed_display_checkpoint: getDatabaseSpeed(speed, getSpeedUnit()?.value ?? '', metric) })} loading={settingsUpdating}>{t('button:save')}</LoadingButton>
        </Box>
    )
}

export default AddMinSpeed