import React from 'react'
import { Theats } from '../../../shared/types/training.type'
import I18nDataGrid from '../../global/I18nDataGrid'
import { GridColDef, GridRenderCellParams, GridRowClassNameParams, GridValueGetterParams } from '@mui/x-data-grid';
import { t } from 'i18next';
import dayjs from 'dayjs';
import Duration from 'dayjs/plugin/duration'
import { convertStrideLength, formatMetricIntoImperialDistance, getSpeedForChart } from '../../../shared/utils/conversion';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { toNumber } from 'lodash';
import { Box, Typography } from '@mui/material';
import { useAuth } from '../../../hooks/useAuth';
function HeatGrid({ heats, speedUnit, onRowHover, hoveredHeat }: { heats?: Theats[], speedUnit: string, onRowHover: (row?: Theats, action?: 'entered' | 'leave') => void, hoveredHeat: number | null }) {
    const metric = useSelector<RootState>(state => state.user.user?.unit_system) as "metric" | "imperial" | undefined
    const imperial_distance_miles_only = useSelector<RootState>(state => state.user.user?.imperial_distance_miles_only) as boolean
    const { getSpeedUnit, getMetric } = useAuth()
    const columns: GridColDef[] = [
        {
            field: 'idx',
            renderHeader: () => { return "" },
            renderCell: (params: GridRenderCellParams<Theats>) =>
                params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,

        },
        {
            field: 'time',
            renderHeader: () => (
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
                    <Typography >{t('intervals:time')}</Typography>
                    <Typography variant='caption'>({t('intervals:min')})</Typography>
                </Box>
            ), flex: 1,

            valueGetter: (val: GridValueGetterParams<Theats>) => {
                return dayjs.duration(val.row.duration, 'seconds').format('mm:ss')
            }
        },
        {
            field: 'distance',
            renderHeader: () => (
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
                    <Typography >{t('intervals:distance')}</Typography>
                    <Typography variant='caption'>({metric === 'metric' ? t('intervals:meters') : t('intervals:furlongs')})</Typography>
                </Box>
            ),
            flex: 1,
            valueGetter: (val: GridValueGetterParams<Theats>) => {
                return metric === 'metric' ? val.row.distance : formatMetricIntoImperialDistance(val.row.distance, imperial_distance_miles_only)
            }

        },
        {
            field: 'pace',
            renderHeader: () => (
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
                    <Typography >{t('intervals:pace')}</Typography>
                    <Typography variant='caption'>({t(getSpeedUnit()?.key ?? '')})</Typography>
                </Box>
            ),
            flex: 1,
            valueGetter: (val: GridValueGetterParams<Theats>) => {
                return getSpeedForChart(val.row.mean_speed, getSpeedUnit()?.value ?? '')
            }

        },

        {
            field: 'mean_hr',
            renderHeader: () => (
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
                    <Typography >{t('intervals:averageFC')}</Typography>
                    <Typography variant='caption'>({t('intervals:bpm')})</Typography>
                </Box>
            ),
            flex: 1,

        },
        {
            field: 'max_hr',
            renderHeader: () => (
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
                    <Typography >{t('intervals:maxHeartRate')}</Typography>
                    <Typography variant='caption'>({t('intervals:bpm')})</Typography>
                </Box>
            ),

            flex: 1

        },
        {
            field: 'min_hr',
            renderHeader: () => (
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
                    <Typography >{t('intervals:minFC')}</Typography>
                    <Typography variant='caption'>({t('intervals:bpm')})</Typography>
                </Box>
            ),
            flex: 1

        },
        {
            field: 'mean_stride_frequency',
            renderHeader: () => (
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
                    <Typography >{t('intervals:averageCad')}</Typography>
                    <Typography variant='caption'>({t('intervals:stepPerSecond')})</Typography>
                </Box>
            ),
            flex: 1

        },
        {
            field: 'mean_stride_length',
            renderHeader: () => (
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
                    <Typography >{t('intervals:averageAmpl')}</Typography>
                    <Typography variant='caption'>({getMetric()})</Typography>
                </Box>
            ),
            valueGetter: (val: GridValueGetterParams<Theats>) => {
                return convertStrideLength(metric, val.row.mean_stride_length) ?? '-'
            },
            flex: 1

        },
    ];
    const handlePopperOpen = (event: any) => {
        if (event) {
            let id = event.currentTarget?.dataset?.id
            const row = heats && heats.find((r) => r.id === toNumber(id));
            onRowHover(row, 'entered')
        }

    };

    const handlePopperClose = (event: any) => {
        if (event) {

            let id = event.currentTarget?.dataset?.id
            const row = heats && heats.find((r) => r.id === toNumber(id));

            onRowHover(row, 'leave')
        }
    }

    return (
        <I18nDataGrid
            columns={columns}
            rows={heats ?? []}
            getRowClassName={(param: GridRowClassNameParams<Theats>) => param.row.id === hoveredHeat ? 'row-active' : ''}
            componentsProps={{
                row: {
                    onMouseOver: handlePopperOpen,
                    onMouseLeave: handlePopperClose
                }
            }}
        />


    )
}

export default HeatGrid