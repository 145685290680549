import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { t } from 'i18next'
import React from 'react'
import { TuserAccountType } from '../../shared/types/user.type'
import { preselects } from '../../enums/preselects.enum'

function Preselects({ onPreselectChange }: { onPreselectChange: (val: TuserAccountType) => void }) {
    return (
        <FormControl fullWidth size='small'>
            <InputLabel id="demo-simple-select-label">{t('settings:preselectLabel:preselects')}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={t('settings:preselectLabel:preselects')}
                onChange={(e) => onPreselectChange(e.target.value as TuserAccountType)}
            >
                {
                    preselects.map((prs, i) => (
                        <MenuItem key={prs.value} value={prs.value}>{t(prs.title)}</MenuItem>

                    ))
                }

            </Select>
        </FormControl>
    )
}

export default Preselects