import React, { useEffect, useMemo, useState } from 'react'
import { TChannel, TChannelType } from '../../shared/types/Channel.type'
import { Box, Button, Card, Chip, CircularProgress, Collapse, Divider, IconButton, ListItemIcon, Menu, MenuItem, Pagination, Stack, TextField, Typography } from '@mui/material'
import ListSkeleton from '../exams/loader/ListSkeleton'
import ChannelItem from './ChannelItem'
import { ArrowDownward, ArrowUpward, Delete, Edit, Email, MoreVert, PlaylistAdd, Search, Speed, Translate, WhatsApp } from '@mui/icons-material'
import { t } from 'i18next'
import { chunk, get, isEmpty, lowerCase, orderBy, sortBy, uniqueId } from 'lodash'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import I18nDataGrid from '../global/I18nDataGrid'
import ShowMore from './ShowMore'
import { metrics } from '../../enums/register.enums'
import { languages } from '../../enums/languages.enum'
import { useAuth } from '../../hooks/useAuth'

function Channels({ channels, loading, onDelete, isDeleting, onEdit, onDuplicate, createNewChannel, selectable, onSelectionChange, selectedChannels }: { selectedChannels?: string[], onSelectionChange?: (items: string[]) => void, selectable?: boolean, channels: TChannel[], loading: boolean, isDeleting: boolean, onDelete: (id: number, type: TChannelType) => void, onEdit: (item: TChannel) => void, onDuplicate?: (item: TChannel) => void, createNewChannel: () => void }) {
    const [filteredChannels, setFilteredChannels] = useState<TChannel[]>([])
    const [currentChannels, setCurrentChannel] = useState<TChannel[]>([])
    const [page, setPage] = React.useState(1);
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    const { auth, getSpeedUnit, getUserMetric, getUserLang, getUserSpeedUnit } = useAuth()

    useEffect(() => {
        setFilteredChannels(channels)
    }, [channels])

    const filterChannels = (query: string) => {
        let filtered = channels.filter(e => lowerCase(e.name).indexOf(lowerCase(query)) !== -1)
        setFilteredChannels(filtered)
    }


    const paginatedChannels = useMemo(() => {
        return chunk(filteredChannels, 2)
    }, [filteredChannels])


    useEffect(() => {
        if (isEmpty(paginatedChannels)) return
        setCurrentChannel(paginatedChannels[page - 1])
    }, [page, paginatedChannels])

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('channel:name'),
            flex: 1,
            renderCell: (params: GridRenderCellParams<TChannel>) => params.row.own_private_channel ? t('channel:onlyMe') : params.row.name
        },
        {
            field: 'lastName',
            headerName: t('channel:type'),
            flex: 1,
            renderCell: (params: GridRenderCellParams<TChannel>) => <Chip
                sx={{ color: 'white' }}
                icon={params.row['@type'] === 'EmailCommunicationChannel' ? <Email></Email> : <WhatsApp></WhatsApp>}
                color={params.row['@type'] === 'EmailCommunicationChannel' ? 'default' : 'success'}
                label={params.row['@type'] === 'EmailCommunicationChannel' ? t('equimetre:table:email') : 'Whatsapp'}
                size='small'></Chip>
        },
        {
            field: 'targets',
            headerName: t('equimetre:table:email'),
            flex: 3,
            renderCell: (params: GridRenderCellParams<TChannel>) =>
                <ShowMore
                    items={params.row.targets}
                    maxLength={2}
                    renderItem={(item, i) => <Chip sx={{ color: 'white' }} color='info' label={item} size='small'></Chip>}
                ></ShowMore>


        },
        {
            field: 'unit',
            headerName: t('info:unit_system'),
            flex: 1,
            renderCell: (params: GridRenderCellParams<TChannel>) => params.row.own_private_channel ? <Typography variant='caption'>{getUserMetric}</Typography> : params.row?.unit_system ? <Typography variant='caption'>{t(metrics.find(e => e.value === params.row?.unit_system)?.key ?? '')}</Typography> : <></>
        },
        {
            field: 'lang',
            headerName: t('equi:registration:lang:label'),
            flex: 1,
            renderCell: (params: GridRenderCellParams<TChannel>) =>

                (params.row.own_private_channel ? <Chip sx={{ color: 'white' }} icon={<Translate></Translate>} size='small' color='warning' label={t(languages.find(e => e.key === getUserLang)?.value ?? '')}></Chip> : params.row.lang ? <Chip sx={{ color: 'white' }} icon={<Translate></Translate>} size='small' color='warning' label={t(languages.find(e => e.key === params.row?.lang)?.value ?? '')}></Chip> : <></>)

        },
        {
            field: 'speed',
            headerName: t('settings:displayGraphSpeed'),
            flex: 1,
            renderCell: (params: GridRenderCellParams<TChannel>) =>

            (params.row.own_private_channel ? <Chip sx={{ color: 'white' }} icon={<Speed></Speed>} color='info' size='small' label={t(
                getSpeedUnit(
                    String(getUserSpeedUnit), (auth?.unit_system) ?? '')?.key
                ?? '')}></Chip> : params.row.speed_unit ? <Chip sx={{ color: 'white' }} icon={<Speed></Speed>} color='info' size='small' label={t(
                    getSpeedUnit(
                        String(params.row?.speed_unit), (params.row?.unit_system) ?? '')?.key
                    ?? '')}></Chip> : <></>)

        },
        {
            field: 'actions',
            headerName: t('equimetre:table:actions'),
            flex: 1,
            renderCell: (params: GridRenderCellParams<TChannel>) => !params.row.own_private_channel &&
                <Box display={'flex'} justifyContent={'end'} gap={1}>

                    <IconButton onClick={() => onEdit(params.row)} size='small'>
                        <Edit></Edit>
                    </IconButton>
                    <IconButton onClick={() => onDelete(params.row.id, params.row['@type'])} size='small'>
                        <Delete></Delete>
                    </IconButton>
                </Box>



        },
    ];
    if (loading) {
        return <Box display='flex' justifyContent={'center'} p={3}>
            <CircularProgress></CircularProgress>
        </Box>
    }
    return (
        <Box display={'flex'} flexDirection={'column'}>
            <I18nDataGrid
                sx={{ maxHeight: '450px' }}
                columns={columns}
                checkboxSelection={selectable}
                rows={sortBy(channels, channel => channel?.own_private_channel ? -1 : 1)}
                disableRowSelectionOnClick
                pageSizeOptions={[5]}
                loading={loading || isDeleting}
                onRowSelectionModelChange={selection => {
                    typeof onSelectionChange === 'function' && onSelectionChange(selection as string[])
                }}

                rowSelectionModel={selectedChannels}
                getRowId={item => get(item, '@id', uniqueId())}
                components={{
                    NoRowsOverlay: () => (
                        <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                            <Typography variant='caption'>{t('notification:noChannel')}</Typography>
                        </Box>
                    )
                }}
            />
            <Box display={'flex'} justifyContent={'center'} width={'100%'} mt={2}>
                <Button onClick={createNewChannel} startIcon={<PlaylistAdd></PlaylistAdd>} variant='contained' sx={{ width: '40%' }}>{t('button:addChanel')}</Button>
            </Box>
        </Box>
    )
}

export default Channels