import React from 'react'
import Schedules from './Schedules'
import { Box, Button, Divider } from '@mui/material'
import { t } from 'i18next'
import { schedules } from '../../shared/types/schedule.type'
type NewChannelStep4Props = {
    step: number,
    onSave: () => void,
    onBack: () => void,
    schedules: Partial<schedules>[],
    onScheduleChange: ((schedules: Partial<schedules>[]) => void)
}
function NewConfigStep4({ step, onSave, onBack, schedules, onScheduleChange }: NewChannelStep4Props) {
    return (
        <Box>
            <Schedules schedules={schedules} onScheduleChange={onScheduleChange}></Schedules>
            <Divider></Divider>
            <Box display={'flex'} justifyContent={'space-between'} mt={1}>
                <Button onClick={onBack} disabled={step === 0} color='inherit' >{t('button:back')}</Button>
                <Button onClick={onSave} variant='contained'>{t('button:next')}</Button>
            </Box>
        </Box>
    )
}

export default NewConfigStep4