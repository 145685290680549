import { CompareArrows, ControlPointDuplicateRounded, Info } from '@mui/icons-material'
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputAdornment, OutlinedInput, Radio, RadioGroup, TextField, Tooltip, Typography } from '@mui/material'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { t } from 'i18next'
import Input from '@mui/material/Input';
import React, { ChangeEvent, FormEvent, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { TCheckPoint } from '../../shared/types/checkPoint.type';
import { directions } from '../../enums/direction.enum';
import { debounce, get, isEmpty, sortBy, toNumber } from 'lodash';
import { useCheckpoints } from '../../hooks/useCheckpoints';
import { LoadingButton } from '@mui/lab';
import { Ttrack } from '../../shared/types/tracks.type';
import { useMap } from '../../hooks/useMap';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { TFinishLine } from '../../shared/types/finishLine.type';

const GAP = 0.0001
const MAX_CHECKPOINTS = 3
const baseObject: TCheckPoint = {
  name: "",
  averaging_lower_limit: 0,
  averaging_upper_limit: 0,
  direction: 1,
  latitude_a: 0,
  latitude_b: 0,
  longitude_b: 0,
  longitude_a: 0,

}


function CheckPointsForm({ track, checkpoints, finishLine }: { track?: Ttrack | null, checkpoints?: TCheckPoint[], finishLine?: Partial<TFinishLine> | null }) {

  const { checkPoints: data, setCheckpoints: setData, saveCheckpoint, isSaving, updateCheckpoint, isUpdating, deleteCheckPoint, isDeleting } = useCheckpoints()
  const { getCoordinatesThirdPointOfTriangle } = useMap()

  useEffect(() => {
    if (checkpoints) {
      setData(checkpoints)
    }
  }, [checkpoints])

  const onUpdate = (cp: TCheckPoint) => {
    let latlngc = getCoordinatesThirdPointOfTriangle([
      { lat: toNumber(cp.latitude_a), lng: toNumber(cp.longitude_a) },
      { lat: toNumber(cp.latitude_b), lng: toNumber(cp.longitude_b) },
    ], cp.direction).toJSON()


    let item: TCheckPoint = { ...cp, latitude_c: latlngc.lat, longitude_c: latlngc.lng }
    updateCheckpoint(item)
      .then(data => {
        query.refetchQueries(['trackName', track?.id])

      }).catch(err => {
        toast(t('error:generic'), { type: 'error' })

      })



  }

  const deleteCp = (id?: number) => {
    deleteCheckPoint(toNumber(id))
      .then(result => {
        query.refetchQueries(['trackName', track?.id])

      }).catch(err => {
        toast(t('error:generic'), { type: 'error' })

      })
  }

  const canAddNewCp = data.length < MAX_CHECKPOINTS
  const query = useQueryClient()
  const addNewCp = () => {
    if (!canAddNewCp) return
    let newObject: TCheckPoint =
    {
      ...baseObject,
      name: `CheckPoint ${data.length + 1}`,
      track_name: get(track, '@id'),
      latitude_c: 0,
      longitude_c: 0,
      latitude_a: toNumber(finishLine?.latitude_a) + GAP,
      latitude_b: toNumber(finishLine?.latitude_b) + GAP,
      longitude_a: toNumber(finishLine?.longitude_a) + GAP,
      longitude_b: toNumber(finishLine?.longitude_b) + GAP,
    }



    saveCheckpoint(newObject)
      .then(result => {
        query.refetchQueries(['trackName', track?.id])
      }).catch(err => {
        toast(t('error:generic'), { type: 'error' })

      })



  }

  //sort by id
  const sortedData = useMemo(() => {
    if (isEmpty(data)) return []

    return sortBy(data, ['id'])
  }, [data])




  return (
    <Box my={2}>
      <Box display={'flex'} justifyContent={'flex-end'} mb={1}>
        <LoadingButton loading={isSaving} variant='outlined' disabled={!canAddNewCp} onClick={addNewCp}>{t('tracks:addCheckpoint')} ({`${data.length} / ${MAX_CHECKPOINTS} `})</LoadingButton>
      </Box>
      {sortedData.map(cp => (
        <AccCp isDeleting={isDeleting} isUpdating={isUpdating} key={cp.id} onUpdate={onUpdate} deleteCp={deleteCp} cp={cp}></AccCp>
      ))
      }

    </Box >
  )
}

export default CheckPointsForm

const AccCp = memo(({ cp, onUpdate, deleteCp, isUpdating, isDeleting }: { cp: TCheckPoint, onUpdate: (param: TCheckPoint) => void, deleteCp: (id?: number) => void, isUpdating: boolean, isDeleting: boolean }) => {
  const [currentCp, setCurrentCp] = useState<TCheckPoint>(cp)


  useEffect(() => {
    setCurrentCp(cp)
  }, [cp])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {

    let fielName = e.target.name
    setCurrentCp(prev => ({ ...prev, [fielName]: ['name'].includes(fielName) ? e.target.value : toNumber(e.target.value) }))
  }



  const getDistance = useMemo(() => {
    return google.maps.geometry.spherical.computeDistanceBetween({ lat: toNumber(cp ?.latitude_a), lng: toNumber(cp?.longitude_a) }, { lat: toNumber(cp?.latitude_b), lng: toNumber(cp?.longitude_b) })
}, [cp])

  return <Accordion>
    <AccordionSummary
      expandIcon={<GridExpandMoreIcon />}
      aria-controls="panel3-content"
      id="panel3-header"
    >
      {currentCp.name}
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={4}>
        <Grid item md={12} sm={12} xs={12}>
          <TextField name='name' onChange={onChange} defaultValue={currentCp.name} fullWidth label='name' size='small'></TextField>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <OutlinedInput name='averaging_lower_limit' onChange={onChange} defaultValue={currentCp.averaging_lower_limit} fullWidth
            startAdornment={<Tooltip title={t('tracks:averagingValueWarning')}>
              <InputAdornment position="start">
                <Info color='info' />
              </InputAdornment>
            </Tooltip>}
            inputProps={{
              step: 'any',
              'aria-label': 'weight',
            }} type='number' size='small'></OutlinedInput>
          <FormHelperText id="outlined-weight-helper-text">{t('tracks:averagingLowerLimit:imperial')}</FormHelperText>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <OutlinedInput name='averaging_upper_limit' onChange={onChange} defaultValue={currentCp.averaging_upper_limit} fullWidth
            startAdornment={<Tooltip title={t('tracks:averagingValueWarning')}>
              <InputAdornment position="start">
                <Info color='info' />
              </InputAdornment>
            </Tooltip>}
            inputProps={{
              step: 'any',
              'aria-label': 'weight',
            }} type='number' size='small'></OutlinedInput>
          <FormHelperText id="outlined-weight-helper-text">{t('tracks:averagingUpperLimit:imperial')}</FormHelperText>

        </Grid>

        <Grid item md={5} sm={12} xs={12}>
          <Box display={'flex'} alignItems={'center'} gap={3}>

            <TextField name='latitude_a' onChange={onChange} value={currentCp.latitude_a} fullWidth inputProps={{
              step: "any"
            }} type='number' label={t('tracks:latitudeA')} size='small'></TextField>

            <TextField name='longitude_a' onChange={onChange} value={currentCp.longitude_a} fullWidth inputProps={{
              step: "any"
            }} type='number' label={t('tracks:longitudeA')} size='small'></TextField>
          </Box>
        </Grid>
        <Grid item md={2} sm={12} xs={12}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flex={1} flexDirection={'column'}>
            <CompareArrows  sx={{ color: 'gray' }}></CompareArrows>
            <Typography variant='caption' color={'GrayText'}>{getDistance.toFixed(3)}  m</Typography>

          </Box>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>

          <Box display={'flex'} alignItems={'center'} gap={3}>
            <TextField name='latitude_b' onChange={onChange} value={currentCp.latitude_b} fullWidth inputProps={{
              step: "any"
            }} type='number' label={t('tracks:latitudeB')} size='small'></TextField>

            <TextField name='longitude_b' onChange={onChange} value={currentCp.longitude_b} fullWidth inputProps={{
              step: "any"
            }} type='number' label={t('tracks:longitudeB')} size='small'></TextField>
          </Box>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">{t('tracks:checkpointDirectionLabel')}</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(e, value) => {
                setCurrentCp(prev => ({ ...prev, direction: toNumber(value) }))
              }}
            >
              {
                directions.map((dir, i) => (
                  <FormControlLabel key={i} name='direction' checked={currentCp.direction === dir.value} value={dir.value} control={<Radio />} label={t(dir.key)} />

                ))
              }

            </RadioGroup>
          </FormControl>
        </Grid>

      </Grid>
    </AccordionDetails>
    <AccordionActions>
      <LoadingButton loading={isDeleting} onClick={() => deleteCp(currentCp.id)} variant='outlined' color='error' >{t('tracks:deleteThisCheckpoint')}</LoadingButton>
      <LoadingButton loading={isUpdating} onClick={() => onUpdate(currentCp)} variant='contained' color='info' >{t('button:save')}</LoadingButton>
    </AccordionActions>
  </Accordion>;
})
