import { Group, Logout, MoreVert, Person, Settings, Support, SupportAgent } from '@mui/icons-material';
import { Avatar, Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store';
import { logout } from '../../store/reducers/user.reducer';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import { capitalize } from 'lodash';
import SideBarSkeleton from '../user/SideBarSkeleton';
import { toast } from 'react-toastify';
import { routes } from '../../pages/routes';
import { useFileUpload } from '../../hooks/useFileUpload';
import { useQueryClient } from 'react-query';

type menuItem = {
    key: string,
    icon: any,
    fn: () => void
}

function UserPopup() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { auth, isUserFetching, user_avatar_uri } = useAuth()
    const isLoading = useSelector<RootState>(state => state.user.isUserLoading)
    const open = Boolean(anchorEl);
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()
    const { t } = useTranslation()
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const query = useQueryClient()

    //create menu
    const menu: Array<menuItem> = [
        {
            key: 'navbar:profileOptions:profile',
            icon: <Person fontSize="small"></Person>,
            fn: () => navigate(routes.DASHBOARD_ROUTES.PROFILE)
        },
        {
            key: 'navbar:settings',
            icon: <Settings fontSize="small"></Settings>,
            fn: () => navigate(routes.DASHBOARD_ROUTES.SETTINGS)
        },
        {
            key: 'navbar:profileOptions:helpSupport',
            icon: <Support fontSize="small"></Support>,
            fn: () => window.open('https://knowledgebase.equimetre.com/en', '_blank')
        },
        {
            key: 'contact',
            icon: <SupportAgent fontSize="small"></SupportAgent>,
            fn: () => window.open('https://training.arioneo.com/en/contact-the-arioneo-team-heart-rate-monitor-for-racehorses/?__hstc=53838839.2b4406dd09c7110103f76d133cc748fc.1704303036116.1706012719152.1706015968079.5&__hssc=53838839.2.1706015968079&__hsfp=1007729581', '_blank')
        },
        {
            key: 'Referral',
            icon: <Group fontSize="small"></Group>,
            fn: () => window.open('https://share.hsforms.com/13Ovnb8zKSaaHb9JTzZA9IA2ie94?__hstc=53838839.2b4406dd09c7110103f76d133cc748fc.1704303036116.1706012719152.1706015968079.5&__hssc=53838839.2.1706015968079&__hsfp=1007729581', '_blank')
        },
        {
            key: 'navbar:profileOptions:logout',
            icon: <Logout fontSize="small"></Logout>,
            fn: () => {
                dispatch(logout())
                query.removeQueries()
            }
        }
    ]



    //Display loading skeleton
    if (isLoading) {
        return <SideBarSkeleton></SideBarSkeleton>
    }

    return (
        <>
            <Box display={'flex'} alignItems={'center'} gap={1}>
                <Avatar src={user_avatar_uri} sx={{ width: 36, height: 36, cursor: 'pointer' }} onClick={handleClick}></Avatar>
            </Box>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}

            >
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} p={2} gap={1}>
                    <Avatar src={user_avatar_uri} sx={{ width: 50, height: 50}} ></Avatar>
                    <Typography variant='subtitle1'>{auth?.firstname}</Typography>
                    <Typography color='lightgray' variant='subtitle2'>{auth?.email}</Typography>
                </Box>

                <Divider></Divider>

                {
                    menu.map(m => (
                        <MenuItem key={m.key} onClick={() => m.fn()}>
                            <ListItemIcon>
                                {m.icon}
                            </ListItemIcon>
                            <Typography variant='body2'>{t(m.key)}</Typography>
                        </MenuItem>
                    ))
                }
            </Menu>
        </>
    )
}

export default UserPopup