import { LoadingButton } from '@mui/lab'
import { Box, TextField } from '@mui/material'
import { t } from 'i18next'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'

function NewConfig({ name, onNameChange, onSave ,loading}: { name: string, onSave: () => void, onNameChange: (str: string) => void,loading:boolean }) {
    return (
        <Box p={1} display={'flex'} gap={1}>
            <TextField value={name} onChange={e => onNameChange(e.target.value)} size='small' label={t('newConfiguration')}></TextField>
            <LoadingButton loading={loading} onClick={onSave} disabled={isEmpty(name)}>{t('button:save')}</LoadingButton>
        </Box>
    )
}

export default NewConfig