
import { Autocomplete, TextField } from '@mui/material';
import { t } from 'i18next';
import { debounce, isEmpty, uniqueId } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";

type PlaceAutoCompleteProps = {
    onValueChange: (value: string | null) => void,
    defaultValue?: string | null
}

function PlaceAutoComplete({ onValueChange, defaultValue }: PlaceAutoCompleteProps) {

    const [disable, setDisable] = useState(true)

    const {
        ready,
        value,
        suggestions: { status, data, loading },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        callbackName: "YOUR_CALLBACK_NAME",

        requestOptions: {
            types: []
            /* Define search scope here */
        },
        debounce: 300,
    });

    useEffect(() => {
        if (defaultValue) setValue(defaultValue)
    }, [defaultValue])


    const handleInput = (e: any) => {
        // Update the keyword of the input element
        setValue(e.target.value);
    };

    const formattedData = useMemo(() => {
        return data.length > 0 ? data.map(e => e.description) : []
    }, [data])
    return (
        <>
            <Autocomplete
                renderInput={(params) => <TextField onChange={handleInput} defaultValue={value} {...params} label="Adress" />}
                value={value}
                options={formattedData}
                size='small'
                loading={loading}
                
                getOptionLabel={e => e}
                getOptionKey={e => e}
                onChange={(e, value, reason) => {
                    if (reason === 'clear') {
                        setValue('')
                        onValueChange('')
                        clearSuggestions()
                        return
                    }
                    onValueChange(value)
                }}
            ></Autocomplete>
        </>
        // <div ref={ref}>

        //     <input
        //         id='sugInput'
        //         defaultValue={value}
        //         onChange={handleInput}
        //         className='MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-sizeSmall css-md26zr-MuiInputBase-root-MuiOutlinedInput-root'
        //         disabled={!ready}
        //         placeholder="Where are you going?"
        //     />
        //     {/* We can use the "status" to decide whether we should display the dropdown or not */}
        //     {(status === "OK") && <ul>{renderSuggestions()}</ul>}
        // </div>
    )
}

export default PlaceAutoComplete