import { ChevronRight, ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box, Button, Collapse, Divider, Typography } from '@mui/material'
import { t } from 'i18next'
import React, { useState } from 'react'

function ChooseBuild({ createNewConfig }: { createNewConfig: (config: "SIMPLE" | "ADVANCED") => void }) {
    const [open, setOpen] = useState(false)
    const tableOptions = [
        {
            text: t('configuration:tableDifference:date'),
            simple: t('yes'),
            advanced: t('yes')
        },
        {
            text: t('configuration:recipient'),
            simple:t('yes'),
            advanced: t('yes')
        },
        {
            text: t('configuration:tableDifference:speedFilter'),
            simple: t('yes'),
            advanced: t('yes')
        },
        {
            text: t('configuration:tableDifference:trackFilter'),
            simple: '-',
            advanced: t('yes')
        },
        {
            text: t('configuration:tableDifference:horseFilter'),
            simple: '-',
            advanced: t('yes')
        },
        {
            text: t('configuration:tableDifference:typeFilter'),
            simple: '-',
            advanced: t('yes')
        },
    ]
    return (
        <Box >
            <Divider sx={{ my: 2 }}></Divider>
            <Box display={'flex'} gap={1} justifyContent={'end'} alignItems={'center'} width={'100%'}>
                <Button onClick={() => createNewConfig('SIMPLE')} variant='contained'>{t('configuration:simpleBuild')}</Button>
                <Button onClick={() => createNewConfig('ADVANCED')} variant='outlined'>{t('configuration:advancedBuild')}</Button>
            </Box>

            <Typography onClick={() => setOpen(!open)} sx={{ cursor: 'pointer', textDecoration: 'underline', display: 'flex', alignItems: 'center', mt: 5 }} variant='subtitle2'>
                {open ? <ExpandMore></ExpandMore> : <ChevronRight></ChevronRight>}
                {t('configuration:tableDifference')}
                {open ? ` (${t('configuration:hideOptions')})` : ` (${t('configuration:showOptions')})`}
            </Typography>
            <Collapse in={open} sx={{ mt: 2 }}>
                <table style={{ width: '100%', border: '0.5px solid var(--active)', borderRadius: 3, padding: 10 }}>
                    {
                        tableOptions.map(option => (
                            <tr style={{ display: 'flex' }}>
                                <td style={{ flex: 2 }}>
                                    <Typography variant='caption'>
                                        {option.text}
                                    </Typography>
                                </td>
                                <td style={{ flex: 1,textAlign:'center' }}>
                                    <Typography variant='caption'>
                                        {option.simple}
                                    </Typography>
                                </td>
                                <td style={{ flex: 1,textAlign:'center' }}>
                                    <Typography variant='caption'>
                                        {option.advanced}
                                    </Typography>
                                </td>
                            </tr>
                        ))
                    }

                </table>
            </Collapse>
        </Box>
    )
}

export default ChooseBuild