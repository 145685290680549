import { Alert, Box, Container, Divider, LinearProgress } from '@mui/material'
import { DataGrid, GridCellParams, GridColDef, GridToolbar, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import { t } from 'i18next'
import React from 'react'
import { useHorseShoeing } from '../../hooks/useHorseShoeing';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { TUser } from '../../shared/types/user.type';
import DataGridActions from '../../components/global/DataGridActions';
import { setRiderItemToEdit, setRiderModal, setShoeingItemToEdit, setShoeingModal } from '../../store/reducers/modal.reducer';
import { toNumber } from 'lodash';
import I18nDataGrid from '../../components/global/I18nDataGrid';
import { useRiders } from '../../hooks/useRiders';

function Riders() {
  const dispatch = useDispatch()
  const auth = useSelector<RootState>(state => state.user.user) as TUser | null
  const isUserLoading = useSelector<RootState>(state => state.user.isUserLoading) as boolean
  const { riders, isLoading, deleteRider, isDeleting } = useRiders(!isUserLoading, auth?.id)

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'name',
      headerName: t('qualification:rider'),
      flex: 1,
      valueFormatter: (param: GridValueFormatterParams) => {
        return param.value ?? '-'
      }
    },
    {
      field: 'weight',
      headerName: t('parameters:weight') + '(KG)',
      type: 'number',
      flex: 1,
      valueFormatter: (param: GridValueFormatterParams) => {
        return param?.value ?? '-' + ' kg'
      },
    },
    {
      headerName: t('settings:actions'),
      field: '',
      flex: 1,
      align: 'center',
      renderCell: (params: GridCellParams) =>
        <DataGridActions
          deleteTitle='riders:deleteRider'
          deleteSubtitle='riders:deleteRiderMessage'
          onEdit={() => {
            dispatch(setRiderItemToEdit(params.row))
            dispatch(setRiderModal(true))
          }}
          isDeleting={isDeleting}
          onDelete={() => { deleteRider(toNumber(params.id)) }}
        ></DataGridActions>
    }
  ];



  return (
    <Box >
      <Alert severity='info'>
        {t('profileSectionDescription:riders')}
      </Alert>
      <Divider sx={{ my: 3 }}></Divider>
      {(isLoading || isUserLoading) && <LinearProgress></LinearProgress>}
      <I18nDataGrid
        rows={riders}
        loading={(isLoading || isUserLoading)}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
          columns: {
            columnVisibilityModel: {
              id: false
            }
          }
        }}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection={false}
        disableRowSelectionOnClick
      />
    </Box>
  )
}

export default Riders