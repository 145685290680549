import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import BaseModal from './BaseModal'
import { setHorseModal } from '../../store/reducers/modal.reducer'
import { Alert, Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import { t } from 'i18next'
import { get, toNumber } from 'lodash'
import { string, z } from 'zod'
import { useHorses } from '../../hooks/useHorses'
import { LoadingButton } from '@mui/lab'
import { TUser } from '../../shared/types/user.type'
import { toast } from 'react-toastify'
import { THorse } from '../../shared/types/horse.type'
import { useQueryClient } from 'react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { GENDERS } from '../../enums/gender.enums'
import { TBreeds } from '../../shared/types/breed.type'
import { useLocales } from '../../hooks/useLocales'

type HorseFormProps = {
  toggleMicroshipHorse: () => void,
  onCancel: () => void,
  onSaveMicroship: (chipValue: number) => void,
  loading: boolean
}

type AddHorseChipProps = {
  breeds: TBreeds[],
  loading: boolean,
  onCancel: () => void,
  defaultHorse: THorse | null,
  onSave: (data: Partial<THorse>) => void
  onPatch: (id: number, data: Partial<THorse>) => void
}



function AddHorseModal() {
  const open = useSelector<RootState>(state => state.modals.horseModal) as boolean
  let auth = useSelector<RootState>(state => state.user.user) as TUser | null
  const [breeds, setBreeds] = useState<TBreeds[]>([])
  const dispatch = useDispatch()
  const [hasChip, setHasChip] = useState(true)
  const query = useQueryClient()
  const [horseToEdit, setHorseToEdit] = useState<THorse | null>(null)
  const { checkMicroship, saveHorseTrainer, isSavingHorseTrainer, getBreeds, saveHorse, updateHorse } = useHorses(false, auth?.['@id'])
  const onCancel = () => {
    setHorseToEdit(null)
    setHasChip(true)
    dispatch(setHorseModal(false))
  }
  const toggleMicroshipHorse = () => {
    setHasChip(false)
  }



  useEffect(() => {
    if (!open) return
    getBreeds.mutateAsync()
      .then(({ data }) => {
        setBreeds(get(data, 'hydra:member', []))
      }).catch(err => {
        console.log({ err });

      })
  }, [open])

  const saveTrainer = async (data: THorse) => {
    if (auth?.['@id']) {

      try {
        await saveHorseTrainer.mutateAsync({ "horse": data['@id'], "trainer": auth['@id'], "download_ecg_automatically": true })
        toast(t('addHorse:success', { horse: data.name }), { type: 'success' })
        onCancel()
        query.refetchQueries(['horses'])
      } catch (err) {

      }
    }
  }

  const onSave = (chipValue: number) => {
    checkMicroship.mutateAsync(chipValue)
      .then(async ({ data }) => {
        //check if horse has name or no
        if (!data.needs_to_be_named) {
          setHorseToEdit(null)
          await saveTrainer(data)
        } else {
          setHorseToEdit(data)
          setHasChip(false)

        }
      }).catch(err => {
        setHasChip(false)
        setHorseToEdit(null)
      })
  }

  const saveHorseData = (data: Partial<THorse>) => {
    saveHorse.mutateAsync(data)
      .then(result => {
        saveTrainer(result.data)
      }).catch(err => {
        toast(t('error:generic'), { type: 'error' })
      })

  }

  const patchHorse = (id: number, data: Partial<THorse>) => {
    updateHorse.mutateAsync({ id, data })
      .then(result => {
        saveTrainer(result.data)
      }).catch(err => {
        toast(t('error:generic'), { type: 'error' })
      })

  }

  return (
    <BaseModal title='addHorse:addHorse' open={open} handleClose={() => dispatch(setHorseModal(false))}>
      {
        hasChip ?
          <AddHorseWithChip loading={checkMicroship.isLoading || isSavingHorseTrainer} onCancel={onCancel} toggleMicroshipHorse={toggleMicroshipHorse} onSaveMicroship={onSave}></AddHorseWithChip> :
          <AddHorseWithoutChip
            defaultHorse={horseToEdit}
            onSave={saveHorseData}
            onPatch={patchHorse}
            loading={saveHorse.isLoading || updateHorse.isLoading || saveHorseTrainer.isLoading}
            onCancel={onCancel}
            breeds={breeds}></AddHorseWithoutChip>
      }
    </BaseModal>
  )
}

function AddHorseWithChip(props: HorseFormProps) {
  const [err, setErr] = useState(false)
  const [chipValue, setChipValue] = useState("")
  const handleSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value
    if (value.length <= 15) {
      setChipValue(value)
    }
  }
  useEffect(() => {
    if (chipValue) {
      const validateChipSchema = z.string().length(15)

      try {
        validateChipSchema.parse(chipValue);
        setErr(false)
      } catch (err) {
        setErr(true)
      }



    }
  }, [chipValue])


  return (
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <TextField
        error={err}
        helperText={chipValue.length + ('/15')}
        value={chipValue}
        onChange={handleSubmit}
        fullWidth  label={t('addHorse:microchipNumber')} type='number' ></TextField>
      <Button onClick={props.toggleMicroshipHorse} variant='text' sx={{ alignSelf: 'end', }} >{t('addHorse:horseNoMicrochip')}</Button>
      <Box display={'flex'} justifyContent={'end'} gap={1}>
        <LoadingButton loading={props.loading} onClick={props.onCancel} variant='outlined' color='error'>{t('button:cancel')}</LoadingButton>
        <LoadingButton loading={props.loading} disabled={err || chipValue.length === 0} onClick={() => props.onSaveMicroship(toNumber(chipValue))} variant='contained'>{t('button:save')}</LoadingButton>
      </Box>
    </Box>)
}

function AddHorseWithoutChip({ breeds, loading, onCancel, onSave, onPatch, defaultHorse }: AddHorseChipProps) {
  const { getTimePickerLocales } = useLocales()

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<Partial<THorse>>()

  useEffect(() => {
    if (!defaultHorse) return
    setValue('name', defaultHorse?.name)
    setValue('birthday', defaultHorse?.birthday)
    setValue('father_name', defaultHorse?.father_name)
    setValue('father_mother_name', defaultHorse?.father_mother_name)
    setValue('father_mother_name', defaultHorse?.father_mother_name)
    setValue('gender', defaultHorse?.gender)
    setValue('sire', defaultHorse?.sire)
    setValue('breed', get(defaultHorse, 'breed[@id]'))

  }, [defaultHorse])

  const onSubmit: SubmitHandler<Partial<THorse>> = (data) => {
    defaultHorse && defaultHorse.id ? onPatch(defaultHorse.id, data) : onSave(data)
  }



  return (
    <Stack direction={'column'} gap={1} >
      <Alert severity='info'>{t('editMicrochipInfoText')}</Alert>
      {defaultHorse ? <Alert severity='warning'>{t('addHorse:microchipNotFound')}</Alert>
        : <Alert severity='error'>{t('addHorse:warningNoMicrochip')}</Alert>
      }

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction={'column'} gap={2} mt={1}>

          {
            (defaultHorse && defaultHorse?.rfid) && <TextField disabled 
              label={t('addHorse:microchipNumber')}
              defaultValue={defaultHorse?.rfid}></TextField>
          }
          <TextField
            {...register('name', { required: true })} fullWidth  label={t('addHorse:horseName')}></TextField>
          <DatePicker
            localeText={getTimePickerLocales()}
            slotProps={{ textField: { size: 'small', helperText: t('addHorse:birthdateExplanation') } }}
            views={['month', 'year']} label={t('addHorse:birthdate')}
            onChange={(value) => setValue('birthday', String(value))}
          />
          <TextField  {...register('father_name', { required: false })} fullWidth  label={t('horse.damname')}></TextField>
          <TextField  {...register('father_mother_name', { required: false })} fullWidth  label={t('horse.siredamname')}></TextField>
          <TextField  {...register('sire', { required: false })} fullWidth  label={t('addHorse:sireName')}></TextField>
          <FormControl fullWidth >
            <InputLabel id="demo-simple-select-label">{t('gender')}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={t('gender')}
              onChange={e => setValue('gender', String(e.target.value))}
              defaultValue={getValues('gender')}
            >
              {
                GENDERS.map(gender => (
                  <MenuItem key={gender.value} value={gender.value}>{t(gender.key)}</MenuItem>
                ))
              }

            </Select>
          </FormControl>
          <FormControl fullWidth >
            <InputLabel id="demo-simple-select-label">{t('breed:breed')}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={t('breed:breed')}
              onChange={e => setValue('breed', String(e.target.value))}
              defaultValue={getValues('breed')}
            >
              {
                breeds.length > 0 && breeds.map(breed => (
                  breed.in_list && <MenuItem key={breed['@id']} value={breed['@id']}>{t('breed:' + breed.ifce_code)}</MenuItem>
                ))
              }

            </Select>
          </FormControl>
          <Box display={'flex'} justifyContent={'end'} gap={1}>
            <LoadingButton loading={loading} onClick={onCancel} variant='outlined' color='error'>{t('button:cancel')}</LoadingButton>
            <LoadingButton loading={loading} type='submit' variant='contained'>{t('button:save')}</LoadingButton>
          </Box>
        </Stack>
      </form>
    </Stack >)
}
export default AddHorseModal