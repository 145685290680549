import React, { useEffect, useState } from 'react'
import Header from '../../components/dashboard/Header'
import { useAnalyticTable } from '../../hooks/useAnalyticTable'
import { TUser, TuserAccountType } from '../../shared/types/user.type'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { Box, Button, Divider, IconButton, Popover, TextField } from '@mui/material'
import { t } from 'i18next'
import { FilterAlt, GridOn } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { routes } from '../routes'
import Filters from '../../components/analyticTable/Filters'
import { TAnalyticsFilters } from '../../shared/types/analyticsFilters.type'
import ConfigTabs from '../../components/analyticTable/ConfigTabs'
import { TAnalyticView } from '../../shared/types/analyticView.type'
import { debounce, get, isEmpty, isNull } from 'lodash'
import { toast } from 'react-toastify'
import { AnalyticsData } from '../../shared/types/analyticData.type'
import { hands } from '../../enums/trackGroup.enums'
import { harness } from '../../enums/harness.enum'

export type TAnalyticData = {
  track_surfaces: (number | null)[],
  hands: (number | null)[],
  training_types: (number | null)[],
  track_names: (number | null)[],
  track_conditions: (number | null)[],
  horse_shoeings: (number | null)[],
  horses: number[],
  length: number,
  analytics_view: string,
  riders: (number | null)[],
  harnessed_mounteds: (string | null)[],
  horse_ages: (number | null)[],
  min_age: number | null,
  max_age: number | null,
  "is_filtered": false,
  include_horses_out_of_training: boolean,
  "search": {
    "value": string,
    "regex": boolean
  },
  "order": {
    "column": number,
    "dir": 'asc' | 'desc'
  },
  start: number,
  "date_start": string | null,
  "date_end": string | null,
}

function Analytics() {
  const accountType = useSelector<RootState>(state => state.user.user?.type) as TuserAccountType | undefined

  //State
  const [filters, setFilters] = useState<TAnalyticsFilters | undefined>(undefined)
  const [view, setView] = useState<TAnalyticView | undefined>(undefined)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [render, setRender] = useState(false)
  const [analyticData, setAnalyticData] = useState<TAnalyticData>({
    start: 0,
    hands: [],
    horse_ages: [],
    horse_shoeings: [],
    horses: [],
    max_age: null,
    length: 100,
    min_age: null,
    analytics_view: '',
    riders: [],
    track_conditions: [],
    harnessed_mounteds: [],
    track_names: [],
    track_surfaces: [],
    training_types: [],
    is_filtered: false,
    include_horses_out_of_training: false,
    search: {
      regex: false,
      value: ''
    },
    order: {
      column: 0,
      dir: 'desc'
    },
    date_start: null,
    date_end: null,
  })
  const [analyticsData, setAnalyticsData] = useState<AnalyticsData | undefined>(undefined)
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  //Hooks
  const isUserLoading = useSelector<RootState>(state => state.user.isUserLoading) as boolean
  const user = useSelector<RootState>(state => state.user.user) as TUser
  const { AnalyticViews, viewsLoading, getFilters, fetchAnalyticData } = useAnalyticTable(!isUserLoading, user?.['@id'])
  const navigate = useNavigate()

  const isLoading = getFilters.isLoading || viewsLoading

  //Effects
  useEffect(() => {
    getFilters.mutateAsync(user?.id)
      .then((result) => {
        setFilters(result.data)
      }).catch(err => {
      })
  }, [user])

  useEffect(() => {
    if (!view) return
    setAnalyticData(prev => ({
      ...prev,
      horses: view.all_horses ? filters?.horses.map(e => e.id) ?? [] : view?.horses.map(e => e.id) ?? [],
      track_conditions: [...(view.track_conditions ? (filters?.track_conditions.map(e => e.id) ?? []) : get(view, 'track_conditions', []).map(i => i?.id)), ...(view.include_null_track_condition ? [null] : [])],
      training_types: [...(view.training_types ? (filters?.training_types.map(e => e.id) ?? []) : get(view, 'training_types', []).map(i => i?.id)), ...(view.include_null_training_type ? [null] : [])],
      track_surfaces: [...(view.track_surfaces ? (filters?.track_surfaces.map(e => e.id) ?? []) : get(view, 'track_surfaces', []).map(i => i?.id)), ...(view.include_null_track_surface ? [null] : [])],
      track_names: [...(view.track_names ? (filters?.track_names.map(e => e.id) ?? []) : get(view, 'track_names', []).map(i => i?.id)), ...(view.include_null_track_name ? [null] : [])],
      riders: [...(view.riders ? (filters?.riders.map(e => e.id) ?? []) : get(view, 'riders', []).map(i => i?.id)), ...(view.include_null_rider ? [null] : [])],
      horse_ages: [...(view.all_horse_ages ? filters?.horse_ages ?? [] : view?.horse_ages ?? []), ...(view.include_null_horse_age ? [null] : [])],
      hands: [...(view.all_hand_turns ? hands.map(e => e.value) ?? [] : view?.hand_turns ?? []), ...(view.include_null_hand ? [null] : [])],
      ...(accountType === 'TROT' ? { harnessed_mounteds: [...(view.all_harnessed_mounteds ? harness.map(e => e.value) ?? [] : view?.harnessed_mounteds ?? []), ...(view.include_null_harnessed_mounted ? [null] : [])] } : {}),

      include_horses_out_of_training: view.include_horses_out_of_training,
    }))
    setRender(true)
  }, [view, filters])

  useEffect(() => {
    if (!view || !render) return

    fetchAnalyticData.mutateAsync({ id: user.id, data: { ...analyticData, analytics_view: view['@id'] } })
      .then(({ data }) => {
        setAnalyticsData(data)
      }).catch(err => {
        toast(t('error:generic'), { type: 'error' })
      })

    return () => fetchAnalyticData.reset()

  }, [view, analyticData, user, render])


  //Fns
  const editTable = () => {
    navigate(routes.DASHBOARD_ROUTES.MYANALYTICS)
  }
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const debouncedSearch = debounce((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAnalyticData(prev => ({
      ...prev, search: {
        regex: false,
        value: e.target.value
      }
    }))
  }, 500)

  return (
    <>
      <Header text='navbar:analytics'>
        <TextField onChange={(e) => debouncedSearch(e)} disabled={isLoading} size='small' label={t('equimetre:search')}></TextField>
        <Box display={'flex'} justifyContent={'end'} alignItems={'center'} gap={1}>
          <Button disabled={isLoading} startIcon={<GridOn></GridOn>} variant='outlined'>{t('eap:table:buttons:excel')}</Button>
          <Button disabled={isLoading} onClick={handleClick} startIcon={<FilterAlt ></FilterAlt>} variant='outlined'>{t('filters')}</Button>
          <Button onClick={editTable} variant='contained'>{t('editTable')}</Button>

        </Box>
      </Header>
      <Divider sx={{ my: 2 }}></Divider>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ zIndex: 2 }}
      >
        <Filters loading={fetchAnalyticData.isLoading} batchUpdate={arr => setAnalyticData(prev => ({ ...prev, ...arr }))} updateAnalyticData={(items, key) => setAnalyticData(prev => ({ ...prev, [key]: items }))} analyticData={analyticData} currentView={view} filters={filters}></Filters>
      </Popover>
      <Box my={2}></Box>
      <ConfigTabs dataLoading={fetchAnalyticData.isLoading} updateFilter={(key, value) => setAnalyticData(prev => ({ ...prev, [key]: value }))} view={view} analyticsData={analyticsData} loading={isLoading} setView={value => setView(value)} analyticViews={AnalyticViews}></ConfigTabs>
    </>
  )
}

export default Analytics