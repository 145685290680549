import { useMutation, useQuery, useQueryClient } from "react-query"
import { Shoeing } from "../api/shoeing"
import { TShoeing } from "../shared/types/shoeing.type"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { setShoeingModal } from "../store/reducers/modal.reducer"
import { t } from "i18next"
import { useEffect, useState } from "react"
import { get } from 'lodash'
export const useHorseShoeing = (enableQuery: boolean, trainer?: number) => {
    const dispach = useDispatch()
    const queryClient = useQueryClient()
    //Mutations
    const saveShoeingMutation = useMutation((data: Partial<TShoeing>) => Shoeing.save(data))
    const deleteShoeingMutation = useMutation((id: number) => Shoeing.delete(id))
    const updateShoeingMutation = useMutation((data: Partial<TShoeing>) => Shoeing.update(data))

    //End mutations
    const [shoeings, setShoeings] = useState<Array<TShoeing> | []>([])
    const query = useQuery(['shoeing'], () => Shoeing.getShoeing(trainer), {
        refetchOnWindowFocus: false,
        cacheTime: 900000,
        enabled: enableQuery,
        onSuccess: (response) => {
            setShoeings(get(response, 'data.hydra:member', []))
        }
    })

    const saveShoeing = async (data: Partial<TShoeing>) => {
        return saveShoeingMutation.mutateAsync(data)
            .then(({ data }) => {
                dispach(setShoeingModal(false))
                queryClient.refetchQueries(['shoeing'])
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
            })
    }

    const updateShoeing = async (data: Partial<TShoeing>) => {
        return updateShoeingMutation.mutateAsync(data)
            .then(({ data }) => {
                dispach(setShoeingModal(false))
                queryClient.refetchQueries(['shoeing'])
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
            })
    }

    const deleteShoeing = (id: number) => {
        return deleteShoeingMutation.mutateAsync(id)
            .then(({ data }) => {
                dispach(setShoeingModal(false))
                queryClient.refetchQueries(['shoeing'])
                return data
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
                return err
            })

    }
    return {
        saveShoeing,
        updateShoeing,
        deleteShoeing,
        isDeleting: deleteShoeingMutation.isLoading,
        isSaving: saveShoeingMutation.isLoading,
        isUpdating: updateShoeingMutation.isLoading,
        shoeings,
        isLoading: query.isLoading || query.isFetching
    }
}