import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Scale } from '../../pages/trainings/ExamView';
import { CheckPoint, TTrainingSection, Theats } from '../../shared/types/training.type';
import { t } from 'i18next';
import { TrainingSetting } from '../../shared/types/trainingSetting.type';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { isEmpty, toNumber } from 'lodash';
import Graphs from './charts/Graphs';
import { convertSpeed } from '../../shared/utils/conversion';
import { getTime } from '../../shared/utils/MapSection';
import IntervalTraining from './charts/IntervalTraining';
import Sectional from './charts/Sectional';
import CheckpointsSection from './CheckpointsSection';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { Layers } from '@mui/icons-material';
import { scale } from '../../enums/scale.enum';

type TabsProps = {
    scaleOption: Scale
    onChangeScale: (val: Scale) => void,
    distance: string,
    speed: number[]
    bpm: number[],
    rawDistance: number[],
    time: number[],
    strideFrequency: number[],
    strideLength: number[],
    currentIndex: number,
    flIndex?: number | null,
    loading: boolean,
    isFinishLineActive: boolean,
    heats?: Theats[],
    intervals: TTrainingSection[],
    id?: number,
    editable?: boolean,
    checkPoints: CheckPoint[],

}

export default function TabsGraphs({ checkPoints, editable, id, heats, loading, scaleOption, onChangeScale, distance, speed, bpm, rawDistance, time, strideFrequency, strideLength, currentIndex, flIndex, isFinishLineActive, intervals }: TabsProps) {
    //Data
    const [value, setValue] = React.useState('0');
    const [hintValue, setHintValue] = React.useState<string | undefined>("")

    //Hooks
    const userSettings = useSelector<RootState>(state => state.user?.user?.training_settings) as TrainingSetting
    const metric = useSelector<RootState>(state => state.user.user?.unit_system) as "metric" | "imperial" | undefined



    //Fns
    const onExtremesChanged = (value: number) => {
        if (value === 0) {
            if (scaleOption === 'DISTANCE') {
                setHintValue(distance)
            } else {
                let _time = time[time.length - 1] - time[0]
                setHintValue(getTime(_time) + ' min')
            }
            return
        }
        if (scaleOption === 'DISTANCE') {

            setHintValue(convertSpeed(metric, value))
        } else {
            setHintValue(getTime(value) + ' min')
        }
    }

    //

    const tabs = [
        {
            index: '0',
            title: t('forthSection:graph'),
            visible: userSettings.display_section_graphs,
            value: 'GRAPHS',
            component: <Graphs onExtremesChanged={onExtremesChanged} flIndex={flIndex} isFinishLineActive={isFinishLineActive} currentIndex={currentIndex} speed={speed} bpm={bpm} distance={rawDistance} scaleOption={scaleOption} time={time} strideFrequency={strideFrequency} strideLength={strideLength} ></Graphs>

        },
        {
            index: '1',
            title: t('intervalTrainingAnalysis:title'),
            value: 'ITA',
            visible: userSettings.display_section_interval_training_analysis && !isEmpty(heats),
            component: <IntervalTraining flIndex={flIndex} isFinishLineActive={isFinishLineActive} distance={rawDistance} time={time} heats={heats} speed={speed} bpm={bpm} scaleOption={scaleOption}></IntervalTraining>


        },
        {
            index: '2',
            title: t('intervals:title'),
            value: 'SECTIONALS',
            visible: userSettings.display_section_intervals,
            component: <Sectional isFinishLineActive={isFinishLineActive} editable={editable} time={time} currentIndex={currentIndex} intervals={intervals} id={id}></Sectional>

        },
        {
            index: '3',
            title: t('checkpointsCrossings:title'),
            value: 'CP',
            visible: userSettings.display_section_checkpoints && !isEmpty(checkPoints),
            component: <CheckpointsSection
                onChangeScale={(val: Scale) => onChangeScale(val)}
                scaleOption={scaleOption}
                isFinishlineActive={isFinishLineActive}
                flIndex={flIndex}
                checkPoints={checkPoints}
                time={time}
                rawDistance={rawDistance}
                speed={speed}
                bpm={bpm}
            ></CheckpointsSection>

        },
    ]


    const filteredTabs = tabs.filter(e => e.visible)



    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const getTabByIndex = () => {
        return filteredTabs[toNumber(value)]?.component
    }
    const showScale = () => {
        return filteredTabs[toNumber(value)]?.value !== 'CP'
    }


    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList variant='fullWidth' onChange={handleChange}>
                        {
                            filteredTabs.map((tab, i) => (
                                <Tab key={tab.title} label={tab.title} value={String(i)} />

                            ))
                        }
                    </TabList>
                </Box>


                <TabPanel value={value}>
                    <>
                        {showScale() && <Box display={'flex'} justifyContent={'end'}>
                            <Box display={'flex'} flexDirection={'column'} bgcolor={'var(--lightGray)'} borderRadius={3} p={1} mb={1}>
                                <Typography variant='caption' color={'GrayText'} display={'flex'} alignItems={'center'} gap={'3px'}>
                                    <Layers sx={{ width: 14, height: 14 }}></Layers>
                                    {t(!editable ? 'graph:scale' : 'settings:defaultGraphUnitType')}</Typography>
                                <RadioGroup

                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    {scale.map(s => (
                                        <FormControlLabel key={s.value} value={s.value} checked={scaleOption === s.value} control={<Radio onChange={e => {
                                            onChangeScale(e.target.value as Scale)
                                        }} size='small' />} label={<Typography variant='caption' fontWeight={500}>{t(s.key)}</Typography>} />

                                    ))}

                                </RadioGroup>
                            </Box>
                        </Box>}


                        {getTabByIndex()}

                    </>
                </TabPanel>
            </TabContext>
        </Box>
    );
}