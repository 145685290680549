import { Box, Typography } from '@mui/material'
import Lottie from 'lottie-react'
import React from 'react'
import animationEmpty from '../../lottie/animationEmpty.json'
import { t } from 'i18next'
function EmptyState() {
    return (
        <Box mt={5} height={'100%'} width={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
            <Lottie style={{ width: '200px', height: '200px' }} animationData={animationEmpty} loop></Lottie>
            <Typography letterSpacing={1}  variant='h6'>{t('filterTrainings:noResults')}</Typography>
        </Box>
    )
}

export default EmptyState