import { Email, Password } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Card, Checkbox, Divider, FormControlLabel, InputAdornment, TextField, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Link from '@mui/material/Link';

export type loginForm = {
  email: string,
  password: string
}

type loginProps = {
  onRegisterClicked: () => void,
  onForgotPasswordClicked: () => void,
  onSubmitLogin: (params: loginForm) => void,
  isLoading: boolean

}

function LoginForm({ onRegisterClicked, onForgotPasswordClicked, onSubmitLogin, isLoading }: loginProps) {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, touchedFields },
  } = useForm<loginForm>()

  const onSubmit: SubmitHandler<loginForm> = (data) => onSubmitLogin(data)

  return (
    <Box display={'flex'} flexDirection={'column'}  >
      <form onSubmit={handleSubmit(onSubmit)}
        onKeyPress={(e) => {
          if (e.keyCode === 13) {
            e.preventDefault(); // Ensure it is only this code that runs

            alert("Enter was pressed was presses");
          }
        }}
      >
        <Box display={'flex'} flexDirection={'column'} gap={3} >
          {/* register your input into the hook by invoking the "register" function */}
          <TextField   FormHelperTextProps={{ variant: 'standard', error: true }} helperText={errors.email?.message} error={errors.hasOwnProperty('email')} fullWidth label={t('info:email')}  {...register("email",
            {
              required: {
                value: true,
                message: 'Email field is required'
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format"
              }
            })} />

          {/* include validation with required or other standard HTML validation rules */}
          <TextField   FormHelperTextProps={{ variant: 'standard', error: true }} helperText={errors.password?.message} error={errors.hasOwnProperty('password')} fullWidth label={t('security:login:password')} type='password' {...register("password", {
            required: {
              value: true,
              message: 'Password field is required'
            }
          })} />
          {/* errors will return when field validation fails  */}
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
            <FormControlLabel control={<Checkbox  />} label={<Typography variant='body2'>{t('security:login:remember_me')}</Typography>} />
            <Link
              component="a"
              variant="body2"
              onClick={onForgotPasswordClicked}
              sx={{ cursor: 'pointer' }}
            >
              <Typography variant='caption'>
                {t('button:resetPassword')}
              </Typography>
            </Link>
          </Box>

          <LoadingButton loading={isLoading} variant='contained' type="submit"  >{t('security:login:submit')}</LoadingButton>
        </Box>
      </form>
      <Divider sx={{ my: 2, fontSize: 12 }}></Divider>
      <Button onClick={onRegisterClicked} fullWidth variant='outlined' > {t('registration:submit')}</Button >
    </Box >


  )
}

export default LoginForm