import React, { useEffect, useMemo } from 'react'
import { TFinishLine } from '../../shared/types/finishLine.type'
import { TCheckPoint } from '../../shared/types/checkPoint.type'
import { LatLng } from 'use-places-autocomplete'

type MapImageProps = {
    finishLine?: TFinishLine,
    checkPoints?: TCheckPoint[],
    center?: LatLng
}

function MapImage({ finishLine, checkPoints, center }: MapImageProps) {

    const URLofIcon = "https://equimetre-assets.s3.amazonaws.com/build/img/marker-finish-line.png"

    const getFinishLineString = (): string => {
        return `icon:${URLofIcon}|color:0xff0000ff|weight:2|${finishLine?.latitude_a},${finishLine?.longitude_a}|${finishLine?.latitude_b},${finishLine?.longitude_b}`
    }

    const getCheckPointsString = () => {
        let cps = ""
        if (checkPoints && checkPoints?.length > 0) {
            checkPoints.forEach((cp, i) => {
                cps += (`&markers=label:${i + 1}|${cp?.latitude_a},${cp?.longitude_a}|${cp?.latitude_b},${cp?.longitude_b}`)
            })
        }
        return cps
    }

    const link = useMemo(() => {

        let linkParts: string[] = []

        //push the google maps link
        linkParts.push('https://maps.googleapis.com/maps/api/staticmap?')

        //push api key
        linkParts.push(`key=${process.env.REACT_APP_GOOGLE_STATIC_API_KEY}`)

        //zoom
        linkParts.push('zoom=16')

        //Map Type
        linkParts.push('maptype=satellite')

        //image map size
        linkParts.push('size=270x250')

        //center
        //finishLine
        if (finishLine) {
            linkParts.push(`markers=${encodeURI(getFinishLineString())}`)
        } else if (center) {
            linkParts.push(`center=${center?.lat},${center?.lng}`)
        }
        //checkpoints
        linkParts.push(encodeURI(getCheckPointsString()))


        console.log(linkParts.join('&'));


        return linkParts.join('&')

    }, [finishLine, checkPoints, center])



    return (
        <img style={{ borderRadius: 7 }} src={link} alt=''
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "https://placehold.jp/270x250.png";
            }}
        ></img>
    )
}

export default MapImage