import { Grid, Skeleton } from '@mui/material'
import React from 'react'

function TrainingEditSkeleton() {
    return (
        <Grid container spacing={3} p={3}>
            {[...Array(8)].map((e, i) => (
                <Grid key={e} item md={[0, 1].includes(i) ? 6 : 4} sm={[0, 1].includes(i) ? 6 : 4}>
                    <Skeleton height={70}></Skeleton>
                </Grid>
            ))
            }
        </Grid >
    )
}

export default TrainingEditSkeleton