import { LoadingButton } from '@mui/lab'
import { Box, Button, Divider, Skeleton, TextField, Typography } from '@mui/material'
import { t } from 'i18next'
import { isEmpty } from 'lodash'
import React from 'react'

type NewChannelStep1Props = {
    name: string,
    onNameChange: (value: string) => void,
    step: number,
    onSave: () => void,
    onBack: () => void,
    loading: boolean,
    resumePhrase: string,
    isPhraseLoading: boolean
}

function NewConfigStep1({ name, onNameChange, step, onSave, loading, onBack, resumePhrase, isPhraseLoading }: NewChannelStep1Props) {
    return (
        <>
            <Divider sx={{ my: 2 }}></Divider>
            <Box >
                {
                    isPhraseLoading ?
                        <Box>
                            <Skeleton></Skeleton>
                            <Skeleton height={80} sx={{ mt: 3 }}></Skeleton>
                        </Box> : <>
                            <Typography variant='body2'>{resumePhrase}</Typography>
                            <Box mt={4} display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
                                <TextField fullWidth value={name} onChange={e => onNameChange(e.target.value)} label={t('notification:field:config')}></TextField>
                            </Box>
                        </>
                }
            </Box>

            <Divider sx={{ mb: 1, mt: 2 }}></Divider>
            <Box display={'flex'} justifyContent={'space-between'} >
                <Button onClick={onBack} disabled={step === 0} color='inherit' >{t('button:back')}</Button>
                <LoadingButton loading={loading} onClick={onSave} variant='contained' disabled={isEmpty(name) || isPhraseLoading}>{t('button:save')}</LoadingButton>
            </Box>
        </>

    )
}

export default NewConfigStep1