import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Grid, IconButton, Typography } from '@mui/material'
import { GridArrowDownwardIcon } from '@mui/x-data-grid'
import { t } from 'i18next'
import React, { useRef } from 'react'
import { TComptuedKey } from '../../shared/types/exam.type'
import ParamSection from './ParamSection'
import Slider, { Settings, SwipeDirection } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowRight, ChevronLeft, ChevronRight } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../pages/routes'

type KeyParamSectionProps = {
    keyParams?: TComptuedKey[]
}



function KeyParamSection({ keyParams }: KeyParamSectionProps) {
    const navigate = useNavigate()
    const editKeyParam = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        navigate(routes.DASHBOARD_ROUTES.MYTRAININGS)
    }

    return (
        <>
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<GridArrowDownwardIcon />}
                >
                    <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography variant='subtitle2'>{t('keyParameters:title')}</Typography>
                        <Button onClick={editKeyParam} sx={{ mr: 2 }} variant='outlined' color='warning'>{t('settings:editKeyParameters')}</Button>
                    </Box>
                </AccordionSummary>
                <AccordionDetails className="slider-container">
                    <Grid container spacing={4}>
                        {keyParams?.map((key, i) => (
                            <React.Fragment key={i}>
                                <Grid item md={3} sm={6} xs={6}>
                                    <ParamSection key={key['@id']} param={key}></ParamSection>
                                </Grid>
                            </React.Fragment>
                        ))}

                    </Grid>



                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default KeyParamSection