import { useMutation, useQuery } from "react-query"
import { Exams } from "../api/exams"
import { useState } from "react"
import { TtrainingKeyParameter } from "../shared/types/trainingKeyParameter.type"
import { TuserAccountType } from "../shared/types/user.type"
import { useSelector } from "react-redux"
import { RootState } from "../store"
import { get } from "lodash"
import { TcomputedKeyParam, TkeyParamLayout } from "../shared/types/computedKeyParam.type"
import { TsavePreselects, groupByCategory } from "../shared/utils/myTrainings"
import { toast } from "react-toastify"
import { t } from "i18next"

export const useMyTrainings = (trainer?: string) => {
    const accountType = useSelector<RootState>(state => state.user.user?.type) as TuserAccountType
    const [groupedTrainingKeys, setGroupedTrainingKeys] = useState<Record<string, TcomputedKeyParam[]>>({})

    const [trainingKeys, setTrainingKeys] = useState<TtrainingKeyParameter>()

    const getPreselects = useMutation((type: TuserAccountType) => Exams.getPreselects(type))
    const deletePreselects = useMutation(() => Exams.deletePreselects(trainer))
    const savePreselects = useMutation((data: TsavePreselects[]) => Exams.savePreselects(data))
    const getTrainingsKeyParams = useQuery(['keyParams'], () => Exams.getKeyParams(), {
        onSuccess: ({ data }) => {
            setTrainingKeys(data)
            setGroupedTrainingKeys(groupByCategory(data))

        }
    })

    const saveKeyParams = async (data: TsavePreselects[]) => {
        try {
            await deletePreselects.mutateAsync()
            await savePreselects.mutateAsync(data)
            toast(t('layout:updateSuccess'), { type: 'success' })

        } catch (err) {
            toast(t('error:generic'), { type: 'error' })

        }
    }




    return {
        trainingKeys,
        getPreselects,
        groupedTrainingKeys,
        isSavingPreselects: deletePreselects.isLoading || savePreselects.isLoading,
        trainingKeysLoading: getTrainingsKeyParams.isLoading,
        saveKeyParams
    }
}