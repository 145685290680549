import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles';
import { sideBarEnum } from '../../enums/SideBar.enums';
import { useLocation, useNavigate } from 'react-router-dom';
import UserPopup from './UserPopup';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import AnimatedLogo from '../global/AnimatedLogo';

type sideBarProps = {
  open: boolean,
  onClose?: () => void
}
const drawerWidth = 290;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingInline: 8,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',

}));
function SideBar({ open, onClose }: sideBarProps) {
  const theme = useTheme();

  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleLinkClick = (item: any) => {
    if (item.isExternal) {
      window.open(item.path, "_blank")
    } else {
      navigate(item.path)
    }
  }

  const isMdDown = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >

      <Box p={1}>
        <AnimatedLogo></AnimatedLogo>

      </Box>
      <Divider />
      <List sx={{
        height: '100vh'
      }}>
        {sideBarEnum.map(item => (
          <ListItem key={item.key} >

            <ListItemButton
              sx={{
                borderRadius: 1,
                "&.Mui-selected": {
                  backgroundColor: "rgba(155, 20, 26, 0.5)!important",
                }
              }}
              selected={pathname === item.path} onClick={() => handleLinkClick(item)}>
              <ListItemIcon >
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={<Typography fontSize={'14px'}>{t(item.key)}</Typography>} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  )
}

export default SideBar