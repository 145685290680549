import React, { useMemo, useState } from 'react'
import { TSchedule, scheduleType, schedules } from '../../shared/types/schedule.type'
import { Avatar, Box, Button, Chip, Collapse, Divider, IconButton, ListItemIcon, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material'
import { BedroomBaby, CheckCircle, CheckCircleOutlineOutlined, ContactMail, ContentCopy, Delete, DisplaySettings, Edit, Email, EventRepeat, FitnessCenter, Looks3, MilitaryTech, MoreHoriz, MoreVert, Route, Schedule, Send, UnfoldLess, UnfoldMore, Visibility, VisibilityOff, WhatsApp } from '@mui/icons-material'
import { t } from 'i18next'
import { weekDays } from '../../enums/days.enums'
import { useNotificationsSettings } from '../../hooks/useNotificationSettings'
import { toast } from 'react-toastify'
import { useQueryClient } from 'react-query'
import { TChannel } from '../../shared/types/Channel.type'
import { flatten, get, groupBy, isEmpty, isNumber, sortBy, upperCase } from 'lodash'
import ShowMore from './ShowMore'

function ConfigItem({ config, children, onDelete, onEdit, isDeleting, onDuplicate }: { config: TSchedule, children?: React.ReactNode, isDeleting?: boolean, onDelete?: (id: number) => void, onEdit?: (item: TSchedule) => void, onDuplicate?: (item: TSchedule) => void, }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openConfig, setOpenConfig] = useState(false)
    const [openRecipient, setOpenRecipient] = useState(false)

    const channels = get(config, 'communication_channels', []) as TChannel[]

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const horseNames = useMemo(() => {
        if (!config?.horses) return []
        return config.horses.map(e => get(e, 'name', ''))
    }, [config.horses])

    const tracks = useMemo(() => {
        if (!config?.track_names) return []

        return config.track_names.map(e => get(e, 'display_name', ''))
    }, [config.track_names])


    const trainings = useMemo(() => {
        if (!config?.training_types) return []

        return config.training_types.map(e => get(e, 'display_name', ''))
    }, [config.training_types])

    const getResume = () => {
        let schedules = config.schedules

        if (isEmpty(schedules)) return ''

        let schedule_type = schedules[0].type as scheduleType

        if (schedule_type === 'SYNCHRONISED_LAST_HOUR') {
            if (schedules.length === weekDays.length) {
                return t('pdfReports:emails:scheduleDescriptionSynchronisedLastHour')
            } else {

                let days: number[] = sortBy(schedules.map(e => e.day_of_week)) as number[]

                return t('pdfReports:emails:scheduleDescriptionSynchronisedLastHourEveryday', {
                    0: getDaysStr(days)
                })

            }
        } else {
            let groupedBytime = groupBy(schedules, i => String(i.hour) + String(i.minute))

            let values = Object.values(groupedBytime)

            if (isEmpty(values)) return ''

            //Get all with 7 days

            let WithAllWeek = values.filter(e => e.length === 7)
            let notAllWeek = values.filter(e => e.length !== 7)

            if (!isEmpty(WithAllWeek)) {

                return t('pdfReports:emails:scheduleDescriptionFixedTimeEveryday', {
                    1: getTime(WithAllWeek).concat(!isEmpty(notAllWeek) ? ' , ' : '').concat(getOtherTime(notAllWeek))
                })

            } else {
                if (isEmpty(notAllWeek)) return ""

                let firstEl = notAllWeek[0]
                let days: number[] = sortBy(firstEl.map(e => e.day_of_week)) as number[]

                let slicedNotAllWeek = notAllWeek.slice(1, notAllWeek.length - 1)

                return t('pdfReports:emails:scheduleDescriptionFixedTime', {
                    0: getDaysStr(days),
                    1: getTime([firstEl]).concat(!isEmpty(slicedNotAllWeek) ? ' , ' : '').concat(getOtherTime(slicedNotAllWeek))
                })
            }



        }

    }

    const getOtherTime = (notAllWeek: Partial<schedules>[][]) => {
        if (isEmpty(notAllWeek)) return ""

        let str: string[] = []

        notAllWeek.forEach(i => {
            let days: number[] = sortBy(i.map(e => e.day_of_week)) as number[]

            const daysStr = getDaysStr(days)

            const timeStr = getTime([i])

            let concated = daysStr.concat(' ').concat(t('at')).concat(' ').concat(timeStr)

            str.push(concated)




        })

        return str.join(' , ')
    }

    const getTime = (dates: Partial<schedules>[][]) => {
        let time: string[] = []

        dates.forEach(date => {

            let minutes = date[0].minute == 0 ? '00' : String(date[0].minute)

            time.push(String(date[0].hour).concat(':').concat(minutes))
        })

        return time.join(' , ')
    }

    const getDaysStr = (days: number[]) => {

        let str = ''
        if (days.length === 1) {
            str = t(weekDays.find(e => e.value === days[0])?.key ?? "")
        } else {
            let useWithComma = [...days].slice(0, days.length - 1).map((i) => {
                let day = weekDays.find(e => e.value === i)
                return day ? t(day.key) : ''
            })

            str = useWithComma.join(' , ')

            str += ' ' + t('and') + ' ' + t(weekDays.find(e => e.value === days[days.length - 1])?.key ?? "")
        }

        return str

    }

    const getConfigRecap = () => {
        let str: string[] = []

        str.push(config.horses.length === 0 ? t('configuration:allHorses') : t('configuration:someHorses'))
        str.push(config.track_names.length === 0 ? t('configuration:allTracks') : t('configuration:someTracks'))
        str.push(config.training_types.length === 0 ? t('configuration:allTypes') : t('configuration:someTypes'))

        return ` (${str.join(' - ')})`

    }

    const getTruncateStr = (str: string) => {
        try {

            let firstChar = str.charAt(0);
            let lastChar = str.charAt(str.length - 1);

            return upperCase(firstChar).concat(upperCase(lastChar))
        } catch (error) {
            return str
        }

    }

    const getChannelsRecap = () => {
        if (isEmpty(channels)) return <></>
        const allTargets = flatten(channels.map(e => e.targets))

        return (
            <Box ml={1} display='flex' gap={1}>
                <span>(</span>
                {(channels.length > 2 ? channels.slice(0, 2) : channels).map(c => (
                    c.targets.slice(0, 2).map(target => (
                        <Chip size='small' avatar={<Tooltip title={c.name} arrow><Avatar>{getTruncateStr(c.name)}</Avatar></Tooltip>} label={target}></Chip>
                    ))
                ))}
                {allTargets.length > 4 && <ShowMore items={allTargets} maxLength={0} renderItem={(item, i) => (
                    <Typography key={i} variant='caption' fontWeight={400} color={'GrayText'}>{`${item} ${tracks.length - 1 !== i ? " - " : ''}`}</Typography>
                )}></ShowMore>}
                <span>)</span>

            </Box>
        )


    }

    return (
        <Box sx={{ border: "1px solid var(--active)", borderRadius: 2 }}>
            <Box display={'flex'} alignItems={'center'} px={3} py={2} justifyContent={'space-between'} flexWrap={'wrap'}>

                <Typography variant='subtitle1' display={'flex'} alignItems={'center'} fontWeight={500}>{config.name}</Typography>


                <div>
                    <Button
                        size='small'
                        variant='outlined'
                        id="basic-button"
                        disabled={isDeleting}
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <MoreHoriz></MoreHoriz>
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={() => {
                            typeof onDuplicate === 'function' && onDuplicate(config)
                            handleClose()
                        }}>

                            <Typography variant='caption'> {t('tracks:duplicate')}</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            typeof onEdit === 'function' && onEdit(config)
                            handleClose()
                        }}>

                            <Typography variant='caption'> {t('button:edit')}</Typography>

                        </MenuItem>
                        <MenuItem onClick={() => {
                            typeof onDelete === 'function' && onDelete(config.id)
                            handleClose()
                        }}>
                            <Typography variant='caption'> {t('button:delete')}</Typography>
                        </MenuItem>
                    </Menu>
                </div>
            </Box>
            <Divider ></Divider>
            <Box sx={{ px: 3, py: 2, flex: 1, width: '100%' }} display={'flex'} flexDirection={'column'} alignItems={'self-start'}>
                <Button variant='text' size='small' onClick={() => setOpenConfig(!openConfig)} startIcon={openConfig ? <VisibilityOff></VisibilityOff> : <Visibility></Visibility>} sx={{ mt: 1, fontSize: '14px',color:'white' }}>{t('configuration')} {getConfigRecap()}</Button>
                <Collapse in={openConfig} sx={{ width: '100%', px: 3 }}>
                    <Stack direction={'column'} spacing={1} mt={1}>


                        <Typography variant='caption' fontWeight={500} display={'flex'} flexWrap={'wrap'} alignItems={'center'} justifyContent={'space-between'}>
                            {t('horses')}
                            {
                                isEmpty(horseNames) ?
                                    <Typography variant='caption' fontWeight={400} color={'GrayText'}>{t('configuration:allHorses')}</Typography>
                                    : <ShowMore items={horseNames} maxLength={3} renderItem={(item, i) => (
                                        <Typography key={i} variant='caption' fontWeight={400} color={'GrayText'}>{`${item} ${horseNames.length - 1 !== i ? " - " : ''}`}</Typography>
                                    )}></ShowMore>
                            }
                        </Typography>
                        <Typography variant='caption' fontWeight={500} display={'flex'} flexWrap={'wrap'} alignItems={'center'} justifyContent={'space-between'}>
                            {t('editTrainingForm:trackName:label')}
                            {
                                isEmpty(tracks) ?
                                    <Typography variant='caption' fontWeight={400} color={'GrayText'}>{t('configuration:allTracks')}</Typography>
                                    : <ShowMore items={tracks} maxLength={3} renderItem={(item, i) => (
                                        <Typography key={i} variant='caption' fontWeight={400} color={'GrayText'}>{`${item} ${tracks.length - 1 !== i ? " - " : ''}`}</Typography>
                                    )}></ShowMore>
                            }

                        </Typography>
                        <Typography variant='caption' fontWeight={500} display={'flex'} flexWrap={'wrap'} alignItems={'center'} justifyContent={'space-between'}>
                            {t('editTrainingForm:trainingType:label')}
                            {
                                isEmpty(trainings) ?
                                    <Typography variant='caption' fontWeight={400} color={'GrayText'}>{t('configuration:allTypes')}</Typography>
                                    : <ShowMore items={trainings} maxLength={3} renderItem={(item, i) => (
                                        <Typography key={i} variant='caption' fontWeight={400} color={'GrayText'}>{`${item} ${trainings.length - 1 !== i ? " - " : ''}`}</Typography>
                                    )}></ShowMore>
                            }</Typography>

                    </Stack>

                </Collapse>
                <Button sx={{color:'white'}} variant='text' startIcon={<EventRepeat></EventRepeat>}>{t('configuration:triggers')}  {!isEmpty(getResume()) && `(${getResume()})`}</Button>
                <Button sx={{color:'white'}} onClick={() => setOpenRecipient(!openRecipient)} variant='text' startIcon={<Send></Send>}>{t('configuration:recipient')} {getChannelsRecap()}</Button>
                <Collapse in={openRecipient} sx={{ width: '100%', px: 3 }}>
                    {
                        !isEmpty(channels) && <>
                            <Divider></Divider>
                            <Box width={'100%'} mt={1}>
                                {channels.map((channel, i) => (
                                    <>
                                        <Box display={'flex'} justifyContent={'space-between'} my={1}>
                                            <Typography fontWeight={500} variant='caption' display={'flex'} alignItems={'center'} gap={1}>
                                                {channel['@type'] === "EmailCommunicationChannel" ? <Email fontSize='small'></Email> : <WhatsApp fontSize='small'></WhatsApp>} {channel?.name ?? '-'}</Typography>
                                            <ShowMore
                                                items={channel.targets}
                                                maxLength={3}
                                                renderItem={(item, i) => (
                                                    <Chip sx={{ color: 'white' }} color='info' key={i} size='small' label={item}></Chip>
                                                )}
                                            ></ShowMore>
                                        </Box>
                                        {
                                            channels.length - 1 !== i && <Divider></Divider>
                                        }

                                    </>
                                ))}
                            </Box>
                        </>
                    }

                </Collapse>
            </Box>
        </Box>

    )
}

export default ConfigItem