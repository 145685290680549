import React from 'react'
import Navbar from '../../components/layout/navbar'
import { Container } from '@mui/material'
import { Outlet } from 'react-router-dom'

function Dashboard() {
    return (
        <React.Fragment>
            <Navbar>
                    <Outlet></Outlet>
            </Navbar>

        </React.Fragment>
    )
}

export default Dashboard