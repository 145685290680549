import { Dispatch, SetStateAction, useState } from "react"
import { TCheckPoint } from "../shared/types/checkPoint.type"
import { useMutation } from "react-query"
import { Checkpoint } from "../api/checkpoints"
type TFnReturn = {
    checkPoints: TCheckPoint[],
    setCheckpoints: Dispatch<SetStateAction<TCheckPoint[]>>
    saveCheckpoint: (data: TCheckPoint) => Promise<TCheckPoint>,
    updateCheckpoint: (data: TCheckPoint) => Promise<TCheckPoint>,
    deleteCheckPoint: (id: number) => Promise<any>,
    isSaving: boolean,
    isUpdating: boolean,
    isDeleting: boolean,
}

export const useCheckpoints = (): TFnReturn => {
    const [checkPoints, setCheckpoints] = useState<TCheckPoint[]>([])


    //mutations
    const saveMutation = useMutation((data: TCheckPoint) => Checkpoint.save(data))
    const updateMutation = useMutation((data: TCheckPoint) => Checkpoint.update(data))
    const deleteMutation = useMutation((id: number) => Checkpoint.delete(id))

    const saveCheckpoint = async (data: TCheckPoint) => {
        return saveMutation.mutateAsync(data)
            .then(result => {
                setCheckpoints(prev => [...prev, result.data])
                return result
            }).catch(err => {
                return err
            })
    }

    const updateCheckpoint = async (data: TCheckPoint) => {
        return updateMutation.mutateAsync(data).then(result => {
            return result.data
        }).catch(err => {
            return err
        })
    }
    const deleteCheckPoint = async (id: number) => {
        return deleteMutation.mutateAsync(id)
            .then(result => {
                return result
            }).catch(err => {
                return err
            })
    }

    return {
        saveCheckpoint,
        updateCheckpoint,
        deleteCheckPoint,
        checkPoints,
        setCheckpoints,
        isSaving: saveMutation.isLoading,
        isUpdating: updateMutation.isLoading,
        isDeleting: deleteMutation.isLoading,
    }
}