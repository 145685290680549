import { Email, Send } from '@mui/icons-material'
import { Box, Button, Container, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../routes'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../hooks/useAuth'
import { LoadingButton } from '@mui/lab'
import Errors from '../../components/global/Errors'
import { ApiError } from '../../shared/types/apiError.type'

function ForgotPassword() {
  const navigation = useNavigate()
  const { t } = useTranslation()
  const { requestResetPassword, isLoading, isError, error } = useAuth()
  const [email, setEmail] = useState('')
  const err = error?.response?.data as ApiError
  return (
    <form onSubmit={(e) => {
      e.preventDefault()
      requestResetPassword(email)
    }}>

      <Container maxWidth='xs' sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start', height: '100vh', gap: 3 }}>
        <div>
          <Typography fontWeight={500} variant='h4' fontSize={'18px'}>{t('resetting:request:submit')}</Typography>
        </div>

        <Errors isError={isError} error={err}></Errors>

        <TextField onChange={e => setEmail(e.target.value)} value={email} required type='email' variant='standard' fullWidth label={t('info:email')} placeholder='john@gmail.com'></TextField>

        <Box display={'flex'} justifyContent={'space-between'} flexDirection={'column'} width={'100%'}>
          <LoadingButton loading={isLoading} type='submit' fullWidth startIcon={<Send></Send>} variant='contained'   >{t('button:send')}</LoadingButton>
          <Button variant='text' size='small' sx={{ mt: 2 }} onClick={() => navigation(routes.AUTH_ROUTES.LOGIN)}>{t('registration:back')}</Button>
        </Box>


      </Container>
    </form>

  )
}

export default ForgotPassword