import { useMutation, useQuery } from "react-query"
import { Exams } from "../api/exams"
import { useSelector } from "react-redux"
import { RootState } from "../store"
import { TUser } from "../shared/types/user.type"
import { useState } from "react"
import { TExam } from "../shared/types/exam.type"
import { get, isNull, isNumber, toNumber } from "lodash"
import { toast } from "react-toastify"
import { t } from "i18next"
import { Ttraining } from "../shared/types/training.type"
import { TQualifyChoice } from "../shared/types/qualifyChoices.type"
import { TTrainerHorse } from "../shared/types/trainerHorse.type"
import { User } from "../api/user"

export const useExam = (enableQuery: boolean = false, trainer?: string | null, examId?: number | null, horse?: string, examsQueryParams?: string[]) => {
    const [exams, setExams] = useState<Ttraining[]>([])
    const [examsLength, setExamsLength] = useState(0)
    const [exam, setExam] = useState<TExam>()
    const [horseExams, setHorseExams] = useState<Ttraining[]>()
    //choices Mutations
    const getTrainingChoicesMutation = useMutation((id: number) => Exams.getChoices(id))
    const deleteExamMutation = useMutation((id: number) => Exams.deleteTraining(id))
    const editTrainingMutation = useMutation((data: { id: number, data: TQualifyChoice }) => Exams.editTraining(data.id, data.data))
    const editTrainingsMutation = useMutation((data: any) => Exams.editTrainings(data))
    const getQualifyChoices = useMutation((id?: number) => User.getQualifyChoices(id))
    //get Exam Mutation
    const getHorseExams = useMutation(() => Exams.getExams(trainer, horse), {
        onSuccess: ({ data }) => {
            setHorseExams(get(data, 'hydra:member', []))
        },
        onError: () => { }
    })

    //comparaison Mutation
    const compareMutation = useMutation((ids: string) => Exams.getTrainingsKeyParams(ids, trainer))

    const query = useQuery(['exams'], () => Exams.getExams(trainer, undefined, examsQueryParams), {
        enabled: enableQuery,
        refetchOnWindowFocus: false,
        cacheTime: 900000,
        onSuccess: ({ data }) => {
            setExams(get(data, 'hydra:member', []))
            setExamsLength(get(data, 'hydra:totalItems', 0))

        }
    })

    const examQuery = useQuery(['exam', toNumber(examId)], () => Exams.getExam(toNumber(examId)), {
        enabled: isNumber(examId),
        refetchOnWindowFocus: false,
        cacheTime: 900000,
        onSuccess: ({ data }) => {
            setExam(data)

        }
    })

    const getChoices = async (id: number) => {
        return getTrainingChoicesMutation.mutateAsync(id)
            .then((result) => {
                return result.data
            }).catch(err => {
                return err

            })
    }



    return {
        exams,
        exam,
        horseExams,
        examError: examQuery.isError,
        getChoices,
        compare: compareMutation,
        deleteExam: deleteExamMutation,
        editTrainingMutation,
        getHorseExams,
        isItemToEditLoading: getTrainingChoicesMutation.isLoading,
        isItemEditing: editTrainingMutation.isLoading,
        isExamLoading: examQuery.isLoading || examQuery.isFetching,
        isExamsLoading: query.isLoading || query.isFetching,
        getQualifyChoices,
        editTrainings: editTrainingsMutation,
        examsLength
    }
}