import React from 'react'
import { TTrainerHorse } from '../../shared/types/trainerHorse.type'
import { Avatar, Box, Card, Chip, Switch, Typography } from '@mui/material'
import { t } from 'i18next'
import dayjs from 'dayjs'
import { Warning } from '@mui/icons-material'

function HorseCard({ horse, onChange }: { horse: TTrainerHorse, onChange: (id: number, checked: boolean) => void }) {

    const getYear = (date?: string): string | number => {
        if (!date) return ' - '
        return dayjs(date).year()
    }

    return (
        <Card sx={{ p: 2, borderRadius: 3, position: 'relative', opacity: !horse.horse_in_training ? 0.6 : 1 }} elevation={2}>

            <Box display={'flex'} gap={2}>
                <Avatar sx={{ width: 62, height: 62 }} src={horse.horse.avatar_url}></Avatar>
                <Box display={'flex'} flexDirection={'column'} flex={1} justifyContent={'space-between'}>
                    <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
                        <Typography variant='subtitle1' fontWeight={500}>{horse?.horse?.name ?? '-'}</Typography>
                        {horse.trainer && <Switch onChange={(e) => onChange(horse.id, e.target.checked)} size='small' sx={{ zIndex: 2 }} defaultChecked={horse.horse_in_training}></Switch>}

                    </Box>
                    <Box display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'} alignItems={'center'}>
                        <Box display={'flex'} gap={2} flexWrap={'wrap'}>
                            <Typography sx={{ display: 'flex', alignItems: 'center' }} fontWeight={500} variant='caption'>{t('gender')} : <Typography variant='caption'> {horse?.horse?.gender ? t(`genders:byKey:${horse.horse?.gender}`) : '-'}</Typography></Typography>
                            <Typography sx={{ display: 'flex', alignItems: 'center' }} fontWeight={500} variant='caption'>{t('year')} : <Typography variant='caption'> {getYear(horse?.horse?.birthday)}</Typography></Typography>
                            <Typography sx={{ display: 'flex', alignItems: 'center' }} fontWeight={500} variant='caption'>{t('breed:breed')} : <Typography variant='caption'> {typeof horse?.horse?.breed !== 'string' && horse?.horse?.breed?.ifce_code ? t(`breed:${horse.horse?.breed.ifce_code}`) : '-'}</Typography></Typography>
                        </Box>
                        {!horse.horse_in_training && <Chip color='warning' variant='outlined' label={t('trainingStatus:0')} size='small'></Chip>}
                    </Box>
                </Box>
            </Box>
        </Card>
    )
}

export default HorseCard