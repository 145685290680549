import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import { Outlet } from 'react-router-dom';
import SideBar from './SideBar';
import UserPopup from './UserPopup';
import { Segment } from '@mui/icons-material';
import { CircularProgress, Container } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    width: `calc(100% - ${open ? 280 : 0}px)`,
    padding: theme.spacing(3),
    overflow: 'hidden',
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${280}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function Navbar({ children }: { children: React.ReactNode }) {
    const [open, setOpen] = React.useState(true);
    const isLoading = useSelector<RootState>(state => state.user.isUserLoading) as boolean
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex', position: 'relative' }}>
            <AppBar position="fixed" sx={{ backgroundColor: 'transparent', boxShadow: 0 }} open={open} className='glass'>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setOpen(!open)}
                        edge="start"
                        sx={{ mr: 2, ml: open ? 5 : 0 }}
                    >
                        {!open ? <MenuIcon /> : <Segment></Segment>}
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        
                    </Typography>

                    <UserPopup></UserPopup>
                </Toolbar>
            </AppBar>
            <SideBar open={open} onClose={handleDrawerClose}></SideBar>
            <Main open={open} sx={{ mt: 8, maxWidth: `calc(100vw-290px) !important`, position: 'relative' }}>
                {/* render screens here */}
                {
                    isLoading ?
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'80vh'}>
                            <CircularProgress></CircularProgress>
                        </Box>
                        :
                        children

                }

            </Main>
        </Box>
    );
}
