import { AxiosResponse } from "axios";
import axiosInstance from "./base";
import { TExam, TKeyParamLayout } from "../shared/types/exam.type";
import { TTrainingType, Ttraining } from "../shared/types/training.type";
import { TQualifyChoice } from "../shared/types/qualifyChoices.type";
import { TtrainingKeyParameter } from "../shared/types/trainingKeyParameter.type";
import { TuserAccountType } from "../shared/types/user.type";
import { TsavePreselects } from "../shared/utils/myTrainings";
import { isEmpty } from "lodash";

export class Exams {


    static getExams(trainer?: string | null, horse?: string, examsQueryParams?: string[], examPage = 1): Promise<AxiosResponse<Array<Ttraining>>> {
        return axiosInstance.get(`trainings?horseTrainer.trainer=${trainer}`
            .concat(horse ? `&horseTrainer.horse=${horse}` : "")
            .concat('&additional-groups=training:insight')
            .concat((examsQueryParams && !isEmpty(examsQueryParams)) ? examsQueryParams.join('&') : '')
            .concat(`&page=${examPage}`)
        )
    }

    static getExam(exam: number): Promise<AxiosResponse<TExam>> {
        return axiosInstance.get(`trainings/${exam}/show`)
    }
    static getChoices(id: number): Promise<AxiosResponse<any>> {
        return axiosInstance.get(`trainings/${id}/qualify_choices`)
    }
    static deleteTraining(id: number): Promise<AxiosResponse<any>> {
        return axiosInstance.delete(`trainings/${id}`)
    }


    static editTraining(id: number, data: TQualifyChoice): Promise<AxiosResponse<any>> {
        return axiosInstance.patch(`trainings/${id}/qualify`, data, {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            }
        })
    }


    static changeInterval(interval: number, id?: number) {

        return axiosInstance.get(`trainings/${id}/intervals/${interval}`)
    }

    static getTrainingsKeyParams(ids: string, trainer?: string | null): Promise<AxiosResponse<TtrainingKeyParameter>> {
        return axiosInstance.get(`training-key-parameters?add-unit=true`
            .concat(ids ? `&${ids}` : '')
            .concat(trainer ? `&user=${trainer}` : '')
        )

    }

    static getKeyParams(): Promise<AxiosResponse<TtrainingKeyParameter>> {
        return axiosInstance.get(`training-key-parameters?training=/api/training/96836&all_key_parameters=true`)
    }

    static getPreselects(type: TuserAccountType): Promise<AxiosResponse<TKeyParamLayout[]>> {
        return axiosInstance.get(`key_parameters_layouts?default_for_user_type=${type}`)
    }

    static deletePreselects(trainer?: string) {
        return axiosInstance.delete(`key_parameters_layouts/batch_delete?trainer=${trainer}`)
    }

    static savePreselects(data: TsavePreselects[]) {
        return axiosInstance.post('key_parameters_layouts/batch_upload', { key_parameters_layouts: data })
    }

    static getPostTraining(id?: number) {
        return axiosInstance.get(`trainings/${id}/post_process`)
    }

    static editTrainings(data: any): Promise<AxiosResponse<any>> {
        return axiosInstance.patch(`trainings/qualify_multiple`, data, {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            }
        })
    }

}