import { t } from 'i18next'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { TrainingSetting } from '../../shared/types/trainingSetting.type'
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, List, ListItem, ListItemText, Popover, Typography } from '@mui/material'
import { useAuth } from '../../hooks/useAuth'
import { LoadingButton } from '@mui/lab'
import { DisplaySettings, Visibility } from '@mui/icons-material'

function CheckPointsGraphDisplaySettings() {
    const settings = useSelector<RootState>(state => state.user.user?.training_settings) as TrainingSetting
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    let settingsId = useSelector<RootState>(state => state.user.user?.training_settings?.id) as number
    const { updateUserTraingSetting, settingsUpdating } = useAuth()

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const selectOptions = [
        {
            title: t('intervals:pace'),
            value: settings.display_speed_checkpoints_graph,
            checkBoxStr: 'display_speed_checkpoints_graph'
        },
        {
            title: t('intervals:averageFC'),
            value: settings.display_heart_rate_checkpoints_graph,
            checkBoxStr: 'display_heart_rate_checkpoints_graph'
        },
        {
            title: t('intervals:averageAmpl'),
            value: settings.display_stride_length_checkpoints_graph,
            checkBoxStr: 'display_stride_length_checkpoints_graph'
        },
        {
            title: t('intervals:averageCad'),
            value: settings.display_stride_frequency_checkpoints_graph,
            checkBoxStr: 'display_stride_frequency_checkpoints_graph'
        },
    ]

    return (
        <Box display={'flex'} flexDirection={'column'} bgcolor={'var(--lightGray)'} borderRadius={3} p={1}>
            <Typography variant='caption' color={'GrayText'} display={'flex'} alignItems={'center'} gap={'3px'}>
                <DisplaySettings sx={{ width: 14, height: 14 }}></DisplaySettings>
                {t('checkpointsCrossings:displayParametersInGraph')} {settingsUpdating && <CircularProgress sx={{ ml: 1 }} size={12}></CircularProgress>}</Typography>
            <Box display={'flex'} alignItems={'center'} gap={1}>
                <FormGroup row>
                    {
                        selectOptions.map((setting, i) => (
                            <FormControlLabel
                                key={i}
                                control={
                                    <Checkbox disabled={settingsUpdating} checked={setting.value} onChange={(e, checked) => { updateUserTraingSetting(settingsId, { [setting.checkBoxStr]: checked }) }} size='small' />
                                }
                                label={<Typography variant='caption' fontWeight={500}>{setting.title}</Typography>}
                            />
                        ))
                    }
                </FormGroup>
            </Box>
        </Box>
    )
}

export default CheckPointsGraphDisplaySettings