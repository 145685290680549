import { Analytics, CompareArrows, Grass, SpaceDashboard, Timeline, TipsAndUpdates, TravelExplore } from "@mui/icons-material";
import { routes } from "../pages/routes";

export const sideBarEnum = [
    {
        key: 'navbar:dashboard',
        icon: <SpaceDashboard></SpaceDashboard>,
        isExternal: false,
        path: routes.DASHBOARD_ROUTES.HOME
    },
    {
        key: 'navbar:myTrainings',
        icon: <Timeline></Timeline>,
        isExternal: false,
        path: routes.DASHBOARD_ROUTES.TRAININGS
    },
    {
        key: 'navbar:comparison',
        icon: <CompareArrows></CompareArrows>,
        isExternal: false,
        path: routes.DASHBOARD_ROUTES.COMPARAISON
    },
    {
        key: 'navbar:myStable',
        icon: <Grass></Grass>,
        isExternal: false,
        path: routes.DASHBOARD_ROUTES.YARD
    },
    {
        key: 'navbar:analytics',
        icon: <Analytics></Analytics>,
        isExternal: false,
        path: routes.DASHBOARD_ROUTES.ANALYTICS
    },
    {
        key: 'navbar:profileOptions:knowledgeBase',
        icon: <TipsAndUpdates></TipsAndUpdates>,
        isExternal: true,
        path: "https://knowledgebase.equimetre.com/en?__hstc=175492477.2b4406dd09c7110103f76d133cc748fc.1704303036116.1704383196342.1704720064805.3&__hssc=175492477.3.1704720064805&__hsfp=1007729581"
    },
    {
        key: 'navbar:profileOptions:blog',
        icon: <TravelExplore></TravelExplore>,
        isExternal: true,
        path: "https://training.arioneo.com/en/blog-en/?__hstc=175492477.2b4406dd09c7110103f76d133cc748fc.1704303036116.1704383196342.1704720064805.3&__hssc=175492477.3.1704720064805&__hsfp=1007729581"
    },
]