import { Email, Password } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Card, Checkbox, Divider, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import React, { FormEvent, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { galop, metrics } from '../../enums/register.enums'
import { countries } from '../../enums/countries.enum'
import { languages } from '../../enums/languages.enum'
import { useJobs } from '../../hooks/useJobs'
import { Job } from '../../shared/types/job.type'

export type registerForm = {
    email: string,
    lastname: string,
    firstname: string,
    nationality: string,
    telephone: string,
    unit_system: string,
    type: string,
    id_france_galop?: string,
    password: string,
    jobs: string[],
    lang: string,
    country: string
}

type registerProps = {
    onLoginClicked: () => void,
    onRegisterSubmit: (data: registerForm) => void,
    isLoading: boolean
}

const TOS_LINK = "https://equimetre-assets.s3.eu-west-3.amazonaws.com/build/legal/202108_ARIONEO_CGV_FR.pdf?37.3.0"

function RegisterForm({ onLoginClicked, onRegisterSubmit, isLoading }: registerProps) {
    const { t } = useTranslation()
    const [tosChecked, setTosChecked] = useState(false)
    const { getJobs, Jobs }: { getJobs: () => void, Jobs: [] | Job[] } = useJobs()

    //Load jobs 
    useEffect(() => {
        getJobs()
    }, [])

    const {
        register,
        handleSubmit,
        control,
        setValue,
        getValues,
        formState: { errors, touchedFields },
    } = useForm<registerForm>({})

    const onSubmit: SubmitHandler<registerForm> = (data) => onRegisterSubmit(data)

    return (
        <Box display={'flex'} flexDirection={'column'} mt={2}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box display={'flex'} flexDirection={'column'} gap={3} >
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth >
                                <InputLabel
                                    error={errors.hasOwnProperty('jobs')}
                                    id="job-select-label">{t('equi:registration:job:label')}</InputLabel>
                                <Select
                                    {...register('jobs', { required: true })}
                                    error={errors.hasOwnProperty('jobs')}


                                    labelId="job-select-label"
                                    id="demo-simple-select"
                                    label={t('equi:registration:job:label')}
                                    multiple={true}
                                    defaultValue={[]}

                                    onChange={(e: SelectChangeEvent<string[]>) => setValue('jobs', e.target.value as string[])}
                                >
                                    {Jobs.map(job => (
                                        <MenuItem key={job['@id']} value={job['@id']}>{t(job.translation_key)}</MenuItem>

                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth >
                                <InputLabel
                                    error={errors.hasOwnProperty('type')}
                                    id="galope-select-label">{t('equi:registration:product:gallop')}</InputLabel>
                                <Select
                                    {...register('type', { required: true })}
                                    error={errors.hasOwnProperty('type')}

                                    labelId="galope-select-label"
                                    id="demo-simple-select"
                                    defaultValue={"TROT"}
                                    label={t('equi:registration:product:gallop')}

                                >
                                    {galop.map(galop => (
                                        <MenuItem key={galop.key} value={galop.value}>{t(galop.key)}</MenuItem>

                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField
                                    {...register('firstname', { required: true })}
                                    error={errors.hasOwnProperty('firstname')}


                                    label={t('equi:registration:firstname')}
                                >
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField
                                    {...register('lastname', { required: true })}
                                    error={errors.hasOwnProperty('lastname')}


                                    label={t('equi:registration:lastname')}
                                >
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField FormHelperTextProps={{ variant: 'standard', error: true }} helperText={errors.email?.message} error={errors.hasOwnProperty('email')} fullWidth label={t('equi:registration:email')} {...register("email",
                                    {
                                        required: true,
                                        pattern: /\S+@\S+\.\S+/,
                                    })} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <Controller
                                    name="telephone"
                                    control={control}

                                    render={({ field, fieldState }) => (
                                        <MuiTelInput
                                            placeholder={t('equi:registration:phone')}
                                            {...field}
                                            defaultCountry='FR'
                                            helperText={fieldState.invalid ? "Tel is invalid" : ""}
                                            error={errors.hasOwnProperty('telephone')}
                                        />
                                    )}
                                />

                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <FormControl fullWidth >
                                <InputLabel error={errors.hasOwnProperty('nationality')} id="country-select-label">{t('equi:registration:nationality')}</InputLabel>
                                <Select
                                    {...register('nationality', { required: true })}
                                    error={errors.hasOwnProperty('nationality')}

                                    labelId="country-select-label"
                                    id="demo-simple-select"
                                    label={t('equi:registration:nationality')}
                                >
                                    {countries.map(country => (
                                        <MenuItem key={country.code} value={country.code}>{country.name}</MenuItem>

                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <FormControl fullWidth >
                                <InputLabel error={errors.hasOwnProperty('lang')} id="lag-select-label">{t('equi:registration:lang:label')}</InputLabel>
                                <Select
                                    {...register('lang', { required: true })}
                                    error={errors.hasOwnProperty('lang')}

                                    labelId="lag-select-label"
                                    id="demo-simple-select"
                                    label={t('equi:registration:lang:label')}
                                >
                                    {languages.map(lang => (
                                        <MenuItem key={lang.key} value={lang.key}>{t(lang.value)}</MenuItem>

                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth >
                                <InputLabel error={errors.hasOwnProperty('unit_system')} id="unit-select-label">{t('equi:registration:unit_system:label')}</InputLabel>
                                <Select
                                    {...register('unit_system', { required: true })}
                                    error={errors.hasOwnProperty('unit_system')}

                                    labelId="unit-select-label"
                                    id="demo-simple-select"
                                    label={t('equi:registration:unit_system:label')}
                                >
                                    {metrics.map(metric => (
                                        <MenuItem key={metric.key} value={metric.value}>{t(metric.key)}</MenuItem>

                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControl fullWidth>
                                <TextField
                                    {...register('id_france_galop')}


                                    label={t('equi:registration:fgid')}
                                >
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControl fullWidth>
                                <TextField


                                    label={t('equi:registration:address:address')}
                                >
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControl fullWidth>
                                <TextField


                                    label={t('equi:registration:address:address_optional')}
                                >
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <FormControl fullWidth>
                                <TextField


                                    label={t('equi:registration:address:postal_code')}
                                >
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <FormControl fullWidth>
                                <TextField


                                    label={t('equi:registration:address:city')}
                                >
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <FormControl fullWidth >
                                <InputLabel id="cntry-select-label">{t('equi:registration:address:country')}</InputLabel>
                                <Select
                                    {...register('country')}

                                    labelId="cntry-select-label"
                                    label={t('equi:registration:address:country')}
                                >
                                    {countries.map(country => (
                                        <MenuItem key={country.code} value={country.name}>{country.name}</MenuItem>

                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControlLabel control={<Checkbox checked={tosChecked} onChange={e => setTosChecked(e.target.checked)} />} label={<Typography variant='caption'><span  dangerouslySetInnerHTML={{ __html: t('equi:registration:approved_tos', { link: TOS_LINK }) }}></span></Typography>} />

                        </Grid>
                    </Grid>
                    {/* register your input into the hook by invoking the "register" function */}


                    {/* include validation with required or other standard HTML validation rules */}

                    {/* errors will return when field validation fails  */}
                    <LoadingButton disabled={!tosChecked} loading={isLoading} variant='contained' type="submit" >{t('equi:registration:submit')}</LoadingButton>
                </Box>
            </form>

            <Divider sx={{ my: 2, fontSize: 12 }}></Divider>
            <Button onClick={onLoginClicked} fullWidth variant='outlined'>{t('security:login:submit')}</Button>
        </Box>


    )
}

export default RegisterForm