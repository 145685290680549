import { AxiosResponse } from "axios";
import axiosInstance from "./base";
import { TAnalyticParams } from "../shared/types/analyticParameters.type";
import { TAnalyticView } from "../shared/types/analyticView.type";
import { TAnalyticsFilters } from "../shared/types/analyticsFilters.type";
import { TAnalyticData } from "../pages/analytics";
import { AnalyticsData } from "../shared/types/analyticData.type";

export type TNewConfig = { name: string, user: string, insight_parameters: string[] }

export class AnalyticTable {

    static getAnalyticsParameters(): Promise<AxiosResponse<TAnalyticParams[]>> {
        return axiosInstance.get('insight_parameters?available_as_analytics_parameter=true')
    }

    static saveNewConfig(data: TNewConfig): Promise<AxiosResponse<TAnalyticView>> {
        return axiosInstance.post('analytics_views', data)
    }

    static getConfigs(user?: string): Promise<AxiosResponse<Partial<TAnalyticView>>> {
        return axiosInstance.get(`analytics_views?user=${user}`)
    }

    static getViewById(id: number): Promise<AxiosResponse<TAnalyticView>> {
        return axiosInstance.get(`analytics_views/${id}`)
    }

    static deleteView(id: number) {
        return axiosInstance.delete(`analytics_views/${id}`)
    }

    static updateView(id: number, data: Partial<TAnalyticView>): Promise<AxiosResponse<TAnalyticView>> {
        return axiosInstance.patch(`analytics_views/${id}`, data, {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            }
        })
    }

    static getFilters(id: number): Promise<AxiosResponse<TAnalyticsFilters>> {
        return axiosInstance.get(`users/${id}/analytics_filters`)
    }

    static getAnalytics(id: number, data: TAnalyticData): Promise<AxiosResponse<AnalyticsData>> {
        return axiosInstance.post(`users/${id}/analytics_data`,data)
    }
}