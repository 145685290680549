export const directions = [
    {
        key: "tracks:checkpointDirection:0",
        value: 0
    },
    {
        key: "tracks:checkpointDirection:1",
        value: 1
    },
    {
        key: "tracks:checkpointDirection:2",
        value: -1
    },
] as const