import { createTheme } from "@mui/material";
import typography from './typography';
import palette from "./palette";
import breakpoints from "./breakpoints";
import shadows, { customShadows } from "./shadows";
import ComponentsOverrides from "./overrides";

const isLight = false

const themeOptions =
    () => ({
        palette: isLight ? palette.light : palette.dark,
        typography,
        breakpoints,
        shape: { borderRadius: 8 },
        shadows: isLight ? shadows.light : shadows.dark,
        customShadows: isLight ? customShadows.light : customShadows.dark,
    })

const Theme = createTheme(themeOptions());
Theme.components = ComponentsOverrides(Theme);

export default Theme;