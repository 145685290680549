import React from 'react'
import { TTrainerHorse } from '../../shared/types/trainerHorse.type'
import { List } from '@mui/material'
import ComparaisonItem from './ComparaisonItem'
import { Ttraining } from '../../shared/types/training.type'

type ComparaisonListProps = {
    horses: TTrainerHorse[],
    handleExamClick: (checked: boolean, exam: Ttraining) => void
}
function ComparaisonList({ horses, handleExamClick }: ComparaisonListProps) {

    return (
        <List>
            {
                horses.length > 0 && horses.map((horse,i) => (
                    <ComparaisonItem handleExamClick={handleExamClick} horse={horse} key={i}></ComparaisonItem>
                ))
            }
        </List>
    )
}

export default ComparaisonList