import React, { ReactNode, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../../components/dashboard/Header'
import ProfileTabs from '../../components/user/ProfileTabs'

function User() {
    const [title, setTitle] = useState('')
    const [element, setElement] = useState<ReactNode | null>(null)

    return (
        <div>
            <Header text={title} >
                {element}
            </Header>
            <ProfileTabs onRouteChanged={(text, el) => {
                setTitle(text ?? "")
                setElement(el)
            }}>
                <Outlet></Outlet>
            </ProfileTabs>
        </div>
    )
}

export default User