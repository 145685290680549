import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TUser } from "../../shared/types/user.type";
import { TrainingSetting } from "../../shared/types/trainingSetting.type";
import { cloneDeep } from "lodash";

type UserInitialState = {
    isAuthenticated: boolean,
    user: TUser | null,
    isUserLoading: boolean,
    initialSpeedUnit?: string,
    initialUnitSystem?: string,
}

const initialState: UserInitialState = {
    isAuthenticated: true,
    user: null,
    isUserLoading: false
}

export const userReducer = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setAuth: (state, action: PayloadAction<boolean>) => {
            state.isAuthenticated = action.payload
        },
        setUser: (state, action: PayloadAction<TUser | null>) => {
            state.user = action.payload
        },
        setIsUserLoading: (state, action: PayloadAction<boolean>) => {
            state.isUserLoading = action.payload
        },
        updateUserSettings: (state, action: PayloadAction<TrainingSetting>) => {
            let newSettings = cloneDeep(state.user)
            if (!newSettings) return
            newSettings.training_settings = action.payload
            state.user = newSettings
        },
        logout: state => {
            state.isAuthenticated = false
            state.user = null
            window.localStorage.clear()
        },
        setInitialUnitSystem: (state, action: PayloadAction<"metric" | "imperial">) => {
            state.initialUnitSystem = action.payload
        },
        setInitialSpeedUnit: (state, action: PayloadAction<string>) => {
            state.initialSpeedUnit = action.payload
        }
    }
})
export const { logout, setAuth, setUser, setIsUserLoading, updateUserSettings, setInitialUnitSystem, setInitialSpeedUnit } = userReducer.actions
export default userReducer.reducer