import React, { memo, useState } from 'react'
import { TAverageWithInactivity, TAverageWithoutInactivity } from '../../shared/types/exam.type'
import { Box, LinearProgress, Switch, Tooltip, Typography } from '@mui/material'
import { t } from 'i18next'
import { isEmpty } from 'lodash'
import dayjs from 'dayjs'
import { Info } from '@mui/icons-material'
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)


type HrActivityProps = {
    params: {
        includeInactivity?: TAverageWithInactivity[],
        excludeInactivity?: TAverageWithoutInactivity[]
    }
}
const colors = ["#caf0f8", "#90e0ef", "#0077B6", "#013e8b", "#03045e"]
function HrActivityZone({ params }: HrActivityProps) {
    const [include, setInclude] = useState(false)

    return (

        <div>
            <Box bgcolor={'var(--lightGray)'} p={1} mb={2} borderRadius={3} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography pl={1} variant='subtitle2'>{t('hrZones:includeInactivityAndWalk')}</Typography>
                <Switch checked={include} onChange={e => setInclude(e.target.checked)}></Switch>
            </Box>
            {
                <NotIncluded data={!include ? params.excludeInactivity : params.includeInactivity}></NotIncluded>
            }
        </div>
    )
}

const getTime = (seconds: number) => {
    return new Date(seconds * 1000)
        .toISOString()
        .slice(14, 19);
}

const getTitle = (index: number): { title: string, hint: string } => ({ title: t('zone:name:' + index), hint: t('zone:detail:' + index) })

const getBpm = (max?: number, min?: number) => {
    if (max && min) {
        return `${min} - ${max} Bpm`
    }
    if (max && !min) {
        return `> ${max} Bpm`
    } else {
        return `< ${min} Bpm`
    }
}

function NotIncluded({ data }: { data?: TAverageWithoutInactivity[] | TAverageWithInactivity[] }) {
    return <>
        {!isEmpty(data) ? data?.map(item => (
            <Box display={'flex'} flexDirection={'column'}  gap={2} mb={1} key={item?.index}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography sx={{ flex: 1, textAlign: 'left' }} variant='caption' fontWeight={500}>{getTime(item?.time_seconds)}</Typography>
                    <Typography sx={{ flex: 1, textAlign: 'left' }} variant='caption' fontWeight={500}>{getTitle(item?.index).title}</Typography>
                    <Tooltip sx={{ flex: 1, textAlign: 'right' }} placement='left' title={getTitle(item?.index).hint} arrow>
                        <Info color='info' sx={{ width: 15, height: 15 }}></Info>
                    </Tooltip>
                    <Typography sx={{ flex: 1, textAlign: 'right' }} variant='caption' fontWeight={500}>{getBpm(item?.bpm_max, item?.bpm_min)}</Typography>
                    <Typography sx={{ flex: 1, textAlign: 'right' }} variant='caption' fontWeight={500}>{item?.percentage + ' %'}</Typography>
                </Box>
                <LinearProgress sx={{
                    height: 5, borderRadius: 3, '& .MuiLinearProgress-bar': {
                        backgroundColor: colors[item.index]
                    },background:'var(--lightGray)'
                }} variant='determinate' value={item?.percentage}></LinearProgress>
            </Box>
        )) : <></>}
    </>
}

export default memo(HrActivityZone)