import { Autocomplete, Avatar, Box, Checkbox, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Tooltip, Typography } from '@mui/material'
import { t } from 'i18next'
import React, { useState } from 'react'
import { Ttraining } from '../../shared/types/training.type'
import { capitalize, debounce, sortBy } from 'lodash'
import { TQualifyChoices } from '../../shared/types/qualifyChoices.type'
import { Create, ListAlt } from '@mui/icons-material'


type ExamRowProps = {
    exam: Ttraining,
    isExamChecked: boolean,
    qualifyChoices: TQualifyChoices | null,
    handleInputChange: (checked: boolean, exam: Ttraining) => void,
    updateExam: (key: string, value: any, examId: number) => void

}

function ExamRow({ exam, isExamChecked, qualifyChoices, handleInputChange, updateExam }: ExamRowProps) {

    //booleans for wheater show select or text field
    const [useCustomTrainingType, setCustomTrainingType] = useState(false)
    const [useCustomTrackName, setCustomTrackName] = useState(false)
    const [useCustomTrackCondition, setCustomTrackCondition] = useState(false)
    const [useCustomRider, setCustomRider] = useState(false)


    const debouncedFn = debounce((key: string, value: any, examId: number) => {
        updateExam(key, value, examId)
    }, 300)

    return (
        <Grid container>
            <Grid item md={2} sm={12} xs={12} sx={{ placeSelf: 'center' }}>
                <Typography sx={{ alignSelf: 'center', alignItems: 'center', display: 'flex' }} variant='body2'>
                    <Checkbox onChange={(_, checked) => handleInputChange(checked, exam)} checked={isExamChecked} ></Checkbox>
                    {capitalize(exam.horse_trainer.horse.name)}
                </Typography>
            </Grid>
            <Grid item md={10} sm={12} xs={12}>
                <Box display={'flex'} gap={2} alignItems={'center'} flexWrap={'wrap'}>

                    {/* Training Type */}
                    <FormControl sx={{ flex: 1 }}>
                        {/* <InputLabel id="demo-simple-select-label">{t('editTrainingForm:trainingType:label')}</InputLabel> */}
                        {
                            !useCustomTrainingType ?
                                <Autocomplete
                                    options={sortBy(qualifyChoices?.training_types, i => i.display_name)}
                                    id="demo-simple-select"
                                    getOptionLabel={e => e.display_name}
                                    getOptionKey={e => e['@id']}
                                    value={qualifyChoices?.training_types.find(e => e['@id'] === exam.training_type?.['@id'] ?? "")}
                                    onChange={(e, value) => { updateExam('training_type', value, exam.id) }}
                                    renderInput={(params) => <TextField
                                        
                                        {...params} label={t('editTrainingForm:trainingType:label')} />}

                                />
                                /*    <Select
                                       labelId="demo-simple-select-label"
                                       id="demo-simple-select"
                                       value={exam.training_type?.['@id'] ?? ""}
                                       label={t('editTrainingForm:trainingType:label')}
                                       onChange={(event) => {
                                           let item = qualifyChoices?.training_types.find(e => e['@id'] === event.target.value)
                                           updateExam('training_type', item, exam.id)
                                       }}
                                       endAdornment={
                                           <InputAdornment position="start">
                                               <Tooltip title={t('otherTrainingType')}>
                                                   <IconButton
                                                       onClick={() => {
                                                           setCustomTrainingType(true)
                                                       }}
                                                       sx={{ mr: 1 }}
                                                       edge="end"
                                                   >
                                                       <Create></Create>
                                                   </IconButton>
                                               </Tooltip>
                                           </InputAdornment>
                                       }
                                   >
                                       {
                                           qualifyChoices?.training_types.map(type => (
                                               <MenuItem key={type.id} value={type['@id']}>{type.display_name}</MenuItem>
                                           ))
                                       }
                                   </Select> */
                                : <OutlinedInput
                                    onChange={event => debouncedFn('new_training_type', event.target.value, exam.id)}

                                    endAdornment={
                                        <InputAdornment position="start">
                                            <Tooltip title={t('editTrainingForm:trainingType:label')}>
                                                <IconButton
                                                    onClick={() => {
                                                        debouncedFn('new_training_type', '', exam.id)
                                                        setCustomTrainingType(false)
                                                    }}
                                                    edge="end"
                                                >
                                                    <ListAlt></ListAlt>
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                    label={t('editTrainingForm:trainingType:label')}
                                    placeholder={t('otherTrainingType')}
                                ></OutlinedInput>
                        }

                    </FormControl>

                    {/* Track */}
                    <FormControl sx={{ flex: 1 }}>
                        <InputLabel id="demo-simple-select-label">{t('editTrainingForm:trackName:label')}</InputLabel>
                        {
                            !useCustomTrackName ?

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={exam.track_name?.['@id'] ?? ""}
                                    label={t('editTrainingForm:trackName:label')}
                                    onChange={(e) => {
                                        let item = qualifyChoices?.track_names.find(i => i['@id'] === e.target.value)
                                        updateExam('track_name', item, exam.id)
                                    }}
                                    endAdornment={
                                        <InputAdornment position="start">
                                            <Tooltip title={t('newTrackName')}>
                                                <IconButton

                                                    onClick={() => {
                                                        setCustomTrackName(true)
                                                    }}
                                                    sx={{ mr: 1 }}
                                                    edge="end"
                                                >
                                                    <Create></Create>
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                >
                                    {
                                        qualifyChoices?.track_names.map(track => (
                                            <MenuItem key={track.id} value={track['@id']}>{track.display_name}</MenuItem>
                                        ))
                                    }
                                </Select>
                                : <OutlinedInput
                                    onChange={event => debouncedFn('new_track_name', event.target.value, exam.id)}
                                    endAdornment={
                                        <InputAdornment position="start">
                                            <Tooltip title={t('navbar:myTracks')}>
                                                <IconButton
                                                    onClick={() => {
                                                        debouncedFn('new_track_name', '', exam.id)
                                                        setCustomTrackName(false)
                                                    }}
                                                    edge="end"
                                                >
                                                    <ListAlt></ListAlt>
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                    label={t('editTrainingForm:horseShoeing:label')}
                                    placeholder={t('newTrackName')}
                                ></OutlinedInput>
                        }
                    </FormControl>

                    {/* Track surface */}
                    <FormControl sx={{ flex: 1 }}>
                        <InputLabel id="demo-simple-select-label">{t('editTrainingForm:trackSurface:label')}</InputLabel>

                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={exam.track_surface?.['@id'] ?? ""}

                            label={t('editTrainingForm:trackSurface:label')}
                            onChange={(e) => {
                                let item = qualifyChoices?.track_surfaces.find(i => i['@id'] === e.target.value)

                                updateExam('track_surface', item, exam.id)
                            }}
                        >
                            {
                                qualifyChoices?.track_surfaces.map(surface => (
                                    <MenuItem key={surface.id} value={surface['@id']}>{surface.display_name}</MenuItem>
                                ))
                            }
                        </Select>

                    </FormControl>

                    {/* Track condition */}
                    <FormControl sx={{ flex: 1 }}>
                        <InputLabel id="demo-simple-select-label">{t('editTrainingForm:trackCondition:label')}</InputLabel>

                        {
                            !useCustomTrackCondition ?
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={exam.track_condition?.['@id'] ?? ""}

                                    label={t('editTrainingForm:trackCondition:label')}
                                    onChange={(e) => {
                                        let item = qualifyChoices?.track_conditions.find(i => i['@id'] === e.target.value)

                                        updateExam('track_condition', item, exam.id)
                                    }}
                                    endAdornment={
                                        <InputAdornment position="start">
                                            <Tooltip title={t('trackCondition:newTrackCondition')}>
                                                <IconButton
                                                    onClick={() => {
                                                        setCustomTrackCondition(true)
                                                    }}
                                                    sx={{ mr: 1 }}
                                                    edge="end"
                                                >
                                                    <Create></Create>
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    }

                                >
                                    {
                                        qualifyChoices?.track_conditions.map(condition => (
                                            <MenuItem key={condition.id} value={condition['@id']}>{condition.display_name}</MenuItem>
                                        ))
                                    }
                                </Select>
                                : <OutlinedInput
                                    onChange={event => debouncedFn('new_track_condition', event.target.value, exam.id)}
                                    endAdornment={
                                        <InputAdornment position="start">
                                            <Tooltip title={t('navbar:myTrackConditions')}>
                                                <IconButton
                                                    onClick={() => {
                                                        debouncedFn('new_track_condition', '', exam.id)
                                                        setCustomTrackCondition(false)
                                                    }}
                                                    edge="end"
                                                >
                                                    <ListAlt></ListAlt>
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                    label={t('editTrainingForm:trackCondition:label')}
                                    placeholder={t('trackCondition:newTrackCondition')}
                                ></OutlinedInput>
                        }
                    </FormControl>

                    {/* Riedr name */}
                    <FormControl sx={{ flex: 1 }}>
                        <InputLabel id="demo-simple-select-label">{t('editTrainingForm:riderName:label')}</InputLabel>

                        {
                            !useCustomRider ?
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={exam.rider?.['@id'] ?? ""}

                                    label={t('editTrainingForm:riderName:label')}
                                    onChange={(e) => {
                                        let item = qualifyChoices?.riders.find(i => i['@id'] === e.target.value)

                                        updateExam('rider', item, exam.id)
                                    }}
                                    endAdornment={
                                        <InputAdornment position="start">
                                            <Tooltip title={t('otherRider')}>
                                                <IconButton
                                                    onClick={() => {
                                                        setCustomRider(true)
                                                    }}
                                                    sx={{ mr: 1 }}
                                                    edge="end"
                                                >
                                                    <Create></Create>
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                >
                                    {
                                        qualifyChoices?.riders.map(rider => (
                                            <MenuItem key={rider.id} value={rider['@id']}>{rider.display_name}</MenuItem>
                                        ))
                                    }
                                </Select>
                                : <OutlinedInput
                                    onChange={event => debouncedFn('new_rider', event.target.value, exam.id)}
                                    endAdornment={
                                        <InputAdornment position="start">
                                            <Tooltip title={t('navbar:myRiders')}>
                                                <IconButton
                                                    onClick={() => {
                                                        debouncedFn('new_rider', '', exam.id)
                                                        setCustomRider(false)
                                                    }}
                                                    edge="end"
                                                >
                                                    <ListAlt></ListAlt>
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                    label={t('editTrainingForm:riderName:label')}
                                    placeholder={t('otherRider')}
                                ></OutlinedInput>}
                    </FormControl>
                </Box>
            </Grid>
        </Grid>
    )
}

export default ExamRow