import { useMutation } from "react-query"
import { loginForm } from "../components/auth/LoginForm"
import { User } from "../api/user"
import { AxiosError, AxiosResponse } from "axios"
import { useDispatch, useSelector } from "react-redux"
import { logout, setAuth, setInitialSpeedUnit, setInitialUnitSystem, setUser, updateUserSettings } from "../store/reducers/user.reducer"
import { RootState } from "../store"
import { useNavigate } from "react-router-dom"
import { routes } from "../pages/routes"

import type { ApiError } from "../shared/types/apiError.type"
import { useTranslation } from "react-i18next"
import { useEffect, useMemo } from "react"
import { TUser } from "../shared/types/user.type"
import { toast } from "react-toastify"
import { TrainingSetting } from "../shared/types/trainingSetting.type"
import { metrics, speedUnits } from "../enums/register.enums"
import { getCorrectDateFormat } from "../shared/utils/DateUtils"

export const useAuth = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const isAuthenticated = useSelector<RootState>(state => state.user.isAuthenticated)
    const auth = useSelector<RootState>(state => state.user.user) as TUser
    const speedUnit = useSelector<RootState>(state => state.user.user?.default_speed_unit) as string
    const metric = useSelector<RootState>(state => state.user.user?.unit_system) as "metric" | "imperial" | undefined

    //login Mutation
    const loginMutation = useMutation((data: loginForm) => User.login(data))

    //register Mutation
    const registerMutation = useMutation((data: loginForm) => User.register(data))

    //update Mutation
    const updateMutation = useMutation((data: Partial<TUser>) => User.update(data))

    //get Auth User
    const getUserMutation = useMutation(() => User.getUser())

    //reset new password Mutation
    const resetPasswordMutation = useMutation((data: { password: string, token: string }) => User.resetPassword(data))

    //request new password Mutation
    const requestMutation = useMutation((data: { email: string }) => User.requestResetPassword(data))

    //update Settings mutation
    const updateSettingsMutation = useMutation(({ id, data }: { id: number, data: Partial<TrainingSetting> }) => User.updateSettings(id, data))

    //login
    const login = (data: loginForm) => {
        loginMutation.mutateAsync(data)
            .then(result => {
                setToken(result.data.token)
            }).catch(err => {
                handleAuthErrors(err, data)
            })
    }
    //register
    const register = (data: loginForm) => {
        registerMutation.mutateAsync(data)
            .then((result) => {
                navigate(routes.AUTH_ROUTES.EMAIL_SENT, { state: { email: result.data.email } })
            }).catch(err => {
                console.log({ err });
            })
    }

    const updateUser = async (data: Partial<TUser>) => {
        return updateMutation.mutateAsync(data)
            .then((result) => {
                dispatch(setUser(result.data))
                dispatch(setInitialSpeedUnit(result.data.default_speed_unit))
                dispatch(setInitialUnitSystem(result.data.unit_system))
                toast(t('defaultSuccessMessage'), { type: 'success' })
                return result
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
                return err
            })
    }

    //resetPassword
    const resetPassword = (data: { password: string, token: string }) => {
        resetPasswordMutation.mutateAsync(data)
            .then(result => {
                toast(t('change_password:flash:success'), { type: 'success' })
                navigate(routes.AUTH_ROUTES.LOGIN)
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })

            })
    }

    //send reset email
    const requestResetPassword = (email: string) => {
        requestMutation.mutateAsync({ email })
            .then(result => {
                navigate(routes.AUTH_ROUTES.EMAIL_SENT_PASSWORD, { state: { email } })
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
            })
    }

    const getUser = () => {
        getUserMutation.mutateAsync()
            .then((result) => {
                dispatch(setUser(result.data))

            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })

            })
    }

    const disconnect = () => {
        dispatch(logout())
    }

    const handleAuthErrors = (error: AxiosError, userData: loginForm) => {
        let data = error.response?.data as ApiError


        if (data?.code === 409) {
            navigate(routes.AUTH_ROUTES.EMAIL_SENT, { state: { email: userData.email } })
        }
        if (data?.code === 500) {
            toast(t('error:generic'), { type: 'error' })

        }

    }

    const updateUserTraingSetting = async (id: number, settings: Partial<TrainingSetting>) => {
        try {
            let { data } = await updateSettingsMutation.mutateAsync({ id, data: settings })
            dispatch(updateUserSettings(data))
        } catch (err) {
            toast(t('error:generic'), { type: 'error' })

        }
    }


    //User avatar image
    const user_avatar_uri = useMemo(() => {
        return auth?.avatar_url ?? "https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/no-profile-picture-icon.png"
    }, [auth?.avatar_url])


    //get speed Unit

    const getSpeedUnit = (sp?: string, m?: string) => {
        
        let spUn = sp ?? speedUnit
        let mtrc = m ?? metric

        if (spUn === 'm/min') {
            return speedUnits.find(e => e.value === spUn)
        } else {
            let find = speedUnits.find(e => e.value === spUn && e.enabled === mtrc)

            if (!find) return
            if (mtrc === 'metric') {
                if (spUn === 'km/h') {
                    return { ...find, value: 'km/h' }
                }
                if (spUn === 'Red. km') {
                    return { ...find, value: 'Red. km' }
                }
            } else {
                if (spUn === 'km/h') {
                    return { ...find, value: 'mph' }
                }
                if (spUn === 'Red. km') {
                    return { ...find, value: 'Mil. red.' }
                }
            }

        }

    }

    const setToken = (token: string) => {
        localStorage.setItem('token', token)
        dispatch(setAuth(true))
    }

    const getMetric = () => {
        return metric === 'metric' ? t('compare:meters') : t('scale:pieds')
    }

    const isAdmin = useMemo(() => {
        return auth?.roles?.includes('ROLE_SUPER_ADMIN')
    }, [auth])

    const getUserDateFormat = useMemo(() => {
        if (auth?.default_date_format) {
            return getCorrectDateFormat(auth.default_date_format) + ' HH:mm'
        } else {
            return 'DD/MM/YYYY HH:mm'
        }
    }, [auth])



    const getUserMetric = useMemo(() => {
        return t(metrics.find(e => e.value === auth?.unit_system)?.key ?? '')
    }, [auth])

    const getUserLang = useMemo(() => {
        return auth?.lang ?? 'en'
    }, [auth])

    const getUserSpeedUnit = useMemo(() => {
        
        return auth?.default_speed_unit ?? 'km/h'
    }, [auth])



    return {
        login,
        register,
        resetPassword,
        requestResetPassword,
        getUser,
        updateUser,
        isUserFetching: getUserMutation.isLoading,
        isUserError: getUserMutation.isError,
        isLoading: loginMutation.isLoading || registerMutation.isLoading || requestMutation.isLoading || resetPasswordMutation.isLoading || updateMutation.isLoading,
        isError: loginMutation.isError || registerMutation.isError || requestMutation.isError || resetPasswordMutation.isError || updateMutation.isError,
        error: (loginMutation.error || registerMutation.error || requestMutation.error || resetPasswordMutation.error || updateMutation.error) as AxiosError,
        isAuthenticated,
        auth,
        isUpdateUserSuccess: updateMutation.isSuccess,
        user_avatar_uri,
        updateUserTraingSetting,
        settingsUpdating: updateSettingsMutation.isLoading,
        getSpeedUnit,
        getMetric,
        setToken,
        getUserMutation,
        isAdmin,
        disconnect,
        getUserDateFormat,
        getUserMetric,
        getUserLang,
        getUserSpeedUnit
    }
}