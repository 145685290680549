import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import React from 'react'
import { TtrainingKeyParameter } from '../../shared/types/trainingKeyParameter.type'
import { TgroupKeyParam } from '../../shared/utils/comparaison'
import ComparaisonCard from './ComparaisonCard'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { Ttraining } from '../../shared/types/training.type'

type ComparaisonSectionProps = {
    loading: boolean,
    categories: TgroupKeyParam[],
    data?: TtrainingKeyParameter
    compare: () => void
}

function ComparaisonSection({ loading, categories, data, compare }: ComparaisonSectionProps) {
    const checkedExams = useSelector<RootState>(state => state.comparaison.checkedExams) as Ttraining[]

    return (
        <>
            {loading ? <Box display={'flex'} justifyContent={'center'} mt={3} alignItems={'center'} >
                <CircularProgress></CircularProgress>
            </Box>
                : (!data || data?.items?.length === 0) ?
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        {
                            checkedExams.length > 0 ?
                                <Button onClick={compare}>{t('compareHorseSelection:send')}</Button>
                                : <Typography variant='subtitle2'>{t('no_training')}</Typography>
                        }

                    </Box>
                    :
                    <Stack direction={'column'} spacing={2}>
                        {categories?.length > 0 && categories?.map(categorie => (
                            <ComparaisonCard data={data} categorie={categorie} key={categorie.key}></ComparaisonCard>
                        ))}
                    </Stack>
            }
        </>
    )
}

export default ComparaisonSection