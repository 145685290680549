export const GENDERS = [
    {
        key: 'addHorse:gender:male',
        value:'M'
    },
    {
        key: 'addHorse:gender:femelle',
        value:'F'
    },
    {
        key: 'addHorse:gender:hongre',
        value:'H'
    },
]