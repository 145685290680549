import React from 'react'
import { useTranslation } from 'react-i18next';
import LoginForm, { loginForm } from '../../components/auth/LoginForm';
import { Alert, Box, Card, Container, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { routes } from '../routes';
import RegisterForm, { registerForm } from '../../components/auth/RegisterForm';
import { useAuth } from '../../hooks/useAuth';
import { AxiosError } from 'axios';
import { ApiError } from '../../shared/types/apiError.type';
import Errors from '../../components/global/Errors';

function Register() {
  const { t } = useTranslation();
  const { register, isLoading, isError, error } = useAuth()
  const err = error?.response?.data as ApiError

  const navigation = useNavigate()

  return (
    <Grid container height={'100%'} position={'relative'}>

      <Grid item md={12} sm={12} xs={12} display={'flex'} justifyContent={'center'} alignItems={'start'} p={4} >
        <Container maxWidth="sm">
          <Typography variant='h6' fontWeight={'500'} mb={2}>Register to Equimetre!</Typography>
          <Errors isError={isError} error={err}></Errors>

          <RegisterForm isLoading={isLoading} onLoginClicked={() => navigation(routes.AUTH_ROUTES.LOGIN)} onRegisterSubmit={register}></RegisterForm>
        </Container>
      </Grid>
    </Grid>
  )
}

export default Register