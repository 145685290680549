import {
    createBrowserRouter,
} from "react-router-dom";
import Login from "./login";
import Register from "./register";
import ForgotPassword from "./forgotPass";
import GuardRoute from "./guard";
import Layout from "../components/layout/Layout";
import { routes } from "./routes";
import Dashboard from "./dashboard";
import GuestGuard from "./guard/GuestGuard";
import Yard from "./yard";
import Home from "./Home";
import Trainings from "./trainings";
import Comparaison from "./comparaison";
import Analytics from "./analytics";
import EmailSent from './email-sent'
import GeneratePassword from "./generate-password";
import ResetPassword from "./email-sent/ResetPassword";
import User from "./user";
import Profile from "./user/Profile";
import Settings from "./user/Settings";
import Shoeing from "./user/Shoeing";
import Riders from "./user/Riders";
import TracksConditions from "./user/TracksConditions";
import Tracks from "./user/Tracks";
import TrackView from "./user/TrackView";
import ExamView from "./trainings/ExamView";
import MyTrainings from "./user/MyTrainings";
import Notifications from "./settings/notifications";
import ExternalComparaison from "./comparaison/ExternalComparaison";
import AnalyticTable from "./user/AnalyticTable";
import Unsubscribe from "./unsubscribe";
export const router = createBrowserRouter([
    {
        path: '*',
        element: <GuestGuard><Login /></GuestGuard>
    },
    {
        path: routes.AUTH_ROUTES.LOGIN,
        element: <GuestGuard><Login /></GuestGuard>
    },

    {
        path: routes.AUTH_ROUTES.REGISTER,
        element: <GuestGuard><Register /></GuestGuard>

    },
    {
        path: routes.AUTH_ROUTES.EMAIL_SENT,
        element: <GuestGuard><EmailSent /></GuestGuard>
    },
    {
        path: routes.AUTH_ROUTES.EMAIL_SENT_PASSWORD,
        element: <GuestGuard><ResetPassword /></GuestGuard>
    },
    {
        path: routes.AUTH_ROUTES.GENERATE_PASSWORD,
        element: <GuestGuard><GeneratePassword /></GuestGuard>
    },
    {
        path: routes.AUTH_ROUTES.FORGOT_PASSWORD,
        element: <GuestGuard><ForgotPassword /></GuestGuard>,
    },
    {
        path: routes.OTHER_ROUTES.UNSUBSCRIBE,
        element: <Unsubscribe />,
    },
    {
        path: '/dashboard',
        element: <GuardRoute><Dashboard /></GuardRoute>,
        children: [
            {
                path: routes.DASHBOARD_ROUTES.YARD,
                element: <GuardRoute><Yard /></GuardRoute>
            },
            {
                path: routes.DASHBOARD_ROUTES.HOME,
                element: <GuardRoute><Home /></GuardRoute>
            },
            {
                path: routes.DASHBOARD_ROUTES.TRAININGS,
                element: <GuardRoute><Trainings /></GuardRoute>
            },
            {
                path: routes.DASHBOARD_ROUTES.COMPARAISON,
                element: <GuardRoute><Comparaison /></GuardRoute>
            },
            {
                path: routes.DASHBOARD_ROUTES.ANALYTICS,
                element: <GuardRoute><Analytics /></GuardRoute>
            },
            {
                path: routes.DASHBOARD_ROUTES.EXAM_VIEW,
                element: <GuardRoute><ExamView /></GuardRoute>
            },
            {
                path: routes.DASHBOARD_ROUTES.USER,
                element: <GuardRoute><User /></GuardRoute>,
                children: [
                    {
                        path: routes.DASHBOARD_ROUTES.PROFILE,
                        element: <GuardRoute><Profile /></GuardRoute>
                    },
                    {
                        path: routes.DASHBOARD_ROUTES.SETTINGS,
                        element: <GuardRoute><Settings /></GuardRoute>
                    },
                    {
                        path: routes.DASHBOARD_ROUTES.SHOEING,
                        element: <GuardRoute><Shoeing /></GuardRoute>
                    },
                    {
                        path: routes.DASHBOARD_ROUTES.RIDERS,
                        element: <GuardRoute><Riders /></GuardRoute>
                    },
                    {
                        path: routes.DASHBOARD_ROUTES.TRACKSCONDITIONS,
                        element: <GuardRoute><TracksConditions /></GuardRoute>
                    },
                    {
                        path: routes.DASHBOARD_ROUTES.NOTIFICATIONS,
                        element: <GuardRoute><Notifications standalone={false} /></GuardRoute>
                    },
                    {
                        path: routes.DASHBOARD_ROUTES.TRACKS,
                        element: <GuardRoute><Tracks /></GuardRoute>
                    },
                    {
                        path: routes.DASHBOARD_ROUTES.TRACK_VIEW,
                        element: <GuardRoute><TrackView /></GuardRoute>
                    },
                    {
                        path: routes.DASHBOARD_ROUTES.MYTRAININGS,
                        element: <GuardRoute><MyTrainings /></GuardRoute>
                    },
                    {
                        path: routes.DASHBOARD_ROUTES.MYANALYTICS,
                        element: <GuardRoute><AnalyticTable /></GuardRoute>
                    },
                ]
            },

        ]
    },
    {
        path: '/settings',
        children: [{
            path: routes.SETTINGS_ROUTES.PDF,
            element: <Notifications standalone></Notifications>
        },
        {
            path: routes.SETTINGS_ROUTES.COMPARAISON,
            element: <ExternalComparaison></ExternalComparaison>
        },
        ]
    }
])