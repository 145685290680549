import React, { useCallback, useEffect, useMemo, useState } from 'react'
import BaseModal from './BaseModal'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { setRiderItemToEdit, setRiderModal, setShoeingItemToEdit, setShoeingModal, setTrackItemToEdit, setTrackModal, setTrackNameItemToEdit, setTrackNameModal } from '../../store/reducers/modal.reducer'
import { AppBar, Box, Button, CircularProgress, Container, Dialog, FormControl, Grid, IconButton, InputLabel, LinearProgress, MenuItem, Select, TextField, Toolbar, Typography } from '@mui/material'
import { t } from 'i18next'
import { LoadingButton } from '@mui/lab'
import { SubmitHandler, useForm } from 'react-hook-form'
import { TShoeing } from '../../shared/types/shoeing.type'
import { get, isEqual, toNumber } from 'lodash'
import { TUser } from '../../shared/types/user.type'

import { hands, trackGroup, trackSurfaces } from '../../enums/trackGroup.enums'
import { Ttrack, trackSurface } from '../../shared/types/tracks.type'
import { useTracks } from '../../hooks/useTracks'
import PlaceAutoComplete from '../tracks/PlaceAutoComplete'
import { Close } from '@mui/icons-material'
import Map from '../tracks/Map'
import { LatLng, getGeocode, getLatLng } from 'use-places-autocomplete'
import { useQueryClient } from 'react-query'

function TrackName() {
    const open = useSelector<RootState>(state => state.modals.trackNameModal) as boolean
    const itemToEdit = useSelector<RootState>(state => state.modals.trackNameItemToEdit, isEqual) as Ttrack | null
    const auth = useSelector<RootState>(state => state.user.user) as TUser | null
    const { saveTrack, isSaving, updateTrack, isUpdating, getTrackSurfaces, isSurfacesLoading } = useTracks(false)
    const dispatch = useDispatch()
    const [surfaces, setSurfaces] = useState<Array<trackSurface> | []>([])
    const [latlng, setLatlng] = useState<LatLng>()
    const [clickedMapValue, setClickedMapValue] = useState<string | null>()
    useEffect(() => {
        (async () => {
            if (open) {
                let response = await getTrackSurfaces()

                setSurfaces(response)
            }
        })()
    }, [open])

    const query = useQueryClient()
    useEffect(() => {
        if (itemToEdit) {
            setValue('id', itemToEdit.id)
            setValue('name', itemToEdit?.name)
            setValue('track_surface', get(itemToEdit, 'track_surface.@id', ''))
            setValue('comment', itemToEdit?.comment)
            setValue('hand_turn', itemToEdit?.hand_turn)
            setValue('address', itemToEdit?.address)

        }

    }, [itemToEdit, surfaces])

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors, touchedFields },
    } = useForm<Partial<Ttrack>>({
    })

    const onSubmit: SubmitHandler<Partial<Ttrack>> = (data) => {
        const tData: Partial<Ttrack> =
        {
            ...data,
            trainer: get(auth, '@id')
        }
        let promise = itemToEdit ? updateTrack(tData) : saveTrack(tData)

        promise
            .then(() => {
                //On Success reset Form
                reset()
                dispatch(setTrackNameItemToEdit(null))
                dispatch(setTrackNameModal(false))
                itemToEdit && query.refetchQueries(['trackName', itemToEdit.id])
            }).catch(err => { })
    }

    const handleClose = () => {
        dispatch(setTrackNameItemToEdit(null))
        dispatch(setTrackNameModal(false))
        reset()
    }

    const onLocationChange = (value: string | null) => {
        if (value) {

            setValue('address', value)

        }
    }



    return (
        <BaseModal title={itemToEdit ? t('tracks:information') : t('tracks:addTrack')} open={open} handleClose={handleClose}>
            <form onSubmit={handleSubmit(onSubmit)}>

                <Box>

                    <Grid container spacing={3}>

                        <Grid item md={12} xs={12}>
                            <TextField error={errors?.hasOwnProperty('name')} {...register('name', { required: true })}  fullWidth label={t('editTrainingForm:trackName:label')}></TextField>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <PlaceAutoComplete defaultValue={getValues('address')} onValueChange={onLocationChange}></PlaceAutoComplete>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <FormControl fullWidth >
                                <InputLabel error={errors.hasOwnProperty('lang')} >{t('editTrainingForm:trackSurface:label')}</InputLabel>
                                <Select disabled={isSurfacesLoading} defaultValue={get(itemToEdit, 'track_surface.@id', '')} label={t('editTrainingForm:trackSurface:label')} error={errors?.hasOwnProperty('track_surface')} fullWidth  {...register('track_surface', { required: true })}>
                                    {surfaces.map(surface => (
                                        <MenuItem key={surface?.id} value={surface['@id']}>{t(surface?.translation_key?.replaceAll('.', ':') ?? "")}</MenuItem>

                                    ))}
                                </Select>
                                {isSurfacesLoading && <LinearProgress sx={{ height: 2 }}></LinearProgress>}
                            </FormControl>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <FormControl fullWidth >
                                <InputLabel error={errors.hasOwnProperty('lang')} >{t('eap:table:headers:hand')}</InputLabel>
                                <Select defaultValue={itemToEdit?.hand_turn} label={t('eap:table:headers:hand')} error={errors?.hasOwnProperty('hand_turn')} fullWidth  {...register('hand_turn', { required: true })}>
                                    {hands.map(hand => (
                                        <MenuItem key={hand.key} value={hand.value}>{t(hand.key)}</MenuItem>

                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <TextField multiline rows={3} error={errors?.hasOwnProperty('comment')} {...register('comment', { required: false })}  fullWidth label={t('qualification:comment')}></TextField>

                        </Grid>
                    </Grid>


                </Box>
                <Box display={'flex'} mt={2} justifyContent={'end'}>
                    <LoadingButton loading={isSaving || isUpdating} type='submit' variant='contained'>{itemToEdit ? t('button:edit') : t('button:save')}</LoadingButton>
                </Box>
            </form>

        </BaseModal>
    )
}

export default TrackName