import { Box, Button, ButtonGroup, Card, Divider, FormControl, InputLabel, MenuItem, Select, Switch, Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dateFormat, galop, metrics, speedUnits, temperatures } from '../../enums/register.enums'
import { languages } from '../../enums/languages.enum'
import { LoadingButton } from '@mui/lab'
import { TUser } from '../../shared/types/user.type'
import { useAuth } from '../../hooks/useAuth'
import i18n from '../../locales'
import Errors from '../../components/global/Errors'
import { ApiError } from '../../shared/types/apiError.type'

function Settings() {
  const { t } = useTranslation()
  const { auth, updateUser, isLoading, isError, error } = useAuth()
  const err = error?.response?.data as ApiError

  const [settingsData, setSettingsData] = useState<Partial<TUser>>({
    id: auth?.id,
    demo_trainings_enabled: auth?.demo_trainings_enabled,
    unit_system: auth?.unit_system,
    temperature_unit: auth?.temperature_unit,
    lang: auth?.lang,
    default_speed_unit: auth?.default_speed_unit,
    type: auth?.type,
    default_date_format: auth?.default_date_format
  })

  const mappedSpeedUnits = useMemo(() => {
    return speedUnits.filter(speed => speed.enabled === "" || speed.enabled === settingsData.unit_system)
  }, [settingsData?.unit_system])

  const onChange = (key: string, value: string | boolean) => {
    setSettingsData(prev => ({ ...prev, [key]: value }))
  }

  const updateSettings = async () => {
    try {
      if (auth?.lang !== settingsData.lang) {
        i18n.changeLanguage(settingsData.lang)
        window.localStorage.setItem('lang', String(settingsData.lang))
      }
      await updateUser(settingsData)

    } catch (err) { }
  }

  return (
    <Card sx={{ p: 3 }}>

      <Errors isError={isError} error={err}></Errors>

      <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} my={2} alignItems={'center'}>
        <Typography variant='body2' fontWeight={500}>{t('info:enableDemoTrainings')}</Typography>
        <Switch onChange={(e) => onChange('demo_trainings_enabled', e.target.checked)} checked={settingsData.demo_trainings_enabled} />
      </Box>
      <Divider></Divider>

      <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} my={2} alignItems={'center'}>
        <Typography variant='body2' fontWeight={500}>{t('equi:registration:unit_system:label')}</Typography>
        <ButtonGroup disableElevation variant="outlined" aria-label="outlined button group">
          {metrics.map(metric => (
            <Button onClick={(e) => onChange('unit_system', metric.value)} variant={metric.value === settingsData.unit_system ? 'contained' : 'outlined'} key={metric.value}>{t(metric.key)}</Button>
          ))}

        </ButtonGroup>
      </Box>
      <Divider></Divider>


      <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} my={2} alignItems={'center'}>
        <Typography variant='body2' fontWeight={500}>{t('info:temperatureUnit')}</Typography>
        <ButtonGroup disableElevation variant="outlined" aria-label="outlined button group">
          {temperatures.map(temperature => (
            <Button onClick={(e) => onChange('temperature_unit', temperature.value)} variant={temperature.value === settingsData.temperature_unit ? 'contained' : 'outlined'} key={temperature.value}>{t(temperature.key)}</Button>
          ))}
        </ButtonGroup>
      </Box>
      <Divider></Divider>


      <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} my={2} alignItems={'center'}>
        <Typography variant='body2' fontWeight={500}>{t('info:language')}</Typography>
        <FormControl sx={{ width: '200px' }} >
          <InputLabel id="lag-select-label">{t('equi:registration:lang:label')}</InputLabel>
          <Select
            sx={{ width: '200px' }}
            labelId="lag-select-label"
            id="demo-simple-select"
            label={t('equi:registration:lang:label')}
            value={settingsData.lang}
            onChange={(e) => onChange('lang', e.target.value)}
          >
            {languages.map(lang => (
              <MenuItem key={lang.key} value={lang.key}> {t(lang.value)}</MenuItem>

            ))}
          </Select>
        </FormControl>
      </Box>
      <Divider></Divider>
      <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} my={2} alignItems={'center'}>
        <Typography variant='body2' fontWeight={500}>{t('info:defaultSpeedUnit')}</Typography>
        <ButtonGroup disableElevation variant="outlined" aria-label="outlined button group">
          {mappedSpeedUnits.map(speedUnit => (
            <Button onClick={(e) => onChange('default_speed_unit', speedUnit.value)} variant={speedUnit.value === settingsData.default_speed_unit ? 'contained' : 'outlined'} key={speedUnit.key}>{t(speedUnit.key)}</Button>
          ))}
        </ButtonGroup>
      </Box>
      <Divider></Divider>
      <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} my={2} alignItems={'center'}>
        <Typography variant='body2' fontWeight={500}>{t('info:accountType')}</Typography>
        <ButtonGroup disableElevation variant="outlined" aria-label="outlined button group">
          {galop.map(g => (
            <Button onClick={(e) => onChange('type', g.value)} variant={g.value === settingsData.type ? 'contained' : 'outlined'} key={g.value}>{t(g.key)}</Button>
          ))}
        </ButtonGroup>
      </Box>
      <Divider></Divider>
      <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} my={2} alignItems={'center'}>
        <Typography variant='body2' fontWeight={500}>{t('info:defaultDateFormat')}</Typography>
        <ButtonGroup disableElevation variant="outlined" aria-label="outlined button group">
          {dateFormat.map(format => (
            <Button onClick={(e) => onChange('default_date_format', format.value)} variant={format.value === settingsData.default_date_format ? 'contained' : 'outlined'} key={format.value}>{t(format.key)}</Button>
          ))}
        </ButtonGroup>
      </Box>
      <Divider></Divider>

      <Box display={'flex'} mt={2} justifyContent={'end'}>
        <LoadingButton loading={isLoading} onClick={updateSettings} variant='contained'>{t('button:save')}</LoadingButton>
      </Box>
    </Card>
  )
}

export default Settings