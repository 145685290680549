import { AxiosResponse } from "axios"
import { TCheckPoint } from "../shared/types/checkPoint.type"
import axiosInstance from "./base"

export class Checkpoint {

    static save(data: TCheckPoint): Promise<AxiosResponse<TCheckPoint>> {
        return axiosInstance.post('checkpoints', data)
    }

    static delete(id: number): Promise<AxiosResponse<any>> {
        return axiosInstance.delete(`checkpoints/${id}`)
    }

    static update(data: Partial<TCheckPoint>): Promise<AxiosResponse<TCheckPoint>> {
        return axiosInstance.patch(`checkpoints/${data.id}`, data, {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            }
        })
    }

}