import React from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../components/dashboard/Header'
import { Box, Button } from '@mui/material'
import { t } from 'i18next'
import { EditNote } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { setEditTrainingsModal } from '../../store/reducers/modal.reducer'

function Home() {

  //Hooks
  const dispatch = useDispatch()
  //Fns
  const openEditTrainingsModal = () => {
    dispatch(setEditTrainingsModal(true))
  }
  return (
    <>
      <Header text='navbar:dashboard'>
        <Box display={'flex'} justifyContent={'end'}>
          <Button onClick={openEditTrainingsModal} startIcon={<EditNote></EditNote>} variant='contained'>{t('editTrainingForm:fulfillConditionsOfTodaysTrainings')}</Button>
        </Box>
      </Header>
    </>
  )
}

export default Home