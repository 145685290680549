import React, { useMemo } from 'react'
import { TgroupKeyParam } from '../../shared/utils/comparaison'
import { Alert, Avatar, Box, Button, Card, CardContent, Divider, LinearProgress, Stack, Typography } from '@mui/material'
import { TtrainingKeyParameter } from '../../shared/types/trainingKeyParameter.type'
import { TtrainingKeyParameterItem } from '../../shared/types/TrainingKeyParameterItem.type'
import { AccessTime } from '@mui/icons-material'
import { formatDate, getCorrectDateFormat } from '../../shared/utils/DateUtils'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { Link, useNavigate } from 'react-router-dom'
import { routes } from '../../pages/routes'
import { capitalize, maxBy } from 'lodash'

function ComparaisonCard({ categorie, data }: { categorie: TgroupKeyParam, data?: TtrainingKeyParameter }) {
    console.log({ data });

    return (
        <Card>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} fontWeight={500} color="text.secondary" gutterBottom>
                    {categorie.title}
                </Typography>
                <Alert severity='info' >
                    <Typography sx={{ fontSize: 12 }}>{categorie.detail}</Typography>
                </Alert>
                <Stack direction={'column'} spacing={1.5} mt={2}>
                    {(data?.items && data.items.length > 0) && data.items.map((item, i) => (
                        <>
                            <RenderInfos item={item} categorie={categorie.key} key={item['@id']}></RenderInfos>
                            {i !== data.items.length - 1 && <Divider></Divider>}
                        </>
                    ))}
                </Stack>

            </CardContent>
        </Card>
    )
}

const RenderInfos = ({ item, categorie }: { item: TtrainingKeyParameterItem, categorie?: string }) => {
    const dateFormat = useSelector<RootState>(state => state.user.user?.default_date_format) as string | undefined
    const navigate = useNavigate()
    const getDateFormat = (): string => {
        if (dateFormat) {
            return getCorrectDateFormat(dateFormat) + ' HH:mm'
        } else {
            return 'DD/MM/YYYY HH:mm'
        }
    }

    let keyParamItem = useMemo(() => {
        return item.computed_key_parameters.find(e => e.key_parameter_layout?.insight_parameter?.category === categorie)
    }, [categorie, item])

    const viewExam = () => {
        let route = routes.DASHBOARD_ROUTES.EXAM_VIEW.replace(':id', String(item.training.id))
        navigate(route)
    }

    const maxValue = useMemo(() => {
        return maxBy(item.computed_key_parameters, (e) => e.raw_numeric_value && e.key_parameter_layout.insight_parameter.category === categorie)
    }, [item])


    const getPercentage = () => {
        if (!keyParamItem || !maxValue) return 0
        return (keyParamItem.raw_numeric_value * 100) / maxValue.raw_numeric_value
    }


    return (
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={2}>

            {/* Horse and training section */}

            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} gap={1.5} alignItems={'center'} flex={1} flexWrap={'wrap'}>
                <Typography flex={1} sx={{ cursor: 'pointer' }} onClick={viewExam} variant='caption' fontWeight={500}>{capitalize(item?.training?.horse_trainer?.horse?.name ?? " - ")}</Typography>
                <Box display={'flex'} alignItems={'center'} flex={1} flexDirection={'row'} gap={0.5}>
                    <AccessTime sx={{ width: 15, height: 15 }}></AccessTime>
                    {
                        item?.training?.creation_date ?
                            <Typography variant='caption'>{formatDate(item.training.creation_date, getDateFormat())}</Typography>
                            : <span> - </span>
                    }
                </Box>
                <Typography flex={1} textOverflow={'ellipsis'} whiteSpace={'nowrap'} overflow={'hidden'} variant='caption' title={item?.training?.training_type?.display_name}>{item?.training?.training_type?.display_name ?? " - "}</Typography>

            </Box>
            <Divider flexItem orientation='vertical'></Divider>

            {/* Progress Section */}
            <Box display={'flex'} flexDirection={'row'} flex={1} alignItems={'center'} gap={3}>
                <LinearProgress variant="determinate" value={getPercentage()} sx={{ width: '100%', flexGrow: 1, borderRadius: 3, height: 4.5 }} />
                <Typography variant='caption' flexBasis={'100px'}>{keyParamItem?.formatted_value}</Typography>

            </Box>
        </Box>
    )
}

export default ComparaisonCard