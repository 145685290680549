import { useMutation, useQuery } from "react-query"
import { AnalyticTable, TNewConfig } from "../api/analyticTable"
import { useState } from "react"
import { TAnalyticParams } from "../shared/types/analyticParameters.type"
import { get, groupBy } from "lodash"
import { TuserAccountType } from "../shared/types/user.type"
import { TAnalyticView } from "../shared/types/analyticView.type"
import { TAnalyticData } from "../pages/analytics"

export const useAnalyticTable = (enableQuery: boolean = false, user?: string) => {

    const [groupedParams, setGroupedParams] = useState<Partial<Record<string, TAnalyticParams[]>>>({})
    const [allParams, setAllParams] = useState<TAnalyticParams[]>([])
    const [AnalyticViews, setAnalyticViews] = useState<Partial<TAnalyticView>[]>([])

    //querys

    const getConfigs = useQuery(['table_config'], () => AnalyticTable.getConfigs(user), {
        enabled: enableQuery,
        onSuccess({ data }) {
            let views = get(data, 'hydra:member', [])
            setAnalyticViews(views)
        }
    })



    //Mutations
    const getParametersMutation = useMutation(() => AnalyticTable.getAnalyticsParameters(), {
        onSuccess(response) {
            let data = get(response.data, 'hydra:member', []) as TAnalyticParams[]
            setAllParams(data)

            //Group Params by category
            const groups = groupBy(data, item => item.category)
            setGroupedParams(groups)
        },
    })

    const saveNewConfigMutation = useMutation((data: TNewConfig) => AnalyticTable.saveNewConfig(data))

    const getViewByIdMutation = useMutation((id: number) => AnalyticTable.getViewById(id))

    const deleteViewMutation = useMutation((id: number) => AnalyticTable.deleteView(id))

    const updateViewMutation = useMutation(({ id, data }: { id: number, data: Partial<TAnalyticView> & { user?: string } }) => AnalyticTable.updateView(id, data))

    const getFiltersMutation = useMutation((id: number) => AnalyticTable.getFilters(id))

    const fetchAnalyticData = useMutation(({ id, data }: { id: number, data: TAnalyticData }) => AnalyticTable.getAnalytics(id, data))

    //fns
    function getInsightParametersNames(type: TuserAccountType = "GALLOP") {

        switch (type) {
            case "TROT":
                return [
                    'horse.age',
                    'horse.sex',
                    'training.training_type_name',
                    'training.track_name_name',
                    'training.track_condition_name',
                    'training.rider_name',
                    'insight.duration_seconds',
                    'insight.distance_meters',
                    'insight.hand_turn',
                    'insight.trot_avg_speed',
                    'insight.max_speed',
                    'insight.speed_best_2000',
                    'insight.speed_best_1500',
                    'insight.speed_best_500',
                    'insight.speed_best_250',
                    'insight.speed_last_1000',
                    'insight.speed_last_500',
                    'insight.speed_last_250',
                    'insight.max_acceleration',
                    'insight.z5_duration',
                    'insight.z5_distance',
                    'insight.z5_speed',
                    'insight.max_heart_rate',
                    'insight.time_to_100bpm',
                    'insight.bpm_at_end',
                    'insight.bpm_at_end_percent',
                    'insight.mean_hr_first_trot',
                    'insight.mean_speed_first_trot',
                    'insight.mean_symmetry_first_trot',
                    'insight.mean_regularity_first_trot',
                    'insight.stride_frequency_at_45',
                    'insight.stride_length_at_45',
                    'insight.stride_frequency_at_vmax',
                    'insight.stride_length_at_vmax'
                ];
            case "VET":
                return [
                    "horse.age",
                    "training.training_type_name",
                    "training.track_name_name",
                    "training.track_surface_name",
                    "training.track_condition_name",
                    "insight.main_work_duration_seconds",
                    "insight.main_work_distance_meters",
                    "insight.max_speed",
                    "insight.time_best_600",
                    "insight.time_best_200",
                    "insight.max_heart_rate",
                    "insight.z5_duration",
                    "insight.oxygen_dept_time",
                    "insight.bpm_after_effort",
                    "insight.bpm_after_effort_percent",
                    "insight.fast_recovery_quality",
                    "insight.bpm_after_15_min",
                    "insight.bpm_after_15_min_percent",
                    "insight.heart_rate_15_min_recovery_quality",
                    "insight.bpm_at_end",
                    "insight.stride_length_at_60",
                    "insight.stride_frequency_at_60",
                    "insight.max_stride_frequency",
                    "insight.max_stride_length",
                    "insight.mean_hr_first_trot",
                    "insight.mean_speed_first_trot",
                    "insight.mean_symmetry_first_trot",
                    "insight.mean_regularity_first_trot",
                    "insight.mean_hr_first_canter",
                    "insight.mean_speed_first_canter",
                    "insight.mean_regularity_first_canter",
                    "insight.main_work_avg_stride_length",
                    "insight.stride_length_best_600",
                    "insight.stride_length_best_200",
                    "insight.stride_frequency_at_vmax",
                    "insight.stride_length_at_vmax"
                ];

            case "SPORT":
                return [
                    "horse.age",
                    "training.training_type_name",
                    "training.track_name_name",
                    "training.track_surface_name",
                    "training.track_condition_name",
                    "insight.main_work_duration_seconds",
                    "insight.main_work_distance_meters",
                    "insight.max_speed",
                    "insight.time_best_600",
                    "insight.time_best_200",
                    "insight.z5_duration",
                    "insight.oxygen_dept_time",
                    "insight.max_heart_rate",
                    "insight.bpm_after_effort",
                    "insight.bpm_after_effort_percent",
                    "insight.fast_recovery_quality",
                    "insight.bpm_after_15_min",
                    "insight.bpm_after_15_min_percent",
                    "insight.heart_rate_15_min_recovery_quality",
                    "insight.bpm_at_end",
                    "insight.mean_hr_first_trot",
                    "insight.mean_speed_first_trot",
                    "insight.mean_symmetry_first_trot",
                    "insight.mean_regularity_first_trot",
                    "insight.mean_hr_first_canter",
                    "insight.mean_speed_first_canter",
                    "insight.mean_regularity_first_canter",
                    "insight.main_work_avg_stride_length",
                    "insight.stride_frequency_at_60",
                    "insight.stride_length_at_60",
                    "insight.max_stride_frequency",
                    "insight.max_stride_length",
                    "insight.stride_length_best_600",
                    "insight.stride_length_best_200",
                    "insight.stride_frequency_at_vmax",
                    "insight.stride_length_at_vmax"
                ];
            case "GALLOP":
            default:
                return [
                    'horse.age',
                    'training.training_type_name',
                    'training.track_name_name',
                    'training.track_surface_name',
                    'training.track_condition_name',
                    'insight.main_work_duration_seconds',
                    'insight.main_work_distance_meters',
                    'insight.max_speed',
                    'insight.time_last_600',
                    'insight.time_last_200',
                    'insight.time_best_600',
                    'insight.time_best_200',
                    'insight.max_heart_rate',
                    'insight.z5_duration',
                    'insight.oxygen_dept_time',
                    'insight.bpm_after_effort',
                    'insight.bpm_after_effort_percent',
                    'insight.fast_recovery_quality',
                    'insight.bpm_after_15_min',
                    'insight.bpm_after_15_min_percent',
                    'insight.heart_rate_15_min_recovery_quality',
                    'insight.bpm_at_end',
                    'insight.stride_length_at_60',
                    'insight.stride_frequency_at_60',
                    'insight.max_stride_frequency',
                    'insight.max_stride_length',
                    'insight.mean_hr_first_trot',
                    'insight.mean_speed_first_trot',
                    'insight.mean_symmetry_first_trot',
                    'insight.mean_regularity_first_trot',
                    'insight.mean_hr_first_canter',
                    'insight.mean_speed_first_canter',
                    'insight.mean_regularity_first_canter',
                ];
        }
    }

    return {
        getParameters: getParametersMutation,
        allParams,
        groupedParams,
        saveNewConfig: saveNewConfigMutation,
        getInsightParametersNames,
        AnalyticViews,
        getViewById: getViewByIdMutation,
        viewsLoading: getConfigs.isLoading || getConfigs.isFetching,
        deleteView: deleteViewMutation,
        updateView: updateViewMutation,
        getFilters: getFiltersMutation,
        fetchAnalyticData
    }
}