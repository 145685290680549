export const languages = [
    {
        key:'fr',
        value:"lang:french",
    },
    {
        key:'en',
        value:"lang:english",
    },
    {
        key:'de',
        value:"lang:german",
    },
    {
        key:'it',
        value:"lang:italian",
    },
    {
        key:'es',
        value:"lang:spanish",
    },
    {
        key:'tr',
        value:"lang:turkish",
    },
]