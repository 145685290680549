import { applyMiddleware, combineReducers, configureStore, createStore } from '@reduxjs/toolkit'
import userReducer from './reducers/user.reducer'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { thunk } from 'redux-thunk'
import modalReducer from './reducers/modal.reducer'
import comparaisonReducer from './reducers/comparaison.reducer'
const persistConfig = {
    key: 'root',
    storage,
}


const rootReducer = combineReducers({
    user: userReducer,
    modals: modalReducer,
    comparaison: comparaisonReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = createStore(persistedReducer, applyMiddleware(thunk))
export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

