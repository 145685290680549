import React, { useEffect, useState } from 'react'
import Header from '../../components/dashboard/Header'
import { Box, Button, Divider, LinearProgress, TextField } from '@mui/material'
import { Add, Search } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { TUser } from '../../shared/types/user.type'
import { useHorses } from '../../hooks/useHorses'
import { get, isEmpty, lowerCase, orderBy } from 'lodash'
import EmptyState from '../../components/global/EmptyState'
import HorsesLoaderSkeleton from '../../components/horses/HorsesLoaderSkeleton'
import Horses from '../../components/horses/Horses'
import { setHorseModal } from '../../store/reducers/modal.reducer'
import Filters from '../../components/global/Filters'
import { THorse } from '../../shared/types/horse.type'
import { TTrainerHorse } from '../../shared/types/trainerHorse.type'
import { TGroupFilter, TSortFilter } from '../../shared/types/SortType'
import { OrderArray, filterByGroup } from '../../shared/utils/SortArray'
import { getAges, getGenders, getStatus, getValuesBaseOnKey } from '../../shared/utils/horseFn'

function Yard() {
  const { t } = useTranslation()
  const auth = useSelector<RootState>(state => state.user.user) as TUser | null
  const isUserLoading = useSelector<RootState>(state => state.user.isUserLoading) as boolean
  const dispatch = useDispatch()
  const { horses, isLoadingHorses, changeHorseStatus, isHorseUpdating } = useHorses(!isUserLoading, get(auth, '@id'))
  const [search, setSearch] = useState('')
  const onInputChange = (id: number, checked: boolean) => {
    changeHorseStatus(id, { horse_in_training: checked })
  }

  const [activeSort, setActiveSort] = useState("")
  const [activeGroups, setActiveGroups] = useState<{ key: string, values: any[], objectPath: string, isDate?: boolean }[]>([])

  const [sortValues, setSortValues] = useState<Array<TSortFilter>>([
    {
      value: 'desc',
      key: 'NAME',
      objectPath: 'horse.name',
      text: 'name'
    },
    {
      value: 'desc',
      key: 'AGE',
      objectPath: 'horse.birthday',
      text: 'age',
      isDate: true
    },
    {
      value: 'desc',
      key: 'GENDER',
      objectPath: 'horse.gender',
      text: 'gender'
    },
    {
      value: 'desc',
      key: 'STATUS',
      objectPath: 'horse_in_training',
      text: 'training-status'
    },
  ])

  const [groupValus, setGroupValues] = useState<Array<TGroupFilter>>([
    {
      key: 'GENDER',
      objectPath: 'horse.gender',
      text: 'gender',
      values: [],
      open: false
    },
    {
      key: 'AGE',
      objectPath: 'horse.birthday',
      text: 'age',
      isDate: true,
      values: [],
      open: false
    },
    {
      key: 'STATUS',
      objectPath: 'horse_in_training',
      text: 'training-status',
      values: getStatus(horses),
      open: false
    },
  ])

  const [filteredHorses, setFilteredHorses] = useState<TTrainerHorse[]>([])

  const openHorseModal = () => {
    dispatch(setHorseModal(true))
  }


  useEffect(() => {
    setGroupValues(prev => [...prev].map(p => ({ ...p, values: getValuesBaseOnKey(p.key, horses) })))
    setFilteredHorses(orderBy(horses, i => !i.horse_in_training,'asc'))
  }, [horses])

  const handleOpen = (key: string, val: boolean) => {
    let GV = [...groupValus]
    let idx = GV.findIndex(e => e.key === key)
    if (idx !== -1) {
      GV[idx].open = val
      setGroupValues(GV)
    }
  }


  const filterHorses = (query: string) => {
    let filtered = horses.filter(e => lowerCase(e.horse?.name).indexOf(lowerCase(query)) !== -1)
    setFilteredHorses(filtered)
  }

  useEffect(() => {
    if (!search) {
      setFilteredHorses(orderBy(horses, i => !i.horse_in_training,'asc'))
    } else {
      filterHorses(search)
    }
  }, [search])

  const onSortChange = (key: string, value: 'asc' | 'desc') => {
    let find = sortValues.findIndex(e => e.key === key)

    if (find > -1) {
      let items = [...sortValues]
      items[find].value = value
      setSortValues(items)
      setActiveSort(key)
      setFilteredHorses(OrderArray(items[find], filteredHorses))

    }
  }

  const resetFilters = () => {
    setActiveSort("")
    setFilteredHorses(horses)
    setSortValues(prev => [...prev].map(p => ({ ...p, value: 'desc' })))
    setActiveGroups([])
  }

  const onGroupChange = (key: string, value: any, objectPath: string, isDate?: boolean) => {
    let active_groups = [...activeGroups]
    let target_group = active_groups.findIndex(e => e?.key === key)


    if (target_group !== -1) {
      let target_values = active_groups[target_group].values
      //check if value already checked


      if (target_values.includes(value)) {
        let idx = target_values.findIndex(val => val === value)
        target_values.splice(idx, 1)
        active_groups[target_group].values = target_values
      } else {
        target_values.push(value)
        active_groups[target_group].values = target_values

      }


    } else {
      active_groups.push({ key, values: [value], objectPath, isDate })
    }

    setFilteredHorses(filterByGroup(active_groups, horses))

    setActiveGroups(active_groups)
  }


  return (
    <>
      <Header text='navbar:myStable'>
        <TextField
          size='small'
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t('equimetre:search')}
          InputProps={{
            startAdornment: <Search color='disabled'></Search>,
          }}
        ></TextField>
        <Box display={'flex'} gap={1}>

          <Filters
            resetFilters={resetFilters}
            activeSort={activeSort}
            activeGroups={activeGroups}
            onSortChange={onSortChange}
            sortValues={sortValues}
            groupValues={groupValus}
            handleOpen={handleOpen}
            onGroupChange={onGroupChange}
          ></Filters>
          <Button onClick={openHorseModal} variant='contained' startIcon={<Add></Add>}>{t('dashboard:addHorse')}</Button>
        </Box>

      </Header>
      <Divider sx={{ mt: 2 }}></Divider>
      {isHorseUpdating && <LinearProgress></LinearProgress>}
      <Box mb={2} ></Box>
      {
        isLoadingHorses ? <HorsesLoaderSkeleton></HorsesLoaderSkeleton> :
          <>
            <Horses onChange={onInputChange} horses={filteredHorses}></Horses>
          </>
      }
    </>
  )
}

export default Yard