
export const galop = [
    {
        key: "equi:registration:product:gallop",
        value: 'GALLOP'
    },
    {
        key: "equi:registration:product:trot",
        value: 'TROT'
    },
    {
        key: "equi:registration:product:vet",
        value: 'VET'
    },
]

export const metrics = [
    {
        key: 'unit_system.metric',
        value: 'metric'
    },
    {
        key: 'unit_system.imperial',
        value: 'imperial'
    },
] as const

export const temperatures = [
    {
        key: 'units:temperature:full:celsius',
        value: 'celsius'
    },
    {
        key: 'units:temperature:full:fahrenheit',
        value: 'fahrenheit'
    },
]

export const dateFormat = [
    {
        key: 'info:dateDDMMYYYY',
        value: 'd/m/Y'
    },
    {
        key: 'info:dateMMDDYYYY',
        value: 'm/d/Y'
    },
]


export const speedUnits = [
    {
        key: 'info:km\/h',
        value: 'km/h',
        enabled: "metric"
    },
    {
        key: 'info:mph',
        value: 'km/h',
        enabled: "imperial"
    },
    {
        key: 'info:m\/min',
        value: 'm/min',
        enabled: ""
    },
    {
        key: 'info:Red. km',
        value: 'Red. km',
        enabled: "metric"
    },
    {
        key: 'info:Mil. red',
        value: 'Red. km',
        enabled: "imperial"
    },
]