import { Close, ConfirmationNumber, Minimize, Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, Button, CircularProgress, Collapse, Divider, Fade, IconButton, Tooltip, Typography } from '@mui/material'
import { t } from 'i18next'
import React, { useState } from 'react'
import { TRider } from '../../shared/types/rider.type'
import { TEquimetre, Ttraining } from '../../shared/types/training.type'
import { THorse } from '../../shared/types/horse.type'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { TUser } from '../../shared/types/user.type'
import { useMutation, useQueryClient } from 'react-query'
import { Exams } from '../../api/exams'
import { toast } from 'react-toastify'
import UnitChanger from './UnitChanger'


const NAVBAR_HEIGHT = '64px'

type bannerProps = {
    open: boolean,
    handleClose: () => void,
    trainer?: Pick<TRider, 'id' | 'name' | '@id'>,
    macAdress?: Pick<TEquimetre, 'mac_address'>,
    horse?: Pick<THorse, 'id' | 'name'>
    exam?: Pick<Ttraining, 'id' | '@id'>
}

function Banner({ open, handleClose, trainer, macAdress, horse, exam }: bannerProps) {
    const postProcessMutation = useMutation(() => Exams.getPostTraining(exam?.id))
    const queryClient = useQueryClient()


    const BACKOFFICE_LINK = process.env.REACT_APP_BACKOFFICE_URI

    const createNewTicket = () => {

        let macAdressId = `/api/mac_addresses/${macAdress?.mac_address.id}`

        window.open(`${BACKOFFICE_LINK}#/tickets/create/?mac_address=${macAdressId}&training=${exam?.['@id']}&client=${trainer?.['@id']}`, '_blank');

    }

    const getPostProcessTraining = () => {
        postProcessMutation.mutateAsync()
            .then(result => {
                toast('Post processing done', { type: 'success' })
                queryClient.refetchQueries(['exam', exam?.id])

            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
            })
    }

    return (
        <Collapse in={open}>
            <Fade in={open}>
                <div className='banner' style={{ position: 'fixed', top: NAVBAR_HEIGHT, zIndex: 10, left: 0, width: '100%', }}>
                    <Box display={'flex'} justifyContent={'end'} px={2}>
                        <IconButton size='small' onClick={handleClose}>
                            <Close sx={{ color: 'white' }}></Close>
                        </IconButton>
                    </Box>


                    <Box display={'flex'} justifyContent={'end'} alignItems={'center'} width={'100%'} px={2} py={1} gap={3}>

                        {/* Exam Info */}
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography variant='caption' color={'white'}>{t('blocks:trainer')} : <a target='_blank' rel="noreferrer" style={{ color: 'white', textDecoration: 'none' }} href={`${BACKOFFICE_LINK}#/users/%2Fapi%2Fusers%2F${trainer?.id}/show`}>{trainer?.name}</a></Typography>
                            <Typography variant='caption' color={'white'}>{t('equimetre:table:equimetre')} : <a target='_blank' rel="noreferrer" style={{ color: 'white', textDecoration: 'none' }} href={`${BACKOFFICE_LINK}#/mac_addresses/%2Fapi%2Fmac_addresses%2F${macAdress?.mac_address?.id}/show`}>{macAdress?.mac_address.mac_address}</a></Typography>
                            <Typography variant='caption' color={'white'}>{t('equimetre:table:horse')} : <a style={{ color: 'white', textDecoration: 'none' }} target='_blank' rel="noreferrer" href={`${BACKOFFICE_LINK}#/horses/%2Fapi%2Fhorses%2F${horse?.id}/show`}>{horse?.name}</a></Typography>
                        </Box>

                        <Box display={'flex'} flexDirection={'column'} alignItems={'self-start'} justifyContent={'flex-start'}>
                            <Typography onClick={getPostProcessTraining} sx={{ cursor: 'pointer' }} variant='caption' color={'white'}>Post process training
                                {postProcessMutation.isLoading && <CircularProgress sx={{ color: 'white', ml: 1 }} size={8}></CircularProgress>}
                            </Typography>
                            <Typography variant='caption' color={'white'}>
                                <a style={{ color: 'white', textDecoration: 'none' }} target='_blank' rel="noreferrer" href={`${BACKOFFICE_LINK}#/trainings/%2Fapi%2Ftrainings%2F${exam?.id}/show`}>Détail admin</a>
                            </Typography>

                        </Box>
                        <Box display={'flex'} flexDirection={'column'} alignItems={'self-start'} justifyContent={'flex-start'}>
                            <UnitChanger></UnitChanger>
                        </Box>

                        <Button startIcon={<ConfirmationNumber></ConfirmationNumber>} onClick={createNewTicket} size='small' sx={{ color: 'white', borderColor: 'white' }} variant='outlined'>Create a ticket</Button>

                    </Box>


                </div>
            </Fade>

        </Collapse>

    )
}

export default Banner