import { Grid, Skeleton } from '@mui/material'
import React from 'react'
import EditTrainingsItemSkeleton from './EditTrainingsItemSkeleton'

function EditTrainingsSkeleton() {
    return (
        <>
            {[...Array(6)].map(e => (
                <EditTrainingsItemSkeleton key={2}></EditTrainingsItemSkeleton>
            ))}

        </>

    )
}

export default EditTrainingsSkeleton