import { Box, Button, Card, Container, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import Error from '../../components/global/Error';
import { useMailSubscribtion } from '../../hooks/useMailSubscribtion';
import { UnsubscribeOutlined } from '@mui/icons-material';
import DeleteModal from '../../components/modals/DeleteModal';
import ListSkeleton from '../../components/exams/loader/ListSkeleton';
import { TContactOptIn } from '../../shared/types/Channel.type';
import { useMutation } from 'react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import EmptyState from '../../components/global/EmptyState';
import { isEmpty } from 'lodash';


function Unsubscribe() {
  //State
  let [searchParams, setSearchParams] = useSearchParams();


  //constantes
  const email = searchParams.get('email') ?? undefined
  const uuid = searchParams.get('uuid') ?? undefined

  //Hooks
  const { channels, channelsLoading, refetch } = useMailSubscribtion({ email, uuid })


  return (
    <Container maxWidth="lg" sx={{ my: 2 }}>
      <Card >
        <Box sx={{ p: 3 }}>
          <Typography variant='h6'>{t('subscription:title')}</Typography>
        </Box>
        <Divider></Divider>
        {channelsLoading ? <ListSkeleton length={4} height={50}></ListSkeleton> :
          (isEmpty(channels) && !channelsLoading) ? <EmptyState></EmptyState> :
            <List>
              {channels.map((channel, index) => (
                <>
                  <ChannelElement onSaveSuccess={() => refetch()} channel={channel}></ChannelElement>
                  {index !== channels.length - 1 && <Divider></Divider>}
                </>
              ))}
            </List>
        }
      </Card>
    </Container>
  )
}

function ChannelElement({ channel, onSaveSuccess }: { channel: TContactOptIn, onSaveSuccess: () => void }) {

  const [openConfirmationModal, setConfirmationModal] = useState(false)
  const leaveMutation = useMutation((link: string) => axios.post(link))


  const leave = () => {
    leaveMutation.mutateAsync(channel.opt_out_url)
      .then((res) => {
        toast(t('rights:success'), { type: 'success' })
        onSaveSuccess()
        setConfirmationModal(false)
      }).catch(err => {
        toast(t('error:generic'), { type: 'error' })

      })
  }

  return (
    <ListItem>
      <ListItemText >
        <Typography variant='body2'>
          {t('subscription:listeName', {
            name: channel.communication_channel_name,
            sender_name: channel.invited_by_full_name,
            sender_email: channel.invited_by_email
          })}
        </Typography>
      </ListItemText>
      <ListItemIcon >
        <Button disabled={!channel.opted_in} onClick={() => setConfirmationModal(true)} startIcon={<UnsubscribeOutlined></UnsubscribeOutlined>} variant='contained' >{t('subscription:unsubscribe')}</Button>
      </ListItemIcon>


      {/* Confirmation Modal */}

      <DeleteModal
        open={openConfirmationModal}
        handleClose={() => setConfirmationModal(false)}
        title={t('subscription:unsubscribe')}
        subtitle={t('subscription:leave', {
          list_name: channel.communication_channel_name
        })}
        isDeleting={leaveMutation.isLoading}
        dangerText='subscription:unsubscribe'
        onDelete={leave}
      />

    </ListItem>
  )
}

export default Unsubscribe