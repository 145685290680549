
import React, { useEffect, useMemo, useRef, useState } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts, { PlotLineOrBand } from 'highcharts'
import dayjs from 'dayjs'
import Duration from 'dayjs/plugin/duration'
import { Scale } from '../../../pages/trainings/ExamView'
import { mergeArr, startTimeFrom0 } from '../../../shared/utils/MapSection'
import { convertSpeed, convertStrideLength, getConvertedSpeed, getSpeedForChart, getSpeedTooltipForChart } from '../../../shared/utils/conversion'
import { get, isEmpty, set, toNumber } from 'lodash'
import { t } from 'i18next'
import { useAuth } from '../../../hooks/useAuth'
import { useChart } from '../../../hooks/useChart'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { CheckPoint } from '../../../shared/types/training.type'
dayjs.extend(Duration)

type CheckPointsChartProps = {
    speed: number[]
    bpm: number[],
    distance: number[],
    time: number[],
    flIndex?: number | null,
    scaleOption: Scale,
    isFinishLineActive: boolean,
    checkpoints: CheckPoint[]

}

function CheckPointsChart({ time, speed, bpm, distance, flIndex, scaleOption, isFinishLineActive, checkpoints }: CheckPointsChartProps) {
    const { getSpeedUnit } = useAuth()
    const { addFinishLine } = useChart()
    const chartRef = useRef<HighchartsReact.RefObject>(null)
    const metric = useSelector<RootState>(state => state.user.user?.unit_system) as "metric" | "imperial" | undefined
    const [isZoomActive, setIsZoomActive] = useState(false)


    const formattedTime = useMemo(() => {
        return startTimeFrom0(time)
    }, [time])

    useEffect(() => {
        drawFinishLine()
    }, [flIndex, scaleOption, formattedTime, distance, isFinishLineActive, isZoomActive])

    const drawFinishLine = () => {
        if (!isFinishLineActive) return
        let svgs = get(chartRef.current?.chart, 'svgs', [])

        if (!isEmpty(svgs)) {
            /* @ts-ignore */
            svgs.forEach(svg => svg?.destroy())
        }
        /* @ts-ignore */
        set(chartRef.current?.chart, 'svgs', [])

        addFinishLine(flIndex ?? null, scaleOption, distance, formattedTime, chartRef.current?.chart, 8)
    }

    const options = {
        chart: {
            type: 'line',
            zoomType: "x",
            backgroundColor: 'transparent',
            animation: false,
        },
        credits: {
            enabled: false
        },
        title: {
            text: ''
        },
        legend: { enabled: false },

        yAxis: [{
            labels: {
                style: {
                    color: 'white'
                },

                /* @ts-ignore */
                formatter: function () {
                    /* @ts-ignore */
                    return getSpeedForChart(toNumber(this.value), getSpeedUnit()?.value ?? "")
                }
            }, // Primary yAxis
            title: {
                text: t('graph:vitesse') + ` (${t(getSpeedUnit()?.key ?? "")})`,
                style: {
                    color: "rgb(44, 175, 254)"
                }
            },
            opposite: false
        }, { // Primary yAxis
            title: {
                text: t('graph:bpm'),
                style: {
                    color: 'rgba(245, 39, 39, 0.8)'
                }
            },
            labels: {
                style: {
                    color: 'white'
                },
            },
            opposite: true
        }],
        xAxis: {
            events: {
                afterSetExtremes: function (event: Highcharts.AxisSetExtremesEventObject) {
                    let min = event.min
                    let max = event.max
                    if (min < 0) {
                        max -= Math.abs(min)
                        min = 0
                    }

                    addLabels()

                    if (event.dataMin == min && event.dataMax == max)
                        setIsZoomActive(false)
                    else
                        setIsZoomActive(true)

                }
            },
            crosshair: {
                width: 1,
            },
            type: scaleOption === 'DISTANCE' ? 'linear' : 'datetime',
            labels: {
                style: {
                    color: 'white'
                },
                /* @ts-ignore */
                formatter: function () {
                    /* @ts-ignore */
                    return scaleOption === 'DISTANCE' ? convertSpeed(metric, this.value) : dayjs.duration(this.value, 'seconds').minutes() + ' mn'
                }
            }
        },
        tooltip: {
            shared: true,
            shadow: false,
            style: {
                fontWeight: 'bold',
                fontSize: '12px',
                backgound: 'transparent'
            },
            positioner: function () {
                return { x: get(this, 'chart.plotWidth', 0) - 80, y: -10 };
            },
            formatter: function (this: Highcharts.TooltipFormatterContextObject) {
                let str = ''
                str += this?.points && this?.points[0]?.y + " " + t('graph:bpm') + ' - '
                /* @ts-ignore */
                str += getSpeedTooltipForChart(getSpeedForChart(toNumber(this.points[1].y), getSpeedUnit()?.value), getSpeedUnit()?.value)
                return str

            },
        },
        series: [
            {
                yAxis: 0,
                data: mergeArr(scaleOption === 'DISTANCE' ? distance : formattedTime, speed),

                point: {

                },
            },
            {
                yAxis: 1,
                data: mergeArr(scaleOption === 'DISTANCE' ? distance : formattedTime, bpm),
                color: 'rgba(245, 39, 39, 0.8)',
                point: {
                },
            },
        ]
    }

    useEffect(() => {
        addLabels()
    }, [checkpoints, scaleOption])

    const removeLabels = () => {
        let labels: Highcharts.SVGElement[] = get(chartRef.current?.chart, 'labels', [])

        if (!isEmpty(labels)) {
            labels.forEach(label => label.destroy())
            /* @ts-ignore */
            set(chartRef.current?.chart, 'labels', labels)
        }
    }
    const addLabels = () => {
        let chart = chartRef.current?.chart
        if (isEmpty(checkpoints) || !chart) return
        let data: number[] = get(chart, 'series[0].xData', [])
        let labels: Highcharts.SVGElement[] = []

        removeLabels()

        checkpoints.forEach((cp, i) => {

            let box = chart?.renderer.label(
                `<p style="margin-top:14px;background-color:rgba(39, 58, 245, 0.8);color:white;padding:8px;border:0.5px solid #eee;transform: translateX(-50%);border-radius:5px;box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;">${cp?.checkpoint?.name} - ${t('intervals:gait:' + cp.gait)} </br>
                <strong> ${t('intervals:pace')}</strong> : ${getConvertedSpeed(cp.speed, getSpeedUnit()?.value ?? '')}</br>
                <strong>${t('intervals:averageFC')}</strong> : ${cp.heart_rate} BPM </br>
                <strong>${t('intervals:averageCad')}</strong> : ${cp.stride_frequency} str/sec </br>
                <strong>${t('intervals:averageAmpl')}</strong> : ${convertStrideLength(metric, cp.stride_length)} ${metric === 'metric' ? t('compare:meters') : t('scale:pieds')}
                </p>`,
                chart?.xAxis[0].toPixels(data[cp.index_moment], false), 0, undefined, undefined, undefined, true)
                .attr({
                    'text-anchor': "middle",
                })
                .css({
                    fontSize: '12px',
                })
                .add().hide()



            let label = chart?.renderer.label(String(i + 1) + '</br> |', chart?.xAxis[0].toPixels(data[cp.index_moment], false), 0)
                .attr({
                    'text-anchor': "middle",
                })
                .css({
                    fontSize: '12px',
                    color: 'white',
                    cursor: 'copy',
                }).on('mouseover', () => {
                    box && box.translate(chart?.xAxis[0].toPixels(data[cp.index_moment], false) ?? 0, 15).show()
                })
                .on('mouseout', () => {
                    box && box.hide()

                })
                .add().toFront()

            label && labels.push(label)
        })
        /* @ts-ignore */
        set(chartRef.current?.chart, 'labels', labels)
    }

    return (
        <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            options={options}
            containerProps={{ style: { position: 'relative', height: '220px' } }}
        />
    )
}

export default CheckPointsChart