import { useMutation, useQuery } from "react-query"
import { Horse, saveTrainerType } from "../api/horses"
import { useState } from "react"
import { THorse } from "../shared/types/horse.type"
import { TTrainerHorse } from "../shared/types/trainerHorse.type"
import { get, isEmpty } from "lodash"
import { toast } from "react-toastify"
import { t } from "i18next"

export const useHorses = (enableQuery: boolean = false, trainer?: string) => {
    const [horses, setHorses] = useState<TTrainerHorse[]>([])

    const updateHorseTrainerMutation = useMutation(({ id, data }: { id: number, data: Partial<TTrainerHorse> }) => Horse.updateHorseStatus(id, data))
    const checkMicroshipMutation = useMutation((microchip: number) => Horse.checkMicroship(microchip))
    const saveHorseTrainerMutation = useMutation((data: saveTrainerType) => Horse.saveTrainerHorse(data))
    const getBreedsMutation = useMutation(() => Horse.getBreeds())
    const saveHorseMutation = useMutation((data: Partial<THorse>) => Horse.saveHorse(data))
    const updateHorseMutation = useMutation(({ id, data }: { id: number, data: Partial<THorse> }) => Horse.updateHorse(id, data))
    const queryHorses = useQuery(['horses'], () => Horse.getHorses(trainer), {
        enabled: enableQuery && !isEmpty(trainer),
        refetchOnWindowFocus: false,
        cacheTime: 900000,
        onSuccess: ({ data }) => {
            setHorses(get(data, 'hydra:member', []))
        }
    })

    const updateHorseState = (id: number, data: Partial<TTrainerHorse>) => {
        if (horses.length > 0) {
            let find = horses.find(horse => horse.id === id)
            let index = horses.findIndex(horse => horse.id === id)
            if (!find) return
            let newObject = Object.assign(find, data)
            let _horses = [...horses]
            _horses[index] = newObject

            setHorses(_horses)
        }
    }

    const changeHorseStatus = (id: number, data: Partial<TTrainerHorse>) => {
        updateHorseTrainerMutation.mutateAsync({ id, data })
            .then(result => {
                updateHorseState(id, data)
            }).catch(err => {
                toast(t('error:generic'), { type: 'error' })
            })
    }


    return {
        horses,
        changeHorseStatus,
        getBreeds: getBreedsMutation,
        updateHorse: updateHorseMutation,
        saveHorse: saveHorseMutation,
        checkMicroship: checkMicroshipMutation,
        saveHorseTrainer: saveHorseTrainerMutation,
        isSavingHorseTrainer: saveHorseTrainerMutation.isLoading,
        isHorseUpdating: updateHorseTrainerMutation.isLoading,
        isLoadingHorses: queryHorses.isLoading || queryHorses.isFetching
    }
}