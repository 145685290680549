import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Checkbox, Chip, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Switch, TextField, Typography } from '@mui/material'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { t } from 'i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { hands } from '../../enums/trackGroup.enums'
import { TTrainingType } from '../../shared/types/training.type'
import { Ttrack, trackSurface } from '../../shared/types/tracks.type'
import { THorse } from '../../shared/types/horse.type'
import { TTrainerHorse } from '../../shared/types/trainerHorse.type'
import { get, random, toNumber, uniqBy, uniqueId } from 'lodash'
import { TRider } from '../../shared/types/rider.type'
import { TtrackCondition } from '../../shared/types/trackCondition.type'
import { TShoeing } from '../../shared/types/shoeing.type'
import { harness } from '../../enums/harness.enum'
import { TAnalyticView } from '../../shared/types/analyticView.type'
import { LoadingButton } from '@mui/lab'
import ListSkeleton from '../exams/loader/ListSkeleton'
import { TuserAccountType } from '../../shared/types/user.type'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

type SettingsProps = {
    trainingTypes: TTrainingType[],
    tracks: Ttrack[],
    horses: TTrainerHorse[],
    riders: TRider[],
    trackConditions: TtrackCondition[],
    shoeing: TShoeing[],
    updateViewSetting: (data: any) => void,
    isUpdating: boolean,
    dataLoading: boolean,
    selectedView: TAnalyticView | null,
    surfaces: trackSurface[]

}

function Settings({ trainingTypes, tracks, horses, riders, trackConditions, shoeing, updateViewSetting, isUpdating, dataLoading, selectedView, surfaces }: SettingsProps) {
    //Hooks
    const accountType = useSelector<RootState>(state => state.user.user?.type) as TuserAccountType | undefined

    //states
    const [all_hand_turns, setAllHandsTurn] = useState<boolean>(true)
    const [setlectedHands, setSelectedHands] = useState<{ key: string, value: number }[]>([])

    const [showTrainingType, setShowTrainingType] = useState(true)
    const [selectedTypes, setSelectedTypes] = React.useState<TTrainingType[]>([]);


    const [showPiste, setShowPiste] = useState(true)
    const [selectedTracks, setSelectedTracks] = useState<Ttrack[]>([])

    const [showHorses, setShowHorses] = useState(true)
    const [selectedHorses, setSelectedHorses] = React.useState<THorse[]>([]);

    const [showAges, setShowAges] = useState(true)
    const [selectedAges, setSelectedAges] = React.useState<string[]>([]);

    const [showRiders, setShowRiders] = useState(true)
    const [selectedRiders, setSelectedRiders] = React.useState<TRider[]>([]);

    const [showSurfaces, setShowSurfaces] = useState(true)
    const [selectedSurfaces, setSelectedSurfaces] = React.useState<trackSurface[]>([]);

    const [showHarness, setShowHarness] = useState(true)
    const [selectedHarness, setSelectedHarness] = React.useState<{ key: string, value: string }[]>([]);

    const [showTrackCondition, setShowTrackCondition] = useState(true)
    const [selectedTrackCondition, setSelectedTrackCondition] = React.useState<TtrackCondition[]>([]);

    const [showShoeing, setShowShoeing] = useState(true)
    const [selectedShoeing, setSelectedShoeing] = React.useState<TShoeing[]>([]);


    const [nullHands, setNullHands] = useState<boolean>(true)
    const [nullHarness, setNullHarness] = useState<boolean>(true)
    const [nullHorseAge, setNullHorseAge] = useState<boolean>(true)
    const [nullHorseShoeing, setNullHorseShoeing] = useState<boolean>(true)
    const [nullRider, setNullRider] = useState<boolean>(true)
    const [nullTrackCondition, setNullTrackCondition] = useState<boolean>(true)
    const [nullTrackName, setNullTrackName] = useState<boolean>(true)
    const [nullTrackSurface, setNullTrackSurface] = useState<boolean>(true)
    const [nullTrainingType, setNullTrainingType] = useState<boolean>(true)

    //Effects
    const _horses = useMemo(() => {
        return uniqBy(horses.map(e => e.horse), '@id')
    }, [horses])

    useEffect(() => {
        if (!selectedView) return

        setAllHandsTurn(selectedView.all_hand_turns)
        setSelectedHands(hands.filter(e => selectedView.hand_turns?.includes(e.value)))

        setShowTrainingType(selectedView.all_training_types)
        setSelectedTypes(selectedView?.training_types ?? [])

        setShowPiste(selectedView.all_track_names)
        setSelectedTracks(selectedView?.track_names ?? [])

        setShowHorses(selectedView.all_horses)
        setSelectedHorses(get(selectedView, 'horses', []) as THorse[])

        setShowAges(selectedView.all_horse_ages)
        setSelectedAges(selectedView.horse_ages?.map(e => String(e)) ?? [])

        setShowRiders(selectedView.all_riders)
        setSelectedRiders(selectedView.riders)

        setShowSurfaces(selectedView.all_track_surfaces)
        setSelectedSurfaces(selectedView.track_surfaces)

        setShowHarness(selectedView.all_harnessed_mounteds)
        setSelectedHarness(harness.filter(e => selectedView.harnessed_mounteds?.includes(e.value)))

        setShowTrackCondition(selectedView.all_track_conditions)
        setSelectedTrackCondition(selectedView.track_conditions)

        setShowShoeing(selectedView.all_horse_shoeings)
        setSelectedShoeing(selectedView.horse_shoeings)
    }, [selectedView])


    //fns
    const handleChangeAge = (event: SelectChangeEvent<typeof selectedAges>) => {
        const {
            target: { value },
        } = event;
        console.log({ value });

        setSelectedAges(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const saveSettings = () => {
        let data: any = {
            hand_turns: setlectedHands.map(e => e.value),
            all_hand_turns,

            all_harnessed_mounteds: showHarness,
            harnessed_mounteds: selectedHarness.map(e => e.value),

            all_horse_ages: showAges,
            horse_ages: selectedAges.map(e => toNumber(e)),

            all_horse_shoeings: showShoeing,
            horse_shoeings: selectedShoeing.map(e => e['@id']),

            all_horses: showHorses,
            horses: selectedHorses.map(e => e['@id']),

            all_riders: showRiders,
            riders: selectedRiders.map(e => e['@id']),

            all_track_conditions: showTrackCondition,
            track_conditions: selectedTrackCondition.map(e => e['@id']),

            all_track_names: showPiste,
            track_names: selectedTracks.map(e => e['@id']),

            all_track_surfaces: showSurfaces,
            track_surfaces: selectedSurfaces.map(e => e['@id']),

            all_training_types: showTrainingType,
            training_types: selectedTypes.map(e => e['@id']),


            "include_null_hand": nullHands,
            "include_null_harnessed_mounted": nullHarness,
            "include_null_horse_age": nullHorseAge,
            "include_null_horse_shoeing": nullHorseShoeing,
            "include_null_rider": nullRider,
            "include_null_track_condition": nullTrackCondition,
            "include_null_track_name": nullTrackName,
            "include_null_track_surface": nullTrackSurface,
            "include_null_training_type": nullTrainingType,





        }

        updateViewSetting(data)

    }

    console.log({ selectedAges: selectedAges.length === 20 })
    return (
        <Accordion elevation={1} sx={{ mt: 2 }} defaultExpanded>
            <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
                    <Typography variant='subtitle2'>{t('navbar:settings')}</Typography>
                    <LoadingButton loading={isUpdating} variant='contained' sx={{ mr: 2 }} onClick={e => {
                        e.stopPropagation()
                        saveSettings()
                    }}>{t('button:save')}</LoadingButton>
                </Box>

            </AccordionSummary>
            <AccordionDetails>
                {
                    dataLoading ? <ListSkeleton length={10} height={100}></ListSkeleton>
                        : <>

                            <Box display={'flex'} flexDirection={'column'} my={2}>
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography variant='body2' fontWeight={500}>{t('notification:field:showTrainingType')}</Typography>
                                    <Switch checked={!showTrainingType} onChange={(_, checked) => {
                                        setShowTrainingType(!checked)
                                    }}></Switch>
                                </Box>

                                {
                                    !showTrainingType &&
                                    <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} my={2} alignItems={'center'}>
                                        <Typography variant='body2' fontWeight={500}>{t('editTrainingForm:trainingType:label')}</Typography>
                                        <FormControl >
                                            <Autocomplete
                                                id="demo-simple-select"
                                                options={trainingTypes}
                                                multiple
                                                disableCloseOnSelect
                                                sx={{ maxWidth: '500px', minWidth: '300px' }}
                                                getOptionLabel={(option) => option.display_name ?? ' - '}
                                                getOptionKey={option => option['@id']}
                                                value={selectedTypes}
                                                renderInput={(params) => <TextField {...params} label={t('editTrainingForm:trainingType:label')} />}
                                                onChange={(_, values) => setSelectedTypes(values)}
                                                PaperComponent={(paperProps) => (
                                                    <AdditionalValues
                                                        paperProps={paperProps}
                                                        nullValue={nullTrainingType}
                                                        updateNullValue={(value) => setNullTrainingType(value)}
                                                        allSelected={selectedTypes.length === trainingTypes.length && nullTrainingType}
                                                        updateSelect={values => setSelectedTypes(values)}
                                                        values={trainingTypes}

                                                    />)}
                                            ></Autocomplete> </FormControl>
                                    </Box>
                                }

                            </Box>

                            <Divider></Divider>

                            <Box display={'flex'} flexDirection={'column'} my={2} >
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography variant='body2' fontWeight={500}>{t('notification:field:showPiste')}</Typography>
                                    <Switch checked={!showPiste} onChange={(_, checked) => {
                                        setShowPiste(!checked)
                                    }}></Switch>
                                </Box>
                                {
                                    !showPiste &&
                                    <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} alignItems={'center'} mt={2}>
                                        <Typography variant='body2' fontWeight={500}>{t('editTrainingForm:trackName:label')}</Typography>

                                        <FormControl  >
                                            <Autocomplete
                                                id="demo-simple-select"
                                                options={tracks}
                                                multiple
                                                disableCloseOnSelect
                                                sx={{ maxWidth: '500px', minWidth: '300px' }}
                                                getOptionLabel={(option) => option.display_name ?? ' - '}
                                                getOptionKey={option => option['@id']}
                                                value={selectedTracks}
                                                renderInput={(params) => <TextField {...params} label={t('editTrainingForm:trackName:label')} />}
                                                onChange={(_, values) => setSelectedTracks(values)}
                                                PaperComponent={(paperProps) => <AdditionalValues
                                                    allSelected={selectedTracks.length === tracks.length && nullTrackName}
                                                    updateSelect={values => setSelectedTracks(values)}
                                                    values={tracks}
                                                    paperProps={paperProps} nullValue={nullTrackName} updateNullValue={(value) => setNullTrackName(value)} ></AdditionalValues>}


                                            ></Autocomplete>


                                        </FormControl>
                                    </Box>
                                }

                            </Box>
                            <Divider></Divider>

                            <Box display={'flex'} flexDirection={'column'} my={2}>

                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography variant='body2' fontWeight={500}>{t('notification:field:showHorses')}</Typography>
                                    <Switch checked={!showHorses} onChange={(_, checked) => {
                                        setShowHorses(!checked)
                                    }}></Switch>
                                </Box>

                                {!showHorses &&
                                    <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} my={2} alignItems={'center'}>
                                        <Typography variant='body2' fontWeight={500}>{t('horses')}</Typography>
                                        <FormControl >
                                            <Autocomplete
                                                id="demo-simple-select"
                                                options={_horses}
                                                multiple
                                                disableCloseOnSelect
                                                sx={{ maxWidth: '500px', minWidth: '300px', maxHeight: '250px', overflow: 'auto' }}
                                                getOptionLabel={(option) => (option?.display_name ?? option?.name) ?? ' - '}
                                                getOptionKey={option => get(option, '@id')}
                                                value={selectedHorses}
                                                renderInput={(params) => <TextField {...params} label={t('horses')} />}
                                                onChange={(_, values) => {
                                                    setSelectedHorses(values)
                                                }}
                                                PaperComponent={(paperProps) => (
                                                    <AdditionalValues
                                                        paperProps={paperProps}
                                                        nullValue={false}
                                                        updateNullValue={(value) => { }}
                                                        allSelected={selectedHorses.length === _horses.length}
                                                        updateSelect={values => setSelectedHorses(values)}
                                                        values={_horses}
                                                        hideNA

                                                    />)}
                                            ></Autocomplete>



                                        </FormControl>
                                    </Box>}
                            </Box>
                            <Divider></Divider>

                            <Box display={'flex'} flexDirection={'column'} my={2}>

                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography variant='body2' fontWeight={500}>{t('notification:field:showAges')}</Typography>
                                    <Switch checked={!showAges} onChange={(_, checked) => {
                                        setShowAges(!checked)
                                    }}></Switch>
                                </Box>

                                {
                                    !showAges && <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} my={2} alignItems={'center'}>
                                        <Typography variant='body2' fontWeight={500}>{t('age')}</Typography>
                                        <FormControl >
                                            <InputLabel id="demo-simple-select-label">{t('age')}</InputLabel>
                                            <Select
                                                sx={{ width: '300px' }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                multiple
                                                value={selectedAges}
                                                onChange={e => {
                                                    handleChangeAge(e)
                                                }}
                                                renderValue={selected => (
                                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                        {selected.map((e, i) => (
                                                            <Chip key={e} label={e} ></Chip>
                                                        ))}
                                                    </Box>
                                                )}
                                                label={t('age')}>
                                                <AdditionalValues
                                                    allSelected={selectedAges.length === 20 && nullHorseAge}
                                                    updateSelect={values => setSelectedAges(values)}
                                                    values={[...Array(20)].map((e, i) => String(i))}
                                                    paperProps={{}}
                                                    nullValue={nullHorseAge}

                                                    updateNullValue={(value) => setNullHorseAge(value)} ></AdditionalValues>

                                                {[...Array(20)].map((item, i) =>
                                                    <MenuItem key={i} value={i + 1}>{i + 1}</MenuItem>

                                                )}
                                            </Select>


                                        </FormControl>
                                    </Box>
                                }

                            </Box>
                            <Divider></Divider>

                            <Box display={'flex'} flexDirection={'column'} my={2} >
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography variant='body2' fontWeight={500}>{t('notification:field:showRiders')}</Typography>
                                    <Switch checked={!showRiders} onChange={(_, checked) => {
                                        setShowRiders(!checked)
                                    }}></Switch>
                                </Box>
                                {
                                    !showRiders &&
                                    <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} alignItems={'center'} mt={2}>
                                        <Typography variant='body2' fontWeight={500}>{t('editTrainingForm:riderName:label')}</Typography>

                                        <FormControl  >
                                            <Autocomplete
                                                id="demo-simple-select"
                                                options={riders}
                                                multiple
                                                disableCloseOnSelect
                                                sx={{ maxWidth: '500px', minWidth: '300px' }}
                                                getOptionLabel={(option) => option?.display_name ?? option.name}
                                                getOptionKey={option => option['@id']}
                                                value={selectedRiders}
                                                renderInput={(params) => <TextField {...params} label={t('editTrainingForm:riderName:label')} />}
                                                onChange={(_, values) => setSelectedRiders(values)}
                                                PaperComponent={(paperProps) => <AdditionalValues
                                                    allSelected={selectedRiders.length === riders.length && nullRider}
                                                    updateSelect={values => setSelectedRiders(values)}
                                                    values={riders}
                                                    paperProps={paperProps} nullValue={nullRider} updateNullValue={(value) => setNullRider(value)} ></AdditionalValues>}

                                            ></Autocomplete>


                                        </FormControl>
                                    </Box>
                                }

                            </Box>
                            <Divider></Divider>

                            <Box display={'flex'} flexDirection={'column'} my={2} >
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography variant='body2' fontWeight={500}>{t('notification:field:showSurfaces')}</Typography>
                                    <Switch checked={!showSurfaces} onChange={(_, checked) => {
                                        setShowSurfaces(!checked)
                                    }}></Switch>
                                </Box>
                                {
                                    !showSurfaces &&
                                    <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} alignItems={'center'} mt={2}>
                                        <Typography variant='body2' fontWeight={500}>{t('editTrainingForm:trackSurface:label')}</Typography>

                                        <FormControl  >
                                            <Autocomplete
                                                id="demo-simple-select"
                                                options={surfaces}
                                                multiple
                                                disableCloseOnSelect
                                                sx={{ maxWidth: '500px', minWidth: '300px' }}
                                                getOptionLabel={(option) => option.display_name ?? option.name}
                                                getOptionKey={option => option['@id']}
                                                value={selectedSurfaces}
                                                renderInput={(params) => <TextField {...params} label={t('editTrainingForm:trackSurface:label')} />}
                                                onChange={(_, values) => setSelectedSurfaces(values)}
                                                PaperComponent={(paperProps) => <AdditionalValues
                                                    allSelected={selectedSurfaces.length === surfaces.length && nullRider}
                                                    updateSelect={values => setSelectedSurfaces(values)}
                                                    values={surfaces}
                                                    paperProps={paperProps} nullValue={nullTrackSurface} updateNullValue={(value) => setNullTrackSurface(value)} ></AdditionalValues>}

                                            ></Autocomplete>


                                        </FormControl>
                                    </Box>
                                }

                            </Box>
                            <Divider></Divider>

                            <Box display={'flex'} flexDirection={'column'} my={2} >
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography variant='body2' fontWeight={500}>{t('notification:field:showTrackCondition')}</Typography>
                                    <Switch checked={!showTrackCondition} onChange={(_, checked) => {
                                        setShowTrackCondition(!checked)
                                    }}></Switch>
                                </Box>
                                {
                                    !showTrackCondition &&
                                    <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} alignItems={'center'} mt={2}>
                                        <Typography variant='body2' fontWeight={500}>{t('editTrainingForm:trackCondition:label')}</Typography>

                                        <FormControl  >
                                            <Autocomplete
                                                id="demo-simple-select"
                                                options={trackConditions}
                                                multiple
                                                disableCloseOnSelect
                                                sx={{ maxWidth: '500px', minWidth: '300px' }}
                                                getOptionLabel={(option) => option.display_name ?? ' - '}
                                                getOptionKey={option => option['@id']}
                                                value={selectedTrackCondition}
                                                renderInput={(params) => <TextField {...params} label={t('editTrainingForm:trackCondition:label')} />}
                                                onChange={(_, values) => setSelectedTrackCondition(values)}
                                                PaperComponent={(paperProps) => <AdditionalValues
                                                    allSelected={selectedTrackCondition.length === trackConditions.length && nullTrackCondition}
                                                    updateSelect={values => setSelectedTrackCondition(values)}
                                                    values={trackConditions}
                                                    paperProps={paperProps} nullValue={nullTrackCondition} updateNullValue={(value) => setNullTrackCondition(value)} ></AdditionalValues>}

                                            ></Autocomplete>


                                        </FormControl>
                                    </Box>
                                }

                            </Box>
                            <Divider></Divider>

                            <Box display={'flex'} flexDirection={'column'} my={2} >
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography variant='body2' fontWeight={500}>{t('notification:field:showShoeing')}</Typography>
                                    <Switch checked={!showShoeing} onChange={(_, checked) => {
                                        setShowShoeing(!checked)
                                    }}></Switch>
                                </Box>
                                {
                                    !showShoeing &&
                                    <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} alignItems={'center'} mt={2}>
                                        <Typography variant='body2' fontWeight={500}>{t('qualification:horseShoeing')}</Typography>

                                        <FormControl  >
                                            <Autocomplete
                                                id="demo-simple-select"
                                                options={shoeing}
                                                multiple
                                                disableCloseOnSelect
                                                sx={{ maxWidth: '500px', minWidth: '300px' }}
                                                getOptionLabel={(option) => option?.display_name ?? option?.name ?? ' - '}
                                                getOptionKey={option => option['@id']}
                                                value={selectedShoeing}
                                                renderInput={(params) => <TextField {...params} label={t('qualification:horseShoeing')} />}
                                                onChange={(_, values) => setSelectedShoeing(values)}
                                                PaperComponent={(paperProps) => <AdditionalValues
                                                    allSelected={selectedShoeing.length === shoeing.length && nullHorseShoeing}
                                                    updateSelect={values => setSelectedShoeing(values)}
                                                    values={shoeing}
                                                    paperProps={paperProps} nullValue={nullHorseShoeing} updateNullValue={(value) => setNullHorseShoeing(value)} ></AdditionalValues>}

                                            ></Autocomplete>


                                        </FormControl>
                                    </Box>
                                }

                            </Box>
                            <Divider></Divider>

                            <Box display={'flex'} flexDirection={'column'} my={2} >
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography variant='body2' fontWeight={500}>{t('notification:field:showHand')}</Typography>
                                    <Switch checked={!all_hand_turns} onChange={(_, checked) => {
                                        setAllHandsTurn(!checked)
                                    }}></Switch>
                                </Box>
                                {
                                    !all_hand_turns &&
                                    <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} alignItems={'center'} mt={2}>
                                        <Typography variant='body2' fontWeight={500}>{t('units:hands')}</Typography>

                                        <FormControl  >
                                            <Autocomplete
                                                id="demo-simple-select"
                                                options={hands}
                                                multiple
                                                disableCloseOnSelect
                                                sx={{ maxWidth: '500px', minWidth: '300px' }}
                                                getOptionLabel={(option) => t(option.key)}
                                                getOptionKey={option => option.value}
                                                value={setlectedHands}
                                                renderInput={(params) => <TextField {...params} label={t('units:hands')} />}
                                                onChange={(_, values) => setSelectedHands(values)}
                                                PaperComponent={(paperProps) => <AdditionalValues
                                                    allSelected={setlectedHands.length === hands.length && nullHands}
                                                    updateSelect={values => setSelectedHands(values)}
                                                    values={hands}
                                                    paperProps={paperProps} nullValue={nullHands} updateNullValue={(value) => setNullHands(value)} ></AdditionalValues>}

                                            ></Autocomplete>


                                        </FormControl>
                                    </Box>
                                }

                            </Box>
                            {
                                accountType === 'TROT' &&
                                <>
                                    <Divider></Divider>

                                    <Box display={'flex'} flexDirection={'column'} my={2} >
                                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                            <Typography variant='body2' fontWeight={500}>{t('notification:field:showHarness')}</Typography>
                                            <Switch checked={!showHarness} onChange={(_, checked) => {
                                                setShowHarness(!checked)
                                            }}></Switch>
                                        </Box>
                                        {
                                            !showHarness &&
                                            <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} alignItems={'center'} mt={2}>
                                                <Typography variant='body2' fontWeight={500}>{t('qualification:harnessedMounted:harnessedMounted')}</Typography>

                                                <FormControl  >
                                                    <Autocomplete
                                                        id="demo-simple-select"
                                                        options={harness}
                                                        multiple
                                                        disableCloseOnSelect
                                                        sx={{ maxWidth: '500px', minWidth: '300px' }}
                                                        getOptionLabel={(option) => t(option.key)}
                                                        getOptionKey={option => option.value}
                                                        value={selectedHarness}
                                                        renderInput={(params) => <TextField {...params} label={t('qualification:harnessedMounted:harnessedMounted')} />}
                                                        onChange={(_, values) => setSelectedHarness(values)}
                                                        PaperComponent={(paperProps) => <AdditionalValues
                                                            allSelected={selectedHarness.length === harness.length && nullHarness}
                                                            updateSelect={values => setSelectedHarness(values)}
                                                            values={harness}
                                                            paperProps={paperProps} nullValue={nullHarness} updateNullValue={(value) => setNullHarness(value)} ></AdditionalValues>}

                                                    ></Autocomplete>


                                                </FormControl>
                                            </Box>
                                        }

                                    </Box>
                                </>
                            }

                        </>

                }


            </AccordionDetails>
        </Accordion >
    )
}

function AdditionalValues({ paperProps, updateNullValue, nullValue, allSelected, updateSelect, values, hideNA }: { paperProps: React.HTMLAttributes<HTMLElement>, updateNullValue: (checked: boolean) => void, nullValue: boolean, allSelected: boolean, updateSelect: (values: any) => void, values: any, hideNA?: boolean }) {
    const { children, ...restPaperProps } = paperProps;
    return (
        <Paper >
            <Box
                display={'flex'}
                flexDirection={'column'}
                onMouseDown={(e) => e.preventDefault()} // prevent blur
                pl={1.5}
                py={0.5}
            >
                <FormControlLabel

                    label={t('ownerSelection:selectAll')}
                    control={
                        <Checkbox
                            defaultChecked={allSelected}
                            onChange={(_, checked) => {
                                updateSelect(!checked ? [] : values)
                                if (!hideNA) {
                                    console.log({ hideNA });

                                    updateNullValue(checked)
                                }

                            }}
                            id="select-all-checkbox" />
                    }
                />
                {
                    !hideNA && <FormControlLabel
                        label="N/A"
                        control={
                            <Checkbox
                                onChange={(_, checked) => {
                                    updateNullValue(checked)
                                }}
                                defaultChecked={nullValue}
                            />
                        }
                    />
                }

            </Box>
            <Divider />
            {children}
        </Paper>
    )
}

export default Settings