import { Box, Typography } from '@mui/material'
import React from 'react'
import { DetailInfoProps } from './MapSection'
import { t } from 'i18next'

function MapDetailCard({ detail }: { detail: DetailInfoProps }) {
    return (
        <Box display={'flex'} gap={2} alignItems={'center'}>
            <Box position={'relative'} width={75} height={75} borderRadius={'50%'} bgcolor={"var(--lightGray)"} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <div className="icon">
                    <span className={detail.icon}>
                        <span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span></span>
                </div>
            </Box>
            <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} alignItems={'flex-start'}>
                <Typography fontWeight={500} variant='h5'>{detail?.value ?? '-'}</Typography>
                <Typography variant='caption' fontWeight={400} color={'GrayText'}>{t(detail.title)}</Typography>
            </Box>
        </Box>
    )
}

export default MapDetailCard