import React from 'react'
function Icon({ name }: { name: string }) {
    return (
        <div style={{ height: '70px' }}>
            <span className={`eq-icon-2 eq-${name} `}>
                <span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span></span>
        </div>
    )
}

export default Icon